import { gql } from "@apollo/client";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CiUndo } from "react-icons/ci";
import { Button } from "swash/Button";
import { Card } from "swash/Card";
import {
  EllipsisVertical,
  IoCheckmark,
  IoPencil,
  IoTrash,
  LinkTilted,
} from "swash/Icon";
import { Loader } from "swash/Loader";
import { Menu, MenuButton, MenuItem, useMenuStore } from "swash/Menu";
import { Tooltip } from "swash/Tooltip";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useClipboard } from "use-clipboard-copy";

import { useSearchParams } from "@/components/SearchParams";
import { Time } from "@/components/Time";
import { useEnhancedState } from "@/components/rich-editor/utils/useEnhancedState";
import { useSafeMutation } from "@/containers/Apollo";
import { Comment as DisplayComment } from "@/containers/Comment";
import { useCommentScope } from "@/containers/article/panels/comments/CommentScopeProvider";
import { useArticleId } from "@/containers/routes/article/ArticleContext";
import { UserAvatar } from "@/containers/user/UserAvatar";
import { UserHoverCardTooltip } from "@/containers/user/UserHoverCard";

import {
  ArticleCommentFormFragment,
  CreateArticleCommentForm,
  UpdateArticleCommentForm,
} from "./ArticleCommentForm";

const ActionsButton = ({ menu }) => {
  const open = menu.useState("open");
  return (
    <Tooltip tooltip={open ? null : "Plus d’actions"} placement="top">
      <MenuButton asChild store={menu}>
        <Button
          appearance="text"
          variant="secondary"
          aria-label="Actions"
          scale="xs"
          iconOnly
        >
          <EllipsisVertical />
        </Button>
      </MenuButton>
    </Tooltip>
  );
};

const ActionsMenu = ({ menu, setReadOnly, setDeletingComment, comment }) => {
  const clipboard = useClipboard();
  const articleId = useArticleId();

  const refs = useRef({ articleId, comment, clipboard });

  const copyLink = useCallback(() => {
    const { articleId, comment, clipboard } = refs.current;
    clipboard.copy(
      `${window.location.origin}/articles/${articleId}/collaborative/${
        comment.scope === "notes" ? "notes" : "comments"
      }?commentIds[]=${comment.id}`,
    );
  }, []);

  return (
    <Menu store={menu} aria-label="Actions">
      <MenuItem
        scale="sm"
        onClick={() => {
          setReadOnly(false);
          menu.hide();
        }}
      >
        <IoPencil />
        Modifier
      </MenuItem>
      <MenuItem scale="sm" onClick={copyLink}>
        <LinkTilted />
        Copier le lien
      </MenuItem>
      <MenuItem
        scale="sm"
        variant="danger"
        onClick={() => {
          setDeletingComment(comment);
          menu.hide();
        }}
      >
        <IoTrash />
        Supprimer
      </MenuItem>
    </Menu>
  );
};

const DisplayAnchorText = ({ comment }) => {
  const scope = useCommentScope();
  if (scope === "notes" || comment.parentId) return null;
  const { anchorText } = comment;
  return (
    <span className="flex rounded-l-lg rounded-r-md bg-orange-bg pl-1 text-sm group-data-[resolved]:bg-grey-border">
      <p className="flex-1 rounded-md bg-orange-bg-light px-2 py-1 line-clamp-3 group-data-[resolved]:bg-grey-bg group-data-[focused]:bg-transparent">
        {anchorText ? anchorText : <Loader />}
      </p>
    </span>
  );
};

const ToggleArticleCommentMutation = gql`
  mutation ArticleCommentForm_updateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      ... on CommentThread {
        ...ArticleCommentForm_commentThread
      }
    }
  }
  ${ArticleCommentFormFragment}
`;

const ResolveCommentToggleAction = ({ comment }) => {
  const [, setSearchParams] = useSearchParams();
  const commentRef = useRef(comment);
  const [updateComment, { loading }] = useSafeMutation(
    ToggleArticleCommentMutation,
    { skip: comment.parentId },
  );
  const [resolved, setResolved] = useEnhancedState(
    comment.resolved,
    (nextState) => {
      updateComment({
        variables: {
          input: {
            id: comment.id,
            resolved: nextState,
          },
        },
      });
    },
  );
  if (comment.parentId || loading) return null;
  if (resolved) {
    return (
      <Tooltip tooltip="Restaurer" placement="top">
        <Button
          appearance="text"
          variant="secondary"
          aria-label="Restaurer"
          scale="xs"
          iconOnly
          onClick={() => {
            setResolved(false);
            setSearchParams({ commentIds: [commentRef.current.id] });
          }}
        >
          <CiUndo />
        </Button>
      </Tooltip>
    );
  }
  return (
    <Tooltip tooltip="Résoudre" placement="top">
      <Button
        appearance="text"
        variant="secondary"
        aria-label="Résoudre"
        scale="xs"
        iconOnly
        onClick={() => {
          setResolved(true);
          setSearchParams({ commentIds: [commentRef.current.id] });
        }}
      >
        <IoCheckmark />
      </Button>
    </Tooltip>
  );
};

export const useArticleCommentResolving = ({
  resolvedComments,
  setSelectedId,
}) => {
  const [searchParams] = useSearchParams();
  const commentIds = useMemo(
    () => searchParams.commentIds ?? [],
    [searchParams.commentIds],
  );
  const isCommentResolved = useMemo(
    () =>
      Boolean(
        commentIds.length &&
          resolvedComments.some(({ id }) => id === commentIds[0]),
      ),
    [commentIds, resolvedComments],
  );
  useEffect(() => {
    if (isCommentResolved) return;
    setSelectedId("opened");
  }, [isCommentResolved, setSelectedId]);
  return isCommentResolved;
};

const Comment = ({ comment, setDeletingComment }) => {
  const { user, mine, date } = comment;
  const menu = useMenuStore();
  const [readOnly, setReadOnly] = useState(true);

  const userDenomination = `${user.firstNameInitials} ${user.lastName}`;
  return (
    <div className="group flex w-full flex-col gap-y-2">
      <div className="flex items-center gap-2 text-sm leading-6">
        <UserHoverCardTooltip user={user}>
          <div className="flex items-center gap-2">
            <UserAvatar user={user} size={18} />
            <span className="text-sm leading-6">
              <span className="font-semibold">{userDenomination}</span>
            </span>
          </div>
        </UserHoverCardTooltip>
        <span className="text-grey-on before:mr-2 before:content-['•']">
          <Time date={date}>{moment(date).format("DD/MM/YYYY à HH:mm")}</Time>
        </span>
        <div className="ml-auto opacity-0 transition-opacity group-hover:opacity-100 aria-expanded:opacity-100">
          <ResolveCommentToggleAction comment={comment} />

          {mine && (
            <>
              <ActionsButton menu={menu} />
              <ActionsMenu
                menu={menu}
                comment={comment}
                setDeletingComment={setDeletingComment}
                setReadOnly={setReadOnly}
              />
            </>
          )}
        </div>
      </div>
      <DisplayAnchorText comment={comment} />
      {readOnly ? (
        <DisplayComment comment={comment} />
      ) : (
        <UpdateArticleCommentForm
          comment={comment}
          onSubmit={() => {
            setReadOnly(true);
          }}
          onDiscard={() => setReadOnly(true)}
        />
      )}
    </div>
  );
};

const wordings = {
  notes: {
    label: "Réponses à la consigne",
    placeholder: "Répondre à la consigne...",
  },
  text: {
    label: "Réponses au commentaire",
    placeholder: "Répondre au commentaire...",
  },
};

const ArticleCommentResponseList = ({
  responses,
  setDeletingComment,
  article,
  queryOptions,
  parentId,
  comment,
}) => {
  const scope = useCommentScope();

  if (!responses) {
    return (
      <div className="flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  const { label, placeholder } = wordings[scope] ?? wordings.text;

  return (
    <>
      {responses.length ? (
        <div role="list" aria-label={label} className="flex flex-col gap-4">
          {responses.map((response) => (
            <div
              key={response.globalId}
              role="listitem"
              className="flex w-full flex-col items-start px-4 last:pb-4"
            >
              <Comment
                comment={response}
                setDeletingComment={setDeletingComment}
              />
            </div>
          ))}
        </div>
      ) : null}
      {!comment.resolved && (
        <CreateArticleCommentForm
          article={article}
          queryOptions={queryOptions}
          parentId={parentId}
          confirmMessage="Répondre"
          placeholder={placeholder}
          type={comment.type}
          showFormButtons={false}
          className={clsx(!responses.length && "pt-4")}
        />
      )}
    </>
  );
};

export const ArticleComment = ({
  comment,
  setDeletingComment,
  article,
  queryOptions,
}) => {
  const { responses } = comment;
  const commentRef = useRef(comment);
  const ref = useRef();
  const scope = useCommentScope();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsRef = useLiveRef(searchParams);
  const manual = useRef(false);
  const isCommentFocus = useMemo(() => {
    const searchCommentParams = searchParams.commentIds ?? [];
    return searchCommentParams.some((id) => id === commentRef.current.id);
  }, [searchParams.commentIds]);

  const isComment = scope === "text";
  const isCommentRef = useRef(isComment);

  const scrollToCommentCard = useCallback(
    /**
     * @param {ScrollIntoViewOptions} [options]
     */
    (options) => {
      if (ref.current && isCommentFocus) {
        ref.current?.scrollIntoView({ behavior: "auto", ...options });
      }
    },
    [isCommentFocus],
  );

  useEffect(() => {
    scrollToCommentCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!manual.current) {
      scrollToCommentCard({ behavior: "smooth" });
    }
    if (manual.current) {
      manual.current = false;
    }
  }, [scrollToCommentCard]);

  const handleFocus = useCallback(() => {
    if (!isCommentRef.current || commentRef.current.resolved) return;
    // prevent scroll
    manual.current = true;
    if (!searchParamsRef.current.commentIds?.includes(commentRef.current.id)) {
      setSearchParams({ commentIds: [commentRef.current.id] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchParams]);

  const handleBlur = useCallback(
    (event) => {
      if (!isCommentRef.current) return;
      if (event.currentTarget.contains(event.relatedTarget)) {
        return;
      }
      setSearchParams(null);
    },
    [setSearchParams],
  );

  return (
    <Card
      ref={ref}
      tabIndex={0}
      data-focused={isCommentFocus ? "" : undefined}
      data-resolved={comment.resolved ? "" : undefined}
      className={clsx(
        "group flex w-full flex-col overflow-hidden",
        isComment && !comment.resolved && "cursor-pointer",
      )}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      <div
        className={clsx(
          "group p-4",
          scope === "text" &&
            "group-data-[focused]:bg-orange-bg-light group-data-[resolved]:group-data-[focused]:bg-grey-bg-light",
        )}
      >
        <Comment comment={comment} setDeletingComment={setDeletingComment} />
      </div>
      <ArticleCommentResponseList
        responses={responses.nodes}
        setDeletingComment={setDeletingComment}
        article={article}
        queryOptions={queryOptions}
        parentId={comment.id}
        comment={comment}
      />
    </Card>
  );
};

ArticleComment.fragments = {
  article: gql`
    fragment ArticleComment_article on Article {
      globalId
      notes(where: { resolved: false }) {
        nodes {
          id
          ...ArticleCommentForm_commentThread
        }
        totalCount
      }
      comments(where: { resolved: false }) {
        nodes {
          id
          ...ArticleCommentForm_commentThread
        }
        totalCount
      }
    }
    ${ArticleCommentFormFragment}
  `,
};
