import { forwardRef } from "react";

const SvgWordpress = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m15.019 18.652 3.047-8.848c.508-1.25.762-2.441.762-3.574 0-.391-.02-.736-.059-1.035A9.817 9.817 0 0 1 20 9.98c0 1.823-.45 3.503-1.348 5.04a10.12 10.12 0 0 1-3.633 3.633zm-7.851.937 3.008-8.711 3.066 8.399a.855.855 0 0 0 .078.156c-1.093.378-2.2.567-3.32.567-.951 0-1.895-.137-2.832-.411zM1.64 4.511a9.888 9.888 0 0 1 3.575-3.3A9.789 9.789 0 0 1 10 0a9.883 9.883 0 0 1 6.738 2.617h-.117c-.482 0-.879.176-1.191.528a1.75 1.75 0 0 0-.488 1.23c0 .091.006.183.019.274a.68.68 0 0 0 .059.274c.026.104.045.188.058.253s.052.15.118.254c.052.117.084.202.097.254.027.039.079.124.157.254l.137.253.351.547c.157.287.28.534.372.743.104.208.201.501.293.879.091.364.137.742.137 1.133 0 .247-.026.534-.078.859-.066.325-.137.625-.216.899-.078.26-.195.644-.351 1.152a2.532 2.532 0 0 0-.098.313l-.996 3.34-3.613-10.743 1.133-.097c.169-.013.28-.091.332-.235.065-.143.058-.28-.02-.41s-.201-.195-.371-.195a32.93 32.93 0 0 1-2.656.137c-.3 0-.671-.007-1.113-.02-.43-.026-.794-.052-1.094-.078l-.43-.019a.408.408 0 0 0-.391.175.458.458 0 0 0-.039.41c.065.144.182.222.352.235.339.039.69.065 1.055.078l1.543 4.277-2.168 6.563L3.868 5.313l1.133-.097c.17-.013.287-.091.352-.235.066-.143.059-.28-.019-.41s-.202-.195-.371-.195a33.078 33.078 0 0 1-2.676.137h-.644zM0 10c0-1.433.286-2.787.859-4.063l4.766 13.047c-1.693-.821-3.054-2.038-4.082-3.653C.514 13.73 0 11.952 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWordpress);
export default ForwardRef;
