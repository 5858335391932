import { forwardRef } from "react";

const SvgChartPie = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m10.516 10.378 8.43-2.533c.704 2.317.476 4.555-.683 6.712zM7.022.375a9.276 9.276 0 0 1 3.476-.333c1.158.11 2.237.437 3.235.98s1.874 1.27 2.626 2.182a8.777 8.777 0 0 1 1.608 3.05L9.554 8.789zM.05 10.378A8.84 8.84 0 0 1 1.067 7.03a8.575 8.575 0 0 1 2.182-2.644 8.782 8.782 0 0 1 3.014-1.608l2.515 8.45 7.747 4.179a8.356 8.356 0 0 1-3.106 3.272 8.909 8.909 0 0 1-4.327 1.313c-1.553.062-3.04-.277-4.456-1.016a8.543 8.543 0 0 1-2.663-2.22 8.625 8.625 0 0 1-1.61-3.013c-.331-1.048-.436-2.17-.313-3.365z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChartPie);
export default ForwardRef;
