import { forwardRef } from "react";

const SvgCameraOff = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M18.563 6.188h-2.536c-.129 0-.288-.084-.413-.215l-1.115-1.76a.672.672 0 0 0-.059-.079c-.384-.449-.905-.697-1.463-.697H9.023c-.558 0-1.079.248-1.464.697a.668.668 0 0 0-.058.08L6.386 5.975c-.095.104-.23.215-.37.215v-.344a.688.688 0 0 0-.688-.687H4.297a.688.688 0 0 0-.688.687v.344h-.171a2.065 2.065 0 0 0-2.063 2.063V16.5a2.065 2.065 0 0 0 2.063 2.063h15.124a2.065 2.065 0 0 0 2.063-2.063V8.25a2.065 2.065 0 0 0-2.063-2.063ZM11 15.813a4.125 4.125 0 1 1 0-8.25 4.125 4.125 0 0 1 0 8.25Z"
      fill="#F9FAFB"
    />
    <path
      d="M11 14.438a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5Z"
      fill="#F9FAFB"
    />
    <path
      d="M18.837 20.688a1.437 1.437 0 0 0 1.019-2.451L4.136 2.424a1.438 1.438 0 0 0-2.039 2.027l15.72 15.813a1.436 1.436 0 0 0 1.02.424Z"
      fill="#F9FAFB"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCameraOff);
export default ForwardRef;
