
      export default {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "AbstractWorkflowState",
        "possibleTypes": [
          {
            "name": "ExternalWorkflowState"
          },
          {
            "name": "RichArticleState"
          },
          {
            "name": "WorkflowState"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ActivityItem",
        "possibleTypes": [
          {
            "name": "AuditTrail"
          },
          {
            "name": "CommentResponse"
          },
          {
            "name": "CommentThread"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "AlertChannel",
        "possibleTypes": [
          {
            "name": "EmailAlertChannel"
          },
          {
            "name": "FacebookAlertChannel"
          },
          {
            "name": "PushAlertChannel"
          },
          {
            "name": "TwitterAlertChannel"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ArticleBase",
        "possibleTypes": [
          {
            "name": "Article"
          },
          {
            "name": "ArticleRevision"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ArticleEvent",
        "possibleTypes": [
          {
            "name": "ExposureArticleEvent"
          },
          {
            "name": "GenericArticleEvent"
          },
          {
            "name": "PublicationArticleEvent"
          },
          {
            "name": "ScheduleArticleEvent"
          },
          {
            "name": "WorkflowArticleEvent"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ArticleHistoryItem",
        "possibleTypes": [
          {
            "name": "Event"
          },
          {
            "name": "ExposureArticleEvent"
          },
          {
            "name": "GenericArticleEvent"
          },
          {
            "name": "PublicationArticleEvent"
          },
          {
            "name": "ScheduleArticleEvent"
          },
          {
            "name": "WorkflowArticleEvent"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ArticleMediaBase",
        "possibleTypes": [
          {
            "name": "ArticleMedia"
          },
          {
            "name": "ArticleMediaRevision"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Comment",
        "possibleTypes": [
          {
            "name": "CommentNotification"
          },
          {
            "name": "CommentResponse"
          },
          {
            "name": "CommentThread"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CommentMentionData",
        "possibleTypes": [
          {
            "name": "User"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Connection",
        "possibleTypes": [
          {
            "name": "ActivityConnection"
          },
          {
            "name": "AlertConnection"
          },
          {
            "name": "AppConnection"
          },
          {
            "name": "AppDeliveryConnection"
          },
          {
            "name": "ArticleConnection"
          },
          {
            "name": "ArticleEventConnection"
          },
          {
            "name": "ArticleHistoryConnection"
          },
          {
            "name": "ArticleMediaConnection"
          },
          {
            "name": "ArticleMediaRevisionConnection"
          },
          {
            "name": "ArticleRevisionConnection"
          },
          {
            "name": "ArticleTeaserConnection"
          },
          {
            "name": "ArticleTeaserContextConnection"
          },
          {
            "name": "AspectRatioConnection"
          },
          {
            "name": "AudioConnection"
          },
          {
            "name": "AuditTrailConnection"
          },
          {
            "name": "AuthorConnection"
          },
          {
            "name": "BlacklistedContributorConnection"
          },
          {
            "name": "BlockTemplateConnection"
          },
          {
            "name": "BlogConnection"
          },
          {
            "name": "CampaignConnection"
          },
          {
            "name": "CommentResponseConnection"
          },
          {
            "name": "CommentThreadConnection"
          },
          {
            "name": "CustomFieldConnection"
          },
          {
            "name": "CustomTypeConnection"
          },
          {
            "name": "CustomTypeContentConnection"
          },
          {
            "name": "EditorialFormatConnection"
          },
          {
            "name": "EditorialTypeConnection"
          },
          {
            "name": "EmojiConnection"
          },
          {
            "name": "EventConnection"
          },
          {
            "name": "EventDispatcherConnection"
          },
          {
            "name": "ExportTaskConnection"
          },
          {
            "name": "ExposureConnection"
          },
          {
            "name": "ExternalWorkFlowStateConnection"
          },
          {
            "name": "FlagConnection"
          },
          {
            "name": "FtpImportItemConnection"
          },
          {
            "name": "ImageAgencyConnection"
          },
          {
            "name": "ImageConnection"
          },
          {
            "name": "KeywordConnection"
          },
          {
            "name": "LayoutConnection"
          },
          {
            "name": "LayoutEditorialTypeConnection"
          },
          {
            "name": "LexemeConnection"
          },
          {
            "name": "LiveConnection"
          },
          {
            "name": "LiveInputConnection"
          },
          {
            "name": "LiveTagConnection"
          },
          {
            "name": "MediaDescriptorConnection"
          },
          {
            "name": "MediaPublicationConnection"
          },
          {
            "name": "MessageConnection"
          },
          {
            "name": "MixModelConnection"
          },
          {
            "name": "NewsConnection"
          },
          {
            "name": "NewsletterConnection"
          },
          {
            "name": "NewsletterDeliveryConnection"
          },
          {
            "name": "NotificationConnection"
          },
          {
            "name": "PeriodicalConnection"
          },
          {
            "name": "PeriodicalReleaseConnection"
          },
          {
            "name": "PeriodicalSectionConnection"
          },
          {
            "name": "PeriodicalSectionLayoutConnection"
          },
          {
            "name": "PostConnection"
          },
          {
            "name": "PredicateConnection"
          },
          {
            "name": "PreviewConnection"
          },
          {
            "name": "PricingTierConnection"
          },
          {
            "name": "ProductConnection"
          },
          {
            "name": "PublicationConnection"
          },
          {
            "name": "RoleConnection"
          },
          {
            "name": "SectionConnection"
          },
          {
            "name": "SnippetConnection"
          },
          {
            "name": "SourceConnection"
          },
          {
            "name": "StagingAreaTypeConnection"
          },
          {
            "name": "TagConnection"
          },
          {
            "name": "TaskConnection"
          },
          {
            "name": "UserActivityConnection"
          },
          {
            "name": "UserAuthTokenConnection"
          },
          {
            "name": "UserConnection"
          },
          {
            "name": "UserDeviceConnection"
          },
          {
            "name": "UserEventConnection"
          },
          {
            "name": "WorkflowConnection"
          },
          {
            "name": "WorkflowStateConnection"
          },
          {
            "name": "WorkflowStateTemplateConnection"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CursorConnection",
        "possibleTypes": [
          {
            "name": "AggregatedRevisionCursorConnection"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CustomFieldConfig",
        "possibleTypes": [
          {
            "name": "ArticleCustomFieldConfig"
          },
          {
            "name": "BooleanCustomFieldConfig"
          },
          {
            "name": "DateCustomFieldConfig"
          },
          {
            "name": "FileCustomFieldConfig"
          },
          {
            "name": "ImageCustomFieldConfig"
          },
          {
            "name": "RichTextCustomFieldConfig"
          },
          {
            "name": "TextCustomFieldConfig"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "EditorNode",
        "possibleTypes": [
          {
            "name": "EditorNodeArchive"
          },
          {
            "name": "EditorNodeArticle"
          },
          {
            "name": "EditorNodeArticleMedia"
          },
          {
            "name": "EditorNodeContribution"
          },
          {
            "name": "EditorNodeCustomTypesContent"
          },
          {
            "name": "EditorNodeImage"
          },
          {
            "name": "EditorNodeProduct"
          },
          {
            "name": "EditorNodeProductSummary"
          },
          {
            "name": "EditorNodeSeparator"
          },
          {
            "name": "EditorNodeSnippet"
          },
          {
            "name": "EditorNodeText"
          },
          {
            "name": "EditorNodeTweet"
          },
          {
            "name": "EditorNodeVideo"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "EventResource",
        "possibleTypes": [
          {
            "name": "Alert"
          },
          {
            "name": "Article"
          },
          {
            "name": "Author"
          },
          {
            "name": "Blog"
          },
          {
            "name": "Campaign"
          },
          {
            "name": "CampaignStagingArea"
          },
          {
            "name": "Contribution"
          },
          {
            "name": "CustomTypeContent"
          },
          {
            "name": "EditorialType"
          },
          {
            "name": "FtpImportItem"
          },
          {
            "name": "GenericPublication"
          },
          {
            "name": "Live"
          },
          {
            "name": "Message"
          },
          {
            "name": "Newsletter"
          },
          {
            "name": "NewsletterPublication"
          },
          {
            "name": "PeriodicalPublication"
          },
          {
            "name": "PeriodicalRelease"
          },
          {
            "name": "PeriodicalStagingArea"
          },
          {
            "name": "Post"
          },
          {
            "name": "PublicationStagingArea"
          },
          {
            "name": "Snippet"
          },
          {
            "name": "Source"
          },
          {
            "name": "Tag"
          },
          {
            "name": "TagStagingArea"
          },
          {
            "name": "TagTemplateStagingArea"
          },
          {
            "name": "Task"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "GenericTag",
        "possibleTypes": [
          {
            "name": "Blog"
          },
          {
            "name": "Campaign"
          },
          {
            "name": "Tag"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "GenericTask",
        "possibleTypes": [
          {
            "name": "Message"
          },
          {
            "name": "Task"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Integration",
        "possibleTypes": [
          {
            "name": "AccengageIntegration"
          },
          {
            "name": "BatchIntegration"
          },
          {
            "name": "CheetahIntegration"
          },
          {
            "name": "DailymotionIntegration"
          },
          {
            "name": "FacebookIntegration"
          },
          {
            "name": "ForecastIntegration"
          },
          {
            "name": "FtpIntegration"
          },
          {
            "name": "GcpIntegration"
          },
          {
            "name": "GrammarlyIntegration"
          },
          {
            "name": "MailchimpIntegration"
          },
          {
            "name": "OnesignalIntegration"
          },
          {
            "name": "ProlexisIntegration"
          },
          {
            "name": "ScribbleIntegration"
          },
          {
            "name": "SelligentIntegration"
          },
          {
            "name": "SlackIntegration"
          },
          {
            "name": "TwipeIntegration"
          },
          {
            "name": "TwitterIntegration"
          },
          {
            "name": "WebhookIntegration"
          },
          {
            "name": "YoutubeIntegration"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "IntegrationConfig",
        "possibleTypes": [
          {
            "name": "AccengageIntegrationConfig"
          },
          {
            "name": "BatchIntegrationConfig"
          },
          {
            "name": "CheetahIntegrationConfig"
          },
          {
            "name": "DailymotionIntegrationConfig"
          },
          {
            "name": "FacebookIntegrationConfig"
          },
          {
            "name": "ForecastIntegrationConfig"
          },
          {
            "name": "FtpIntegrationConfig"
          },
          {
            "name": "GcpIntegrationConfig"
          },
          {
            "name": "GrammarlyIntegrationConfig"
          },
          {
            "name": "MailchimpIntegrationConfig"
          },
          {
            "name": "OnesignalIntegrationConfig"
          },
          {
            "name": "ProlexisIntegrationConfig"
          },
          {
            "name": "ScribbleIntegrationConfig"
          },
          {
            "name": "SelligentIntegrationConfig"
          },
          {
            "name": "SlackIntegrationConfig"
          },
          {
            "name": "TwipeIntegrationConfig"
          },
          {
            "name": "TwitterIntegrationConfig"
          },
          {
            "name": "WebhookIntegrationConfig"
          },
          {
            "name": "YoutubeIntegrationConfig"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "LiveInput",
        "possibleTypes": [
          {
            "name": "ContributionLiveInput"
          },
          {
            "name": "TweetLiveInput"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Media",
        "possibleTypes": [
          {
            "name": "Archive"
          },
          {
            "name": "Article"
          },
          {
            "name": "CustomTypeContent"
          },
          {
            "name": "Image"
          },
          {
            "name": "Product"
          },
          {
            "name": "ProductSummary"
          },
          {
            "name": "Snippet"
          },
          {
            "name": "Tweet"
          },
          {
            "name": "Video"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "NewsletterProviderConfig",
        "possibleTypes": [
          {
            "name": "CheetahNewsletterProviderConfig"
          },
          {
            "name": "MailchimpNewsletterProviderConfig"
          },
          {
            "name": "SelligentNewsletterProviderConfig"
          },
          {
            "name": "WebhookNewsletterProviderConfig"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Node",
        "possibleTypes": [
          {
            "name": "AccengageIntegration"
          },
          {
            "name": "Alert"
          },
          {
            "name": "App"
          },
          {
            "name": "AppDelivery"
          },
          {
            "name": "Archive"
          },
          {
            "name": "Article"
          },
          {
            "name": "ArticleAlternateVersion"
          },
          {
            "name": "ArticleMedia"
          },
          {
            "name": "ArticleMediaCollection"
          },
          {
            "name": "ArticleMediaEvent"
          },
          {
            "name": "ArticleMediaRevision"
          },
          {
            "name": "ArticleRelatedContent"
          },
          {
            "name": "ArticleRevision"
          },
          {
            "name": "ArticleSchedule"
          },
          {
            "name": "ArticleSpeech"
          },
          {
            "name": "ArticleTeaser"
          },
          {
            "name": "ArticleTeaserContext"
          },
          {
            "name": "AspectRatio"
          },
          {
            "name": "Audio"
          },
          {
            "name": "AuditTrail"
          },
          {
            "name": "Author"
          },
          {
            "name": "BatchIntegration"
          },
          {
            "name": "BlacklistedContributor"
          },
          {
            "name": "BlockTemplate"
          },
          {
            "name": "Blog"
          },
          {
            "name": "Campaign"
          },
          {
            "name": "CampaignStagingArea"
          },
          {
            "name": "CheetahIntegration"
          },
          {
            "name": "CommentNotification"
          },
          {
            "name": "CommentResponse"
          },
          {
            "name": "CommentThread"
          },
          {
            "name": "Contribution"
          },
          {
            "name": "ContributionLiveInput"
          },
          {
            "name": "CustomField"
          },
          {
            "name": "CustomType"
          },
          {
            "name": "CustomTypeContent"
          },
          {
            "name": "DailymotionIntegration"
          },
          {
            "name": "EditorNodeArchive"
          },
          {
            "name": "EditorNodeArticle"
          },
          {
            "name": "EditorNodeArticleMedia"
          },
          {
            "name": "EditorNodeContribution"
          },
          {
            "name": "EditorNodeCustomTypesContent"
          },
          {
            "name": "EditorNodeImage"
          },
          {
            "name": "EditorNodeProduct"
          },
          {
            "name": "EditorNodeProductSummary"
          },
          {
            "name": "EditorNodeSeparator"
          },
          {
            "name": "EditorNodeSnippet"
          },
          {
            "name": "EditorNodeText"
          },
          {
            "name": "EditorNodeTweet"
          },
          {
            "name": "EditorNodeVideo"
          },
          {
            "name": "EditorialFormat"
          },
          {
            "name": "EditorialType"
          },
          {
            "name": "Emoji"
          },
          {
            "name": "Event"
          },
          {
            "name": "EventDispatcher"
          },
          {
            "name": "ExportTask"
          },
          {
            "name": "Exposure"
          },
          {
            "name": "ExposureArticleEvent"
          },
          {
            "name": "ExternalWorkflowState"
          },
          {
            "name": "FacebookIntegration"
          },
          {
            "name": "File"
          },
          {
            "name": "Flag"
          },
          {
            "name": "ForecastIntegration"
          },
          {
            "name": "FtpImportItem"
          },
          {
            "name": "FtpIntegration"
          },
          {
            "name": "GcpIntegration"
          },
          {
            "name": "GenericArticleEvent"
          },
          {
            "name": "GenericPublication"
          },
          {
            "name": "GrammarlyIntegration"
          },
          {
            "name": "Image"
          },
          {
            "name": "ImageAgency"
          },
          {
            "name": "Keyword"
          },
          {
            "name": "Layout"
          },
          {
            "name": "LayoutEditorialType"
          },
          {
            "name": "Lexeme"
          },
          {
            "name": "Live"
          },
          {
            "name": "LiveTag"
          },
          {
            "name": "MailchimpIntegration"
          },
          {
            "name": "MediaDescriptor"
          },
          {
            "name": "MediaPublication"
          },
          {
            "name": "Message"
          },
          {
            "name": "MixModel"
          },
          {
            "name": "News"
          },
          {
            "name": "Newsletter"
          },
          {
            "name": "NewsletterDelivery"
          },
          {
            "name": "NewsletterPublication"
          },
          {
            "name": "Notification"
          },
          {
            "name": "OnesignalIntegration"
          },
          {
            "name": "Periodical"
          },
          {
            "name": "PeriodicalPublication"
          },
          {
            "name": "PeriodicalRelease"
          },
          {
            "name": "PeriodicalSection"
          },
          {
            "name": "PeriodicalSectionLayout"
          },
          {
            "name": "PeriodicalStagingArea"
          },
          {
            "name": "Post"
          },
          {
            "name": "Predicate"
          },
          {
            "name": "PredicateConclusion"
          },
          {
            "name": "Preview"
          },
          {
            "name": "PricingTier"
          },
          {
            "name": "Product"
          },
          {
            "name": "ProductSummary"
          },
          {
            "name": "ProlexisIntegration"
          },
          {
            "name": "PublicationArticleEvent"
          },
          {
            "name": "PublicationStagingArea"
          },
          {
            "name": "PushSubscription"
          },
          {
            "name": "RevisionGroup"
          },
          {
            "name": "Role"
          },
          {
            "name": "ScheduleArticleEvent"
          },
          {
            "name": "ScribbleIntegration"
          },
          {
            "name": "Section"
          },
          {
            "name": "SelligentIntegration"
          },
          {
            "name": "SlackIntegration"
          },
          {
            "name": "Snippet"
          },
          {
            "name": "Source"
          },
          {
            "name": "StagingAreaType"
          },
          {
            "name": "Status"
          },
          {
            "name": "Tag"
          },
          {
            "name": "TagStagingArea"
          },
          {
            "name": "TagTemplateStagingArea"
          },
          {
            "name": "Task"
          },
          {
            "name": "Tweet"
          },
          {
            "name": "TweetLiveInput"
          },
          {
            "name": "TwipeIntegration"
          },
          {
            "name": "TwitterIntegration"
          },
          {
            "name": "User"
          },
          {
            "name": "UserActivity"
          },
          {
            "name": "UserAuthToken"
          },
          {
            "name": "UserDevice"
          },
          {
            "name": "Video"
          },
          {
            "name": "WebhookIntegration"
          },
          {
            "name": "Workflow"
          },
          {
            "name": "WorkflowArticleEvent"
          },
          {
            "name": "WorkflowState"
          },
          {
            "name": "WorkflowStateTemplate"
          },
          {
            "name": "YoutubeIntegration"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "NotificationContent",
        "possibleTypes": [
          {
            "name": "Article"
          },
          {
            "name": "ArticleSchedule"
          },
          {
            "name": "ArticleSpeech"
          },
          {
            "name": "CommentNotification"
          },
          {
            "name": "Event"
          },
          {
            "name": "FtpImportItem"
          },
          {
            "name": "IntegrationCheck"
          },
          {
            "name": "Message"
          },
          {
            "name": "News"
          },
          {
            "name": "NewsletterDelivery"
          },
          {
            "name": "NotificationLockContent"
          },
          {
            "name": "Task"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "NotificationLockContentResource",
        "possibleTypes": [
          {
            "name": "Article"
          },
          {
            "name": "Post"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "Publication",
        "possibleTypes": [
          {
            "name": "GenericPublication"
          },
          {
            "name": "NewsletterPublication"
          },
          {
            "name": "PeriodicalPublication"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "StagingArea",
        "possibleTypes": [
          {
            "name": "CampaignStagingArea"
          },
          {
            "name": "PeriodicalStagingArea"
          },
          {
            "name": "PublicationStagingArea"
          },
          {
            "name": "TagStagingArea"
          },
          {
            "name": "TagTemplateStagingArea"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "StagingAreaContent",
        "possibleTypes": [
          {
            "name": "ArticleStagingAreaContent"
          },
          {
            "name": "TagStagingAreaContent"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ThirdPartyService",
        "possibleTypes": [
          {
            "name": "AccengageThirdPartyService"
          },
          {
            "name": "BatchThirdPartyService"
          },
          {
            "name": "CheetahThirdPartyService"
          },
          {
            "name": "DailymotionThirdPartyService"
          },
          {
            "name": "FacebookThirdPartyService"
          },
          {
            "name": "ForecastThirdPartyService"
          },
          {
            "name": "FtpThirdPartyService"
          },
          {
            "name": "GcpThirdPartyService"
          },
          {
            "name": "GrammarlyThirdPartyService"
          },
          {
            "name": "MailchimpThirdPartyService"
          },
          {
            "name": "OnesignalThirdPartyService"
          },
          {
            "name": "ProlexisThirdPartyService"
          },
          {
            "name": "ScribbleThirdPartyService"
          },
          {
            "name": "SelligentThirdPartyService"
          },
          {
            "name": "SlackThirdPartyService"
          },
          {
            "name": "TwipeThirdPartyService"
          },
          {
            "name": "TwitterThirdPartyService"
          },
          {
            "name": "WebhookThirdPartyService"
          },
          {
            "name": "YoutubeThirdPartyService"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "UserEventItem",
        "possibleTypes": [
          {
            "name": "Event"
          },
          {
            "name": "UserActivity"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "UserProfile",
        "possibleTypes": [
          {
            "name": "MessagesUserProfile"
          },
          {
            "name": "TasksUserProfile"
          }
        ]
      }
    ]
  }
}
    