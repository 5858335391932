import * as amplitude from "@amplitude/analytics-browser";
import deepEqual from "deep-equal";
import type { FormApi, Unsubscribe } from "final-form";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import runtimeConfig from "@/config/runtime";

import type { User } from "./User";

type LogEvent = (
  eventType: string,
  eventProperties?: Record<string, any>,
) => void;

type SetUser = (user: User) => void;

type CreateFromDecorator = (
  params: CreateDecoratorParams,
) => (form: FormApi) => Unsubscribe;

type AmplitudeContextTypes = {
  logEvent: LogEvent;
  createFormDecorator: CreateFromDecorator;
  setUser: SetUser;
};

const AmplitudeContext = createContext<AmplitudeContextTypes | null>(null);

export function AmplitudeInitializer({
  children,
}: {
  children: React.ReactNode;
}) {
  const initializedRef = useRef(false);

  const setUser = useCallback((user) => {
    amplitude.setUserId(user.globalId);

    const identify = new amplitude.Identify();
    identify
      .set("mainRole", user.currentRole?.name ?? "")
      .set(
        "roles",
        user.roles.map(({ name }) => name),
      )
      .set("mainService", user.currentSection?.name ?? "")
      .set(
        "services",
        user.sections.map(({ name }) => name),
      )
      .set("screenWidth", window.innerWidth);
    amplitude.identify(identify);

    initializedRef.current = true;
  }, []) as SetUser;

  const logEvent = useCallback((eventType, eventProperties) => {
    if (!initializedRef.current) return;
    amplitude.track(eventType, {
      clientId: runtimeConfig.clientId,
      "location.pathname": window.location.pathname,
      "location.search": window.location.search,
      ...(eventProperties || {}),
    });
  }, []) as LogEvent;

  const createFormDecorator = useCallback(
    (params: CreateDecoratorParams) => createDecorator(logEvent, params),
    [logEvent],
  );

  const value = useMemo(
    () => ({ logEvent, createFormDecorator, setUser }),
    [logEvent, createFormDecorator, setUser],
  );

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
}

export function useAmplitude() {
  const ctx = useContext(AmplitudeContext);
  if (!ctx) return {};
  return {
    logEvent: ctx.logEvent,
    initUser: ctx.setUser,
  };
}

export type useAmplitudeFormDecoratorParams = {
  eventType: string;
  format: CreateDecoratorParams["format"];
};

export function useAmplitudeFormDecorator({
  eventType,
  format,
}: useAmplitudeFormDecoratorParams) {
  const ctx = useContext(AmplitudeContext);
  if (!ctx) {
    throw Error(
      "useAmplitudeFormDecorator requires AmplitudeInitializer to be used higher in the component tree",
    );
  }
  const [decorator] = useState(() =>
    ctx.createFormDecorator({
      eventType,
      format,
    }),
  );
  return decorator;
}

export function LogAmplitudePageView() {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    if (!logEvent) return;
    setTimeout(() => {
      logEvent("pageview");
    });
  }, [logEvent]);
  return null;
}

export type LogAmplitudeErrorDisplayProps = {
  error: Error | null;
  errorType: string;
};

export function LogAmplitudeErrorDisplay({
  error,
  errorType,
}: LogAmplitudeErrorDisplayProps) {
  const { logEvent } = useAmplitude();

  const eventProperties = useMemo(
    () => ({
      "error.type": errorType,
      ...(error?.message && { "error.message": error.message }),
      ...(error?.stack && { "error.stack": error.stack }),
    }),
    [error, errorType],
  );

  useEffect(() => {
    setTimeout(() => {
      if (!logEvent) return;
      logEvent("errorDisplay", eventProperties);
    });
  }, [logEvent, eventProperties]);
  return null;
}

type Filters = Record<string, any>;

function formatFilters(filters: Filters): Record<string, any> {
  return Object.entries(filters).reduce((acc, [key, value]) => {
    if (value === null) return acc;
    if (Array.isArray(value)) return { ...acc, [key]: value };
    if (typeof value !== "object") return { ...acc, [key]: value };
    if (value.in) return { ...acc, [key]: value.in };
    if (value.eq) return { ...acc, [key]: value.eq };
    return { ...acc, ...formatFilters(value) };
  }, {});
}

type CreateDecoratorParams = {
  eventType: string;
  format: (values: Record<string, any>) => Filters;
};

function createDecorator(
  logEvent: LogEvent,
  { eventType, format }: CreateDecoratorParams,
) {
  let previousValues = {};
  return (form: FormApi) => {
    const unsubscribe = form.subscribe(
      ({
        values,
        dirtyFields,
      }: {
        values: Filters;
        dirtyFields: { [key: string]: boolean };
      }) => {
        if (deepEqual(previousValues, values)) return;
        previousValues = values;
        const formattedFilters = formatFilters(
          format ? format(values) : values,
        );
        const eventProperties = Object.entries(formattedFilters).reduce(
          (acc, [key, value]) => {
            return {
              ...acc,
              [`${key}.value`]: value,
              [`${key}.touched`]: Boolean(dirtyFields[key]),
            };
          },
          {},
        );

        logEvent(eventType, eventProperties);
      },
      { values: true, dirtyFields: true },
    );
    return unsubscribe;
  };
}
