import { gql, useApolloClient } from "@apollo/client";
import { memo } from "react";
import {
  AlertBubbleOutline,
  Exposition,
  IoReaderOutline,
  PieChartOutline,
} from "swash/Icon";
import { PanelHeader } from "swash/Panel";
import { Tab, TabList, useTabState } from "swash/Tab";
import { Tooltip } from "swash/Tooltip";

import {
  Article,
  ArticleAuditTrailsProvider,
  getArticleAuditTrailFragment,
} from "@/components/ArticleAuditTrail";
import { useSafeQuery } from "@/containers/Apollo";
import {
  ArticleConfidentialLabel,
  ArticleConfidentialLabelProps,
} from "@/containers/article/ArticleConfidentialLabel";
import {
  ArticleEditorialFormatLabel,
  ArticleEditorialFormatLabelProps,
} from "@/containers/article/ArticleEditorialFormatLabel";
import {
  ArticleEditorialTypeLabel,
  ArticleEditorialTypeLabelProps,
} from "@/containers/article/ArticleEditorialTypeLabel";
import { ArticleIdLabel } from "@/containers/article/ArticleIdLabel";
import {
  ArticleLayoutPricingIndicator,
  ArticleLayoutPricingIndicatorProps,
} from "@/containers/article/ArticleLayoutPricingIndicator";
import {
  ArticleLiveLink,
  ArticleLiveLinkProps,
} from "@/containers/article/ArticleLiveLink";
import {
  ArticlePublishedLink,
  ArticlePublishedLinkProps,
} from "@/containers/article/ArticlePublishedLink";
import {
  ArticleReferenceLabel,
  ArticleReferenceLabelProps,
} from "@/containers/article/ArticleReferenceLabel";
import {
  ArticleTitleLink,
  ArticleTitleLinkProps,
} from "@/containers/article/ArticleTitleLink";
import { ArticleAnalyticsPanel } from "@/containers/article/panels/analytics";
import { ArticleCommentsPanel } from "@/containers/article/panels/comments";
import { ArticleExposurePanel } from "@/containers/article/panels/exposure/ArticleExposurePanel";

import type { PanelInterface } from "../PanelManager";
import { HasPermission } from "../User";
import { ArticlePanel } from "./ArticlePanel";

type ArticlePanelHeaderTabsProps = {
  articleId: number;
};

type ArticlePanelProps = {
  articleId: number;
};

type PanelHooks = {
  activate: (props: ArticlePanelProps) => void;
  checkIsActivated: (props: ArticlePanelProps) => boolean;
};

const usePanelHooks = (
  Panel: PanelInterface<ArticlePanelProps>,
): PanelHooks => {
  const activate = Panel.useActivate();
  const checkIsActivated = Panel.useCheckIsActivated();
  return { activate, checkIsActivated };
};

const ArticlePanelHeaderTabs = memo((props: ArticlePanelHeaderTabsProps) => {
  const panels = {
    [ArticlePanel.key]: usePanelHooks(ArticlePanel),
    [ArticleCommentsPanel.key]: usePanelHooks(ArticleCommentsPanel),
    [ArticleAnalyticsPanel.key]: usePanelHooks(ArticleAnalyticsPanel),
    [ArticleExposurePanel.key]: usePanelHooks(ArticleExposurePanel),
  } as Record<string, PanelHooks>;
  const panelProps: ArticlePanelProps = { articleId: props.articleId };
  const selectedId =
    Object.entries(panels).find(([, panel]) =>
      panel.checkIsActivated(panelProps),
    )?.[0] ?? null;
  const tab = useTabState({
    variant: "bar",
    size: "lg",
    selectedId,
    setSelectedId: (id) => {
      if (id) {
        panels[id]?.activate(panelProps);
      }
    },
  });
  return (
    <TabList state={tab} aria-label="Autour de l’article">
      <Tooltip tooltip="Informations">
        <Tab state={tab} id={ArticlePanel.key}>
          <IoReaderOutline />
        </Tab>
      </Tooltip>
      <Tooltip tooltip="Consignes">
        <Tab state={tab} id={ArticleCommentsPanel.key}>
          <AlertBubbleOutline />
        </Tab>
      </Tooltip>
      <Tooltip tooltip="Exposition">
        <Tab state={tab} id={ArticleExposurePanel.key} aria-label="Exposition">
          <Exposition />
        </Tab>
      </Tooltip>
      <HasPermission
        permission={["stats:full", "stats:exposures"]}
        method="some"
      >
        <Tooltip tooltip="Statistiques">
          <Tab state={tab} id={ArticleAnalyticsPanel.key}>
            <PieChartOutline />
          </Tab>
        </Tooltip>
      </HasPermission>
    </TabList>
  );
});

export type ArticlePanelHeaderProps = {
  article: ArticleTitleLinkProps["article"] &
    ArticlePublishedLinkProps["article"] &
    ArticleLayoutPricingIndicatorProps["article"] &
    ArticleLiveLinkProps["article"] &
    ArticleConfidentialLabelProps["article"] &
    ArticleReferenceLabelProps["article"] &
    ArticleEditorialFormatLabelProps["article"] &
    ArticleEditorialTypeLabelProps["article"] &
    Article;
  onClose: () => void;
};

export const ArticlePanelHeader = ({
  article,
  onClose,
}: ArticlePanelHeaderProps) => {
  return (
    <PanelHeader
      bordered={false}
      title={
        <div className="flex gap-4">
          <span className="mt-px">
            <ArticleLayoutPricingIndicator article={article} />
          </span>
          <ArticleTitleLink
            className="inline-flex leading-5"
            article={article}
          />
        </div>
      }
      onClose={onClose}
    >
      <div className="mt-1 ml-8 flex flex-wrap items-center gap-x-4 gap-y-1">
        <div className="flex flex-wrap items-center font-accent text-xs leading-5 text-grey-on [&>*]:before:px-1.5 [&>*]:before:text-grey-border-light [&>*]:before:content-['|'] [&>*:first-child]:before:hidden">
          <ArticleConfidentialLabel article={article} />
          <ArticleReferenceLabel article={article} />
          <ArticleEditorialFormatLabel article={article} />
          <ArticleEditorialTypeLabel article={article} />
          <span className="flex">
            <ArticleIdLabel article={article} />
          </span>
        </div>
        <div className="-mx-2 flex flex-wrap items-center">
          <ArticlePublishedLink article={article} scale="xs" />
          <ArticleLiveLink article={article} scale="xs" />
        </div>
      </div>
      <div className="-mx-2 mt-4">
        <ArticlePanelHeaderTabs articleId={article.id} />
      </div>
    </PanelHeader>
  );
};

ArticlePanelHeader.fragments = {
  article: gql`
    fragment ArticlePanelHeader_article on Article {
      ...ArticleTitleLink_article
      ...ArticlePublishedLink_article
      ...ArticleLayoutPricingIndicator_article
      ...ArticleLiveLink_article
      ...ArticleConfidentialLabel_article
      ...ArticleReferenceLabel_article
      ...ArticleEditorialFormatLabel_article
      ...ArticleEditorialTypeLabel_article
      ...AuditTrail_ArticlePanelHeader_article
    }
    ${ArticleTitleLink.fragments.article}
    ${ArticlePublishedLink.fragments.article}
    ${ArticleLayoutPricingIndicator.fragments.article}
    ${ArticleLiveLink.fragments.article}
    ${ArticleConfidentialLabel.fragments.article}
    ${ArticleReferenceLabel.fragments.article}
    ${ArticleEditorialTypeLabel.fragments.article}
    ${ArticleEditorialFormatLabel.fragments.article}
    ${getArticleAuditTrailFragment(["editorialFormat"], "ArticlePanelHeader")}
  `,
};

const ArticleQuery = gql`
  query ArticlePanelHeader_article($articleId: Int!) {
    article(id: $articleId) {
      id
      ...ArticlePanelHeader_article
    }
  }
  ${ArticlePanelHeader.fragments.article}
`;

type ArticleQueryVariables = {
  articleId: number;
};

type ArticleQueryData = {
  article: ArticlePanelHeaderProps["article"];
};

export type StandaloneArticlePanelHeaderProps = {
  articleId: number;
  onClose: () => void;
};

export const StandaloneArticlePanelHeader =
  memo<StandaloneArticlePanelHeaderProps>((props) => {
    const client = useApolloClient();
    const article = client.readFragment<ArticlePanelHeaderProps["article"]>({
      id: `Article:${props.articleId}`,
      fragment: ArticlePanelHeader.fragments.article,
      fragmentName: "ArticlePanelHeader_article",
    });
    useSafeQuery<ArticleQueryData, ArticleQueryVariables>(ArticleQuery, {
      variables: { articleId: props.articleId },
      skip: Boolean(article),
    });

    if (!article) return null;

    return (
      <ArticleAuditTrailsProvider article={article}>
        <ArticlePanelHeader article={article} onClose={props.onClose} />
      </ArticleAuditTrailsProvider>
    );
  });
