import { gql, useSubscription } from "@apollo/client";
import { useCallback, useState } from "react";
import { PageLoader } from "swash/Loader";
import { Tab, TabPanel, TabSelectList, useTabState } from "swash/Tab";

import { useSafeQuery } from "@/containers/Apollo";
import { useArticleCommentResolving } from "@/containers/article/panels/comments/ArticleComment";
import {
  ArticleCommentDeleteDialog,
  ArticleCommentFormFragment,
  CreateArticleCommentForm,
} from "@/containers/article/panels/comments/ArticleCommentForm";
import { ArticleCommentList } from "@/containers/article/panels/comments/ArticleCommentList";
import { CommentScopeProvider } from "@/containers/article/panels/comments/CommentScopeProvider";

const ArticleNotesArticleFragment = gql`
  fragment ArticleNotes_article on Article {
    globalId
    notes(where: { resolved: false }) {
      nodes {
        id
        ...ArticleCommentForm_commentThread
      }
      totalCount
    }
    resolvedNotes: notes(where: { resolved: true }) {
      nodes {
        id
        anchorText
        resolved
        resolvedAt
        resolvedBy {
          id
          firstNameInitials
          lastName
        }
        ...ArticleCommentForm_commentThread
      }
      totalCount
    }
  }
  ${ArticleCommentFormFragment}
`;

const ArticleSubscription = gql`
  subscription ArticleNotes_articleUpdated($articleId: Int!) {
    articleUpdated(where: { id: { eq: $articleId } }) {
      id
      ...ArticleNotes_article
    }
  }
  ${ArticleNotesArticleFragment}
`;

const Query = gql`
  query ArticleNotes_article($articleId: Int!) {
    article(id: $articleId) {
      id
      ...ArticleNotes_article
    }
  }
  ${ArticleNotesArticleFragment}
`;

const ArticleNotesContainer = ({ article, queryOptions }) => {
  const [selectedId, setSelectedId] = useState();
  const isCommentResolved = useArticleCommentResolving({
    resolvedComments: article.resolvedNotes.nodes,
    setSelectedId,
  });
  const tab = useTabState({
    selectedId,
    setSelectedId,
    defaultSelectedId: isCommentResolved ? "resolved" : "open",
  });

  const [deletingComment, setDeletingComment] = useState(null);
  const handleCommentDeleteClose = useCallback(() => {
    setDeletingComment(null);
  }, []);

  return (
    <>
      <TabSelectList
        state={tab}
        aria-label="Filtrer les consignes"
        className="px-4 pb-2"
      >
        <Tab state={tab} id="opened" label="Consignes ouvertes">
          Ouvertes
        </Tab>
        <Tab state={tab} id="resolved" label="Consignes résolues">
          Résolues
        </Tab>
      </TabSelectList>
      <TabPanel
        state={tab}
        tabId="opened"
        nude
        className="flex h-full min-h-0 flex-col justify-between"
      >
        <ArticleCommentList
          comments={article.notes.nodes}
          setDeletingComment={setDeletingComment}
          article={article}
          queryOptions={queryOptions}
        />
        <CreateArticleCommentForm
          article={article}
          queryOptions={queryOptions}
          confirmMessage="Ajouter"
          placeholder="Ajouter une nouvelle consigne..."
        />
      </TabPanel>
      <TabPanel
        state={tab}
        tabId="resolved"
        nude
        className="flex h-full min-h-0 flex-col"
      >
        <ArticleCommentList
          comments={article.resolvedNotes.nodes}
          setDeletingComment={setDeletingComment}
          article={article}
          queryOptions={queryOptions}
          resolved
        />
      </TabPanel>

      <ArticleCommentDeleteDialog
        commentId={deletingComment?.id}
        parentId={deletingComment?.parentId}
        queryOptions={queryOptions}
        onClose={handleCommentDeleteClose}
        resolved={deletingComment?.resolved}
      />
    </>
  );
};

export const ArticleNotes = ({ articleId }) => {
  const queryOptions = {
    query: Query,
    variables: { articleId },
  };
  const { data } = useSafeQuery(queryOptions.query, {
    variables: queryOptions.variables,
    skip: !articleId,
  });

  useSubscription(ArticleSubscription, {
    variables: {
      articleId,
    },
    skip: !data,
    onData: ({ client, data: { data } }) => {
      if (!data) return;
      const queryData = client.readQuery(queryOptions);
      if (!queryData) return;
      client.writeQuery({
        ...queryOptions,
        data: {
          ...queryData,
          article: {
            ...queryData.article,
            ...data.articleUpdated,
          },
        },
      });
    },
  });

  if (!data) {
    return <PageLoader />;
  }

  return (
    <CommentScopeProvider scope="notes">
      <ArticleNotesContainer
        article={data.article}
        queryOptions={queryOptions}
      />
    </CommentScopeProvider>
  );
};
