import { Portal } from "ariakit/portal";
import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { PanelBody, PanelFooter } from "swash/Panel";
import { Popover } from "swash/Popover";

import { CorrelateLabel } from "@/components/CorrelateLabel";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { RichEditorTextBoxField } from "@/components/fields/RichEditorTextBoxField";
import { useTextInputField } from "@/components/fields/TextInputField";
import { Form } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { muteEditorEvents } from "@/components/teleporters/EditorBlockCapsule";

const FIELD_NAME_TITLE = "title";
const FIELD_NAME_PREFIX = "prefix";

function TitleField({
  metadataTitle,
  articleTitle,
  onSubmit,
  checkIsCorrelated,
}) {
  const form = useForm();
  const field = useTextInputField(FIELD_NAME_TITLE, {
    parse: (v) => v,
  });
  const {
    input: { value },
  } = field.state.field;

  const onCorrelate = () => {
    if (!metadataTitle) {
      return form.change(FIELD_NAME_TITLE, articleTitle);
    }
    onSubmit({ title: undefined });
  };

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>
        <CorrelateLabel
          onClick={onCorrelate}
          hidden={checkIsCorrelated(value)}
          resourceLabelName="titre"
        >
          Titre de l’article lié
        </CorrelateLabel>
      </FieldLabel>
      <RichEditorTextBoxField {...field} w={1} />
    </FieldGroup>
  );
}

function PrefixField() {
  const field = useTextInputField(FIELD_NAME_PREFIX, {
    required: true,
    parse: (v) => v,
  });

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>Préfixe du lien</FieldLabel>
      <RichEditorTextBoxField {...field} w={1} />
    </FieldGroup>
  );
}

export function EditArticleMetadataPopover({
  onSubmit,
  initialValues,
  metadataTitle,
  articleTitle,
  state,
}) {
  const checkTitleIsCorrelated = (value) =>
    !metadataTitle && value === (articleTitle ?? "");

  return (
    <Portal>
      <Popover
        state={state}
        aria-label="Édition du bloc article"
        style={{ width: 328, zIndex: 8000 }}
        lazy={false}
        {...muteEditorEvents()}
      >
        {state.open ? (
          <Form
            onSubmit={(data) => {
              onSubmit(data, checkTitleIsCorrelated(data.title));
              setTimeout(() => state.hide());
            }}
            initialValues={initialValues}
            aria-label="Édition du bloc article"
          >
            <FormErrorToaster />
            <PanelBody>
              <Fieldset>
                <FieldsetField>
                  <PrefixField />
                </FieldsetField>
                <FieldsetField>
                  <TitleField
                    articleTitle={articleTitle}
                    metadataTitle={metadataTitle}
                    checkIsCorrelated={checkTitleIsCorrelated}
                    onSubmit={onSubmit}
                  />
                </FieldsetField>
              </Fieldset>
            </PanelBody>
            <PanelFooter>
              <Button
                type="button"
                variant="secondary"
                appearance="text"
                onClick={() => state.hide()}
              >
                Annuler
              </Button>
              <FormSubmit>Enregistrer</FormSubmit>
            </PanelFooter>
          </Form>
        ) : null}
      </Popover>
    </Portal>
  );
}
