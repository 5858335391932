import styled from "@xstyled/styled-components";
import { Separator as AriakitSeparator } from "ariakit/separator";
import { forwardRef } from "react";

const InnerSeparator = styled.hrBox`
  border: 0;
  border-top: 1;
  border-color: layout-border;
  margin: 0;
`;

export const Separator = forwardRef((props, ref) => {
  return <AriakitSeparator ref={ref} as={InnerSeparator} {...props} />;
});
