import { forwardRef } from "react";

const SvgLeMonde = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.862 4.239c.014-.363.174-.746.481-1.151a.853.853 0 0 1 .188-.251l.252-.335c.111-.167.237-.334.376-.502l.44.335-.314.502c-.21.307-.252.53-.126.67.125.139.369.146.732.02l1.506-.544c.586-.237 1.082-.314 1.486-.23.404.098.718.272.941.523.21.265.356.599.439 1.004l2.532-1.549 2.155 1.695 2.74-1.695 2.072 1.151c.223.14.418.224.585.251a.948.948 0 0 0 .398 0c.112-.042.286-.118.523-.23l.398-.251.334.544-.857.502c-.405.265-.691.565-.858.9-.153.335-.23.774-.23 1.318v8.18c0 .279.014.474.042.586a.518.518 0 0 0 .272.272l.251.146.753-.397.251.481-2.615 1.611-.92-.628a1.334 1.334 0 0 1-.44-.502c-.097-.181-.146-.432-.146-.753V8.611c0-.642.084-1.158.251-1.549.153-.39.411-.739.774-1.046l.335-.251-2.532-1.444-1.108.691v10.627c0 .419-.056.712-.168.879-.097.168-.348.349-.753.544l-1.485.754h-.168v-11.8c0-.265-.028-.453-.083-.565-.042-.111-.147-.23-.314-.355l-.858-.732-1.13.606v6.109c0 .6-.07 1.088-.21 1.465-.153.362-.418.662-.795.899l-2.113 1.298-.23-.314c.293-.237.502-.523.628-.858.112-.321.167-.739.167-1.255V6.079c0-.502-.062-.83-.188-.983-.125-.14-.411-.182-.857-.126l-1.298.23c-.46.056-.822 0-1.088-.167-.279-.181-.418-.446-.418-.795zM0 17.607a8.7 8.7 0 0 1 2.406-1.883c.935-.502 1.869-.753 2.804-.753 1.046 0 1.883.342 2.51 1.025l.942-.544.314.544-3.013 1.82c-.125-.348-.363-.641-.712-.879-.334-.237-.753-.362-1.255-.377-.572-.028-1.192.07-1.862.294a6.572 6.572 0 0 0-1.883 1.024z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLeMonde);
export default ForwardRef;
