import { forwardRef } from "react";

const SvgPage = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.66 17.969c0 .104.033.188.098.254a.341.341 0 0 0 .254.098h11.797a.344.344 0 0 0 .254-.098.324.324 0 0 0 .097-.234V7.499h-5.47a.321.321 0 0 1-.234-.097.318.318 0 0 1-.117-.254V1.66H4.012a.322.322 0 0 0-.235.098.316.316 0 0 0-.097.233l-.02 15.978Zm-1.231 2.03a.37.37 0 0 1-.293-.135.397.397 0 0 1-.118-.292V4.999L2 3.319V.41c0-.117.039-.214.118-.292A.393.393 0 0 1 2.41 0H12l5.84 5.82v13.752a.4.4 0 0 1-.41.41h-2.91L2.429 20Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPage);
export default ForwardRef;
