import { forwardRef } from "react";

const SvgQuestionCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.353 16.13c0 .115.045.211.134.288a.394.394 0 0 0 .288.115h2.491a.438.438 0 0 0 .287-.115.39.39 0 0 0 .115-.288v-2.452a.431.431 0 0 0-.115-.287.39.39 0 0 0-.287-.115H8.775a.39.39 0 0 0-.403.402zm-2.28-8.391c0 .141.064.211.191.211h2.203c.128 0 .198-.057.211-.172.051-.818.498-1.227 1.341-1.227.294 0 .537.083.728.249.179.166.269.389.269.671 0 .293-.122.568-.364.823a1.636 1.636 0 0 1-.268.23l-.153.115c-.971.703-1.456 1.488-1.456 2.357v.843c0 .025.025.064.077.115.051.051.089.077.115.077h2.107c.128 0 .192-.058.192-.173.012-.511.089-.907.229-1.187.154-.269.428-.537.824-.805.358-.217.62-.396.786-.536.153-.127.287-.268.402-.421.205-.319.307-.818.307-1.495 0-.651-.179-1.219-.537-1.705a3.154 3.154 0 0 0-1.379-1.091 4.631 4.631 0 0 0-1.858-.364c-1.201 0-2.146.3-2.835.9-.69.601-1.066 1.463-1.13 2.587zM0 10c0-1.801.454-3.48 1.361-5.038A9.949 9.949 0 0 1 5 1.322 9.864 9.864 0 0 1 10 0c1.814 0 3.5.447 5.058 1.341a9.887 9.887 0 0 1 3.601 3.621A9.887 9.887 0 0 1 20 10c0 1.826-.447 3.506-1.341 5.038A10.086 10.086 0 0 1 15 18.678C13.481 19.559 11.814 20 10 20c-1.839 0-3.518-.447-5.038-1.341a10.07 10.07 0 0 1-3.64-3.678A9.827 9.827 0 0 1 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgQuestionCircle);
export default ForwardRef;
