import { gql } from "@apollo/client";
import clsx from "clsx";
import { memo, useCallback } from "react";
import { Card } from "swash/Card";
import { IoExpand } from "swash/Icon";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";
import { Checkbox } from "swash/controls/Checkbox";

import { ImageFluidFragment } from "@/components/Image";
import { ImageEditDisclosure } from "@/containers/image/ImageEditDisclosure";

import { useDragUrl } from "../Dnd";
import {
  ImagePreviewDialog,
  ImagePreviewDialogDisclosure,
  useImagePreviewDialogState,
} from "./ImagePreview";
import { ImageSummary } from "./ImageSummary";
import { useImageEditMultiple } from "./image-edit-multiple/ImageEditMultipleContext";

export const ImageCard = ({ image }) => {
  const imageId = image.id;
  const [, dragRef] = useDragUrl(image.siriusUrl);

  return (
    <Card
      ref={dragRef}
      className="group relative flex h-full cursor-grab flex-col overflow-hidden"
      role="listitem"
      data-test-no-radius
    >
      <ImageEditMultipleCheckbox
        imageId={imageId}
        className="absolute top-2 left-2 z-30"
      />
      <ImageEditDisclosure
        imageId={imageId}
        iconOnly
        appearance="fill-light"
        className="absolute top-2 right-2 z-30 opacity-0 group-hover:opacity-100"
      />
      <ImagePreview image={image} />
      <ImageSummary image={image} />
    </Card>
  );
};

const ImageEditMultipleCheckbox = memo(({ imageId, className, ...props }) => {
  const context = useImageEditMultiple();
  const checked = context?.imageIds.includes(imageId);

  const handleChange = useCallback(
    (event) => {
      if (!context) return null;

      const { setImageIds } = context;
      if (event.currentTarget.checked) {
        setImageIds((imageIds) => [...imageIds, imageId]);
      } else {
        setImageIds((imageIds) => imageIds.filter((id) => id !== imageId));
      }
    },
    [imageId, context],
  );

  if (!context) return null;

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      className={clsx(
        checked ? "opacity-100" : "opacity-0 group-hover:opacity-100",
        className,
      )}
      {...props}
    />
  );
});

const ImagePreview = ({ image }) => {
  const previewState = useImagePreviewDialogState({ imageId: image.id });

  return (
    <>
      <ImagePreviewDialogDisclosure
        title="Prévisualiser l’image"
        {...previewState}
      >
        {(disclosureProps) => (
          <PreviewLink {...disclosureProps}>
            <PreviewLinkContent className="background-grid flex h-40 grow flex-col items-center justify-center">
              <div className="absolute z-20 flex h-full w-full items-center justify-center opacity-0 transition group-hover:opacity-100">
                <div className="absolute h-full w-full bg-dusk-bg-stronger opacity-20" />
              </div>
              <img
                className="z-10 h-full max-w-none"
                alt={image.fluid.alt}
                src={image.fluid.src}
              />
            </PreviewLinkContent>
            <PreviewLinkHovering>
              <IoExpand />
            </PreviewLinkHovering>
          </PreviewLink>
        )}
      </ImagePreviewDialogDisclosure>
      <ImagePreviewDialog {...previewState} />
    </>
  );
};

ImageCard.fragments = {
  image: gql`
    fragment ImageCard_image on Image {
      id
      expired
      url
      siriusUrl
      fluid(maxHeight: 160) {
        ...ImageFluidFragment
      }
      ...ImageSummary_image
    }
    ${ImageFluidFragment}
    ${ImageSummary.fragments.image}
  `,
};
