import { forwardRef } from "react";

const SvgImageAlignRight = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M11.213 5.632c0 .244.092.458.275.641s.412.274.686.274c.26 0 .481-.084.664-.252a.903.903 0 0 0 .274-.663c0-.275-.091-.504-.274-.687s-.404-.274-.664-.274a.903.903 0 0 0-.663.274.896.896 0 0 0-.297.687zm-.778 4.76V3.87h8.695v4.165l-1.533-1.556-.114-.114c-.245-.214-.489-.321-.733-.321-.29 0-.549.13-.778.389l-.046.046-.298.344a50.004 50.004 0 0 1-1.281 1.395l.092.046c.213.138.45.374.709.71s.397.588.412.755c.016.137-.068.137-.252 0h-.022a9.481 9.481 0 0 0-1.808-.893l-.229-.114-.183-.069zm-.893.915c0 .107.046.198.138.275a.373.373 0 0 0 .274.114h9.657a.375.375 0 0 0 .275-.114.353.353 0 0 0 .114-.252V3.389a.35.35 0 0 0-.114-.252c-.061-.076-.153-.114-.275-.114H9.954a.418.418 0 0 0-.274.092c-.076.061-.115.152-.115.274zM0 3.87h7.826V3H0zm0 1.739h7.826v-.87H0zm0 1.762v-.87h7.826v.847zm0 1.717h7.826v-.87H0zm0 1.739h7.826v-.87H0zm0 1.739h7.826v-.87H0zm0 1.739h20v-.87H0zm0 1.739h20v-.87H0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImageAlignRight);
export default ForwardRef;
