import { forwardRef } from "react";

export const LetterIcon = forwardRef(({ children, ...props }, ref) => {
  if (typeof children !== "string") return null;
  return (
    <svg ref={ref} viewBox="0 0 20 20" width="1em" height="1em" {...props}>
      <text
        x="10"
        y="15"
        textAnchor="middle"
        textLength={9 * children.length}
        fontSize="20"
        fontWeight="700"
        fill="currentColor"
        lengthAdjust="spacingAndGlyphs"
        style={{ userSelect: "none", fontFamily: "var(--font-sans)" }}
      >
        {children}
      </text>
      <path d="M1 18.2h18V20H1z" fill="currentColor" fillRule="evenodd" />
    </svg>
  );
});
