import { forwardRef } from "react";

const SvgSync = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.401 19.902v-5.914h5.914L5.02 16.303c1.634 1.154 3.288 1.731 4.961 1.731 2.192 0 4.066-.778 5.623-2.334.713-.701 1.277-1.57 1.692-2.607A8.778 8.778 0 0 0 17.957 10h1.965c-.116 2.918-1.083 5.285-2.899 7.101a9.934 9.934 0 0 1-3.209 2.14A10 10 0 0 1 9.981 20c-2.296 0-4.423-.759-6.381-2.277zM0 10c.116-2.906 1.083-5.267 2.898-7.082A9.927 9.927 0 0 1 6.109.778 9.774 9.774 0 0 1 9.941 0c2.296 0 4.423.758 6.381 2.276L18.521.097v5.914h-5.914l2.295-2.315c-1.634-1.141-3.288-1.711-4.961-1.711-2.192 0-4.066.771-5.622 2.315a7.429 7.429 0 0 0-1.693 2.626A8.609 8.609 0 0 0 1.965 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSync);
export default ForwardRef;
