import React, { forwardRef, memo } from "react";
import { Button, ButtonProps } from "swash/Button";
import { IoExitOutline } from "swash/Icon";
import { Link } from "swash/Link";
import { Tooltip } from "swash/Tooltip";

export type PublishedLinkProps = {
  url: string | null;
} & {
  className?: string;
  scale?: ButtonProps["scale"];
  iconOnly?: boolean;
};

export const PublishedLink = memo<PublishedLinkProps>(
  forwardRef<HTMLButtonElement, PublishedLinkProps>(
    ({ url, className, scale, iconOnly }, ref) => {
      if (!url) return null;
      return (
        <Tooltip tooltip={iconOnly ? "Voir en ligne" : null}>
          <Button
            ref={ref}
            scale={scale}
            className={className}
            appearance="text"
            iconOnly={iconOnly}
            asChild
          >
            <Link
              href={url}
              target="_blank"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <IoExitOutline />
              {iconOnly ? null : "Voir en ligne"}
            </Link>
          </Button>
        </Tooltip>
      );
    },
  ),
);
