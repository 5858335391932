import { forwardRef } from "react";

const SvgChartLine = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M20 7.032V20H0v-1.853l7.006-6.728 4.198 3.88zM20 0v4.8a.8.8 0 0 1-1.6 0V2.876l-7.19 6.76-4.19-3.873-5.324 5.112a.999.999 0 0 1-1.414-.03 1 1 0 0 1 .03-1.414l6.68-6.416 4.206 3.886L16.839 1.6H15.2a.8.8 0 0 1 0-1.6z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChartLine);
export default ForwardRef;
