import { memo } from "react";

import { BooleanFilterField } from "@/containers/search/filterFields/BooleanFilterField";

const options = {
  true: "Autorisées sur le print",
  false: "Non-autorisées sur le print",
};

const name = "printAuthorized";

const Filter = memo(() => {
  return (
    <BooleanFilterField name={name} label="Autorisation print" enum={options} />
  );
});

export const PrintAuthorizedFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
