import { forwardRef } from "react";

const SvgLock = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.546 12.84c0 .277.072.535.216.776.132.228.319.414.56.559v1.966a.43.43 0 0 0 .108.288.363.363 0 0 0 .27.109h.776a.366.366 0 0 0 .271-.109.366.366 0 0 0 .108-.27v-1.984c.24-.132.427-.319.559-.559.144-.241.216-.493.216-.758a1.506 1.506 0 0 0-1.532-1.533c-.433 0-.8.144-1.1.433a1.574 1.574 0 0 0-.451 1.082zM6.617 8.457h6.925V4.616c0-.541-.12-.932-.361-1.173-.24-.24-.625-.361-1.154-.36h-3.86c-.529 0-.92.12-1.172.36-.241.241-.361.626-.361 1.155zM2 19.549V8.909c0-.121.054-.229.162-.325a.53.53 0 0 1 .361-.126h1.028V4.617c0-.565.126-1.136.379-1.714A4.972 4.972 0 0 1 6.455.378 4.093 4.093 0 0 1 8.132 0h3.913c.553 0 1.112.126 1.677.378a4.972 4.972 0 0 1 2.525 2.525c.253.565.379 1.13.379 1.695v3.86h1.028c.145 0 .265.042.361.126s.144.193.144.325v10.64c0 .12-.048.228-.144.324a.498.498 0 0 1-.342.127H2.523a.568.568 0 0 1-.36-.127.468.468 0 0 1-.163-.324z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLock);
export default ForwardRef;
