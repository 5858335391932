import { forwardRef } from "react";

const SvgLogo = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 214 214"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path
        d="m137.101 106.528-30.103-57.771.011-.021-.01.003L84 4.605l.644-.287C91.324 1.402 98.693 0 106.995 0c8.386 0 14.97 1.134 21.778 4.061l.62.271.602.273v.017c20.978 9.447 30.803 27.398 30.998 44.38l.002.514a45.794 45.794 0 0 1-4.1 18.952l-.287.624zM76.894 106.5l30.103 57.771-.011.021.01-.003 23 44.134-.645.287c-6.68 2.916-14.048 4.318-22.351 4.318-8.386 0-14.97-1.134-21.777-4.061l-.62-.271-.603-.273v-.017c-20.978-9.447-30.803-27.398-30.997-44.38L53 163.51a45.794 45.794 0 0 1 4.1-18.951l.288-.624z"
        opacity={0.4}
      />
      <path
        d="M107 0c8.303 0 15.67 1.402 22.351 4.318l.644.287c6.295 2.835 11.958 6.68 15.913 10.626-25.075 12.493-28.01 42.92-8.808 91.28L84 4.605l.603-.273C91.633 1.204 98.36 0 107 0zM106.908 213.011c-8.303 0-15.67-1.402-22.351-4.318l-.644-.287c-6.295-2.835-11.958-6.68-15.913-10.626 25.075-12.493 28.01-42.92 8.808-91.28l53.1 101.906-.603.273c-7.03 3.128-13.757 4.332-22.397 4.332z"
        opacity={0.7}
      />
      <path d="m84 4.605 72.608 139.342a45.82 45.82 0 0 1 4.387 19.576c0 17.132-9.81 35.352-31 44.894L57.388 69.075A45.82 45.82 0 0 1 53 49.5c0-17.133 9.81-35.353 31-44.895z" />
      <path
        d="M107.002 0c8.304 0 15.673 1.402 22.354 4.319l.644.286c-9.843 8.628-19.557 17.615-23.002 44.139L84 4.605l.603-.272C91.633 1.204 98.362 0 107.003 0zM106.998 213.044c-8.304 0-15.673-1.402-22.354-4.319l-.644-.287 1.18-1.038 1.177-1.048.878-.797.875-.808.58-.548.577-.555.287-.28.573-.568.57-.577.565-.587.562-.599.28-.303.554-.617.55-.63c6.21-7.22 11.451-17.147 13.794-35.183L130 208.438l-.603.273c-7.03 3.128-13.759 4.333-22.4 4.333z"
        opacity={0.7}
      />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgLogo);
export default ForwardRef;
