import { forwardRef } from "react";

const SvgTimes = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2 15.625c0-.058.015-.102.044-.131l5.515-5.515-5.493-5.515c-.03-.029-.044-.066-.044-.109s.007-.079.021-.109l2.224-2.223v.021c.029-.029.066-.044.109-.044s.087.015.131.044v-.021l5.515 5.514 5.515-5.514c.014-.015.051-.022.109-.022a.15.15 0 0 1 .109.044v-.022l2.201 2.202a.148.148 0 0 1 .044.109c0 .058-.015.101-.044.131L12.463 9.98l5.471 5.493c.044.044.066.087.066.131s-.015.087-.044.131l-2.201 2.202c-.015.029-.051.044-.109.044s-.095-.008-.109-.022l-5.515-5.515-5.515 5.493c-.014.03-.051.044-.109.044s-.094-.007-.109-.021l-2.223-2.224A.262.262 0 0 1 2 15.627z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTimes);
export default ForwardRef;
