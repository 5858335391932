import { forwardRef } from "react";

const SvgRelated = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.075 12.797c-.142-.522-.083-1.008.178-1.458a1.983 1.983 0 0 1 1.192-.907 1.963 1.963 0 0 1 1.458.178c.154.106.284.213.391.32l3.13-1.778c-.332-.652-.498-1.304-.498-1.956a4.155 4.155 0 0 1 1.227-2.969C7.971 3.409 8.961 3 10.123 3c1.161 0 2.151.403 2.97 1.209.817.818 1.226 1.813 1.226 2.987 0 .593-.143 1.198-.427 1.814l2.791 1.582a1.81 1.81 0 0 1 1.138-.569c.451-.047.901.066 1.352.338.438.285.705.7.8 1.245a1.966 1.966 0 0 1-.32 1.458c-.155.214-.345.392-.57.534s-.468.231-.729.267a1.939 1.939 0 0 1-1.404-.338 1.888 1.888 0 0 1-.516-.551c-.284-.463-.373-.931-.267-1.405L13.27 9.953c-.7.806-1.606 1.268-2.72 1.387l.017 2.329c.12.024.261.083.427.178.451.261.741.646.871 1.156.142.51.083 1.008-.178 1.493-.285.463-.67.759-1.156.89-.498.13-.995.065-1.493-.196a1.788 1.788 0 0 1-.889-1.174 1.887 1.887 0 0 1 .195-1.458c.273-.439.641-.729 1.103-.871v-2.365c-.9-.154-1.689-.575-2.365-1.262l-3.27 1.831a1.8 1.8 0 0 1-.197 1.369 1.902 1.902 0 0 1-1.209.907c-.51.142-.99.077-1.44-.196a1.789 1.789 0 0 1-.889-1.173z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRelated);
export default ForwardRef;
