import { forwardRef } from "react";

const SvgPaperPlane = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.283 11.179 20 5.305l-7.774 8.518zm-1.276 4.306.811-3.761 3.003 1.687zm-1.595-5.462 15.23-4.838-13.183 5.462-.85 3.92zM0 7.126 19.509 5 3.881 9.492z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPaperPlane);
export default ForwardRef;
