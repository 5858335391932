import { forwardRef } from "react";

const SvgRecommendation = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M20 10c0 5.523-4.477 10-10 10a9.956 9.956 0 0 1-4.65-1.145l-3.948.987a1.026 1.026 0 0 1-1.244-1.244l.987-3.947a9.937 9.937 0 0 1-.798-2.029l3.214-2.43a.61.61 0 0 1 .853.118L6.06 12.49a1.826 1.826 0 0 0 2.56.355l5.411-4.093-.383 2.766a.609.609 0 1 0 1.206.168l.586-4.223a.609.609 0 0 0-.52-.687L10.7 6.19a.609.609 0 0 0-.167 1.207l2.766.384-5.412 4.092a.61.61 0 0 1-.853-.118L5.385 9.576a1.827 1.827 0 0 0-2.559-.355L.083 11.295A10.095 10.095 0 0 1 0 10C0 4.477 4.477 0 10 0s10 4.477 10 10Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgRecommendation);
export default ForwardRef;
