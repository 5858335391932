import { forwardRef } from "react";

const SvgCaretDown = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2.256 7.001h15.465c.133 0 .221.037.266.111.03.073.007.162-.067.265L10.3 14.91a.357.357 0 0 1-.289.134.427.427 0 0 1-.31-.134L2.078 7.399c-.074-.103-.096-.192-.067-.265.03-.089.111-.134.244-.134z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCaretDown);
export default ForwardRef;
