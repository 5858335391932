import { forwardRef } from "react";

const SvgPublication = (props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    style={{
      enableBackground: "new 0 0 100 100",
    }}
    xmlSpace="preserve"
    width="1em"
    height="1em"
    ref={ref}
    {...props}
  >
    <path
      d="M60 0H10v100h80V30.002L60 0zm2.5 9.571L80.428 27.5H62.5V9.571zM85 95H15V5h42.5v27.5H85V95zM50 15H25v30h25V15zm-5 25H30V20h15v20zM25 50h50v5H25v-5zm30-10h20v5H55v-5zm0 25H25v-5h30v5zm0 10H25v-5h30v5zm5 0h15V60H60v15zm5-10h5v5h-5v-5zM25 80h50v5H25v-5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPublication);
export default ForwardRef;
