import { forwardRef } from "react";

const SvgCalendar = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14.842 10.898v-2.05h2.031v2.031zm-.019 2.753h2.05v-2.05h-2.05zm-.45-9.725V.84c0-.208.085-.404.254-.586a.827.827 0 0 1 1.192 0 .766.766 0 0 1 .254.586v3.066a.887.887 0 0 1-.235.625.886.886 0 0 1-.625.234.807.807 0 0 1-.586-.253.924.924 0 0 1-.254-.586zm-2.441 6.972v-2.05h2.051v2.031zm0 2.753h2.05v-2.05h-2.05zm0 2.735h2.05v-2.031h-2.05zm-2.909-5.488v-2.05h2.07v2.031zm0 2.753h2.07v-2.05h-2.07zm0 2.735h2.07v-2.031h-2.07zm-2.871-5.488v-2.05h2.031v2.031zm0 2.753h2.031v-2.05H6.152zm0 2.735h2.031v-2.031H6.152zM4.121 3.926V.84c0-.208.078-.404.234-.586a.83.83 0 0 1 1.192 0A.805.805 0 0 1 5.8.84v3.066a.886.886 0 0 1-.234.625.886.886 0 0 1-.625.234.805.805 0 0 1-.586-.253.84.84 0 0 1-.234-.586zm-.86 9.725h2.05v-2.05h-2.05zm-.019 2.735h2.07v-2.031h-2.07zm-1.406 1.601h16.366V6.621H1.836zM0 19.823V1.602h3.476v.899c-.339.312-.521.742-.547 1.289-.026.599.156 1.061.547 1.387.391.325.879.488 1.465.488.547 0 1.003-.13 1.367-.391.443-.325.671-.781.684-1.367s-.176-1.049-.566-1.387v-.918h7.246v.899c-.404.351-.593.781-.567 1.289.092 1.211.795 1.816 2.109 1.816.6 0 1.107-.156 1.524-.469.404-.312.606-.761.606-1.347 0-.521-.189-.944-.567-1.269v-.918H20v18.22H.002z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCalendar);
export default ForwardRef;
