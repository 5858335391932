/**
 * @param {object} props
 * @param {import('./LockApi').LockInfo['locker']['user']} props.user
 */
export function LockerName({ user }) {
  return [
    user.firstName ? `${user.firstName.charAt(0)}.` : null,
    user.lastName ?? `#${user.id}`,
  ]
    .filter(Boolean)
    .join(" ");
}
