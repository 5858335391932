import { forwardRef } from "react";

const SvgFileTimes = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.34 8.886V.371c0-.052.006-.1.019-.146a.188.188 0 0 1 .078-.107.246.246 0 0 1 .108-.088.357.357 0 0 1 .146-.03h11.132l5.175 5.175v14.452c0 .052-.01.1-.029.146a.24.24 0 0 1-.089.107.238.238 0 0 1-.107.088.357.357 0 0 1-.146.03h-7.402v-1.484h5.976a.345.345 0 0 0 .127-.02.244.244 0 0 0 .088-.059.356.356 0 0 0 .068-.097.268.268 0 0 0 .03-.118V6.659h-4.883a.283.283 0 0 1-.107-.019.244.244 0 0 1-.088-.059.359.359 0 0 1-.069-.097.281.281 0 0 1-.029-.118V1.484H5.097a.277.277 0 0 0-.205.088.28.28 0 0 0-.088.205v.019l.019 7.089H3.339zM0 18.514c0-.013.004-.026.01-.04l.01-.019 3.457-3.457L.02 11.542l-.01-.019-.01-.059.01-.02a.078.078 0 0 0 .01-.039l1.387-1.367a.17.17 0 0 1 .039-.03c.014-.006.027-.01.04-.01s.026.004.039.01a.155.155 0 0 1 .04.03v-.02l3.456 3.457 3.437-3.457c.013 0 .023-.003.029-.01.007-.006.023-.01.049-.01.013 0 .026.004.04.01.013.007.019.017.019.03v-.02l1.387 1.387.01.019c.006.013.01.026.01.04a.146.146 0 0 1-.01.059.078.078 0 0 0-.01.039l-3.457 3.437 3.437 3.437a.17.17 0 0 1 .03.039c.006.014.01.027.01.04s-.004.026-.01.039a.088.088 0 0 0-.01.04L8.595 19.98l-.019.01a.082.082 0 0 1-.04.01.13.13 0 0 1-.059-.01l-.019-.01-3.437-3.456-3.456 3.456a.045.045 0 0 0-.03.01c-.006.007-.023.01-.049.01a.084.084 0 0 1-.039-.01l-.019-.01-1.406-1.386a.102.102 0 0 0-.01-.04.08.08 0 0 1-.01-.039z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFileTimes);
export default ForwardRef;
