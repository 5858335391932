import { forwardRef } from "react";

const SvgCaretUp = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2.256 14.043c-.133 0-.214-.044-.244-.133-.029-.074-.007-.163.067-.266L9.7 6.133A.426.426 0 0 1 10.011 6c.118 0 .214.044.288.133l7.621 7.533c.074.103.097.192.067.266-.045.073-.133.11-.266.11H2.256z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCaretUp);
export default ForwardRef;
