import { forwardRef } from "react";

const SvgEyeSlash = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m18.293.293 1.414 1.414-3.978 3.979c.334.213.659.44.974.68.568.432 1.052.857 1.452 1.278.399.41.73.778.993 1.104.263.327.479.632.647.915l.205.332-.268.283c-.179.169-.473.432-.883.789-.4.347-.773.652-1.12.915a18.593 18.593 0 0 1-3.439 2.051c-.662.294-1.372.531-2.129.71s-1.478.268-2.161.268c-.986 0-2.009-.175-3.07-.524l-4.223 4.22-1.414-1.414 3.64-3.64a14.232 14.232 0 0 1-1.274-.724 23.758 23.758 0 0 1-2.208-1.593c-.61-.505-1.041-.894-1.293-1.167L0 9.993a.79.79 0 0 1 .111-.205c.273-.452.662-.967 1.167-1.545a14.476 14.476 0 0 1 1.814-1.751 11.07 11.07 0 0 1 2.965-1.751A10.637 10.637 0 0 1 10 4a10.855 10.855 0 0 1 3.869.718zM12.68 9.015c0 .747-.257 1.383-.773 1.909a2.6 2.6 0 0 1-1.909.789c-.096 0-.19-.004-.283-.013l-1.099 1.098c.438.16.899.24 1.383.24.715 0 1.388-.174 2.019-.521a3.927 3.927 0 0 0 1.467-1.467c.357-.62.536-1.293.536-2.019 0-.469-.083-.932-.25-1.387L12.67 8.747c.008.088.012.177.012.268zM15.3 8.006a5.47 5.47 0 0 1-.315 3.091 5.37 5.37 0 0 1-1.184 1.766l.001.001a15.592 15.592 0 0 0 2.492-1.278 15.705 15.705 0 0 0 2.114-1.593c-.799-.725-1.835-1.388-3.108-1.987zm-10.631.078c-1.094.568-2.024 1.205-2.792 1.909 1.26 1.15 2.601 2.048 4.023 2.694l.065-.067-.045-.048A5.066 5.066 0 0 1 5 11.081a5.447 5.447 0 0 1-.394-2.05c0-.316.021-.631.063-.947zm3.8-1.277c-.683.105-1.393.289-2.129.552a4.025 4.025 0 0 0-.379 1.656l.001.001c0 .725.179 1.398.537 2.019.128.222.273.427.436.616l.945-.946a2.588 2.588 0 0 1-.578-1.674c0-.442.105-.863.315-1.262.2-.389.484-.71.852-.962zm.207 2.398-.552.536c.068.174.15.331.249.473l.481-.483a1.484 1.484 0 0 1-.178-.526zm2.853-2.398.143.106.072-.072a11.245 11.245 0 0 0-.215-.034z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEyeSlash);
export default ForwardRef;
