import { gql } from "@apollo/client";

import { DraftEditorNodeFragment } from "@/containers/editor/DraftEditorFragments";
import { CustomTypeContentNode } from "@/containers/editor/nodes/CustomTypeContentNode";
import { ContributionNodeFragment } from "@/containers/live/postNodes/ContributionNodeFragment";

export const LivePostEditorPostFragment = gql`
  fragment LivePostEditorPostFragment on Post {
    id
    title
    flagId
    nodes {
      id
      type
      ...DraftEditorNodeFragment
      ... on EditorNodeContribution {
        contributionsId
        contribution {
          id
          ...ContributionNode_contribution
        }
      }
      ... on EditorNodeCustomTypesContent {
        customTypesContentsId
        customTypesContent {
          id
          ...CustomTypeContentNode_customTypeContent
        }
      }
    }
    scheduledAt
  }

  ${DraftEditorNodeFragment}
  ${ContributionNodeFragment}
  ${CustomTypeContentNode.fragments.content}
`;
