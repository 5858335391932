import React from "react";

/**
 * Inserts a separator between each item in an array of React nodes.
 * @template T - The type of the items in the array.
 * @param {T[]} values - The array of React nodes to be separated.
 * @param {React.ReactNode} separator - The separator to be inserted between each item.
 * @returns {React.ReactNode[]} - An array of React nodes with the separator inserted between each item.
 */
export function intersperse<T extends React.ReactNode>(
  values: T[],
  separator: React.ReactNode,
) {
  return values.map((value, index) => (
    <React.Fragment key={index}>
      {index > 0 ? separator : null}
      {value}
    </React.Fragment>
  ));
}
