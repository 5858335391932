import { forwardRef } from "react";

const SvgMapMarker = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.289 6.047c0 .757.268 1.409.802 1.957a2.665 2.665 0 0 0 1.957.802c.757 0 1.409-.267 1.957-.802a2.663 2.663 0 0 0 .803-1.957c0-.757-.268-1.409-.803-1.957-.535-.535-1.187-.802-1.957-.802a2.64 2.64 0 0 0-1.937.802c-.548.548-.822 1.2-.822 1.957zm-3.288 0c0-.796.157-1.566.47-2.309a5.93 5.93 0 0 1 1.292-1.957A5.814 5.814 0 0 1 7.72.469 6.035 6.035 0 0 1 10.048 0c.796 0 1.573.157 2.329.469a6.228 6.228 0 0 1 1.957 1.292 6.228 6.228 0 0 1 1.292 1.957c.313.757.47 1.533.47 2.329 0 .548-.098 1.135-.294 1.761l-.098.333c-.431 1.07-1.083 2.551-1.957 4.442a176.455 176.455 0 0 1-2.505 5.245l-1.018 2.016c-.039.104-.098.156-.176.156-.066 0-.124-.052-.176-.156-.092-.17-.19-.366-.294-.587a24.702 24.702 0 0 0-.411-.822l-.412-.822a300.178 300.178 0 0 1-2.407-5.049c-.9-1.944-1.546-3.418-1.937-4.423C4.137 7.319 4 6.621 4 6.048z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMapMarker);
export default ForwardRef;
