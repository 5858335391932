import { forwardRef } from "react";

const SvgComment = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0 8.944c0-1.275.454-2.446 1.361-3.511.907-1.066 2.123-1.907 3.649-2.525C6.535 2.303 8.198 2 10 2c1.815 0 3.498.31 5.049.928 1.499.618 2.703 1.466 3.61 2.544C19.553 6.537 20 7.694 20 8.943c0 1.263-.454 2.433-1.361 3.512-.92 1.078-2.137 1.92-3.649 2.524-1.525.618-3.188.927-4.99.927-1.367 0-2.715-.197-4.043-.592L1.893 17.82c-.105.052-.184.059-.236.019-.04-.039-.046-.112-.02-.217l.947-4.004C1.769 13 1.137 12.283.69 11.468A5.003 5.003 0 0 1 0 8.943z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComment);
export default ForwardRef;
