import { gql } from "@apollo/client";
import { memo } from "react";

import { QueryFilterField } from "@/containers/search/filterFields/QueryFilterField";

const query = gql`
  query AgencyFilter_imageAgencies($where: ImageAgencyWhere) {
    connection: imageAgencies(where: $where) {
      nodes {
        id
        label
        name
      }
    }
  }
`;

const name = "agencies";

const Filter = memo(() => {
  return (
    <QueryFilterField
      name={name}
      label="Agences"
      valueSelector="name"
      query={query}
      searchVariables={{ where: { enabled: true } }}
      searchable={false}
      parseNodes={(data) => {
        const nodes = data.connection.nodes;
        return nodes.length
          ? [...nodes, { name: "other", label: "Autres" }]
          : nodes;
      }}
    />
  );
});

export const AgencyFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
