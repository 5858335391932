import { forwardRef } from "react";
import { useField } from "react-final-form";

import { FieldControl } from "@/components/fields/FieldControl";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useFieldState } from "@/components/fields/FieldState";
import { RelatedArticleSelector } from "@/containers/common/RelatedArticleSelector";
import { composeValidators, mustBeFilled } from "@/services/forms/validators";

export function useRelatedArticleField(
  name,
  { required = false, orientation = "vertical", format, ...options } = {},
) {
  const validators = [];

  if (required) {
    validators.push(mustBeFilled);
  }

  const validateFunction = validators.length
    ? composeValidators(...validators)
    : undefined;

  const field = useField(name, {
    validate: validators.length
      ? (value, ...args) => validateFunction(format(value), ...args)
      : undefined,
    format,
    ...options,
  });
  return useFieldState({ field, required, orientation });
}
export const RelatedArticleControl = forwardRef((props, ref) => {
  return <FieldControl ref={ref} as={RelatedArticleSelector} {...props} />;
});

export function RelatedArticleField({
  name,
  label,
  hint,
  placeholder,
  limit,
  multi,
  disabled,
  ...options
}) {
  const field = useRelatedArticleField(name, options);
  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <RelatedArticleControl
        limit={limit}
        placeholder={placeholder}
        multi={multi}
        label={label}
        disabled={disabled}
        {...field}
      />
    </FieldGroup>
  );
}
