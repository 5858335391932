import { forwardRef } from "react";

const SvgChevronDoubleUp = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m10.116 9.267 6.951 6.596V20l-6.951-6.572L3.024 20v-4.161zm0-9.267 6.951 6.572v4.138l-6.951-6.549L3 10.71V6.572z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronDoubleUp);
export default ForwardRef;
