import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Badge as SwashBadge } from "swash/Badge";
import { PanelBody } from "swash/Panel";
import { Tab, TabList, TabPanel, useTabState } from "swash/Tab";

import { createPanel } from "@/containers/PanelManager";
import { TrackPanel } from "@/containers/article/ArticleMetaPanel";
import { StandaloneArticlePanelHeader } from "@/containers/article/ArticlePanelHeader";
import { ArticleComments } from "@/containers/article/panels/comments/ArticleComments";
import { ArticleNotes } from "@/containers/article/panels/comments/ArticleNotes";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { useArticleSelector } from "@/containers/routes/article/ArticleContext";

export const ArticleCommentPanelBody = ({ articleId }) => (
  <PanelBody className="flex flex-col p-0 pt-4">
    <ArticleNotes articleId={articleId} />
  </PanelBody>
);
export const ArticleCollaborationPanelBody = ({ articleId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = matchPath(
    { path: "articles/:articleId/collaborative/:tabId" },
    location.pathname,
  );
  const tab = useTabState({
    variant: "bar",
    defaultSelectedId: "notes",
    setSelectedId: (nextTabId) => {
      navigate(`/articles/${articleId}/collaborative/${nextTabId}`);
    },
    selectedId: match?.params.tabId,
  });
  const { nbComments, nbNotes } = useArticleSelector((article) => ({
    nbNotes: article.notes.totalCount,
    // comments + responses in thread
    nbComments:
      article.comments.totalCount +
      article.comments.nodes.reduce(
        (result, node) => result + node.responses.totalCount,
        0,
      ),
  }));

  return (
    <>
      <div className="z-secondary-side-panel flex flex-col gap-4 bg-white px-4 py-2">
        <TabList state={tab} aria-label="Commentaires de l’article">
          <Tab state={tab} id="notes">
            Consignes{" "}
            {nbNotes !== 0 && (
              <SwashBadge color="primary" scale="sm">
                {nbNotes < 100 ? nbNotes : "99+"}
              </SwashBadge>
            )}
          </Tab>
          <Tab state={tab} id="comments">
            Commentaires{" "}
            {nbComments !== 0 && (
              <SwashBadge color="primary" scale="sm">
                {nbComments < 100 ? nbComments : "99+"}
              </SwashBadge>
            )}
          </Tab>
        </TabList>
      </div>
      <PanelBody className="flex flex-col p-0">
        <TabPanel
          state={tab}
          tabId="notes"
          className="flex h-full min-h-0 flex-col justify-between"
        >
          <ArticleNotes articleId={articleId} />
        </TabPanel>
        <TabPanel
          state={tab}
          tabId="comments"
          className="flex h-full min-h-0 flex-col"
        >
          <ArticleComments articleId={articleId} />
        </TabPanel>
      </PanelBody>
    </>
  );
};

export const ArticleCommentsPanel = createPanel({
  key: "article-notes",
  title: "Consignes de l'article",
  render: ({ articleId, onClose }) => (
    <>
      <TrackPanel panelKey="article-notes" />
      <StandaloneArticlePanelHeader articleId={articleId} onClose={onClose} />
      <ArticleCommentPanelBody articleId={articleId} />
    </>
  ),
});
