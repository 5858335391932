import { forwardRef } from "react";

const SvgMobile = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.938 17.324H15.18V2.246H4.938zM4.001 2.813c0-.612.052-1.081.156-1.406.13-.378.274-.632.43-.762a2.22 2.22 0 0 1 .644-.371c.209-.052.475-.098.801-.137.039 0 .124-.013.254-.04C6.846.033 8.575 0 9.473 0h.644c1.342 0 3.22.026 3.637.078.013.013.039.019.078.019h.078c.352.053.632.105.84.157.508.104.847.332 1.016.683.208.43.312 1.055.312 1.875v15.059c0 .625-.227 1.133-.683 1.523-.456.404-1.049.606-1.778.606H6.481c-.717 0-1.309-.195-1.778-.586-.456-.404-.69-.918-.703-1.543z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMobile);
export default ForwardRef;
