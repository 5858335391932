import { forwardRef } from "react";

const SvgTextSquare = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.824 8.246V5.219h10.878v3.027h-.547c0-.508-.143-.905-.43-1.191-.286-.273-.683-.41-1.191-.41h-2.012v8.007c0 .183.098.313.293.391.274.104.716.156 1.328.156v.879h-5.78v-.859c.247 0 .469-.013.664-.04.651-.052.977-.228.977-.527V6.644H7.011c-.508 0-.911.137-1.211.41-.286.287-.43.684-.43 1.191h-.547zM1.66 18.245h16.659V2.66H1.679zM0 19.183V1.723a.72.72 0 0 1 .215-.528A.731.731 0 0 1 .723 1h18.554c.195 0 .365.065.508.195a.72.72 0 0 1 .215.528v17.46a.696.696 0 0 1-.215.508.656.656 0 0 1-.508.215H.743a.717.717 0 0 1-.527-.215.654.654 0 0 1-.215-.508z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTextSquare);
export default ForwardRef;
