import { forwardRef } from "react";

const SvgUneditable = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m11.993 6.552.728-.709a.24.24 0 0 1 .173-.077.26.26 0 0 1 .191.077l1.072 1.073a.236.236 0 0 1 .077.172.257.257 0 0 1-.077.192l-.708.728zM5.844 3.946l4.386 4.387 1.342-1.341 1.437 1.437-1.342 1.341 4.387 4.387A7.127 7.127 0 0 0 17.357 10a7.127 7.127 0 0 0-.997-3.697 7.49 7.49 0 0 0-2.681-2.682A7.211 7.211 0 0 0 10 2.644c-1.507 0-2.892.434-4.156 1.302zm-.097 10.192.844-2.165 1.436 1.437-2.165.824c-.051.013-.083.013-.095 0-.013-.013-.019-.045-.02-.096zM2.644 10c0 1.341.332 2.573.996 3.698a7.51 7.51 0 0 0 2.682 2.682 7.21 7.21 0 0 0 3.678.976c1.738 0 3.296-.561 4.675-1.685l-4.445-4.464-1.762 1.763-1.437-1.437L8.794 9.77 4.349 5.326C3.213 6.692 2.644 8.251 2.644 10zM0 10c0-1.788.454-3.467 1.36-5.038A9.774 9.774 0 0 1 5 1.322 9.864 9.864 0 0 1 10 0a9.89 9.89 0 0 1 5.039 1.341c1.545.907 2.745 2.107 3.601 3.602C19.547 6.501 20 8.186 20 10s-.447 3.493-1.341 5.038c-.895 1.52-2.114 2.727-3.659 3.621S11.788 20 10 20a9.9 9.9 0 0 1-5.038-1.36A9.797 9.797 0 0 1 1.341 15 9.816 9.816 0 0 1 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUneditable);
export default ForwardRef;
