import { forwardRef } from "react";

const SvgExternalLink = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.163 10.307c0-.131.046-.242.138-.334l5.968-5.948-1.669-1.69c-.196-.195-.15-.306.138-.332h5.791a.425.425 0 0 1 .334.098.433.433 0 0 1 .137.314v5.732c-.039.34-.144.432-.314.275l-1.688-1.668-5.949 5.929a.469.469 0 0 1-.334.157.52.52 0 0 1-.353-.138L7.32 10.66a.553.553 0 0 1-.157-.354zM2 14.547v-7.97c0-.943.334-1.748 1.002-2.415A3.288 3.288 0 0 1 5.417 3.16h3.102L10.6 5.222H4.651a.563.563 0 0 0-.412.176.513.513 0 0 0-.157.373v9.56a.537.537 0 0 0 .55.55h9.561a.604.604 0 0 0 .412-.156.533.533 0 0 0 .157-.393V9.384l2.061 2.06v3.103c0 .942-.327 1.747-.982 2.415-.667.667-1.479 1-2.434 1H5.436c-.942 0-1.754-.333-2.434-1A3.292 3.292 0 0 1 2 14.547z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExternalLink);
export default ForwardRef;
