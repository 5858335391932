import { forwardRef } from "react";

const SvgThumbtack = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m0 20 .611-1.852 7.399-7.405L4.328 7.04c.582-.594 1.222-1.019 1.92-1.275.709-.257 1.378-.326 2.007-.21a3.019 3.019 0 0 1 1.605.856l4.328-4.314-.611-.612a.889.889 0 0 1-.244-.629c0-.233.081-.436.244-.611A.87.87 0 0 1 14.188 0c.245 0 .448.082.611.245l4.939 4.943a.8.8 0 0 1 .262.611.83.83 0 0 1-.245.612c-.174.175-.384.262-.628.262s-.448-.087-.611-.262l-.611-.612-4.328 4.315c.466.477.757 1.018.873 1.624.117.641.047 1.31-.209 2.009a5.494 5.494 0 0 1-1.274 1.921l-3.7-3.685-7.416 7.388z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgThumbtack);
export default ForwardRef;
