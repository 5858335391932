import { forwardRef } from "react";

const SvgClipboard = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.622 0C7.986 0 7.748.76 7.818 1.864H6.71c-.889-.028-1.315.554-1.519 1.308H2.185C1.532 3.172 1 3.702 1 4.356l.008 14.312c0 .653.532 1.184 1.185 1.184l15.09.006c.654 0 1.186-.532 1.186-1.185L18.46 4.362c0-.654-.532-1.185-1.185-1.185h-2.997c-.203-.757-.629-1.341-1.517-1.313h-1.11C11.721.76 11.486 0 9.848 0zM2.888 18.097a.548.548 0 0 1-.547-.547L2.334 5.415c0-.302.245-.547.547-.547h2.127c-.005.323 0 .621-.004.867h9.46c-.003-.245.002-.543-.003-.864h2.12c.3 0 .547.246.547.547l.007 12.136a.547.547 0 0 1-.547.547z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgClipboard);
export default ForwardRef;
