import { useApolloClient, useMutation, useSubscription } from "@apollo/client";
import { captureException } from "@sentry/browser";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { NavLink as ReactRouterLink } from "react-router-dom";
import streamSaver from "streamsaver";
import {
  EmptyState,
  EmptyStatePicture,
  EmptyStateTitle,
} from "swash/EmptyState";
import { Link } from "swash/Link";
import { PageLoader } from "swash/Loader";
import { useToaster } from "swash/Toast";
import { useLiveRef } from "swash/utils/useLiveRef";

import faceWithMonocle from "@/assets/imgs/emojis/face_with_monocle.png";
import {
  DragInitializer,
  DraggableListRow,
  DroppableListBody,
  Gripper,
} from "@/components/DraggableList";
import {
  InfiniteScrollLoader,
  InfiniteScrollMarker,
  useInfiniteScrollState,
} from "@/components/InfiniteScroll";
import { Section } from "@/components/Layout";
import {
  List,
  ListBody,
  ListCell,
  ListHeader,
  ListHeaderCell,
  ListRow,
  useListState,
} from "@/components/List";
import { ListTotalCount } from "@/components/ListTotalCount";
import { ExportToolbarItem } from "@/components/teleporters/HeaderToolbar";
import { mergeConnections, useFetchMoreQuery } from "@/containers/Apollo";
import { HasExperimentalFeature, HasPermission } from "@/containers/User";
import { downloadListAsCSV } from "@/services/csv";
import dndManager from "@/services/dndManager";
import { moveItems } from "@/services/utils";

import { CRUDListContextProvider } from "./Context";

const ENABLED_COLUMN = {
  id: "activation",
  Header() {
    return "État";
  },
  Value({ node }) {
    return node.enabled ? "Activé" : "Désactivé";
  },
  width: 0.1,
};

/**
 * Create CRUD list.
 * @param {object} ctx
 * @param {import('./index').CRUDDescriptor} ctx.descriptor
 */
export function createCRUDList({ descriptor }) {
  if (!descriptor.crudOperations.includes("list")) {
    return { List: null };
  }

  function CRUDExportToolbarItem({ filters, disabled }) {
    const client = useApolloClient();
    async function handleExport() {
      // Stream method
      if (descriptor.operations.CsvSubscription) {
        const fileStream = streamSaver.createWriteStream(
          `${descriptor.slug}-${new Date().toJSON()}.csv`,
        );

        fetch("/api/graphql/csv", {
          method: "POST",
          credentials: "same-origin",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            query: descriptor.operations.CsvSubscription.loc.source.body,
            variables: { filters },
          }),
        })
          .then((res) => res.body.pipeTo(fileStream))
          .catch((error) => {
            captureException(error);
          });

        return;
      }

      // Legacy method
      try {
        const {
          data: {
            connection: { nodes },
          },
        } = await client.query({
          query: descriptor.operations.ConnectionQuery,
          variables: {
            export: true,
            offset: 0,
            limit: 2000,
            filters,
          },
          fetchPolicy: "network-only",
        });

        const list = descriptor.formatCSV({ nodes });
        downloadListAsCSV(list, { title: descriptor.slug });
      } catch (error) {
        // Ignore error
      }
    }

    return <ExportToolbarItem onExport={handleExport} disabled={disabled} />;
  }

  function CRUDListCell({ column, node, index, draggable, ...list }) {
    const EditLink = useCallback(
      ({ children }) => (
        <Link asChild>
          <ReactRouterLink
            to={`${descriptor.baseUrl}/${descriptor.slug}/${node.id}`}
          >
            {children}
          </ReactRouterLink>
        </Link>
      ),
      [node.id],
    );

    return draggable && index === 0 ? (
      <ListCell {...list} colId={column.id} w={column.width}>
        <Gripper>
          <column.Value node={node} EditLink={EditLink} />
        </Gripper>
      </ListCell>
    ) : (
      <ListCell {...list} colId={column.id} w={column.width}>
        <column.Value node={node} EditLink={EditLink} />
      </ListCell>
    );
  }

  function CRUDDraggableListView({
    nodes,
    totalCount,
    filterable,
    hasMore,
    loadMore,
    loading,
    query,
    variables,
  }) {
    const infiniteScroll = useInfiniteScrollState({
      hasMore,
      loadMore,
      loading,
    });

    const columns = useMemo(
      () => [
        ...descriptor.components.columns,
        ...(descriptor.enableDisable ? [ENABLED_COLUMN] : []),
      ],
      [],
    );

    const listState = useMemo(
      () =>
        columns.reduce((acc, column) => {
          return {
            ...acc,
            columns: {
              ...acc.columns,
              [column.id]: { props: { w: column.width } },
            },
          };
        }, {}),
      [columns],
    );

    const list = useListState(listState);

    const toaster = useToaster();

    const [moveNode] = useMutation(descriptor.operations.MoveNodeMutation, {
      update: (cache, { data: { moveNode } }) => {
        const queryOptions = {
          query,
          variables,
        };
        const data = cache.readQuery(queryOptions);
        const nounNodes = data.connection.nodes.filter(
          (node) => !moveNode.map((node) => node.id).includes(node.id),
        );
        const orderedNodes = [...nounNodes, ...moveNode].sort(
          (a, b) => a.rank - b.rank,
        );

        cache.writeQuery({
          ...queryOptions,
          data: {
            connection: {
              ...data.connection,
              nodes: orderedNodes,
            },
          },
        });
      },
    });

    function onDragEnd(dropResult) {
      // dropped nowhere
      if (!dropResult.destination) {
        return;
      }
      const { source, destination } = dropResult;

      // reordering list
      if (source.index !== destination.index) {
        const movedNode = nodes[source.index];
        const orderedNodes = moveItems(source.index, destination.index, nodes);

        moveNode({
          variables: {
            input: {
              id: movedNode.id,
              targetIndex: destination.index,
              ...descriptor.operations.operationVariables,
            },
          },
          optimisticResponse: {
            __typename: "Mutation",
            moveNode: orderedNodes.map((node, index) => ({
              ...node,
              rank: index,
            })),
          },
        }).catch(async () => {
          toaster.warning("Le tri de la liste a échoué. Veuillez réessayer.", {
            dismissDelay: 0,
          });
        });
      }
    }

    const handlerRef = useLiveRef({ onDragEnd });

    useEffect(() => {
      const {
        current: { onDragEnd },
      } = handlerRef;
      dndManager.addListener(`${descriptor.slug}`, {
        onDragEnd,
      });
      return () => dndManager.removeListener(`${descriptor.slug}`);
    }, [handlerRef, nodes]);

    return (
      <DragInitializer>
        {filterable && <ListTotalCount totalCount={totalCount} />}
        <List pb={2} {...list} display="flex" flexDirection="column">
          <ListHeader {...list} display="flex">
            {columns.map((column) => (
              <HasExperimentalFeature
                key={column.id}
                feature={column.experimentalFeatures || []}
              >
                <HasPermission permission={column.permissions || []}>
                  <ListHeaderCell {...list} colId={column.id}>
                    <column.Header />
                  </ListHeaderCell>
                </HasPermission>
              </HasExperimentalFeature>
            ))}
          </ListHeader>
          <DroppableListBody
            {...list}
            droppableId={descriptor.slug}
            display="flex"
            flexDirection="column"
          >
            {nodes.map((node, index) => (
              <DraggableListRow
                {...list}
                key={node.id}
                index={index}
                node={node}
                droppableId={descriptor.slug}
                display="flex"
              >
                {columns.map((column, index) => (
                  <HasExperimentalFeature
                    key={column.id}
                    feature={column.experimentalFeatures || []}
                  >
                    <HasPermission permission={column.permissions || []}>
                      <CRUDListCell
                        {...list}
                        key={column.id}
                        node={node}
                        column={column}
                        index={index}
                        display="flex"
                        draggable
                      />
                    </HasPermission>
                  </HasExperimentalFeature>
                ))}
              </DraggableListRow>
            ))}
          </DroppableListBody>
        </List>
        <InfiniteScrollMarker {...infiniteScroll} />
        <InfiniteScrollLoader {...infiniteScroll} />
      </DragInitializer>
    );
  }

  function DefaultDataGrid({ nodes }) {
    const list = useListState();
    const columns = useMemo(
      () => [
        ...descriptor.components.columns,
        ...(descriptor.enableDisable ? [ENABLED_COLUMN] : []),
      ],
      [],
    );

    return (
      <List pb={2} {...list}>
        <ListHeader {...list}>
          {columns.map((column) => (
            <HasExperimentalFeature
              key={column.id}
              feature={column.experimentalFeatures || []}
            >
              <HasPermission permission={column.permissions || []}>
                <ListHeaderCell {...list} key={column.id} colId={column.id}>
                  <column.Header />
                </ListHeaderCell>
              </HasPermission>
            </HasExperimentalFeature>
          ))}
        </ListHeader>
        <ListBody {...list}>
          {nodes.map((node) => (
            <ListRow key={node.id} {...list}>
              {columns.map((column) => (
                <HasExperimentalFeature
                  key={column.id}
                  feature={column.experimentalFeatures || []}
                >
                  <HasPermission permission={column.permissions || []}>
                    <CRUDListCell
                      {...list}
                      key={column.id}
                      node={node}
                      column={column}
                    />
                  </HasPermission>
                </HasExperimentalFeature>
              ))}
            </ListRow>
          ))}
        </ListBody>
      </List>
    );
  }

  function CRUDListView({
    hasMore,
    loadMore,
    loading,
    nodes,
    totalCount,
    filterable,
  }) {
    const infiniteScroll = useInfiniteScrollState({
      hasMore,
      loadMore,
      loading,
    });
    const DataGridComponent = descriptor.components.DataGrid || DefaultDataGrid;
    return (
      <>
        {filterable && <ListTotalCount totalCount={totalCount} />}
        <DataGridComponent nodes={nodes} />
        <InfiniteScrollMarker {...infiniteScroll} />
        <InfiniteScrollLoader {...infiniteScroll} />
      </>
    );
  }

  const CRUDDraggableListQuery = forwardRef(
    ({ filters: baseFilters, filterable }, ref) => {
      const filters = baseFilters.search
        ? { ...baseFilters, search: String(baseFilters.search) }
        : baseFilters;

      const { query, ...options } = {
        query: descriptor.operations.ConnectionQuery,
        variables: {
          filters,
          ...descriptor.operations.operationVariables,
        },
        fetchPolicy: "network-only",
      };

      const { error, data, fetchMore, fetchMoreLoading, refetch } =
        useFetchMoreQuery(query, options);

      if (error) throw error;

      const handleLoadMore = () => {
        fetchMore({
          variables: { offset: data.connection.nodes.length },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              connection: mergeConnections(
                previousResult.connection,
                fetchMoreResult.connection,
              ),
            };
          },
        });
      };

      if (descriptor.operations.UpdatedSubscription) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useSubscription(descriptor.operations.UpdatedSubscription, {
          variables: {
            ids: data?.connection.nodes.map((node) => node.id).sort(),
          },
          skip: !data,
          onData: ({ client }) => {
            const { connection } = client.readQuery({ query, ...options });

            const sortedNodes = [...connection.nodes].sort((node1, node2) => {
              return node1.rank - node2.rank;
            });

            client.writeQuery({
              query,
              ...options,
              data: {
                connection: {
                  ...connection,
                  nodes: sortedNodes,
                },
              },
            });
          },
        });
      }

      useImperativeHandle(ref, () => ({ refetch }), [refetch]);

      if (!data) {
        return (
          <>
            {descriptor.components.SettingsButton && (
              <descriptor.components.SettingsButton />
            )}
            <CRUDExportToolbarItem filters={filters} disabled />
            <PageLoader />
          </>
        );
      }

      const { nodes } = data.connection;

      if (nodes.length === 0) {
        return (
          <>
            {descriptor.components.SettingsButton && (
              <descriptor.components.SettingsButton />
            )}
            <CRUDExportToolbarItem filters={filters} disabled />
            <EmptyState>
              <EmptyStatePicture src={faceWithMonocle} />
              <EmptyStateTitle>
                Aucun résultat ne correspond à votre recherche.
              </EmptyStateTitle>
            </EmptyState>
          </>
        );
      }

      return (
        <>
          {descriptor.components.SettingsButton && (
            <descriptor.components.SettingsButton />
          )}
          <CRUDExportToolbarItem filters={filters} />
          <CRUDDraggableListView
            query={query}
            variables={options.variables}
            nodes={nodes}
            filterable={filterable}
            totalCount={data.connection.totalCount}
            hasMore={data.connection.pageInfo.hasMore}
            loadMore={handleLoadMore}
            loading={fetchMoreLoading}
          />
        </>
      );
    },
  );

  const CRUDListQuery = forwardRef(
    ({ filters: baseFilters, filterable }, ref) => {
      const filters = baseFilters.search
        ? { ...baseFilters, search: String(baseFilters.search) }
        : baseFilters;
      const { error, data, fetchMore, fetchMoreLoading, refetch } =
        useFetchMoreQuery(descriptor.operations.ConnectionQuery, {
          variables: {
            ...(descriptor.operations.operationVariables
              ? {
                  ...descriptor.operations.operationVariables,
                  filters: {
                    ...descriptor.operations.operationVariables.filters,
                    ...filters,
                  },
                }
              : { filters }),
          },
          fetchPolicy: "network-only",
        });

      useImperativeHandle(ref, () => ({ refetch }), [refetch]);

      if (error) throw error;
      if (!data) {
        return (
          <>
            {descriptor.components.SettingsButton && (
              <descriptor.components.SettingsButton />
            )}
            <CRUDExportToolbarItem filters={filters} disabled />
            <PageLoader />
          </>
        );
      }

      const { nodes, totalCount } = data.connection;

      if (nodes.length === 0) {
        return (
          <>
            {descriptor.components.SettingsButton && (
              <descriptor.components.SettingsButton />
            )}
            <CRUDExportToolbarItem filters={filters} disabled />
            <EmptyState>
              <EmptyStatePicture src={faceWithMonocle} />
              <EmptyStateTitle>
                Aucun résultat ne correspond à votre recherche.
              </EmptyStateTitle>
            </EmptyState>
          </>
        );
      }

      function handleLoadMore() {
        fetchMore({
          variables: { offset: data.connection.nodes.length },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              connection: mergeConnections(
                previousResult.connection,
                fetchMoreResult.connection,
              ),
            };
          },
        });
      }

      return (
        <>
          {descriptor.components.SettingsButton && (
            <descriptor.components.SettingsButton />
          )}
          <CRUDExportToolbarItem filters={filters} />
          <CRUDListView
            filters={filters}
            filterable={filterable}
            nodes={nodes}
            hasMore={data.connection.pageInfo.hasMore}
            loadMore={handleLoadMore}
            loading={fetchMoreLoading}
            totalCount={totalCount}
          />
        </>
      );
    },
  );

  function CRUDList() {
    const listRef = useRef();
    const filters = descriptor.components.useFiltersState();
    const refetch = useCallback(() => listRef.current.refetch(), []);
    const draggable = Boolean(descriptor.operations.MoveNodeMutation);
    const filterable = Boolean(descriptor.components.FiltersFields);

    return (
      <CRUDListContextProvider refetch={refetch} descriptor={descriptor}>
        <descriptor.components.Filters {...filters} />
        <Section maxWidth="100%" width="100%" pt={filterable ? 0 : 3}>
          {draggable ? (
            <CRUDDraggableListQuery
              {...filters}
              filterable={filterable}
              ref={listRef}
            />
          ) : (
            <CRUDListQuery {...filters} filterable={filterable} ref={listRef} />
          )}
        </Section>
      </CRUDListContextProvider>
    );
  }

  return { List: CRUDList };
}
