import { getIn } from "final-form";
import { merge } from "lodash-es";

/**
 * Filter
 * @param {*} param0
 * @returns
 */
export function getDirtyVariables({ getters, dirtyFields }) {
  return Object.entries(dirtyFields).reduce(
    (variables, [fieldName, isDirty]) => {
      if (!isDirty) return variables;

      const getter = getters[fieldName];
      if (!getter) return variables;

      return merge(variables, getter());
    },
    {},
  );
}

/**
 * @typedef Field
 * @property {function} [format] field formatter
 * @property {function} [parse] field parser
 */

/**
 * Format values.
 * Article -> Form Data
 * @param {[Field]} fields
 * @param {object} resources
 */
export function formatValues(fields, resources) {
  return Object.keys(fields).reduce(
    (res, key) => ({
      ...res,
      [key]: getIn(fields, key).format(resources),
    }),
    {},
  );
}

/**
 * Parse values.
 * Form Data -> Update Data
 * @param {[Field]} fields
 * @param {[string]} keys
 * @param {object} values
 * @param {object} resources
 */
export function parseValues(fields, keys, values, resources) {
  return keys.reduce((res, key) => {
    return merge(res, getIn(fields, key)?.parse(values, resources) || {});
  }, {});
}
