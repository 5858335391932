import styled, { th, useTheme } from "@xstyled/styled-components";
import { transparentize } from "polished";
import * as React from "react";

const InnerSwitchContainer = styled.div`
  position: relative;
  display: inline-flex;
  padding: 2px 0;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  width: 36;
  height: 20;
  top: 0;
  left: 0;
  cursor: pointer;
  /* Disabled input controls do not fire any mouse event, and we love mouse events 🐭 ! */
  &[disabled] {
    pointer-events: none;
  }
`;

const InnerSwitch = styled.box`
  background-color: grey;
  border-radius: 10;
  width: 36;
  height: 20;
  transition: base;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  [data-switch-toggle] {
    width: 16;
    height: 16;
    border-radius: 50%;
    background-color: white;
    box-shadow: switch;
    transition: base;
    transform: translateX(-8px);
  }
  &:focus-within {
    box-shadow: 0 0 0 2px
      ${({ backgroundColor }) =>
        (p) =>
          transparentize(0.7, th.color(backgroundColor || "grey")(p))};
  }
  &[data-checked="true"] {
    background-color: ${({ backgroundColor }) => backgroundColor};
    [data-switch-toggle] {
      transform: translateX(8px);
    }
  }
  &[data-disabled="true"] {
    opacity: 0.38;
  }
`;

/**
 * @param {string} tint
 * @param {boolean} checked
 */
const useTintProps = (tint, checked) => {
  const theme = useTheme();

  if (!tint) return {};
  const backgroundColor = th.color(tint)({ theme });

  return checked ? { backgroundColor } : {};
};

/** @type {import('react').FC<any>} */
export function Switch(props) {
  const {
    onChange,
    checked,
    disabled,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    value,
    tint = "primary",
    id,
  } = props;
  const tintProps = useTintProps(tint, checked);

  return (
    <InnerSwitchContainer>
      <InnerSwitch
        data-checked={checked}
        data-disabled={disabled}
        {...tintProps}
      >
        <div data-switch-toggle />
        <Input
          type="checkbox"
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          value={value}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          id={id}
        />
      </InnerSwitch>
    </InnerSwitchContainer>
  );
}
