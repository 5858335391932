import { forwardRef } from "react";

const SvgExclamationCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.372 7.146c0 .319.026.619.077.901l.747 3.793c.038.166.102.306.191.421.102.127.204.191.306.191h.633c.089 0 .185-.064.287-.191.09-.115.154-.249.191-.403l.747-3.908a3.52 3.52 0 0 0 .077-.804V3.87a.431.431 0 0 0-.115-.287.387.387 0 0 0-.287-.115H8.774a.39.39 0 0 0-.403.402v3.276zm-.019 8.984c0 .115.044.211.134.288a.388.388 0 0 0 .287.115h2.452a.39.39 0 0 0 .403-.403v-2.451a.434.434 0 0 0-.115-.288.39.39 0 0 0-.288-.115H8.774c-.115 0-.21.038-.287.115s-.115.172-.115.288zM0 10c0-1.8.453-3.48 1.36-5.038A9.959 9.959 0 0 1 5 1.322 9.861 9.861 0 0 1 10 0c1.813 0 3.499.447 5.058 1.342a9.892 9.892 0 0 1 3.601 3.62A9.89 9.89 0 0 1 20 10c0 1.827-.447 3.506-1.341 5.039A10.11 10.11 0 0 1 15 18.679C13.48 19.56 11.813 20 10 20c-1.839 0-3.518-.447-5.038-1.341a10.054 10.054 0 0 1-3.64-3.678A9.827 9.827 0 0 1 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExclamationCircle);
export default ForwardRef;
