import { forwardRef } from "react";

const SvgLetterNewsletter = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2 13.604V0h2.223l3.021 7.676.684 1.881h.038l-.038-1.881V0h1.843v13.604H7.757l-3.211-7.98-.684-1.9h-.057l.038 1.9v7.98zm10.425 0V0h2.071v11.837h4.294v1.767zM1 18.2h18V20H1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLetterNewsletter);
export default ForwardRef;
