import { gql } from "@apollo/client";

import { ArticlePrintStatusIcon } from "@/containers/article/ArticlePrintInfosIcon";

export function PrintStateCell({ article }) {
  return (
    <div className="flex justify-center text-xl">
      <ArticlePrintStatusIcon article={article} />
    </div>
  );
}

PrintStateCell.fragments = {
  article: gql`
    fragment PrintStateCell_article on Article {
      ...ArticlePrintStatusIcon_article
    }

    ${ArticlePrintStatusIcon.fragments.article}
  `,
};
