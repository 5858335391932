import { gql } from "@apollo/client";

import { ArticleLocker } from "../ArticleLocker";

export function CurrentCell({ article }) {
  return (
    <div className="flex justify-center">
      <ArticleLocker article={article} />
    </div>
  );
}

CurrentCell.fragments = {
  article: gql`
    fragment CurrentCell_article on Article {
      ...ArticleLocker_article
    }

    ${ArticleLocker.fragments.article}
  `,
};
