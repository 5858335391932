import deepEqual from "deep-equal";
import { getIn } from "final-form";
import { useCallback } from "react";
import { useFormState } from "react-final-form";

import { FieldsetField } from "@/components/fields/Fieldset";
import {
  BooleanField,
  DateField,
  EnumField,
  FileField,
  ImageField,
  RelatedArticleField,
  RichTextField,
  StringField,
} from "@/containers/admin/CRUD/fields";

import { ThirdPartyTextField } from "./ThirdPartyTextField";

export function CustomFields({ customFields, name, required, disabled }) {
  return customFields.map((customField) => {
    switch (customField.type) {
      case "text":
      case "richText":
      case "image":
      case "file":
      case "date":
      case "article":
      case "boolean":
        return (
          <FieldsetField key={customField.slug}>
            <CustomField
              customField={customField}
              name={name}
              required={required}
              disabled={disabled}
            />
          </FieldsetField>
        );
      default:
        return null;
    }
  });
}

export function CustomField({ customField, name, required, disabled }) {
  const fieldName = `${name}.${customField.slug}`;
  const parse = useCallback(
    (value) => {
      return {
        type: customField.type,
        name: customField.slug,
        value: {
          [customField.type]: value,
        },
        multi: customField.config.multi,
      };
    },
    [customField.type, customField.slug, customField.config.multi],
  );

  const state = useFormState({
    subscription: {
      values: true, // without "values" subscription, initialValues not always updated 🤷‍♂️
      initialValues: true,
    },
  });
  const initialValue = getIn(state.initialValues, fieldName);

  switch (customField.type) {
    case "text": {
      const format = (customFieldValue) => customFieldValue?.value.text ?? null;
      const isEqual = (a, b) => a?.value.text === b?.value.text;

      switch (customField.config.appearance) {
        case "textInput":
          return (
            <StringField
              name={fieldName}
              formatBeforeValidate
              format={format}
              parse={parse}
              isEqual={isEqual}
              label={customField.config.label}
              placeholder={customField.config.placeholder}
              hint={customField.config.hint}
              required={required ?? customField.config.required}
              multiline={customField.config.textInputType === "textarea"}
              type={customField.config.textInputType}
              disabled={disabled}
            />
          );
        case "thirdParty":
          return (
            <ThirdPartyTextField
              name={fieldName}
              formatBeforeValidate
              format={format}
              parse={parse}
              isEqual={isEqual}
              label={customField.config.label}
              placeholder={customField.config.placeholder}
              hint={customField.config.hint}
              required={required ?? customField.config.required}
              disabled={disabled}
              thirdPartyScript={customField.config.thirdPartyScript}
              globalId={customField.globalId}
            />
          );
        case "checkbox": // TODO Remove this case when checkbox will be on select-v2
          return (
            <EnumField
              name={fieldName}
              label={customField.config.label}
              format={format}
              parse={parse}
              isEqual={deepEqual}
              placeholder={customField.config.placeholder}
              hint={customField.config.hint}
              required={required ?? customField.config.required}
              appearance={customField.config.appearance}
              enum={customField.config.allowedValues.reduce(
                (obj, node) => ({
                  ...obj,
                  [node.value]: node.label,
                }),
                {},
              )}
              disabled={disabled}
              initialValue={
                initialValue ??
                (customField.config.default
                  ? parse(
                      customField.config.multi
                        ? [customField.config.default]
                        : customField.config.default,
                    )
                  : undefined)
              }
              multi={customField.config.multi}
            />
          );
        case "select":
          return (
            <EnumField
              name={fieldName}
              label={customField.config.label}
              format={(v) => {
                const items = customField.config.allowedValues;
                if (customField.config.multi) {
                  return v?.value.text?.map(
                    (v) => items.find((item) => item.value === v) ?? [],
                  );
                }
                return (
                  items.find((item) => item.value === v?.value.text) ?? null
                );
              }}
              parse={(v) => {
                const finalValue = parse(v);
                if (customField.config.multi) {
                  finalValue.value.text = v.map((v) => v.value);
                } else {
                  finalValue.value.text = v?.value ?? null;
                }
                return finalValue;
              }}
              isEqual={deepEqual}
              placeholder={customField.config.placeholder}
              hint={customField.config.hint}
              required={required ?? customField.config.required}
              appearance={customField.config.appearance}
              enum={customField.config.allowedValues}
              disabled={disabled}
              clearable
              initialValue={
                initialValue ??
                (customField.config.default
                  ? parse(
                      customField.config.multi
                        ? [customField.config.default]
                        : customField.config.default,
                    )
                  : undefined)
              }
              multi={customField.config.multi}
            />
          );

        default:
          return null;
      }
    }
    case "richText":
      return (
        <RichTextField
          name={fieldName}
          format={(customFieldValue) =>
            customFieldValue?.value.richText ?? null
          }
          parse={parse}
          isEqual={(a, b) => deepEqual(a?.value.richText, b?.value.richText)}
          label={customField.config.label}
          placeholder={customField.config.placeholder}
          hint={customField.config.hint}
          required={required ?? customField.config.required}
          disabled={disabled}
        />
      );
    case "date":
      return (
        <DateField
          name={fieldName}
          formatBeforeValidate
          format={(customFieldValue) => customFieldValue?.value.date ?? null}
          parse={parse}
          isEqual={(a, b) => a?.value.date === b?.value.date}
          label={customField.config.label}
          hint={customField.config.hint}
          required={required ?? customField.config.required}
          disabled={disabled}
        />
      );
    case "image": {
      return (
        <ImageField
          name={fieldName}
          format={(customFieldValue) => customFieldValue?.value.image ?? null}
          parse={parse}
          isEqual={(a, b) => a?.value.image === b?.value.image}
          label={customField.config.label}
          placeholder={customField.config.placeholder}
          hint={customField.config.hint}
          required={required ?? customField.config.required}
          disabled={disabled}
        />
      );
    }
    case "file":
      return (
        <FileField
          name={fieldName}
          format={(customFieldValue) => customFieldValue?.value.file ?? null}
          parse={parse}
          isEqual={(a, b) => a?.value.file === b?.value.file}
          label={customField.config.label}
          placeholder={customField.config.placeholder}
          hint={customField.config.hint}
          required={required ?? customField.config.required}
          disabled={disabled}
        />
      );
    case "article":
      return (
        <RelatedArticleField
          name={fieldName}
          format={(customFieldValue) => {
            const fieldValue = customFieldValue?.value.article;
            if (Array.isArray(fieldValue)) {
              return fieldValue.map((value) => ({ id: value }));
            }
            return fieldValue ? [{ id: fieldValue }] : [];
          }}
          parse={(value) => {
            return {
              name: customField.slug,
              type: customField.type,
              multi: customField.config.multi,
              value: {
                article: customField.config.multi
                  ? value.map((value) => value.id)
                  : value[0]?.id ?? [],
              },
            };
          }}
          isEqual={deepEqual}
          label={customField.config.label}
          placeholder={customField.config.placeholder}
          limit={customField.config.limit}
          multi={customField.config.multi}
          hint={customField.config.hint}
          required={required ?? customField.config.required}
          disabled={disabled}
        />
      );

    case "boolean":
      return (
        <BooleanField
          name={fieldName}
          initialValue={
            initialValue ?? parse(customField.config.checkedDefault ?? false)
          }
          format={(customFieldValue) =>
            customFieldValue?.value.boolean ?? false
          }
          parse={parse}
          isEqual={(a, b) => a?.value.boolean === b?.value.boolean}
          label={customField.config.label}
          placeholder={customField.config.placeholder}
          disabled={disabled}
        />
      );

    default:
      return null;
  }
}
