import { useQuery } from "@apollo/client";
import styled, { x } from "@xstyled/styled-components";
import { useEffect, useMemo, useRef } from "react";
import { useField } from "react-final-form";
import {
  Select,
  SelectBadgeValue,
  SelectCaret,
  SelectClearButton,
  SelectDisclosure,
  SelectMenu,
  SelectOption,
  SelectPlaceholder,
  SelectSearch,
  SelectValue,
  useSelectState,
} from "swash/Select";
import { intersperse } from "swash/utils/intersperse";
import { useLiveRef } from "swash/utils/useLiveRef";

import {
  resolveProperty,
  useNodesToEnum,
} from "@/containers/admin/CRUD/fields/util";

const EllipsisSelectValue = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const getFormatters = ({ multi }) => {
  if (multi) {
    return {
      format: (value) => (value ? value.in : []),
      parse: (value) => (value && value.length ? { in: value } : null),
    };
  }
  return {
    format: (value) => (value ? value.eq : null),
    parse: (value) => (value ? { eq: value } : null),
  };
};

function useData({ query, variables, skip, parseNodes, valueSelector }) {
  const { data: queryData } = useQuery(query, {
    nextFetchPolicy: "cache-first",
    variables,
    skip,
  });
  const previousDataRef = useRef(undefined);
  useEffect(() => {
    if (queryData) previousDataRef.current = queryData;
  }, [queryData]);
  const data = queryData ?? previousDataRef.current;
  const parseNodesRef = useLiveRef(parseNodes);
  const nodes = useMemo(
    () => (data ? parseNodesRef.current(data) : []),
    [data, parseNodesRef],
  );
  const index = useNodesToEnum(nodes, {
    valueSelector,
    labelSelector: (node) => node,
  });
  if (!data) return null;
  return { nodes, index };
}

export function QueryFilterField({
  name,
  label,
  searchVariables = {},
  query,
  multi = true,
  labelSelector = "label",
  valueSelector = "id",
  disabled,
  parseNodes = (data) => data.connection.nodes,
  renderOptionLabel = (node) => resolveProperty(labelSelector, node),
  renderValue = renderOptionLabel,
  searchable,
  icon,
  animated = true,
  showPlaceholder = true,
  footer,
  scale = "base",
  ...others
}) {
  const formatters = getFormatters({ multi });
  const field = useField(name, { ...formatters, ...others });
  const value = formatters.parse(field.input.value);

  const minimalData = useData({
    query,
    parseNodes,
    valueSelector,
    variables: {
      ...searchVariables,
      where: {
        ...searchVariables.where,
        ...(searchable && { [valueSelector]: value }),
      },
    },
    skip: searchable && value === null,
  });

  const select = useSelectState({
    value: field.input.value,
    onChange: field.input.onChange,
    clearable: true,
    multi,
    disabled,
    searchable,
  });

  const searchResultData = useData({
    query,
    parseNodes,
    valueSelector,
    variables: {
      ...searchVariables,
      where: {
        ...searchVariables.where,
        search: select.state.search.value || null,
      },
    },
    skip: !searchable,
  });

  const resultData = searchable ? searchResultData : minimalData;

  const getNodeByValue = (value) =>
    minimalData?.index[value] ?? searchResultData?.index[value] ?? null;

  return (
    <Select>
      <SelectDisclosure {...select} scale={scale}>
        {multi ? (
          <SelectBadgeValue scale={scale} {...select}>
            {({ value: values }) => {
              const items = values
                .map((value) => {
                  const node = getNodeByValue(value);
                  return node ? renderValue(node) : null;
                })
                .filter(Boolean);
              if (items.every((item) => typeof item === "string")) {
                return items.join(", ");
              }
              return intersperse(items, <x.div h={4} />);
            }}
          </SelectBadgeValue>
        ) : (
          <SelectValue as={EllipsisSelectValue} {...select}>
            {({ value }) => {
              const node = getNodeByValue(value);
              return node ? renderValue(node) : null;
            }}
          </SelectValue>
        )}
        {searchable && !(value && !showPlaceholder) && (
          <SelectSearch {...select} placeholder={label} />
        )}
        {!searchable && (
          <SelectPlaceholder {...select} visible={multi}>
            {label}
          </SelectPlaceholder>
        )}
        <SelectCaret {...select} as={icon} animated={animated} />
        <SelectClearButton {...select} />
      </SelectDisclosure>
      <SelectMenu {...select} aria-label={label} footer={footer}>
        {resultData?.nodes.map((node) => {
          const value = resolveProperty(valueSelector, node);
          return (
            <SelectOption key={value} {...select} value={value}>
              {renderOptionLabel(node)}
            </SelectOption>
          );
        })}
      </SelectMenu>
    </Select>
  );
}
