import { forwardRef } from "react";

const SvgTimesCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.249 12.606c0 .102.038.198.115.287l1.743 1.725c.077.077.173.115.288.115s.21-.032.287-.096L10 12.319l2.299 2.299a.39.39 0 0 0 .288.115.513.513 0 0 0 .306-.096l1.724-1.743a.431.431 0 0 0 .115-.287.435.435 0 0 0-.096-.288l-2.318-2.318 2.299-2.299c.077-.076.115-.172.115-.287s-.032-.211-.096-.288l-1.743-1.743a.431.431 0 0 0-.287-.115.435.435 0 0 0-.288.096L10 7.683 7.701 5.384c-.076-.077-.172-.115-.287-.115s-.211.032-.287.096L5.383 7.108c-.076.077-.115.172-.115.287s.032.211.096.288l2.318 2.318L5.383 12.3a.487.487 0 0 0-.134.306zM0 10c0-1.749.428-3.397 1.284-4.942.447-.766.996-1.482 1.647-2.146a9.145 9.145 0 0 1 2.127-1.628A10.043 10.043 0 0 1 10 0c1.75 0 3.397.428 4.943 1.284a9.912 9.912 0 0 1 2.126 1.628 9.762 9.762 0 0 1 2.606 4.54C19.892 8.27 20 9.119 20 10c0 1.75-.428 3.397-1.284 4.943a9.603 9.603 0 0 1-1.647 2.126A9.929 9.929 0 0 1 10 20c-1.749 0-3.397-.428-4.942-1.284a10.328 10.328 0 0 1-2.146-1.628 9.243 9.243 0 0 1-1.609-2.146A9.925 9.925 0 0 1 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTimesCircle);
export default ForwardRef;
