import { forwardRef } from "react";

const SvgReload = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M19.521 7.01V1.098l-2.18 2.18c-.106-.117-.197-.242-.31-.355-3.896-3.895-10.213-3.895-14.11 0-3.895 3.897-3.895 10.213 0 14.11 3.897 3.896 10.214 3.896 14.11 0l-1.414-1.415c-3.11 3.11-8.17 3.11-11.28 0s-3.11-8.17 0-11.281c3.11-3.11 8.17-3.11 11.28 0 .113.113.198.243.303.362L13.607 7.01z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgReload);
export default ForwardRef;
