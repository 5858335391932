import { forwardRef } from "react";

const SvgChevronDoubleDown = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.023 4.161V0l7.092 6.573L17.066 0v4.138l-6.951 6.595zM3 13.428V9.291l7.115 6.548 6.951-6.548v4.137L10.115 20z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChevronDoubleDown);
export default ForwardRef;
