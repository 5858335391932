import { forwardRef } from "react";

const SvgQuestion = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.738 19.333V15.35a.63.63 0 0 1 .178-.467.641.641 0 0 1 .468-.2h4.071a.68.68 0 0 1 .467.179.678.678 0 0 1 .178.466v4.005c0 .178-.059.334-.178.467s-.274.2-.467.2H8.406a.595.595 0 0 1-.467-.2.593.593 0 0 1-.2-.467zM4 5.695c.09-1.81.69-3.211 1.802-4.204C6.929.497 8.472 0 10.43 0c1.008 0 1.98.185 2.914.556 1.009.401 1.802.994 2.381 1.78s.868 1.721.868 2.803c0 1.128-.163 1.936-.49 2.426-.178.252-.4.481-.667.689-.267.222-.683.512-1.246.867-.652.43-1.105.875-1.357 1.335-.237.445-.356 1.076-.356 1.892a.34.34 0 0 1-.111.245.305.305 0 0 1-.223.089H8.739c-.074 0-.141-.038-.2-.112-.089-.089-.133-.156-.133-.2v-1.357c0-1.394.778-2.669 2.335-3.826l.267-.2c.371-.282.66-.615.868-1.001.118-.208.178-.453.178-.735 0-.445-.149-.808-.445-1.09-.297-.267-.69-.4-1.18-.4-1.364 0-2.091.66-2.18 1.98-.015.193-.126.289-.333.289H4.334c-.104 0-.178-.03-.223-.089A.296.296 0 0 1 4 5.696z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgQuestion);
export default ForwardRef;
