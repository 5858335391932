import { gql } from "@apollo/client";
import "moment/locale/fr";
import { memo } from "react";

import { SpreadArticleLastUserUpdate } from "@/containers/article/ArticleLastUserUpdate";

const CellContent = memo(({ lastUserUpdate }) => {
  return (
    <div className="text-sm">
      <SpreadArticleLastUserUpdate lastUserUpdate={lastUserUpdate} />
    </div>
  );
});

export const UpdatedCell = ({ article }) => {
  return <CellContent lastUserUpdate={article.lastUserUpdate} />;
};

UpdatedCell.fragments = {
  article: gql`
    fragment UpdatedCell_article on Article {
      lastUserUpdate
    }
  `,
};
