import { gql } from "@apollo/client";

import { ArticleStatePublish } from "@/containers/article/ArticleStatePublish";

export const StateCell = ({ article }) => {
  return <ArticleStatePublish article={article} />;
};

StateCell.fragments = {
  article: gql`
    fragment StateCell_article on Article {
      ...ArticleStatePublish_article
    }

    ${ArticleStatePublish.fragments.article}
  `,
};
