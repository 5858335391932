import {
  ContentBlock,
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js-es";
import { fromJS } from "immutable-es";
import { flushSync } from "react-dom";

import { selectBlock } from "@/components/rich-editor/modifiers/selectBlock";
import { setContent } from "@/components/rich-editor/modifiers/setContent";

function generateRandomKey() {
  return Math.random().toString(36).substring(7);
}

/**
 * Add block to a specific index.
 * @param {import('draft-js').EditorState} editorState
 * @param {object} blockObj
 * @param {number} targetIndex
 * @param {object} [entityObj]
 * @param {object} [options]
 * @param {boolean} [options.select]
 * @param {boolean} [options.silence]
 */
export function addBlock(
  { editorState, setEditorState },
  { data, ...blockObj },
  targetIndex,
  entityObj,
  { select = true, silence = false } = {},
) {
  const { contentState, contentBlock } = (() => {
    const contentState = editorState.getCurrentContent();

    const blocks = contentState.getBlockMap().toArray();

    let contentBlock = new ContentBlock({
      //atomic blocks need a space character to be rendered
      text: blockObj.type === "atomic" ? " " : "",
      key: blockObj.key || generateRandomKey(),
      ...blockObj,
    });
    if (data) {
      contentBlock = contentBlock.set("data", fromJS(data));
    }
    const newBlocks = Array.from(blocks);
    newBlocks.splice(targetIndex, 0, contentBlock);

    const withBlock = ContentState.createFromBlockArray(newBlocks);
    if (entityObj) {
      const withEntity = withBlock.createEntity(
        entityObj.type,
        entityObj.mutability,
        entityObj.data,
      );
      const entityKey = withEntity.getLastCreatedEntityKey();
      const range = SelectionState.createEmpty(contentBlock.getKey()).set(
        "focusOffset",
        1,
      );
      return {
        contentState: Modifier.applyEntity(withEntity, range, entityKey),
        contentBlock,
      };
    }
    return { contentState: withBlock, contentBlock };
  })();

  const withBlock = silence
    ? setContent(editorState, contentState)
    : EditorState.push(editorState, contentState, "add-block");
  const selectBlockEditorState = (editorState = withBlock) =>
    selectBlock(editorState, contentBlock.getKey(), select);

  if (setEditorState) {
    // (1) Push the block
    flushSync(() => {
      setEditorState(withBlock);
    });

    flushSync(() => {
      setEditorState((editorState) => selectBlockEditorState(editorState));
    });
  }

  return selectBlockEditorState();
}
