import { forwardRef } from "react";

const SvgCamera = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.824 11.648c0 .575.203 1.065.608 1.471.405.418.896.627 1.471.627s1.065-.203 1.47-.608c.419-.418.628-.915.628-1.49s-.203-1.065-.608-1.471a2.047 2.047 0 0 0-1.471-.608c-.588 0-1.085.197-1.49.589a2.027 2.027 0 0 0-.608 1.49zm-1.922-.059c0-1.098.392-2.039 1.177-2.823.784-.772 1.725-1.157 2.823-1.157s2.039.385 2.823 1.157a3.88 3.88 0 0 1 1.157 2.823 3.972 3.972 0 0 1-1.157 2.863c-.771.784-1.705 1.176-2.803 1.176v-.019c-1.111 0-2.059-.392-2.843-1.176-.785-.785-1.177-1.732-1.177-2.843zm-4-4.805v-.333c0-.078.053-.176.157-.294.105-.105.196-.157.275-.157h1.922c.091 0 .189.052.294.157.092.105.137.203.137.294v.373c0 .079-.045.17-.137.275-.105.091-.196.137-.275.137H2.353c-.091 0-.189-.052-.294-.157-.104-.117-.157-.215-.157-.294zM.001 14.55c0 .928.196 3.588.588 3.98.379.393 1.013.589 1.902.589l14.744.059c.994 0 1.7-.203 2.118-.608.431-.419.647-1.138.647-2.157V6.844c0-.667-.268-1.248-.804-1.745-.549-.51-1.255-.765-2.117-.765H14.49l-.078-.216-.255-.862c-.104-.262-.202-.458-.294-.589s-.216-.255-.373-.372C13.216 2.098 12.687 2 11.902 2H7.824c-.654 0-1.164.092-1.53.275-.353.196-.614.516-.784.961l-.255.823-.019.236H2.51c-.876.013-1.516.215-1.922.608C.196 5.295 0 5.936 0 6.825v9.725z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCamera);
export default ForwardRef;
