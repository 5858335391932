import { ContentBlock, ContentState, EditorState } from "draft-js-es";
import { flushSync } from "react-dom";

import { acceptSelection } from "@/components/rich-editor/modifiers/acceptSelection";
import { selectBlock } from "@/components/rich-editor/modifiers/selectBlock";
import { setContent } from "@/components/rich-editor/modifiers/setContent";

/**
 * Move block to a specific index.
 * @param {{editorState: import('draft-js').EditorState, setEditorState?:(editorState:import('draft-js').EditorState) => void }} editorState
 * @param {string} blockKey
 * @param {number || null} [nextSelectedIndex]
 * @param {boolean} [silence]
 */
export function removeBlock(
  { editorState, setEditorState },
  blockKey,
  nextSelectedIndex = 0,
  silence = false,
) {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlockMap().toArray();
  const index = blocks.findIndex((block) => block.getKey() === blockKey);
  if (index === -1) return editorState;

  // Mutate the array and remove the block
  blocks.splice(index, 1);

  // Always keep one block
  if (blocks.length === 0) {
    blocks.push(
      new ContentBlock({
        text: "",
        type: "paragraph",
      }),
    );
  }
  const blockRemovedEditorState = silence
    ? setContent(
        editorState,
        ContentState.createFromBlockArray(blocks, contentState.getEntityMap()),
      )
    : EditorState.push(
        editorState,
        ContentState.createFromBlockArray(blocks, contentState.getEntityMap()),
        "remove-range",
      );

  if (nextSelectedIndex === null) {
    const withOriginalSelection = acceptSelection(
      blockRemovedEditorState,
      editorState.getSelection(),
    );
    return withOriginalSelection;
  }

  // Select the block at the same place
  const selectedBlock =
    blocks[Math.min(blocks.length - 1, index + nextSelectedIndex)] ??
    blocks[Math.min(blocks.length - 1, index)];

  const selectBlockEditorState = (editorState = blockRemovedEditorState) =>
    selectBlock(
      editorState,
      selectedBlock.getKey(),
      nextSelectedIndex !== null,
    );

  if (setEditorState) {
    // (1) Remove the block
    flushSync(() => {
      setEditorState(blockRemovedEditorState);
    });

    // (2) Then select the next block
    flushSync(() => {
      setEditorState((editorState) => selectBlockEditorState(editorState));
    });
  }

  return selectBlockEditorState();
}
