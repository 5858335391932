import { gql } from "@apollo/client";
import clsx from "clsx";

import type { CellType } from "@/containers/list/ListState";

export type SignaturesCellArticle = {
  signature: string | null;
};

export const signaturesCell: CellType<SignaturesCellArticle> = {
  key: "signatures",
  title: "Signataires",
  width: 120,
  fragment: gql`
    fragment signaturesCell_article on Article {
      signature
    }
  `,
  render: ({ node }) => (
    <div
      className={clsx("select-none text-sm", !node.signature && "text-grey-on")}
    >
      {node.signature || "Aucun"}
    </div>
  ),
};
