import { EnumFilterField } from "@/containers/search/filterFields/EnumFilterField";

const parse = (value) => {
  switch (value) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return null;
  }
};
const format = (value) => {
  switch (value) {
    case true:
      return "true";
    case false:
      return "false";
    default:
      return null;
  }
};

export function BooleanFilterField(props) {
  return (
    <EnumFilterField multi={false} parse={parse} format={format} {...props} />
  );
}
