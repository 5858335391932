import { forwardRef } from "react";

const SvgCaption = (props, ref) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M16.252 0H3.748A3.752 3.752 0 0 0 0 3.748v12.504A3.752 3.752 0 0 0 3.748 20h12.504A3.753 3.753 0 0 0 20 16.252V3.748A3.753 3.753 0 0 0 16.252 0ZM18.5 16.252a2.25 2.25 0 0 1-2.25 2.249H3.749A2.25 2.25 0 0 1 1.5 16.25V3.749A2.25 2.25 0 0 1 3.75 1.5h12.503A2.25 2.25 0 0 1 18.5 3.75v12.503ZM4.098 11.754a.749.749 0 0 1 .75-.75h1.344a.75.75 0 1 1 0 1.5H4.848a.748.748 0 0 1-.75-.75Zm4.331 0a.749.749 0 0 1 .75-.75h5.973a.75.75 0 0 1 0 1.5H9.18a.748.748 0 0 1-.75-.75Zm4.51 2.999a.749.749 0 0 1-.75.75H4.848a.75.75 0 0 1 0-1.5h7.341a.749.749 0 0 1 .75.75Zm2.986 0a.749.749 0 0 1-.75.75.76.76 0 0 1-.76-.75.74.74 0 0 1 .737-.75h.024a.75.75 0 0 1 .75.75Z"
      fill="currentColor"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCaption);
export default ForwardRef;
