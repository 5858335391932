import { forwardRef } from "react";

const SvgComments = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.791 7.942c0-.888.322-1.724.965-2.509.643-.76 1.512-1.358 2.606-1.796C10.444 3.212 11.621 3 12.895 3c1.3 0 2.497.219 3.591.656 1.068.438 1.924 1.036 2.567 1.796.631.772.947 1.596.947 2.471 0 1.274-.605 2.381-1.815 3.321l.676 2.509c.026.09.019.155-.02.194-.051.038-.122.038-.212 0l-2.857-1.487c-.927.271-1.879.406-2.857.406-1.3 0-2.497-.219-3.591-.656-1.068-.425-1.924-1.024-2.567-1.796-.644-.759-.965-1.583-.965-2.471zM0 11.321c0-1.03.418-1.969 1.255-2.819.836-.85 1.937-1.461 3.301-1.834a4.899 4.899 0 0 0-.251 1.525c0 1.146.373 2.195 1.119 3.147.734.94 1.744 1.68 3.031 2.22 1.287.554 2.69.83 4.209.83h.232c-.67.605-1.506 1.082-2.51 1.429a9.596 9.596 0 0 1-3.166.521c-1.056 0-2.027-.141-2.915-.425l-2.877 1.487c-.103.051-.18.064-.232.038-.051-.038-.064-.109-.038-.212l.695-2.548c-.592-.451-1.049-.966-1.371-1.545S0 11.951 0 11.321z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgComments);
export default ForwardRef;
