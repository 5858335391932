import { forwardRef } from "react";

const SvgChartBar = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M16.421 19.111H20V4.697h-3.579zm-5.428.001h3.579V1h-3.579zm-5.428-.001h3.461v-8.908H5.565zM0 19.131v-3.52h3.599v3.5z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgChartBar);
export default ForwardRef;
