import { gql } from "@apollo/client";
import { forwardRef, memo } from "react";
import { IoCheckmarkCircle, Scheduler, Uncheck } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { Time } from "@/components/Time";
import { EventStatusOverlay } from "@/containers/events/EventStatusOverlay";

export type DateDisplayProps = { date: string | null };

export const DateDisplay = (props: DateDisplayProps) => {
  if (!props.date) return null;
  return (
    <div>
      le <Time date={props.date} format="LLL" />
    </div>
  );
};

export type AuthorDisplayProps = { user: { fullName: string } | null };

export const AuthorDisplay = (props: AuthorDisplayProps) => {
  if (!props.user?.fullName) return null;
  return <div>par {props.user.fullName}</div>;
};

export type Event = {
  actor: {
    fullName: string;
  } | null;
  date: string;
  status: string;
};

type TooltipContentProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
};

const TooltipContent = (props: TooltipContentProps) => {
  return (
    <>
      <div className="font-semibold">{props.title}</div>
      {props.children}
    </>
  );
};

type ArticlePublishStatus =
  | "published"
  | "unpublished"
  | "scheduled"
  | "pending";

const getArticlePublishStatus = (article: {
  lastPublishedHidden: boolean;
  published: boolean;
  scheduled: boolean;
}): ArticlePublishStatus => {
  if (article.lastPublishedHidden) return "unpublished";
  if (article.published) return "published";
  if (article.scheduled) return "scheduled";
  return "pending";
};

const statusConfigs: Record<
  ArticlePublishStatus,
  { icon: JSX.Element; label: string }
> = {
  published: {
    icon: <IoCheckmarkCircle className="text-success-bg-strong" size={22} />,
    label: "Publié",
  },
  unpublished: {
    icon: <Uncheck width={22} height={22} className="text-danger-bg-strong" />,
    label: "Dépublié",
  },
  pending: {
    icon: <div className="h-2 w-2 rounded-full bg-grey-bg-strong" />,
    label: "Brouillon",
  },
  scheduled: { icon: <Scheduler width={20} height={20} />, label: "Programmé" },
};

type ArticlePublishStatusIconProps = {
  status: ArticlePublishStatus;
} & React.HTMLAttributes<HTMLDivElement>;

const ArticlePublishStatusIcon = memo(
  forwardRef<HTMLDivElement, ArticlePublishStatusIconProps>((props, ref) => {
    const config = statusConfigs[props.status];
    return (
      <div
        ref={ref}
        className="flex h-[22px] w-[22px] items-center justify-center"
        {...props}
      >
        {config.icon}
      </div>
    );
  }),
);

const getLabelForStatus = (status: ArticlePublishStatus) => {
  return statusConfigs[status].label;
};

export type ArticlePublishStatusProps = {
  article: {
    lastPublishedHidden: boolean;
    published: boolean;
    scheduled: boolean;
    scheduledAt: string | null;
    scheduledBy: {
      id: string;
      fullName: string;
    } | null;
    latestPublishEvents: {
      nodes: Event[];
    };
  };
};

const ArticlePendingStatus = memo(() => {
  return (
    <Tooltip tooltip={<TooltipContent title={getLabelForStatus("pending")} />}>
      <ArticlePublishStatusIcon status="pending" />
    </Tooltip>
  );
});

type ArticleScheduledStatusProps = {
  scheduledAt: string | null;
  scheduledBy: {
    fullName: string;
  } | null;
};

const ArticleScheduledStatus = memo((props: ArticleScheduledStatusProps) => {
  return (
    <Tooltip
      tooltip={
        props.scheduledAt ? (
          <TooltipContent
            title={
              <>
                {getLabelForStatus("scheduled")} le{" "}
                <Time date={props.scheduledAt} format="LLL" />
              </>
            }
          >
            <AuthorDisplay user={props.scheduledBy} />
          </TooltipContent>
        ) : null
      }
    >
      <ArticlePublishStatusIcon status="scheduled" />
    </Tooltip>
  );
});

type ArticlePublishedStatusProps = {
  status: "published" | "unpublished";
  events: Event[];
};

const ArticlePublishedStatus = memo((props: ArticlePublishedStatusProps) => {
  const [nonSuccessEvent = null] = props.events.filter(
    (event) => event.status !== "success",
  );
  const [event = null] = props.events;

  return (
    <EventStatusOverlay event={nonSuccessEvent} scale={0.8}>
      <Tooltip
        tooltip={
          <TooltipContent title={getLabelForStatus(props.status)}>
            <DateDisplay date={event?.date ?? null} />
            <AuthorDisplay user={event?.actor ?? null} />
          </TooltipContent>
        }
      >
        <ArticlePublishStatusIcon status={props.status} />
      </Tooltip>
    </EventStatusOverlay>
  );
});

export type ArticlePublishStatusLabelProps = {
  article: {
    lastPublishedHidden: boolean;
    published: boolean;
    scheduled: boolean;
  };
};

export const ArticlePublishStatusLabel = (
  props: ArticlePublishStatusLabelProps,
) => {
  const status = getArticlePublishStatus(props.article);
  const config = statusConfigs[status];
  return config.label as unknown as JSX.Element;
};

ArticlePublishStatusLabel.fragments = {
  article: gql`
    fragment ArticlePublishStatusLabel_article on Article {
      published
      scheduled
      lastPublishedHidden
    }
  `,
};

export const ArticlePublishStatus = ({
  article,
}: ArticlePublishStatusProps) => {
  const status = getArticlePublishStatus(article);

  switch (status) {
    case "published":
    case "unpublished":
      return (
        <ArticlePublishedStatus
          status={status}
          events={article.latestPublishEvents.nodes}
        />
      );

    case "scheduled":
      return (
        <ArticleScheduledStatus
          scheduledAt={article.scheduledAt}
          scheduledBy={article.scheduledBy}
        />
      );

    case "pending":
      return <ArticlePendingStatus />;
  }
};

ArticlePublishStatus.fragments = {
  article: gql`
    fragment ArticlePublishStatus_article on Article {
      id
      published
      scheduled
      scheduledAt
      scheduledBy {
        id
        fullName
      }
      lastPublishedHidden
      latestPublishEvents: events(
        where: {
          name: { eq: "article" }
          action: { in: ["prePublish", "published"] }
        }
        limit: 2
      ) {
        nodes {
          id
          status
          date
          actor {
            id
            fullName
          }
          ...EventStatusOverlay_event
        }
      }
    }

    ${EventStatusOverlay.fragments.event}
  `,
};
