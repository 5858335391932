import { forwardRef } from "react";

const SvgMinusCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.096 11.226c0 .115.038.211.115.287a.388.388 0 0 0 .287.115h8.985a.454.454 0 0 0 .306-.115.387.387 0 0 0 .115-.287V8.774a.387.387 0 0 0-.115-.287.388.388 0 0 0-.287-.115H5.517a.454.454 0 0 0-.306.115.387.387 0 0 0-.115.287zM0 10c0-1.827.447-3.512 1.342-5.058.447-.728.977-1.405 1.59-2.03A9.719 9.719 0 0 1 4.943 1.36C6.488.453 8.174 0 10 0c1.827 0 3.512.447 5.058 1.341A9.943 9.943 0 0 1 18.678 5C19.56 6.532 20 8.199 20 10a9.999 9.999 0 0 1-.766 3.85 9.823 9.823 0 0 1-2.146 3.238 9.926 9.926 0 0 1-3.237 2.145A10.005 10.005 0 0 1 10 20c-1.826 0-3.505-.447-5.038-1.342a9.951 9.951 0 0 1-3.64-3.64C.441 13.486 0 11.813 0 9.999z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgMinusCircle);
export default ForwardRef;
