/* eslint-disable @lemonde/import/no-illegal-import */
import { gql } from "@apollo/client";
import { x } from "@xstyled/styled-components";
import { EditorState, Modifier } from "draft-js-es";
import { truncate } from "lodash-es";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useForm } from "react-final-form";
import { Alert } from "swash/Alert";
import { Button } from "swash/Button";
import { IoOpenOutline, IoPencil, IoTrash } from "swash/Icon";
import { Link } from "swash/Link";
import { Tab, TabList, TabPanel, useTabState } from "swash/Tab";
import { useLiveRef } from "swash/utils/useLiveRef";
import {
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  useComboboxStore,
} from "swash/v2/Combobox";

import {
  CheckboxField,
  useCheckboxField,
} from "@/components/fields/CheckboxField";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { useSelectField } from "@/components/fields/SelectField";
import { Form } from "@/components/forms/Form";
import { getTextFromSelection } from "@/components/rich-editor/utils/Selection";
import { ERRORS } from "@/config/messages";
import { useSafeQuery } from "@/containers/Apollo";
import {
  EnumFiltersField,
  StringField,
  useNodesToEnumArray,
} from "@/containers/admin/CRUD";
import { checkIsAnchor, getAnchors } from "@/services/anchor";
import { mustBeURL } from "@/services/forms/validators";
import { getShortUrl } from "@/services/url";

import { useSubscribeFormValue } from "../../forms/FormSubscribe";
import { addDecorator } from "../modifiers/addDecorator";
import { setContent } from "../modifiers/setContent";
import {
  ComposeEntity,
  CompositeEntityDecoratorProps,
  compositeEntityHandler,
  getAnchorComposeEntity,
  getComposeEntitySelections,
  isActive,
  mergeCompositeEntityData,
  useCompositeEntityDecoratorState,
  withCompositeEntities,
} from "../utils/CompositeEntity";
import {
  PluginPopover,
  State,
  forceSelection,
  getAnchorEntityKey,
  usePluginPopover,
} from "../utils/PluginPopover";
import { name as anchorControlName } from "./anchor-control";
import { COMPOSE_ENTITY_TYPE as LINK_ENTITY_TYPE } from "./link-convert";

export * from "./link-convert";

export const name = "link";
export const command = "link";

const { handleEntityKeyCommand, removeEntity, addEntity } =
  compositeEntityHandler({
    command,
    composeEntityType: LINK_ENTITY_TYPE,
    getComposeEntityData: ({ composeEntity }) => {
      return { url: "", ...composeEntity };
    },
  });

type LinkDecoratorProps = CompositeEntityDecoratorProps;

function LinkDecorator({
  entityKey,
  contentState,
  children,
  filter,
}: LinkDecoratorProps) {
  const { entities, props } = useCompositeEntityDecoratorState({
    entityKey,
    filter,
    contentState,
  });
  const [linkEntity] = entities;
  if (!linkEntity) return children;
  return (
    <a
      className={`border-b border-blue-border text-primary-on transition-all
        data-[focused]:rounded-t-sm data-[hovered]:border-b-2
        data-[focused]:bg-blue-bg-hover-transparent
        data-[affiliate]:text-primary-200 data-[affiliate]:data-[hovered]:text-primary-300`}
      data-affiliate={linkEntity["affiliate"] ? "" : undefined}
      href={linkEntity["url"]}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {children}
    </a>
  );
}

const filter = ({ type }: ComposeEntity) => type === LINK_ENTITY_TYPE;

export const getInitialEditorState = ({
  editorState,
}: {
  editorState: EditorState;
}) => {
  return addDecorator(editorState, [
    withCompositeEntities({
      filter,
      component: LinkDecorator,
      strategy: (entities, index) => {
        return Boolean(index & 1) && entities.length > 0;
      },
    }),
    withCompositeEntities({
      filter,
      component: LinkDecorator,
      strategy: (entities, index) => {
        return !(index & 1) && entities.length > 0;
      },
    }),
  ]);
};

export function checkIsActive(state: State) {
  if (state.readOnly) return false;
  return isActive(LINK_ENTITY_TYPE, state);
}

function checkAnchorIsActive(state: State) {
  return state.plugins.some((plugin) => plugin.name === anchorControlName);
}

const checkIsUrl = (value?: string) => {
  return (
    value &&
    /^(https?):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@\-/]))?$/.test(
      value,
    )
  );
};

export function transformPastedText(text: string, state: State) {
  const { editorState } = state;
  const selection = editorState.getSelection();
  const hasSelection =
    selection.getAnchorOffset() !== selection.getFocusOffset();
  const url = checkIsUrl(text) ? text : "";

  if (!url || !hasSelection) {
    return { transformedText: text };
  }

  const newContentState = addEntity(state, { url });

  if (!newContentState) return;

  return { newContentState };
}

type updateLinkOptions = {
  url: string;
  text: string;
  affiliate: boolean;
};

function updateLink(
  state: State,
  composeEntityKey: string,
  { url, affiliate, text }: updateLinkOptions,
) {
  const { editorState, anchorBlock } = state;
  if (!anchorBlock) {
    // eslint-disable-next-line no-console
    console.error("Anchor block not found");
    return;
  }
  const entityKey = getAnchorEntityKey(state);
  if (!entityKey) return;
  const composeEntitySelection = getComposeEntitySelections(
    state,
    composeEntityKey,
  );
  if (!composeEntitySelection) return;

  const contentStateWithEntity = mergeCompositeEntityData(
    state,
    composeEntityKey,
    composeEntitySelection,
    { url, affiliate },
  );

  const newEditorState = setContent(
    state.editorState,
    text
      ? Modifier.replaceText(
          contentStateWithEntity,
          composeEntitySelection,
          text,
          editorState.getCurrentInlineStyle(),
          entityKey,
        )
      : contentStateWithEntity,
  );
  forceSelection(state, {
    editorState: newEditorState,
  });
}

export const handleKeyCommand = (state: State, cmd: string) => {
  return handleEntityKeyCommand({
    state,
    cmd,
  });
};

const SearchArticleQuery = gql`
  query LinkPluginSearchArticleQuery($search: String) {
    articles(
      where: {
        search: $search
        searchTemplate: titleAndChapo
        isPublished: true
      }
      limit: 10
    ) {
      nodes {
        id
        title
        url
      }
    }
  }
`;

const mustNotBeSiriusURL = (value?: string) => {
  if (!value) return undefined;

  try {
    const url = new URL(value);
    if (url.hostname.endsWith(".sirius.press"))
      return ERRORS.forms.validators.mustNotBeSiriusURL;
  } catch {
    return undefined;
  }
  return;
};

type SearchArticleQueryData = {
  articles: {
    nodes: any[];
  };
};
type SearchArticleQueryVariable = {
  search: any;
};

type UrlFieldProps = {
  name?: string;
  ref: React.ForwardedRef<HTMLElement | undefined>;
  onEscape: () => void;
};

const UrlField = memo<UrlFieldProps>(
  forwardRef<HTMLElement | undefined, UrlFieldProps>(
    ({ name = "link" }, ref) => {
      const field = useSelectField(name, {
        validate: (value?: string) => {
          return value?.startsWith("#")
            ? undefined
            : mustBeURL(value, { requireProtocol: false }) ||
                mustNotBeSiriusURL(value);
        },
        required: true,
        disabled: false,
        label: "Lien",
        id: "link",
        orientation: "horizontal",
      });

      const { value, onChange } = field.state.field.input;

      const combobox = useComboboxStore({
        value,
        setValue: onChange,
      });
      const search = combobox.useState("value");

      const { data } = useSafeQuery<
        SearchArticleQueryData,
        SearchArticleQueryVariable
      >(SearchArticleQuery, {
        variables: {
          search,
        },
      });

      const articles = data?.articles.nodes ?? [];

      return (
        <>
          {/* @ts-expect-error use a js component */}
          <FieldGroup {...field} w="100%">
            <Combobox
              ref={ref as React.RefObject<HTMLInputElement>}
              store={combobox}
              raw={false}
              placeholder="Coller un lien ou effectuer une recherche"
            />
            {/* @ts-expect-error use a js component */}
            <FieldError {...field} />
          </FieldGroup>
          {!!search && (
            <ComboboxPopover store={combobox} aria-label="Articles" gutter={4}>
              {articles.map((article) => (
                <ComboboxItem
                  store={combobox}
                  key={article.id}
                  value={article.url}
                >
                  {article.title}
                </ComboboxItem>
              ))}
              {!articles.length && (
                <div className="p-1 text-grey-on-light">
                  Aucun article trouvé.
                </div>
              )}
            </ComboboxPopover>
          )}
        </>
      );
    },
  ),
);

const AffiliatedUrlField = (props: any) => {
  const field = useCheckboxField("affiliate", {
    orientation: "vertical",
    ...props,
  });
  return (
    <>
      {/* @ts-expect-error use a js component */}
      <CheckboxField {...field}>
        <x.span fontSize="sm">Lien d’affiliation</x.span>
      </CheckboxField>
    </>
  );
};

function LinkEditor({
  state,
  composeEntity,
}: {
  state: State;
  composeEntity: ComposeEntity;
}) {
  const { hasFocus } = state;
  const url = composeEntity["url"] ?? "";
  const affiliate = composeEntity["affiliate"] ?? false;
  const urlRef = useLiveRef(url);
  const isSiriusURL = mustNotBeSiriusURL(urlRef.current);
  const stateRef = useLiveRef(state);
  const composeEntityRef = useLiveRef(composeEntity);
  const selection = getComposeEntitySelections(state, composeEntity.key);

  const selectedText = getTextFromSelection(
    state.editorState.getCurrentContent(),
    selection,
  );

  const anchorIsActive = checkAnchorIsActive(state);

  const removeIfInvalid = useCallback(() => {
    if (!urlRef.current || isSiriusURL) {
      removeEntity(stateRef.current, composeEntityRef.current);
      return true;
    }
    return false;
  }, [urlRef, isSiriusURL, stateRef, composeEntityRef]);

  const pluginPopoverState = usePluginPopover({
    onEscape: removeIfInvalid,
    state,
    initialEdit: url === "",
    keepOpenedOnClick: false,
  });
  const { edit, setEdit, inputRef } = pluginPopoverState;

  useEffect(() => {
    if (isSiriusURL) {
      setEdit(true);
    }
  }, [isSiriusURL, setEdit]);

  if (!hasFocus && !edit) return null;

  return (
    <PluginPopover pluginPopoverState={pluginPopoverState}>
      <div className="select-none rounded-md border bg-white p-2 focus:outline-none">
        {edit ? (
          <FormUrl
            url={url}
            affiliate={affiliate}
            stateRef={stateRef}
            setEdit={setEdit}
            inputRef={inputRef}
            removeIfInvalid={removeIfInvalid}
            anchorIsActive={anchorIsActive}
            text={composeEntity["text"] || selectedText}
            composeEntity={composeEntity}
          />
        ) : (
          <ArticleLink
            url={url}
            setEdit={setEdit}
            stateRef={stateRef}
            composeEntity={composeEntity}
          />
        )}
      </div>
    </PluginPopover>
  );
}

type ArticleLinkProps = {
  url: string;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  stateRef: React.MutableRefObject<State>;
  composeEntity: any;
};

const ArticleLink = memo<ArticleLinkProps>(
  ({ url, setEdit, stateRef, composeEntity }) => {
    const { lockFocus, unlockFocus } = stateRef.current;
    const isAnchor = checkIsAnchor(url);
    const anchors = getAnchors(stateRef.current);
    const missingAnchor =
      isAnchor && !anchors.some((anchor) => anchor.id === url);

    const handleMouseDown = (event: any) => {
      event.preventDefault();
      lockFocus();
    };

    useEffect(
      () => () => {
        unlockFocus();
      },
      [unlockFocus],
    );

    return (
      <div>
        <div className="flex items-center justify-between gap-2">
          <Link
            icon={<IoOpenOutline />}
            className="text-sm"
            href={url}
            target="_blank"
            onMouseDown={handleMouseDown}
          >
            {truncate(
              checkIsAnchor(url)
                ? url.replace("huit-anchor-", "")
                : getShortUrl(url),
              { length: 28 },
            )}
          </Link>
          <div className="flex gap-1">
            <Button
              iconOnly
              scale="sm"
              appearance="text"
              aria-label="Éditer le lien"
              onMouseDown={handleMouseDown}
              onClick={() => setEdit(true)}
            >
              <IoPencil />
            </Button>
            <Button
              iconOnly
              scale="sm"
              appearance="text"
              variant="danger"
              aria-label="Supprimer le lien"
              onMouseDown={handleMouseDown}
              onClick={() => removeEntity(stateRef.current, composeEntity)}
            >
              <IoTrash />
            </Button>
          </div>
        </div>
        {missingAnchor && (
          <Alert
            level="warning"
            compact
            className="mt-2"
            actions={({ buttonProps }) => (
              <>
                <Button
                  {...buttonProps}
                  onMouseDown={handleMouseDown}
                  onClick={() => setEdit(true)}
                >
                  Modifier
                </Button>
                <Button
                  {...buttonProps}
                  onMouseDown={handleMouseDown}
                  onClick={() => removeEntity(stateRef.current, composeEntity)}
                >
                  Supprimer
                </Button>
              </>
            )}
          >
            L’ancre sélectionnée n’est plus présente dans cet article.
          </Alert>
        )}
      </div>
    );
  },
);

const withHttp = (url: string) =>
  !/^https?:\/\//i.test(url) ? `http://${url}` : url;

type FormUrlProps = {
  url: string;
  affiliate: boolean;
  stateRef: React.MutableRefObject<State>;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef: React.MutableRefObject<HTMLElement | undefined>;
  removeIfInvalid: () => boolean;
  anchorIsActive: boolean;
  text: string;
  composeEntity: ComposeEntity;
};

const FormUrl = ({
  url,
  affiliate,
  stateRef,
  setEdit,
  inputRef,
  removeIfInvalid,
  anchorIsActive,
  text: initialText,
  composeEntity,
}: FormUrlProps) => {
  const tab = useTabState({
    variant: "bar",
    size: "sm",
    defaultSelectedId: checkIsAnchor(url) ? "anchor" : "link",
  });

  const refs = useLiveRef({
    tab,
    initialText,
    composeEntityKey: composeEntity.key,
  });

  const handleSubmit = useCallback(
    ({ url, affiliate, text: newText, ...values }: any) => {
      const { tab, initialText, composeEntityKey } = refs.current;
      const linkText = newText || url;
      const hasTextChanged = initialText !== linkText;
      const valueUrl = values[tab.selectedId ?? ""];
      const urlWithHttp = valueUrl.startsWith("#")
        ? valueUrl
        : withHttp(valueUrl);
      updateLink(stateRef.current, composeEntityKey, {
        url: urlWithHttp,
        affiliate,
        text: hasTextChanged ? linkText : undefined,
      });
      setEdit(false);
    },
    [setEdit, stateRef, refs],
  );

  const initialValues = useMemo(
    () => ({
      url,
      text: initialText,
      affiliate,
      link: !checkIsAnchor(url) ? url : null,
      anchor: checkIsAnchor(url) ? url : null,
    }),
    [url, affiliate, initialText],
  );

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        setTouched: (name, state) => {
          const field = state.fields[name];
          if (field) {
            field.touched = true;
          }
        },
      }}
    >
      {!anchorIsActive ? (
        <TabArticleUrl
          stateRef={stateRef}
          setEdit={setEdit}
          inputRef={inputRef}
          removeIfInvalid={removeIfInvalid}
        />
      ) : (
        <>
          <TabList state={tab} aria-label="article link">
            <Tab state={tab} id="link">
              URL ou article
            </Tab>
            <Tab state={tab} id="anchor">
              Ancre
            </Tab>
          </TabList>
          <TabPanel state={tab} tabId="link" lazy>
            <TabArticleUrl
              stateRef={stateRef}
              setEdit={setEdit}
              inputRef={inputRef}
              removeIfInvalid={removeIfInvalid}
            />
          </TabPanel>
          <TabPanel state={tab} tabId="anchor" lazy>
            <TabAnchor stateRef={stateRef} />
          </TabPanel>
        </>
      )}
    </Form>
  );
};

const TextField = memo(
  ({ onEscape, onEnter }: { onEscape: () => void; onEnter: () => void }) => {
    return (
      <>
        {/* @ts-expect-error use a js component */}
        <StringField
          scale="xs"
          name="text"
          rich
          placeholder="Texte du lien"
          required
          showTools={false}
          maxRows={3}
          onKeyDown={(event: any) => {
            switch (event.key) {
              case "Escape":
                onEscape();
                break;
              case "Enter":
                onEnter();
                break;
            }
          }}
        />
      </>
    );
  },
);

type TabArticleUrlProps = {
  stateRef: React.MutableRefObject<State>;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  inputRef: React.MutableRefObject<HTMLElement | undefined>;
  removeIfInvalid: () => boolean;
};

const TabArticleUrl = memo(
  ({ stateRef, setEdit, inputRef, removeIfInvalid }: TabArticleUrlProps) => {
    const { lockFocus } = stateRef.current;
    const refs = useLiveRef({ setEdit, forceSelection, removeIfInvalid });
    const form = useForm();

    const handleMouseDown = (event: any) => {
      event.preventDefault();
      form.submit();
      lockFocus();
    };

    const handleEnter = useCallback(() => {
      form.submit();
    }, [form]);

    const handleEscape = useCallback(() => {
      const { setEdit, removeIfInvalid, forceSelection } = refs.current;
      setEdit(false);
      if (!removeIfInvalid()) {
        forceSelection(stateRef.current);
      }
    }, [refs, stateRef]);

    return (
      <div className="mt-2 flex flex-col gap-2">
        <TextField onEscape={handleEscape} onEnter={handleEnter} />
        <UrlField ref={inputRef} onEscape={handleEscape} />
        <AffiliatedUrlField />
        <Button type="submit" scale="xs" onMouseDown={handleMouseDown}>
          Insérer le lien
        </Button>
      </div>
    );
  },
);

const TabAnchor = memo(
  ({ stateRef }: { stateRef: React.MutableRefObject<State> }) => {
    const { lockFocus } = stateRef.current;
    const anchors = getAnchors(stateRef.current);
    const anchorsIds = anchors.map(({ id }) => id);
    const anchor = useSubscribeFormValue("anchor");
    const missingAnchor = anchor && !anchorsIds.includes(anchor);
    const hasAnchors = anchors.length > 0;
    const form = useForm();

    const handleMouseDown = (event: any) => {
      event.preventDefault();
      lockFocus();
    };

    if (missingAnchor) {
      return (
        <Alert
          className="mt-2"
          level="warning"
          compact
          actions={
            hasAnchors
              ? ({ buttonProps }) => (
                  <Button
                    {...buttonProps}
                    onClick={() => form.change("anchor", null)}
                  >
                    Choisir une autre ancre
                  </Button>
                )
              : undefined
          }
        >
          L’ancre sélectionnée n’est plus présente dans cet article.
        </Alert>
      );
    }

    if (!hasAnchors) {
      return (
        <Alert level="info" compact className="mt-2">
          Aucune ancre n’est présente dans cet article. Commencez par ajouter
          une ancre à votre texte avant de faire un lien vers cette ancre.
        </Alert>
      );
    }

    return (
      <div className="mt-2 flex flex-col gap-2">
        <AnchorField anchors={anchors} />
        <Button type="submit" scale="xs" onMouseDown={handleMouseDown}>
          Insérer l’ancre
        </Button>
      </div>
    );
  },
);

const AnchorField = memo<{ anchors: any[] }>(({ anchors }) => {
  const enumValues = useNodesToEnumArray(anchors);
  return (
    <EnumFiltersField
      // @ts-expect-error use a js component
      name="anchor"
      enum={enumValues}
      format={(v: any) =>
        enumValues.find((item: any) => item.value === v) || null
      }
      parse={(v: any) => v?.value}
      label="Choisir une ancre"
    />
  );
});

export const StaticControls = (state: State) => {
  const active = checkIsActive(state);
  if (!active) return null;
  // there can be only one link by composite entity
  const [composeEntity] = getAnchorComposeEntity(LINK_ENTITY_TYPE, state);
  if (!composeEntity) return null;
  return <LinkEditor state={state} composeEntity={composeEntity} />;
};
