import { forwardRef } from "react";

const SvgFire = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2.015 14.117a7.208 7.208 0 0 1 .66-3.492c.492-1.044 1.328-1.912 2.51-2.604l-.018.056a1.57 1.57 0 0 0-.056.151c-.025.064-.05.133-.075.208-.378.931-.592 1.724-.642 2.378-.025.202-.02.365.019.491.037.138.113.296.226.472.189.264.535.409 1.038.434.717.025 1.177-.233 1.378-.774.189-.553.164-1.453-.075-2.699-.013-.05-.088-.459-.226-1.227l-.095-.509c-.05-.29-.094-.579-.132-.869a5.988 5.988 0 0 1-.076-.774 3.992 3.992 0 0 1 0-.773c.139-1.397.83-2.737 2.076-4.02.227-.214.378-.353.453-.415L9.169 0c-.076.692 0 1.522.226 2.491.227.956.554 1.869.98 2.737.429.868.895 1.598 1.398 2.189.516.592.975.925 1.378 1 .227.05.403.019.529-.094.113-.114.17-.309.17-.585-.013-.705-.41-1.888-1.19-3.548a10.05 10.05 0 0 1 2.416 1.585 8.499 8.499 0 0 1 1.66 2.076c.794 1.422 1.178 2.982 1.152 4.681 0 .793-.094 1.604-.283 2.435-.09.364-.183.704-.284 1.019-.1.302-.245.635-.434 1.001a5.377 5.377 0 0 1-.68 1c-.238.29-.553.573-.943.85s-.83.51-1.321.698a8.638 8.638 0 0 1-1.944.453c-.478.05-.75-.063-.811-.34-.051-.264.082-.66.396-1.189.264-.654.446-1.12.547-1.397.088-.277.158-.61.208-1.001.075-.579-.038-1.264-.34-2.057a8.299 8.299 0 0 0-1.265-2.17c.025.654-.056 1.446-.245 2.378a13.329 13.329 0 0 1-.906 2.699c-.465 1.006-1.057 1.768-1.774 2.284-.717.528-1.53.698-2.435.51-1.812-.68-2.888-2.152-3.227-4.417a11.363 11.363 0 0 1-.133-1.17z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFire);
export default ForwardRef;
