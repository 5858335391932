import { parsePhoneNumber } from "awesome-phonenumber";
import { useEffect, useMemo, useState } from "react";
import { useFormState } from "react-final-form";
import { Alert } from "swash/Alert";
import { Button } from "swash/Button";
import { useToaster } from "swash/Toast";

import { PhoneNumberField } from "@/components/fields/PhoneNumberField";
import { TwoFactorField } from "@/components/fields/TwoFactorField";
import {
  FORM_ERROR,
  Form,
  extractGraphQlErrors,
} from "@/components/forms/Form";
import { FormAction, FormActions } from "@/components/forms/FormActions";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import {
  FormFooter,
  FormFooterActions,
  FormFooterSide,
} from "@/components/forms/FormFooter";
import { FormSubmit } from "@/components/forms/FormSubmit";

import { Step } from "./TwoFactorSteps";

function PhoneNumberRequestFormBody({
  updating,
  cancel,
  showAuthenticatorDialog,
}) {
  const { submitting, submitError } = useFormState({
    subscription: {
      submitting: true,
      submitError: true,
    },
  });

  return (
    <>
      <div className="pt-2 pb-4 text-dusk-on-light">
        {`Afin ${
          updating ? "de mettre à jour" : "d’activer"
        }  l’authentification en deux étapes, nous avons besoin de votre numéro de téléphone
        portable.`}
      </div>
      {submitError ? (
        <Alert level="danger" className="mb-4">
          {submitError}
        </Alert>
      ) : null}
      <PhoneNumberField name="phoneNumber" disabled={submitting} required />
      <FormFooter>
        <FormFooterSide />
        <FormFooterActions>
          <FormActions alignItems="center">
            {showAuthenticatorDialog ? (
              <FormAction>
                <Button
                  scale="sm"
                  appearance="text"
                  onClick={() => showAuthenticatorDialog()}
                >
                  Utiliser une application d’authentification
                </Button>
              </FormAction>
            ) : null}
            {cancel ? (
              <FormAction>
                <Button
                  type="button"
                  variant="secondary"
                  appearance="text"
                  onClick={cancel}
                >
                  Annuler
                </Button>
              </FormAction>
            ) : null}
            <FormAction>
              <FormSubmit>Valider</FormSubmit>
            </FormAction>
          </FormActions>
        </FormFooterActions>
      </FormFooter>
    </>
  );
}

function PhoneNumberValidationFormBody({
  visible,
  phoneNumber,
  sendPhoneNumber,
}) {
  const toaster = useToaster();
  const {
    dirtySinceLastSubmit,
    submitting,
    submitError,
    submitErrors,
    submitSucceeded,
  } = useFormState({
    subscription: {
      values: true,
      dirtySinceLastSubmit: true,
      submitting: true,
      submitError: true,
      submitErrors: true,
      submitSucceeded: true,
    },
  });

  const formattedPhoneNumber = phoneNumber
    ? parsePhoneNumber(phoneNumber).number?.international ?? ""
    : "";

  return (
    <>
      <div className="my-2">
        Renseignez le code à usage unique envoyé par SMS au:{" "}
        {formattedPhoneNumber}.
      </div>
      {submitErrors && !dirtySinceLastSubmit ? (
        <Alert level="danger" className="mt-2">
          {submitError || submitErrors.code}
        </Alert>
      ) : null}
      <div className="-ml-6 flex flex-col items-center gap-4">
        <div style={{ width: "300px", height: "72px" }}>
          {visible && (
            <TwoFactorField
              name="code"
              disabled={submitting || submitSucceeded}
              valid={submitSucceeded}
            />
          )}
        </div>
        <div>
          <Button
            scale="sm"
            appearance="text"
            onClick={async () => {
              try {
                await sendPhoneNumber(phoneNumber);
                toaster.success("Un code vient de vous être renvoyé");
              } catch (error) {
                const graphQLErrors = extractGraphQlErrors(error);
                const errorMessage = graphQLErrors[FORM_ERROR];
                toaster.warning(errorMessage);
              }
            }}
          >
            Renvoyer le code
          </Button>
        </div>
      </div>
    </>
  );
}

const getPhoneNumberFormInitialValues = (phoneNumber) => {
  const regionCode = phoneNumber
    ? parsePhoneNumber(phoneNumber).regionCode ?? "FR"
    : "FR";

  return {
    phoneNumber: { number: phoneNumber || "", regionCode },
  };
};

export function TwoFactorPhoneNumberSteps({
  visible,
  phoneNumber,
  updating,
  cancel,
  finish,
  sendPhoneNumberValidation,
  validatePhoneNumber,
  showAuthenticatorDialog,
}) {
  const [state, setState] = useState({
    phoneNumber,
    step: 1,
  });

  const initialValues = useMemo(
    () => getPhoneNumberFormInitialValues(phoneNumber),
    [phoneNumber],
  );

  useEffect(() => {
    if (!visible) {
      setState({ phoneNumber, step: 1 });
    }
  }, [visible, phoneNumber]);

  return (
    <>
      <Step
        num="1"
        label={
          updating
            ? "Mise à jour du numéro de téléphone"
            : "Ajout d'un numéro de téléphone"
        }
        disabled={state.step !== 1}
      >
        {visible && (
          <Form
            initialValues={initialValues}
            onSubmit={async ({ phoneNumber }) => {
              const isoPhoneNumber = parsePhoneNumber(phoneNumber.number, {
                regionCode: phoneNumber.regionCode,
              }).number?.e164;

              if (!isoPhoneNumber) return { [FORM_ERROR]: "Numéro invalide" };

              const result = await sendPhoneNumberValidation(isoPhoneNumber);

              if (result?.error) return result.error;

              setState({ phoneNumber: isoPhoneNumber, step: 2 });
            }}
          >
            <PhoneNumberRequestFormBody
              updating={updating}
              cancel={cancel}
              showAuthenticatorDialog={showAuthenticatorDialog}
            />
          </Form>
        )}
      </Step>
      <Step
        num="2"
        label="Validation du numéro de téléphone"
        disabled={state.step !== 2}
      >
        {visible && (
          <Form
            initialValues={{ code: "" }}
            onSubmit={async ({ code }) => {
              const result = await validatePhoneNumber({
                phoneNumber: state.phoneNumber,
                code,
              });

              if (result?.error) return result.error;

              setTimeout(() => {
                setState({ phoneNumber: state.phoneNumber, step: 3 });
              }, 800);
            }}
          >
            <FormAutoSubmit />
            <PhoneNumberValidationFormBody
              visible={state.step === 2}
              phoneNumber={state.phoneNumber}
              sendPhoneNumber={sendPhoneNumberValidation}
            />
          </Form>
        )}
      </Step>
      <Step
        num="3"
        label={
          updating
            ? "Authentification en deux étapes mise à jour"
            : "Authentification en deux étapes activée"
        }
        disabled={state.step !== 3}
      >
        <div className="py-2">
          Lors de vos prochaines connexions, Sirius pourra vous demander de
          renseigner un code envoyé par SMS.
        </div>
        <div className="flex justify-end gap-2 pt-6">
          {showAuthenticatorDialog && (
            <Button
              type="button"
              variant="secondary"
              appearance="text"
              onClick={showAuthenticatorDialog}
            >
              Associer une application d’authentification
            </Button>
          )}
          <Button type="button" onClick={finish}>
            Terminer
          </Button>
        </div>
      </Step>
    </>
  );
}
