/* eslint-disable @lemonde/import/no-illegal-import */
import clsx from "clsx";
import type { EditorState, SelectionState } from "draft-js-es";
import { RefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { useIsomorphicLayoutEffect } from "swash/utils/useIsomorphicLayoutEffect";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";

import { formatSearchParams, useSearchParams } from "@/components/SearchParams";
import edit from "@/components/icons/Edit";
import { addDecorator } from "@/components/rich-editor/modifiers/addDecorator";
import { setContent } from "@/components/rich-editor/modifiers/setContent";
import {
  ComposeEntity,
  CompositeEntityDecoratorProps,
  compositeEntityHandler,
  getAnchorComposeEntity,
  getComposeEntitySelections,
  isActive,
  mergeCompositeEntityData,
  useCompositeEntityDecoratorState,
  withCompositeEntities,
} from "@/components/rich-editor/utils/CompositeEntity";
import {
  PluginPopover,
  State,
  forceSelection,
  usePluginPopover,
} from "@/components/rich-editor/utils/PluginPopover";
import { getTextFromSelection } from "@/components/rich-editor/utils/Selection";
import { useReadOnly } from "@/containers/ReadOnly";
import { CreateArticleCommentForm } from "@/containers/article/panels/comments/ArticleCommentForm";
import { ArticleCommentsArticleQuery } from "@/containers/article/panels/comments/ArticleComments";
import { CommentScopeProvider } from "@/containers/article/panels/comments/CommentScopeProvider";
import { useArticleSelector } from "@/containers/routes/article/ArticleContext";
import { useArticleEditorShifting } from "@/containers/routes/article/editor/ArticleEditorResizeObserver";

import { COMPOSE_ENTITY_TYPE as COMMENT_ENTITY_TYPE } from "./convert";

export * from "./convert";

export const name = "comment";
export const command = "comment";

const { handleEntityKeyCommand, removeEntity } = compositeEntityHandler({
  command,
  composeEntityType: COMMENT_ENTITY_TYPE,
  getComposeEntityData: ({ composeEntity }) => {
    return { id: -1, resolved: false, ...composeEntity };
  },
  stackable: true,
});

type CommentDecoratorProps = CompositeEntityDecoratorProps & {
  className: string;
};

function CommentDecorator({
  entityKey,
  contentState,
  children,
  className,
  filter,
}: CommentDecoratorProps) {
  const { entities, focused, props } = useCompositeEntityDecoratorState({
    entityKey,
    filter,
    contentState,
  });
  //  prevent scroll when closed comment panel
  const previousFocused = usePrevious(focused);
  const ref = useRef<HTMLSpanElement>();
  const commentsRef = useLiveRef(entities);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const editorShifting = useArticleEditorShifting();
  const readOnly = useReadOnly();
  const isCommentFocus = useMemo(() => {
    const searchCommentParams: number[] = searchParams.commentIds ?? [];
    return searchCommentParams.some(
      (id) => commentsRef.current?.some(({ id: refId }) => refId === id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.commentIds]);

  const scrollToComment = useCallback(
    (options?: ScrollIntoViewOptions) => {
      if (ref.current && isCommentFocus) {
        ref.current?.scrollIntoView({
          behavior: "auto",
          block: "center",
          ...options,
        });
      }
    },
    [isCommentFocus],
  );

  useIsomorphicLayoutEffect(() => {
    if (editorShifting) return;
    scrollToComment();
  }, [editorShifting]);

  useEffect(() => {
    if (!focused && !previousFocused) {
      scrollToComment({ behavior: "smooth" });
    }
  }, [focused, previousFocused, scrollToComment]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      ref={ref as RefObject<HTMLSpanElement>}
      className={clsx(
        className,
        `bg-opacity-[var(--intensity)] transition-all
        data-[hovered]:[--tw-bg-opacity:calc(var(--intensity)+.125)] data-[focused]:[--tw-bg-opacity:calc(var(--intensity)+.25)]
        data-[focused]:data-[hovered]:[--tw-bg-opacity:calc(var(--intensity)+.375)]`,
        readOnly && "cursor-pointer",
      )}
      {...props}
      data-focused={isCommentFocus ? "" : props["data-focused"]}
      onMouseDown={() => {
        const selectedComment = commentsRef.current.at(-1)?.["id"] ?? null;
        if (!readOnly || !selectedComment || selectedComment.resolved) return;
        navigate({
          pathname: "collaborative/comments",
          search: formatSearchParams(
            {
              commentIds: [selectedComment],
            },
            {},
          ),
        });
      }}
    >
      {children}
    </span>
  );
}

const filter = ({ type, resolved }: ComposeEntity) =>
  !resolved && type === COMMENT_ENTITY_TYPE;

export const getInitialEditorState = (state: State) => {
  const { editorState } = state;
  return addDecorator(editorState, [
    withCompositeEntities({
      filter,
      component: CommentDecorator,
      strategy: (comments) => comments.length === 1,
      props: {
        className: "[--intensity:.25] bg-orange-bg-hover",
      },
    }),
    withCompositeEntities({
      filter,
      component: CommentDecorator,
      strategy: (comments) => comments.length === 2,
      props: {
        className: "[--intensity:.5] bg-orange-bg-hover",
      },
    }),
    withCompositeEntities({
      filter,
      component: CommentDecorator,
      strategy: (comments) => comments.length > 2,
      props: {
        className: "[--intensity:.75] bg-orange-bg-hover",
      },
    }),
  ]);
};

export const usePluginProps = (state: State) => {
  const { editorState } = state;
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const selection = editorState.getSelection();
  const comments = getAnchorComposeEntity(COMMENT_ENTITY_TYPE, state);
  const commentIds = comments
    .filter(({ id, resolved }) => id > 1 && !resolved)
    .map(({ id }) => id);
  const commentIdsRef = useLiveRef(commentIds);
  // On focus draft restore focus, and if it was a comment that reopen the panel
  const previousFocus = usePrevious(selection.getHasFocus());
  const match = Boolean(
    matchPath(
      { path: "articles/:articleId/collaborative/comments" },
      location.pathname,
    ),
  );

  useEffect(() => {
    if (previousFocus && selection.getHasFocus()) {
      if (!match && commentIdsRef.current.length) {
        navigate("collaborative/comments");
      }
    }
  }, [commentIdsRef, match, navigate, previousFocus, selection]);

  useEffect(() => {
    if (match && previousFocus && selection.getHasFocus()) {
      const commentIds = commentIdsRef.current;
      setSearchParams({ ...(commentIds.length ? { commentIds } : null) });
    }
  }, [commentIdsRef, match, previousFocus, selection, setSearchParams]);
};

export function checkIsActive(state: State) {
  return isActive(COMMENT_ENTITY_TYPE, state);
}

export const handleKeyCommand = (state: State, cmd: string) =>
  handleEntityKeyCommand({
    state,
    cmd,
  });

const updateComment = (
  state: State,
  commentKey: string,
  selection: SelectionState,
  comment: Partial<ComposeEntity>,
) => {
  const contentStateWithEntity = mergeCompositeEntityData(
    state,
    commentKey,
    selection,
    // use comment id as key
    { ...comment, key: String(comment["id"]), resolved: false },
  );

  const newEditorState = setContent(state.editorState, contentStateWithEntity);
  forceSelection(state, {
    editorState: newEditorState,
  });
};

type FormCommentProps = {
  inputRef: React.MutableRefObject<HTMLElement | undefined>;
  state: State;
  comment: ComposeEntity;
  onEscape: () => void;
};

const useStoredIdentifier = (editorState: EditorState) => {
  const selectedText = getTextFromSelection(
    editorState.getCurrentContent(),
    editorState.getSelection(),
  );
  return `${editorState.getSelection().getAnchorKey()}-${selectedText}`;
};

const FormComment = ({
  inputRef,
  state,
  comment,
  onEscape,
}: FormCommentProps) => {
  const article = useArticleSelector((article) => article);
  const stateRef = useLiveRef(state);
  const { editorState } = stateRef.current;
  const storedIdentifier = useStoredIdentifier(editorState);
  const queryOptions = {
    query: ArticleCommentsArticleQuery,
    variables: { articleId: article.id },
  };
  const selection = getComposeEntitySelections(stateRef.current, comment.key);
  const selectedText = getTextFromSelection(
    editorState.getCurrentContent(),
    selection,
  );

  const refs = useRef({ selection, comment });

  const handleCommentCreated = useCallback(
    ({ id }: { id: number }) => {
      const { selection, comment } = refs.current;
      if (selection) {
        updateComment(state, comment.key, selection, { ...comment, id });
      }
    },
    [state],
  );

  return (
    <CreateArticleCommentForm
      // @ts-expect-error use a js component
      confirmMessage="Ajouter"
      placeholder="Ajouter un commentaire..."
      onCommentCreated={handleCommentCreated}
      onEscape={onEscape}
      ref={inputRef}
      article={article}
      queryOptions={queryOptions}
      storedIdentifier={storedIdentifier}
      anchorText={selectedText}
      autoFocus
      className="rounded-md border border-grey-border-light bg-white pt-4 shadow-sm"
    />
  );
};

const CommentEditor = ({
  state,
  comment,
}: {
  state: State;
  comment: ComposeEntity;
}) => {
  const commentRef = useLiveRef(comment);
  const stateRef = useLiveRef(state);

  const removeComment = useCallback(() => {
    if (commentRef.current?.["id"] === -1) {
      removeEntity(stateRef.current, commentRef.current);
      return true;
    }
    return false;
  }, [commentRef, stateRef]);

  const pluginPopoverState = usePluginPopover({
    onEscape: removeComment,
    state,
    initialEdit: true,
    keepOpenedOnClick: false,
    maxWidth: 470,
  });
  const { setEdit, inputRef } = pluginPopoverState;
  const refs = useLiveRef({ setEdit, forceSelection, removeComment });
  const handleEscape = useCallback(() => {
    const { setEdit, removeComment, forceSelection } = refs.current;
    setEdit(false);
    if (!removeComment()) {
      forceSelection(stateRef.current);
    }
  }, [refs, stateRef]);

  if (!edit) return null;

  return (
    <PluginPopover pluginPopoverState={pluginPopoverState}>
      <FormComment
        inputRef={inputRef}
        state={state}
        comment={comment}
        onEscape={handleEscape}
      />
    </PluginPopover>
  );
};

export const StaticControls = (state: State) => {
  const active = checkIsActive(state);
  if (!active) return null;
  const comments = getAnchorComposeEntity(COMMENT_ENTITY_TYPE, state);
  const comment = comments.find(({ id }: ComposeEntity) => id === -1) ?? null;
  if (!comment) return null;
  return (
    <CommentScopeProvider scope="text">
      <CommentEditor state={state} comment={comment} />
    </CommentScopeProvider>
  );
};
