import { forwardRef } from "react";

const SvgEllipsis = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M16.497 11.503H20V8h-3.503zm-8.043 0h3.503V8H8.454zM0 11.524V8.001h3.522v3.503z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEllipsis);
export default ForwardRef;
