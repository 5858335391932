import { forwardRef } from "react";

const SvgLetterSponso = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1 18.2h18V20H1zm4.109-4.231c-2.316 0-3.735-1.4-4.109-4.183l1.7-.43c.224 2.26 1.232 3.082 2.446 3.082 1.176 0 1.867-.822 1.867-1.98 0-1.27-.635-1.737-2.371-2.727-2.13-1.195-3.156-2.315-3.156-4.313C1.486 1.494 2.886 0 5.108 0c2.54 0 3.474 1.457 3.773 3.716l-1.68.449c-.281-1.663-.748-2.652-2.093-2.652-1.083.018-1.68.765-1.68 1.68 0 1.12.523 1.625 2.054 2.54 2.222 1.307 3.51 2.036 3.51 4.351 0 2.185-1.288 3.885-3.884 3.885zm6.256-.299V.3h3.585c2.708 0 3.68 1.326 3.68 3.922s-1.29 3.903-3.83 3.903h-1.418v5.546zm2.017-7.134h1.232c1.42 0 1.905-.728 1.905-2.297 0-1.606-.486-2.353-1.905-2.353h-1.232z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLetterSponso);
export default ForwardRef;
