import { forwardRef } from "react";

const SvgInfo = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.661 1.554c0-.476.143-.851.429-1.125.286-.286.661-.429 1.125-.429.547 0 .97.137 1.268.411.286.274.429.655.429 1.143 0 .44-.143.786-.429 1.036-.298.25-.714.375-1.25.375-.464 0-.845-.125-1.143-.375-.286-.262-.429-.608-.429-1.036zM7 18.822c0-.512.107-1.25.321-2.214L9.25 7.822l-1.732-.215v-.732c.904-.333 1.648-.571 2.232-.714a6.786 6.786 0 0 1 1.607-.214l.5.321-2.5 12.036h1.964v.679a5.315 5.315 0 0 1-1.357.732c-.512.19-.994.285-1.446.285-.464 0-.833-.113-1.107-.339a1.123 1.123 0 0 1-.411-.84z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInfo);
export default ForwardRef;
