import { forwardRef } from "react";

const SvgTags = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.035 2h2.029c.116 0 .253.011.411.032a.96.96 0 0 1 .317.079c.117.074.259.196.428.365l8.574 8.573a.69.69 0 0 1 .206.459c0 .18-.063.343-.19.491l-6.006 6.006a.275.275 0 0 1-.237.08.357.357 0 0 1-.238-.127l-1.077-1.094 4.849-4.849c.169-.19.253-.354.253-.491 0-.138-.084-.291-.253-.46zM2.029 5.391c0 .359.132.671.396.935s.581.396.951.396c.38 0 .703-.127.967-.38.253-.265.38-.581.38-.951s-.127-.692-.38-.967a1.297 1.297 0 0 0-.951-.396c-.369 0-.692.132-.967.396a1.317 1.317 0 0 0-.396.967zM0 8.355V2.349c0-.095.032-.174.096-.238A.298.298 0 0 1 .333 2h6.022c.085 0 .185.021.301.063a.926.926 0 0 1 .27.174l9.048 9.049c.074.064.111.143.111.238s-.032.174-.095.237l-6.212 6.212c-.063.074-.142.111-.237.111s-.18-.032-.254-.095L.238 8.94a.74.74 0 0 1-.159-.269A.772.772 0 0 1 0 8.354z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTags);
export default ForwardRef;
