import { ApolloLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import * as Sentry from "@sentry/browser";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import { sha256 } from "crypto-hash";
import { createClient } from "graphql-ws";

import { APP_UUID } from "@/containers/AppUuid";
import {
  checkHasTimeoutError,
  checkIsNetworkError,
  checkIsOfflineError,
} from "@/services/apollo/error";

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (checkHasTimeoutError(graphQLErrors)) {
      Sentry.captureMessage("Retry timed out request", {
        context: { graphQLErrors },
      });
      // retry request once
      return forward(operation);
    }

    // GraphQL errors are logged server-side
    // We only need to log network errors client-side
    if (!networkError) return;

    if (checkIsNetworkError(networkError, 401)) {
      window.location.replace(
        `/login?f=${encodeURIComponent(window.location.pathname)}`,
      );
      return;
    }

    if (checkIsOfflineError(networkError)) {
      return;
    }

    const { name, statusCode, result } = networkError;
    const body = (() => {
      try {
        return JSON.stringify(result);
      } catch (error) {
        return result;
      }
    })();
    const contexts = {
      networkError: { name, statusCode, body },
    };
    Sentry.captureException(networkError, { contexts });
  },
);

const contextLink = setContext(() => ({
  headers: {
    "x-sirius-app-uuid": APP_UUID,
  },
}));

const uploadLink = createUploadLink({
  uri: "/api/graphql",
  credentials: "include",
  headers: { "Apollo-Require-Preflight": "true" },
});

const retryLink = new RetryLink();

const persistedQueryLink = createPersistedQueryLink({ sha256 });

const wsClient = createClient({
  url: `wss://${window.location.hostname}/api/subscriptions`,
});

wsClient.on("opened", () => {
  document.dispatchEvent(new Event("sirius-revalidate"));
});

const wsLink = new GraphQLWsLink(wsClient);

export const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  ApolloLink.from([errorLink, retryLink, wsLink]),
  ApolloLink.from([
    errorLink,
    contextLink,
    retryLink,
    persistedQueryLink,
    uploadLink,
  ]),
);
