import { memo } from "react";

import { BooleanFilterField } from "@/containers/search/filterFields/BooleanFilterField";

const options = {
  true: "Autorisées sur le numérique",
  false: "Non-autorisées sur le numérique",
};

const name = "webAuthorized";

const Filter = memo(() => {
  return (
    <BooleanFilterField
      name={name}
      label="Autorisation numérique"
      enum={options}
    />
  );
});

export const WebAuthorizedFilter = {
  name,
  element: <Filter />,
  initialValue: true,
};
