import { forwardRef } from "react";

const SvgEraseText = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m11.234 11.949 1.983-2.141h3.513l-1.994 2.141zm-.612-.01c-.028.288.025.478.158.569h4.357L20 7.076v-.549a.536.536 0 0 0-.074-.306.657.657 0 0 0-.454-.221h-3.798l-4.884 5.422c-.098.113-.154.285-.168.517zm-2.996 1.898h.928v-.517l.074.053c.029.028.043.039.043.032s.017-.042.052-.106a.23.23 0 0 0 .032-.052.316.316 0 0 1 .021-.064.133.133 0 0 0 .032-.063.273.273 0 0 1 .032-.043c.042-.098.098-.207.169-.327a1.476 1.476 0 0 1-.444-.443v.053l-.021-.064h.01V9.839c.169-.232.391-.426.665-.581.281-.147.57-.221.865-.221.303 0 .573.067.812.2.239.134.426.32.559.559l.032-.031.085-.074.221-.243.348-.348c-.457-.633-1.111-.949-1.962-.949-.654 0-1.192.225-1.614.675V6.315h-.939v7.521zm-5.485-2.668.158-.401c.092-.225.173-.419.243-.581l.327-.812.728-1.782 1.434 3.576zM0 13.837h1.097l.358-.865c.19-.464.31-.759.359-.886H5.39l.697 1.751h1.097l-3.08-7.521h-.992z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEraseText);
export default ForwardRef;
