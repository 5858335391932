import { forwardRef } from "react";

const SvgFontCaseAlt = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12.317 11.459c0-.407.153-.743.458-1.01.292-.267.673-.401 1.143-.401.331 0 .655.064.973.191a2.3 2.3 0 0 1 .82.553v1.297c-.407.508-1.004.762-1.793.762-.457 0-.838-.127-1.143-.381a1.37 1.37 0 0 1-.458-1.01zm-.934 0c0 .636.229 1.163.686 1.583.47.419 1.011.629 1.621.629.788 0 1.462-.236 2.021-.706v.553h.934V8.98c0-.712-.21-1.252-.629-1.62-.432-.356-1.023-.534-1.773-.534-.559 0-1.042.089-1.449.267s-.788.471-1.144.878l.515.724c.61-.699 1.265-1.048 1.964-1.048.432 0 .801.114 1.106.343.318.242.477.566.477.973v1.01c-.509-.47-1.176-.705-2.002-.705-.661 0-1.214.19-1.659.572-.445.394-.667.934-.667 1.62zm-6.387-1.106c.331-.864.61-1.564.839-2.098l.42-1.029c.012-.013.152-.363.419-1.049l1.659 4.175H4.997zM2.46 13.461h1.297a9.63 9.63 0 0 1 .191-.458c.114-.28.184-.464.21-.553.279-.673.419-1.016.419-1.029h4.195l.781 2.04h1.297L7.247 4.672H6.122zM.019 7.15h1.183c.839-1.983 2.053-3.527 3.641-4.633C6.432 1.411 8.307.858 10.468.858c1.067 0 2.205.248 3.413.744a9.47 9.47 0 0 1 3.107 2.059l.954.934-1.03.896h2.841V2.727l-.934.934-1.22-1.01C16.391 1.646 15.215.954 14.072.573 12.928.191 11.562.001 9.972 0 7.685 0 5.689.61 3.986 1.831 2.295 3.051.973 4.824.02 7.15zM0 17.216l.935-.934 1.162 1.03c1.144 1.043 2.238 1.754 3.28 2.136 1.042.368 2.338.552 3.889.552 2.351 0 4.455-.629 6.311-1.887 1.843-1.271 3.228-3.038 4.156-5.301h-1.201c-.852 2.009-2.059 3.559-3.623 4.652-1.576 1.093-3.451 1.64-5.624 1.64-1.055 0-2.193-.254-3.413-.763-1.208-.508-2.244-1.194-3.108-2.059l-.934-.934 1.01-.877H0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFontCaseAlt);
export default ForwardRef;
