import { forwardRef } from "react";

const SvgExchange = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.16 6.43h3.34v11.64c0 .235.078.437.234.606a.84.84 0 0 0 .586.234h2.5a.863.863 0 0 0 .606-.234.84.84 0 0 0 .234-.586V6.43H20L14.59 1zM0 13.93h3.321V2.25c0-.221.084-.417.254-.586a.837.837 0 0 1 .586-.234h2.5c.234 0 .43.078.586.234a.766.766 0 0 1 .254.586v11.66h3.32l-5.39 5.41z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExchange);
export default ForwardRef;
