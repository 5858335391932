import { gql } from "@apollo/client";
import { memo } from "react";

import { useDragUrl } from "@/containers/Dnd";
import {
  ImageNode,
  ImageNodeMarker,
} from "@/containers/editor/nodes/ImageNode";
import { ImageNodeFragment } from "@/containers/editor/nodes/ImageNodeFragment";

import { MediaLayout } from "./MediaLayout";
import { getMediaEventTooltip } from "./util";

export const MediaImage = memo(
  ({
    media: {
      media: image,
      metadata,
      published,
      recommendedForLmm,
      ...mediaProps
    },
    draggable,
    onDelete,
    deletable,
    editable,
    expanded,
    ...props
  }) => {
    const [, dragRef] = useDragUrl(image.url);

    return (
      <MediaLayout
        draggable={draggable}
        ref={draggable ? dragRef : null}
        variant={image.expired ? "danger" : undefined}
        deletable={deletable}
        onDelete={() => onDelete(image.id)}
        expanded={expanded}
        {...props}
      >
        <ImageNode
          image={image}
          metadata={metadata}
          published={published}
          tooltip={getMediaEventTooltip(mediaProps)}
          editable={editable}
          expanded={expanded}
        >
          {recommendedForLmm && (
            <ImageNodeMarker>Média Matinale</ImageNodeMarker>
          )}
        </ImageNode>
      </MediaLayout>
    );
  },
);

MediaImage.fragments = {
  media: gql`
    fragment MediaImage_media on Media {
      id
      ...ImageNode_image
    }
    ${ImageNodeFragment.image}
  `,
  media__expanded: gql`
    fragment MediaImage_media__expanded on Media {
      id
      ...ImageNode_image__expanded
    }
    ${ImageNodeFragment.image__expanded}
  `,
};
