import { gql } from "@apollo/client";
import { memo } from "react";

import { QueryFilterField } from "@/containers/search/filterFields/QueryFilterField";

const query = gql`
  query MediaFilter_mediaDescriptors {
    connection: mediaDescriptors(
      offset: 0
      limit: 10
      where: { primaryType: { eq: "image" } }
    ) {
      nodes {
        id: secondaryType
        label
        defaultValue
      }
    }
  }
`;

const name = "types";

const Filter = memo(() => {
  return (
    <QueryFilterField
      name={name}
      label="Type d’images"
      query={query}
      searchable={false}
    />
  );
});

export const MediaFilter = {
  name,
  element: <Filter />,
  initialValue: null,
};
