import { gql } from "@apollo/client";
import { useId } from "ariakit-react-utils/hooks";
import clsx from "clsx";
import moment from "moment";
import React, { memo, useCallback, useMemo, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { IoSparkles } from "swash/Icon";
import { Checkbox } from "swash/controls/Checkbox";
import { FormCheckbox } from "swash/form/FormCheckbox";

import {
  RadioField,
  RadioGroupField,
  useRadioField,
  useRadioGroupField,
} from "@/components/fields/RadioField";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { useSafeQuery } from "@/containers/Apollo";

import { useHasPermission } from "../User";
import { BooleanField, DateField, StringField } from "../admin/CRUD";
import { fieldsMap } from "./ImageFieldsMap";
import { renderPlaceholder } from "./image-edit-multiple/utils";

const ImageUsedCountQuery = gql`
  query ImageUsedCount_image($id: Int!) {
    image(id: $id) {
      id
      usedCount
    }
  }
`;

export const RestrictionSection = ({ images = [] }) => {
  const hasEditUseRightsPermission = useHasPermission([
    "article:image:editUseRights",
  ]);
  const disabled = !hasEditUseRightsPermission || !images[0];
  const field = useRadioGroupField(fieldsMap.restrictionMode.name);
  const same = useMemo(
    () =>
      images.every((image) => {
        const equal = image.restrictionMode === images[0].restrictionMode;
        if (image.restrictionMode === "limited" && equal) {
          return (
            image.usageLimit === images[0].usageLimit &&
            image.expiration === images[0].expiration
          );
        }
        return equal;
      }),
    [images],
  );
  const node = images[0];

  return (
    <>
      <div className="font-accent text-lg font-semibold">Restrictions</div>
      <div className="flex flex-col gap-4">
        {!same && (
          <div className="text-sm text-grey-on">
            <IoSparkles className="inline" /> Sirius a détecté automatiquement
            plusieurs restrictions d’images différentes parmi les images
            importées
          </div>
        )}
        {!disabled ? (
          <div className="m-2">
            <RadioGroupField {...field} className="flex flex-col gap-4">
              <DefaultRestrictionRadio {...field} />
              <LimitedRestrictionRadio images={images} {...field} />
              <BlockedRestrictionRadio {...field} />
            </RadioGroupField>
          </div>
        ) : same ? (
          <RestrictionReadings image={node} />
        ) : null}
        <div className="mt-2 flex items-center gap-3">
          <UseRightSection disabled={disabled} />
        </div>
      </div>
    </>
  );
};

const UseRightSection = ({ disabled }) => {
  return (
    <>
      <span className="font-semibold">Droits d’utilisation :</span>
      <BooleanField
        name={fieldsMap.printAuthorized.name}
        label={fieldsMap.printAuthorized.label}
        disabled={disabled}
      />
      <BooleanField
        name={fieldsMap.webAuthorized.name}
        label={fieldsMap.webAuthorized.label}
        disabled={disabled}
      />
    </>
  );
};

const RestrictionRadio = ({ value, label, children, ...props }) => {
  const restrictionMode = useSubscribeFormValue(fieldsMap.restrictionMode.name);
  const checked = value === restrictionMode;
  const radioField = useRadioField(props, {
    value,
  });

  return (
    <div
      className={clsx(
        "flex flex-col rounded",
        checked ? "bg-primary-bg-light" : "bg-grey-bg-light",
      )}
    >
      <RadioField checked={checked} {...radioField}>
        {label}
      </RadioField>
      {checked ? children : null}
    </div>
  );
};

const DefaultRestrictionRadio = (props) => {
  return (
    <RestrictionRadio
      {...props}
      value="default"
      label="Aucune limite d’usage"
    />
  );
};

const LimitedRestrictionRadio = ({ image, ...props }) => {
  const { dirtySinceLastSubmit, submitting } = useFormState({
    subscription: {
      dirtySinceLastSubmit: true,
      submitting: true,
    },
  });

  const expired = image?.expired;

  return (
    <RestrictionRadio {...props} value="limited" label="Usage limité">
      {expired && !dirtySinceLastSubmit && !submitting && (
        <div className="px-2 pb-2 text-danger-on-light" role="alert">
          Droits expirés. Contactez le service photo.
        </div>
      )}
      <ExpirationField {...props} />
      <UsageLimitField image={image} expired={expired} {...props} />
    </RestrictionRadio>
  );
};

const BlockedRestrictionRadio = (props) => {
  return <RestrictionRadio {...props} value="blocked" label="Usage bloqué" />;
};

const CheckboxField = ({ label, ...props }) => {
  const id = useId();
  return (
    <FormCheckbox className="inline-flex items-center gap-2">
      <Checkbox id={id} {...props} />
      <label className="font-accent text-sm" htmlFor={id}>
        {label}
      </label>
    </FormCheckbox>
  );
};

const ExpirationField = ({ images }) => {
  const form = useForm();
  const expirationDate = useSubscribeFormValue(fieldsMap.expiration.name);

  const [checked, setChecked] = useState(expirationDate);
  const handleChange = useCallback(
    (event) => {
      const value = event.currentTarget.checked;
      if (!value) {
        form.change(fieldsMap.expiration.name, null);
      }
      setChecked(value);
    },
    [form, setChecked],
  );

  return (
    <div className="flex flex-col gap-2 p-2">
      <CheckboxField
        label="Date limite d’usage"
        checked={checked}
        onChange={handleChange}
      />
      {checked && (
        <DateField
          name={fieldsMap.expiration.name}
          placeholder={
            images.length > 1
              ? renderPlaceholder(images, fieldsMap.expiration.name)
              : undefined
          }
          w={1}
          data-test-hidden
          aria-label="Date limite d’usage"
        />
      )}
    </div>
  );
};

const RemainingUsage = ({ images, checked = true, children }) => {
  const multiple = images.length > 1;
  const { data } = useSafeQuery(ImageUsedCountQuery, {
    variables: { id: images[0]?.id },
    skip: !images[0] || multiple,
  });

  const usageLimit = useSubscribeFormValue(fieldsMap.usageLimit.name);

  if (multiple) return null;

  const limit = usageLimit || images[0]?.usageLimit || 0;
  const usedCount = data?.image.usedCount ?? null;
  const remaining =
    usedCount !== null && limit > 0 && checked
      ? Math.max(limit - usedCount, 0)
      : null;

  if (remaining === null) return null;

  return (
    <span
      className={clsx(
        "font-accent text-sm font-normal",
        remaining === 0 && "text-danger-on",
      )}
    >
      {children}
      {remaining > 1 ? `(${remaining} restants)` : `(${remaining} restant)`}
    </span>
  );
};

const UsageLimitField = memo(({ images }) => {
  const multiple = images.length > 1;
  const form = useForm();
  const usageLimit = useSubscribeFormValue(fieldsMap.usageLimit.name);
  const initialChecked = usageLimit > 0 || multiple;
  const [checked, setChecked] = useState(initialChecked);

  const handleChange = useCallback(
    (event) => {
      const value = event.currentTarget.checked;
      if (!value) {
        form.change(fieldsMap.usageLimit.name, null);
      }
      setChecked(value);
    },
    [form, setChecked],
  );

  return (
    <div className="flex flex-col gap-2 p-2">
      <div className="flex items-center justify-between">
        <CheckboxField
          label="Nombre limite d’usages"
          checked={checked}
          onChange={handleChange}
        />
        <RemainingUsage images={images} checked={checked} />
      </div>
      {checked && (
        <StringField
          name={fieldsMap.usageLimit.name}
          type="number"
          min={0}
          parse={(v) => (v > 0 ? Number(v) : null)}
          placeholder={
            multiple
              ? renderPlaceholder(images, fieldsMap.usageLimit.name)
              : "Saisissez une limite d’usages"
          }
        />
      )}
    </div>
  );
});

const RestrictionReadings = ({ image }) => {
  if (!image) return null;

  const { restrictionMode, expired } = image;

  if (restrictionMode === "limited") {
    return (
      <div className="flex flex-col gap-2 rounded bg-grey-bg-light p-2">
        <span className="font-accent font-semibold">Usage limité</span>
        {expired && (
          <div className="pb-2 text-danger-on-light" role="alert">
            Droits expirés. Contactez le service photo.
          </div>
        )}
        <LimitedUsageReadings image={image} />
      </div>
    );
  }

  if (restrictionMode === "blocked") {
    return (
      <div className="rounded bg-danger-bg-light p-2 font-accent font-semibold text-danger-on">
        Usage bloqué
      </div>
    );
  }

  return (
    <div className="rounded bg-grey-bg-light p-2 font-accent font-semibold">
      Aucune limite d’usage
    </div>
  );
};

const LimitedUsageReadings = ({ image }) => {
  if (!image.expiration && !image.usageLimit) return null;

  return (
    <div className="flex flex-col gap-3 font-accent text-sm">
      {image.expiration ? (
        <span>
          Date limite d’usage :{" "}
          <span
            className={clsx(
              "font-semibold",
              image.expiration &&
                new Date() > new Date(image.expiration) &&
                "text-danger-on",
            )}
          >
            {image.expiration
              ? moment(image.expiration).format("DD/MM/YYYY")
              : null}
          </span>
        </span>
      ) : null}
      {image.usageLimit ? (
        <span>
          Nombre limite d’usages :{" "}
          <span className="font-semibold">
            <RemainingUsage images={[image]}>
              <span>{image.usageLimit} </span>
            </RemainingUsage>
          </span>
        </span>
      ) : null}
    </div>
  );
};
