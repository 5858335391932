import { forwardRef } from "react";

const SvgLockOpen = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.546 12.858c0 .265.072.517.216.758.132.228.319.414.56.559v1.966a.43.43 0 0 0 .108.288.366.366 0 0 0 .27.108h.776a.369.369 0 0 0 .379-.378v-1.984c.24-.132.427-.319.559-.559.144-.241.216-.493.216-.758a1.506 1.506 0 0 0-1.532-1.533c-.433 0-.8.144-1.1.433-.289.3-.439.667-.451 1.1zM2 19.549V8.909c0-.12.054-.228.162-.324a.53.53 0 0 1 .361-.127h11.019V4.617c0-.541-.12-.932-.361-1.172-.24-.241-.625-.361-1.154-.361h-3.86c-.529 0-.92.12-1.172.361-.241.24-.361.625-.361 1.154v.776H3.55v-.758c0-.565.126-1.136.379-1.713.252-.565.595-1.064 1.028-1.497S5.889.631 6.454.379A4.08 4.08 0 0 1 8.132 0h3.913c.553 0 1.112.126 1.677.379a4.969 4.969 0 0 1 2.525 2.525c.252.565.379 1.13.379 1.695v3.859h1.028c.144 0 .264.043.36.127a.409.409 0 0 1 .145.324v10.64a.445.445 0 0 1-.145.325.502.502 0 0 1-.342.126H2.523a.575.575 0 0 1-.361-.126.471.471 0 0 1-.162-.325z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLockOpen);
export default ForwardRef;
