import { forwardRef } from "react";

const SvgLetterPush = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M19 18.2V20H1v-1.8zM14.68 0c2.466 0 3.66 1.68 3.866 4.725l-1.905.355c-.037-2.447-.821-3.436-1.96-3.436-1.159 0-2.223 1.214-2.223 4.613v1.568c0 3.269 1.064 4.501 2.26 4.501 1.307 0 1.886-1.027 1.998-3.455l1.849.3c-.224 3.025-1.475 4.799-3.922 4.799-2.708 0-4.333-1.961-4.333-6.313V6.35c0-4.165 1.662-6.35 4.37-6.35zM1.5 13.671V.3h3.586c2.708 0 3.68 1.326 3.68 3.922s-1.29 3.903-3.83 3.903H3.517v5.547zm2.017-7.134H4.75c1.42 0 1.905-.729 1.905-2.297 0-1.607-.486-2.354-1.905-2.354H3.517z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLetterPush);
export default ForwardRef;
