import { gql, useMutation } from "@apollo/client";
import { useCallback, useState } from "react";
import { useToaster } from "swash/Toast";

import { EditInline } from "@/components/EditInline";

const Mutation = gql`
  mutation CharCountEditor_updateArticle($articleId: Int!, $nbCharMax: Int!) {
    updateArticle(input: { id: $articleId, nbCharMax: $nbCharMax }) {
      id
      nbCharMax
    }
  }
`;
/**
 * @param {object} params
 * @param {number} [articleId]
 * @param {string} [title]
 */
export const useCharCountEditorState = ({ articleId, title } = {}) => {
  const [editing, setEditing] = useState(false);
  return {
    editing,
    setEditing,
    articleId: articleId ?? null,
    buttonProps: {
      title: title ?? "Éditer le nombre de caractères maximal",
      onClick: (event) => {
        event.stopPropagation();
        setEditing(true);
      },
      className: "group/edit-inline cursor-pointer",
    },
  };
};

const EditInlineInput = (props) => {
  return <input type="number" {...props} />;
};

/**
 *
 * @param {object} params
 * @param {Object} params.state
 * @param {string | number} params.nbChar
 * @param {string | number} params.nbCharMax
 * @param {function} [params.onSubmit]
 * @param {string} [params.placeholder]
 */
export const CharCountEditor = ({
  state,
  onSubmit,
  placeholder,
  nbChar,
  nbCharMax,
}) => {
  const { editing, setEditing, articleId } = state;
  const [updateArticleNbCharMax] = useMutation(Mutation, {
    variables: { articleId },
    skip: !articleId,
  });
  const toaster = useToaster();

  const handleSubmit = useCallback(
    (value) => {
      setEditing(false);
      if (onSubmit) {
        onSubmit(value);
        return;
      }
      const numValue = Number(value);
      updateArticleNbCharMax({
        variables: {
          nbCharMax: numValue,
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            __typename: "Article",
            id: articleId,
            nbCharMax: numValue,
          },
        },
      }).catch(() => {
        toaster.danger(
          "La mise à jour du nombre de caractères maximal a échoué",
        );
      });
    },
    [articleId, toaster, updateArticleNbCharMax, setEditing, onSubmit],
  );

  const handleClose = useCallback(() => {
    setEditing(false);
  }, [setEditing]);

  return (
    <div className="flex items-center tabular-nums">
      <span className={nbChar > nbCharMax ? "font-bold text-warning-on" : null}>
        {nbChar.toLocaleString()}
      </span>
      <span className="mx-px pl-1 text-grey-on-light">/</span>
      <EditInline
        className="px-1 tabular-nums"
        placeholder={placeholder ?? " "}
        ariaLabel="Nombre de caractères maximal"
        editing={editing}
        initialValue={nbCharMax}
        onClose={handleClose}
        onSubmit={handleSubmit}
        inputComponent={EditInlineInput}
        renderValue={(value) => value.toLocaleString()}
      />
    </div>
  );
};
