import { forwardRef } from "react";

const SvgAlignLeft = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0 3.014V1.4c0-.114.039-.21.117-.285A.409.409 0 0 1 .411 1h10.82c.117 0 .215.039.293.115a.34.34 0 0 1 .137.266v1.634a.38.38 0 0 1-.118.285.45.45 0 0 1-.293.114H.43A.478.478 0 0 1 .117 3.3.383.383 0 0 1 0 3.015zm0 4.862V6.262c0-.114.039-.21.117-.285a.405.405 0 0 1 .294-.115H17.91c.104 0 .202.038.293.115a.36.36 0 0 1 .118.266v1.633c0 .115-.04.21-.118.286a.404.404 0 0 1-.293.114H.43a.478.478 0 0 1-.313-.114A.384.384 0 0 1 0 7.876zm0 4.862v-1.614c0-.114.039-.21.117-.285a.405.405 0 0 1 .294-.115H14.57c.104 0 .202.038.293.115a.356.356 0 0 1 .118.266v1.633a.4.4 0 0 1-.411.4H.43a.474.474 0 0 1-.313-.115.382.382 0 0 1-.117-.285zM0 17.6v-1.614c0-.114.039-.209.117-.286a.408.408 0 0 1 .294-.114H19.57c.117 0 .221.038.313.114a.363.363 0 0 1 .117.267V17.6c0 .114-.039.21-.117.285a.405.405 0 0 1-.294.115H.43a.474.474 0 0 1-.313-.115A.382.382 0 0 1 0 17.6z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAlignLeft);
export default ForwardRef;
