import { gql } from "@apollo/client";
import moment from "moment";
import { Paper, PaperCopy } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { ArticlePrintInfos } from "@/containers/article/ArticlePrintInfos";
import { ArticlePrintSummary } from "@/containers/article/ArticlePrintSummary";

function shoulDisplayPriorPrintIndicator(article) {
  if (article.publicationUrl) {
    return false;
  }

  return moment(article.printDate).isBefore(moment(article.schedulerDate));
}

const PriorPrintDueDateIndicator = ({ article, children }) => {
  if (shoulDisplayPriorPrintIndicator(article)) {
    return (
      <div className="relative">
        {children}
        <div className="absolute top-1/3 left-1/3 h-4 w-4 rounded-full border-2 border-white bg-orange-600 text-center font-accent text-xs font-semibold leading-[0.8rem] text-white">
          !
        </div>
      </div>
    );
  }
  return children;
};

PriorPrintDueDateIndicator.fragments = {
  priorPrintDueDateIndicator: gql`
    fragment PriorPrintDueDateIndicator_article on Article {
      printDate
      schedulerDate
      publicationUrl
    }
  `,
};

export function PrintCoreExposure({ article }) {
  // Print exposure is fulfilled
  if (article.printInfos) {
    return (
      <Tooltip
        tooltip={
          <div className="flex flex-col gap-2">
            <div className="font-bold">
              <ArticlePrintSummary {...article} />
            </div>
            <ArticlePrintInfos printInfos={article.printInfos} />
          </div>
        }
      >
        <div className="flex">
          <PriorPrintDueDateIndicator article={article}>
            {article.printInfos.decorrelated ? <PaperCopy /> : <Paper />}
          </PriorPrintDueDateIndicator>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip tooltip="Print (suggéré)">
      <div className="flex opacity-disabled">
        <PriorPrintDueDateIndicator article={article}>
          <Paper />
        </PriorPrintDueDateIndicator>
      </div>
    </Tooltip>
  );
}

PrintCoreExposure.fragments = {
  article: gql`
    fragment PrintCoreExposure_article on Article {
      id
      printInfos {
        decorrelated
        ...ArticlePrintInfos_printInfos
      }
      ...ArticlePrintSummary_printSummary
      ...PriorPrintDueDateIndicator_article
    }

    ${ArticlePrintInfos.fragments.printInfos}
    ${ArticlePrintSummary.fragments.printSummary}
    ${PriorPrintDueDateIndicator.fragments.priorPrintDueDateIndicator}
  `,
};
