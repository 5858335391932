import { forwardRef } from "react";

const SvgKeys = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.143 12.148v-.902c.35-.025.656-.157.921-.397.252-.229.421-.536.505-.921l1.913-8.267 2.347 6.498a.503.503 0 0 1 0 .325.484.484 0 0 1-.18.253l-1.029.685 4.675 7.148-.344 2.094-.685.469-2.13-.415-4.693-7.129-.83.559c-.145.108-.302.108-.47 0zM5.18 3.069c0 .409.145.758.434 1.047.3.301.655.451 1.064.451.41 0 .76-.144 1.047-.433.301-.301.452-.656.452-1.065s-.145-.758-.434-1.047a1.453 1.453 0 0 0-1.065-.451c-.409 0-.758.144-1.046.433a1.457 1.457 0 0 0-.452 1.065zM1.01 1.137a.361.361 0 0 1 .055-.289A.288.288 0 0 1 1.3.704l4.62-.65C6.15.018 6.401 0 6.677 0c.265 0 .518.012.76.036l4.62.668c.095.012.173.06.233.144.06.085.08.181.055.289l-2.112 8.484a.451.451 0 0 1-.18.27.501.501 0 0 1-.307.108H8.61v8.466l-.777.758v-7.671L7.58 9.999h-.253l-.252 1.535v.776H6.3v.758h.758v.776H6.3v.776h.758v.759H6.3v.776h.758v.776H6.3v.758h.758v.776H6.3v.777h.758v.739L7.04 20h-.83l-1.445-1.517V10H3.61a.501.501 0 0 1-.306-.108.478.478 0 0 1-.163-.253z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgKeys);
export default ForwardRef;
