import { gql } from "@apollo/client";

import { ArticlePublishTime } from "@/containers/article/ArticlePublishTime";

export function HourCell({ article }) {
  return <ArticlePublishTime article={article} />;
}

HourCell.fragments = {
  article: gql`
    fragment HourCell_article on Article {
      ...ArticlePublishTime_article
    }

    ${ArticlePublishTime.fragments.article}
  `,
};
