/* eslint-disable react/no-danger */
import { gql } from "@apollo/client";
import moment from "moment";
import { IoExitOutline, IoLogoTwitter } from "swash/Icon";
import { Link } from "swash/Link";

import { TwitterEmbed } from "@/components/TwitterEmbed";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

const TweetNodeExpanded = ({ tweet }) => {
  return (
    <div className="w-full p-2">
      <TwitterEmbed tweetId={tweet.tweetIdStr} />
    </div>
  );
};

const TweetNodeCondensed = ({ tweet, tooltip }) => {
  const date = new Date(tweet.tweetCreatedAt);
  return (
    <EditorNodeCard>
      <EditorNodeLayout>
        <EditorNodeTooltip tooltip={tooltip}>
          <EditorNodeIcon>
            <IoLogoTwitter />
          </EditorNodeIcon>
        </EditorNodeTooltip>
        <EditorNodeBody fontSize="sm">
          <div className="flex gap-2">
            <div>
              <span className="font-semibold">{tweet.userName}</span> @
              {tweet.userScreenName} - {moment(date).format("DD MMMM à H:mm")}
            </div>
            <Link href={tweet.url} target="_blank" icon={<IoExitOutline />}>
              Voir le tweet
            </Link>
          </div>
          <ChangeText
            title={tweet.text}
            dangerouslySetInnerHTML={{ __html: tweet.text }}
          />
        </EditorNodeBody>
      </EditorNodeLayout>
    </EditorNodeCard>
  );
};

export function TweetNode({ tweet, tooltip, expanded }) {
  if (expanded) return <TweetNodeExpanded tweet={tweet} />;
  return <TweetNodeCondensed tweet={tweet} tooltip={tooltip} />;
}

TweetNode.fragments = {
  tweet: gql`
    fragment TweetNode_tweet on Tweet {
      tweetCreatedAt
      userName
      userScreenName
      text
      url
      tweetIdStr
    }
  `,
};
