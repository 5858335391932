import { forwardRef } from "react";

const SvgUndo = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m0 11.596 8.859-6.85v4.45a29.2 29.2 0 0 1 3.024.137c.872.078 1.75.24 2.634.487 2.745.78 4.573 2.16 5.483 4.137a19.126 19.126 0 0 0-4.215-1.015 35.817 35.817 0 0 0-4.741-.293H8.878v4.605z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUndo);
export default ForwardRef;
