import { forwardRef } from "react";

const SvgUpload = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M16.66 17.481h1.66v-.821h-1.66zM3.536 6.524a.314.314 0 0 1 .078-.254L9.707.117C9.786.039 9.883 0 10 0s.215.039.293.117l6.075 6.133c.078.092.11.183.097.274-.026.091-.098.137-.215.137H12.5v6.25a.398.398 0 0 1-.41.41H7.93a.468.468 0 0 1-.313-.117.398.398 0 0 1-.117-.293v-6.25H3.75c-.117 0-.189-.046-.215-.137zM.001 19.16H20v-3.75c0-.117-.046-.215-.137-.293S19.668 15 19.551 15H.43a.47.47 0 0 0-.313.117.398.398 0 0 0-.117.293v3.75z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUpload);
export default ForwardRef;
