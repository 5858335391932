import { forwardRef } from "react";

const SvgCheck = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0 10.16a.44.44 0 0 1 .097-.292l1.596-1.634c.078-.078.175-.117.292-.117s.214.033.292.098l4.358 4.279c.09.078.194.117.311.117a.44.44 0 0 0 .292-.117l10.466-9.357A.369.369 0 0 1 17.996 3c.116 0 .214.039.292.117l1.595 1.654a.396.396 0 0 1 .117.292.397.397 0 0 1-.117.292L7.238 16.969c-.091.078-.194.117-.311.117s-.214-.039-.292-.117L.118 10.452a.44.44 0 0 1-.117-.292z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCheck);
export default ForwardRef;
