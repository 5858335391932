import { forwardRef } from "react";

const SvgFolder = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m.253 17.177 3.536-8.841a.859.859 0 0 1 .38-.443.924.924 0 0 1 .537-.189h14.825c.189 0 .326.058.41.174.074.126.079.273.016.442l-3.537 8.857a.847.847 0 0 1-.379.442.923.923 0 0 1-.537.19H.679c-.19 0-.326-.058-.41-.174-.074-.126-.079-.279-.016-.458zM0 15.062V5.021a.7.7 0 0 1 .19-.49.679.679 0 0 1 .473-.189h.9l.237-.711a.955.955 0 0 1 .363-.441.857.857 0 0 1 .521-.19h3.379c.179 0 .358.058.537.174a.972.972 0 0 1 .347.458l.237.695h6.283c.19 0 .348.068.474.205a.65.65 0 0 1 .205.474v2.021H3.711c-.295 0-.516.158-.663.474z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFolder);
export default ForwardRef;
