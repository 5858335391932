/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql } from "@apollo/client";
import { forwardRef, memo, useState } from "react";
import { Clickable } from "swash/Clickable";
import { useEventCallback } from "swash/utils/useEventCallback";
import { SelectButton, SelectPopover } from "swash/v2/Select";

import { ExposureIndicator } from "@/containers/ExposureIndicator";
import { useHasPermission } from "@/containers/User";

import {
  ArticleExposure,
  ArticleExposuresSelectList,
  ArticleExposuresSelectStateProps,
  useArticleExposuresSelectState,
} from "./ArticleExposureSelect";

type ArticleExposuresButton = {
  articleExposures: ArticleExposure[];
  onFocus?: () => void;
  onMouseEnter?: () => void;
};

const ArticleExposuresList = ({
  articleExposures,
}: {
  articleExposures: ArticleExposure[];
}) => {
  if (articleExposures.length === 0) {
    return <div className="text-sm text-grey-on">Aucune</div>;
  }
  return (
    <div className="flex select-none flex-wrap items-start gap-2 py-0.5 text-lg">
      {articleExposures.map((articleExposure) => (
        <ExposureIndicator
          key={articleExposure.gid}
          articleExposure={articleExposure}
          article={articleExposure.article}
          exposure={articleExposure.exposure}
        />
      ))}
    </div>
  );
};

const ArticleExposuresButton = memo(
  forwardRef<HTMLDivElement, ArticleExposuresButton>(
    ({ articleExposures, ...props }, ref) => {
      return (
        <Clickable
          ref={ref}
          role="button"
          tabIndex={0}
          className="flex h-full items-start p-1"
          {...props}
        >
          <ArticleExposuresList articleExposures={articleExposures} />
        </Clickable>
      );
    },
  ),
);

const filterArticleExposures = (articleExposures: ArticleExposure[]) => {
  return articleExposures.filter(
    (articleExposure) => articleExposure.suggested || articleExposure.fulfilled,
  );
};

const ArticleExposuresSelector = (props: ArticleExposuresEditProps) => {
  const state = useArticleExposuresSelectState({
    article: props.article,
  });

  return (
    <span onClick={(event) => event.stopPropagation()}>
      <SelectButton asChild store={state.select}>
        <ArticleExposuresButton
          articleExposures={filterArticleExposures(
            props.article.articleExposures.nodes,
          )}
        />
      </SelectButton>
      <SelectPopover store={state.select} modal>
        <ArticleExposuresSelectList state={state} />
      </SelectPopover>
    </span>
  );
};

export type ArticleExposuresEditProps = {
  article: ArticleExposuresSelectStateProps["article"];
};

export const ArticleExposuresEdit = (props: ArticleExposuresEditProps) => {
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));

  const canView = useHasPermission(
    ["exposures:view", "exposures:edit", "exposures:full"],
    {
      method: "some",
    },
  );
  const canEdit = useHasPermission(["exposures:edit", "exposures:full"], {
    method: "some",
  });

  if (!canView) {
    return null;
  }

  if (!canEdit)
    return (
      <ArticleExposuresList
        articleExposures={filterArticleExposures(
          props.article.articleExposures.nodes,
        )}
      />
    );

  if (active) {
    return <ArticleExposuresSelector article={props.article} />;
  }

  return (
    <ArticleExposuresButton
      articleExposures={filterArticleExposures(
        props.article.articleExposures.nodes,
      )}
      onFocus={activate}
      onMouseEnter={activate}
    />
  );
};

ArticleExposuresEdit.fragments = {
  article: gql`
    fragment ArticleExposuresEdit_article on Article {
      id
      ...ArticleExposuresSelect_article
    }
    ${useArticleExposuresSelectState.fragments.article}
  `,
};
