import { forwardRef } from "react";

const SvgParagraph = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5 4.478c0-1.392.348-2.486 1.044-3.283C6.727.398 7.713 0 9.004 0h5.37v20h-1.689V1.347h-2.011V20h-1.67V9.317c-.835 0-1.556-.209-2.163-.626a3.825 3.825 0 0 1-1.367-1.708C5.171 6.262 5.012 5.427 5 4.478"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgParagraph);
export default ForwardRef;
