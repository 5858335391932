/* eslint-disable graphql/template-strings */
import { gql, useSubscription } from "@apollo/client";
import { memo, useCallback, useEffect } from "react";
import { PageLoader } from "swash/Loader";
import {
  PanelSection,
  PanelSectionHeader,
  PanelSectionTitle,
} from "swash/Panel";
import { useLiveRef } from "swash/utils/useLiveRef";

import { mergeConnections, useSafeQuery } from "@/containers/Apollo";
import { RestrictedAction } from "@/containers/RestrictedAction";
import { RichEventsHistory } from "@/containers/events/RichEventsHistory";

import { EventDispatcherButton } from "./EventDispatcherButton";

const EventFragment = gql`
  fragment ArticleEventDispatcherSection_event on Event {
    status
    ...RichEventsHistory_event
  }

  ${RichEventsHistory.fragments.event}
`;

const ArticleFragment = gql`
  fragment ArticleEventDispatcherSection_article on Article {
    id
    dispatchedEvents: events(
      limit: 3
      offset: $offset
      where: {
        name: { eq: "article" }
        action: { eq: "dispatched" }
        dispatcherKey: { eq: $dispatcherKey }
      }
    ) {
      nodes {
        id
        ...ArticleEventDispatcherSection_event
      }
      ...RichEventsHistory_events
    }
  }

  ${EventFragment}
  ${RichEventsHistory.fragments.events}
`;

const ArticleQuery = gql`
  query ArticleEventDispatcherSection_article(
    $articleId: Int!
    $dispatcherKey: String
    $offset: Int
  ) {
    article(id: $articleId) {
      id
      ...ArticleEventDispatcherSection_article
    }
  }

  ${ArticleFragment}
`;

const ArticleSubscription = gql`
  subscription ArticleEventDispatcherSection_articleUpdated(
    $articleId: Int!
    $dispatcherKey: String
    $offset: Int
  ) {
    articleUpdated(where: { id: { eq: $articleId } }) {
      id
      ...ArticleEventDispatcherSection_article
    }
  }

  ${ArticleFragment}
`;

const EventSubscription = gql`
  subscription ArticlePublishState_eventUpdated(
    $articleId: Int!
    $dispatcherKey: String
  ) {
    eventUpdated(
      where: {
        name: { eq: "article" }
        action: { eq: "dispatched" }
        dispatcherKey: { eq: $dispatcherKey }
        articleId: { eq: $articleId }
      }
    ) {
      id
      ...ArticleEventDispatcherSection_event
    }
  }

  ${EventFragment}
`;

const useEventDispatchedArticle = ({ eventDispatcher, articleId }) => {
  const { data, subscribeToMore, refetch, loading, fetchMore } = useSafeQuery(
    ArticleQuery,
    {
      variables: { articleId, dispatcherKey: eventDispatcher.key },
    },
  );
  useSubscription(ArticleSubscription, {
    variables: { articleId, dispatcherKey: eventDispatcher.key },
  });
  const dataRef = useLiveRef(data);
  const loadMore = useCallback(() => {
    const data = dataRef.current;
    fetchMore({
      variables: { offset: data.article.dispatchedEvents.nodes.length },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            dispatchedEvents: mergeConnections(
              previousResult.article.dispatchedEvents,
              fetchMoreResult.article.dispatchedEvents,
            ),
          },
        };
      },
    });
  }, [dataRef, fetchMore]);

  const status = (() => {
    if (!data) return "loading";
    const [event] = data.article.dispatchedEvents.nodes;
    if (!event) return "pending";
    return event.status;
  })();
  const inProgress = status === "queued";
  // Use subscription for real-time
  useEffect(() => {
    subscribeToMore({
      document: EventSubscription,
      variables: { articleId, dispatcherKey: eventDispatcher.key },
      updateQuery: (previousResult, { subscriptionData: { data } }) => {
        if (!data) return previousResult;
        const event = data.eventUpdated;
        const previousNodes = previousResult.article.dispatchedEvents.nodes;
        const index = previousNodes.findIndex((node) => node.id === event.id);
        const nodes = [...previousNodes];
        if (index !== -1) {
          nodes.splice(index, 1, event);
        } else {
          nodes.unshift(event);
        }

        return {
          ...previousResult,
          article: {
            ...previousResult.article,
            dispatchedEvents: {
              ...previousResult.article.dispatchedEvents,
              nodes,
            },
          },
        };
      },
    });
  }, [subscribeToMore, articleId, eventDispatcher.key]);

  // Use polling for backup if subscription fails
  useEffect(() => {
    if (!inProgress) return undefined;
    const interval = setInterval(() => refetch(), 1000);
    return () => clearInterval(interval);
  }, [inProgress, refetch]);

  return {
    status,
    dispatchedEvents: data?.article.dispatchedEvents ?? null,
    loading,
    loadMore,
  };
};

export const ArticleEventDispatcherSection = memo(
  ({ eventDispatcher, articleId }) => {
    const { status, dispatchedEvents, loading, loadMore } =
      useEventDispatchedArticle({
        eventDispatcher,
        articleId,
      });

    const disabled = status === "loading" || status === "queued";

    return (
      <>
        <PanelSection>
          <PanelSectionHeader>
            <PanelSectionTitle>{eventDispatcher.name}</PanelSectionTitle>
            <div>
              <RestrictedAction
                permissions={`eventDispatchers:${eventDispatcher.key}:dispatch`}
              >
                {({ disabled: restrictionDisabled }) => (
                  <EventDispatcherButton
                    eventDispatcher={eventDispatcher}
                    disabled={disabled || restrictionDisabled}
                    articleId={articleId}
                    label={(() => {
                      switch (status) {
                        case "queued":
                          return "Envoi en cours...";
                        case "loading":
                          return "Chargement en cours...";
                        default:
                          return eventDispatcher.buttonLabel ?? "Envoyer";
                      }
                    })()}
                    scale="sm"
                  />
                )}
              </RestrictedAction>
            </div>
          </PanelSectionHeader>
          {status === "loading" ? (
            <PageLoader />
          ) : (
            <RichEventsHistory
              events={dispatchedEvents}
              loading={loading}
              onLoadMore={loadMore}
            />
          )}
        </PanelSection>
      </>
    );
  },
);

ArticleEventDispatcherSection.fragments = {
  eventDispatcher: gql`
    fragment ArticleEventDispatcherSection_eventDispatchers on EventDispatcher {
      name
      buttonLabel
      key
    }
  `,
};
