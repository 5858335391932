import { useMutation } from "@apollo/client";
import arrayMutators from "final-form-arrays";
import { forwardRef } from "react";
import { useFormState } from "react-final-form";
import { Button } from "swash/Button";
import { CardBody, CardFooter } from "swash/Card";
import { DialogDisclosure, useDialogState } from "swash/Dialog";
import { PageLoader } from "swash/Loader";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";

import { GlobalId } from "@/components/GlobalId";
import { Time } from "@/components/Time";
import { Fieldset, FieldsetTitle } from "@/components/fields/Fieldset";
import { Form } from "@/components/forms/Form";
import { FormAction, FormActions } from "@/components/forms/FormActions";
import { FormError } from "@/components/forms/FormError";
import {
  FormFooter,
  FormFooterActions,
  FormFooterSide,
} from "@/components/forms/FormFooter";
import { FormPrompt } from "@/components/forms/FormPrompt";
import { FormReset } from "@/components/forms/FormReset";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useSafeQuery } from "@/containers/Apollo";
import { HasLevelAccess } from "@/containers/User";
import { UsedInSection } from "@/containers/admin/CRUD/usages/UsedInSection";
import { CustomFields } from "@/containers/custom-fields/CustomFields";
import { EventsHistory } from "@/containers/events/EventsHistory";
import { handleUnicityError } from "@/services/forms/customFieldError";
import * as customMutators from "@/services/forms/mutators";

import { CRUDFormContextProvider, useUsedInContext } from "./Context";
import { DeleteConfirmDialog } from "./dialogs/DeleteConfirmDialog";
import { EnableDisableConfirmDialog } from "./dialogs/EnableDisableConfirmDialog";

/*
  The button to manage the state of the resource (enable / disable) if
  the resource supports this.
 */

const InnerEnableDisableButton = forwardRef(
  ({ descriptor, node, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        type="button"
        variant={node.enabled ? "secondary" : "success"}
        {...props}
      >
        {node.enabled ? "Désactiver" : "Activer"}
      </Button>
    );
  },
);

export const EnableDisableButton = ({ descriptor, node }) => {
  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });

  const [updateNode] = useMutation(descriptor.operations.UpdateNodeMutation);

  const dialog = useDialogState();

  if (!node.enabled) {
    return (
      <InnerEnableDisableButton
        node={node}
        onClick={async () => {
          await updateNode({
            variables: {
              input: {
                id: node.id,
                ...(descriptor.parseValues
                  ? descriptor.parseValues(values, node)
                  : {}),
                enabled: !node.enabled,
              },
            },
          });
        }}
      />
    );
  }

  return (
    <>
      <DialogDisclosure state={dialog}>
        {(disclosureProps) => (
          <InnerEnableDisableButton node={node} {...disclosureProps} />
        )}
      </DialogDisclosure>
      <EnableDisableConfirmDialog
        state={dialog}
        onConfirm={() => {
          updateNode({
            variables: {
              input: {
                id: node.id,
                ...(descriptor.parseValues
                  ? descriptor.parseValues(values, node)
                  : {}),
                enabled: !node.enabled,
              },
            },
          });
        }}
        descriptor={descriptor}
        node={node}
      />
    </>
  );
};

/*
  The button to manage the deletion of the resource, if
  the resource supports this.
 */

const InnerDeleteButton = forwardRef(({ descriptor, node, ...props }, ref) => {
  return (
    <Button ref={ref} type="button" variant="danger" {...props}>
      Supprimer
    </Button>
  );
});

export function DeleteButton({ descriptor, node }) {
  const toaster = useToaster();
  const usedInContext = useUsedInContext();

  const [deleteNode] = useMutation(descriptor.operations.DeleteNodeMutation, {
    variables: { id: node.id },
  });

  const handleSubmit = async () => {
    await deleteNode();
    toaster.success(`La ressource « ${node.label} » a été supprimée.`);
  };

  const dialog = useDialogState();

  if (usedInContext && usedInContext.isUsed)
    return (
      <Tooltip tooltip="Cette ressource est encore utilisée, afin de pouvoir la supprimer vous devez d’abord la retirer de toutes ses utilisations">
        <span>
          <InnerDeleteButton node={node} disabled />
        </span>
      </Tooltip>
    );

  return (
    <>
      <DialogDisclosure state={dialog}>
        {(disclosureProps) => (
          <InnerDeleteButton
            node={node}
            disabled={usedInContext && usedInContext.loading}
            {...disclosureProps}
          />
        )}
      </DialogDisclosure>
      <DeleteConfirmDialog
        state={dialog}
        onConfirm={handleSubmit}
        descriptor={descriptor}
        node={node}
      />
    </>
  );
}

function CustomFieldsSection({ CustomFieldsQuery }) {
  const { data } = useSafeQuery(CustomFieldsQuery, {
    variables: {
      input: { scope: "default" },
    },
  });

  if (!data) return <PageLoader />;
  if (!data.customFields.length) return null;

  return (
    <Fieldset>
      <FieldsetTitle>Champs personnalisés</FieldsetTitle>
      <CustomFields customFields={data.customFields} name="customFields" />
    </Fieldset>
  );
}

export function CRUDFormEvent({ globalId, createdAt, updatedAt, events }) {
  return (
    <FormFooterSide>
      <HasLevelAccess level="developer">
        {globalId ? <GlobalId globalId={globalId} /> : null}
      </HasLevelAccess>
      {createdAt && updatedAt ? (
        <div className="my-1 text-xs">
          Créé <Time date={createdAt} />, mis à jour <Time date={updatedAt} />
        </div>
      ) : null}
      {events?.nodes?.length > 0 ? (
        <EventsHistory events={events.nodes} className="my-1 text-xs" />
      ) : null}
      <FormError />
    </FormFooterSide>
  );
}

/**
 * Create CRUD form.
 * @param {object} options
 * @param {import('./index').CRUDDescriptor} options.descriptor
 */
export function createCRUDForm({ descriptor }) {
  if (
    !descriptor.crudOperations.includes("create") &&
    !descriptor.crudOperations.includes("edit")
  ) {
    return { Form: null };
  }
  function CRUDForm({ initialValues, onSubmit, node }) {
    return (
      <CRUDFormContextProvider
        operations={descriptor.operations}
        node={node}
        usedInEnabled={Boolean(descriptor.operations.UsedInQueries)}
      >
        <Form
          collaborative
          autoComplete="off"
          initialValues={initialValues}
          onSubmit={handleUnicityError(async (...args) => {
            await onSubmit(...args);
          })}
          mutators={{ ...customMutators, ...arrayMutators }}
        >
          <FormPrompt />
          <CardBody>
            <descriptor.components.Fields node={node} />
            {descriptor.operations.CustomFieldsQuery ? (
              <div className="mt-8">
                <CustomFieldsSection
                  CustomFieldsQuery={descriptor.operations.CustomFieldsQuery}
                />
              </div>
            ) : null}
            <div className="mt-6">
              <UsedInSection
                operations={descriptor.operations}
                label={node?.label}
                resource={node}
              />
            </div>
          </CardBody>
          <CardFooter>
            <FormFooter>
              <CRUDFormEvent {...node} />
              <FormFooterActions>
                <FormActions>
                  {node &&
                    descriptor.deletion &&
                    descriptor.crudOperations.includes("delete") && (
                      <FormAction>
                        <DeleteButton descriptor={descriptor} node={node} />
                      </FormAction>
                    )}
                  {node && descriptor.enableDisable && (
                    <FormAction>
                      <EnableDisableButton
                        descriptor={descriptor}
                        node={node}
                      />
                    </FormAction>
                  )}
                  <FormAction>
                    <FormReset initialValues={initialValues} />
                  </FormAction>
                  <FormAction>
                    <SubmitButton descriptor={descriptor} node={node} />
                  </FormAction>
                </FormActions>
              </FormFooterActions>
            </FormFooter>
          </CardFooter>
        </Form>
      </CRUDFormContextProvider>
    );
  }

  return { Form: CRUDForm };
}

function SubmitButton({ descriptor, node }) {
  const dialog = useDialogState();
  const { initialValues, values } = useFormState({
    subscription: {
      initialValues: true,
      values: true,
    },
  });

  const showPopupConfirmation =
    descriptor.confirmOnSave && descriptor.confirmOnSave(values, initialValues);

  if (!showPopupConfirmation)
    return (
      <FormSubmit>
        {node
          ? "Enregistrer"
          : `Créer ${descriptor.term.article} ${descriptor.term.singular}`}
      </FormSubmit>
    );

  const { ConfirmDialog } = descriptor.components;

  return (
    <>
      <DialogDisclosure state={dialog}>
        {(disclosureProps) => (
          <Button type="button" {...disclosureProps}>
            {node
              ? "Enregistrer"
              : `Créer ${descriptor.term.article} ${descriptor.term.singular}`}
          </Button>
        )}
      </DialogDisclosure>
      <ConfirmDialog
        state={dialog}
        descriptor={descriptor}
        node={node}
        values={values}
      />
    </>
  );
}
