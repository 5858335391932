import { forwardRef } from "react";

const SvgCrop = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.845 19.783h3.087v-2.292h-3.087zm-6.607-6.155h6.606V7.022zm-1.083-1.084 6.606-6.606H6.155zm-3.086 4.17V.542h3.068v2.31h9.711L18.682 0l1.101 1.084-2.852 2.851v9.693H20v3.087H3.069zM0 5.938V2.852h2.292V5.92z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCrop);
export default ForwardRef;
