import { gql } from "@apollo/client";
import moment from "moment";
import { NamedExoticComponent, memo } from "react";

import type { DocumentNode } from "../Apollo";

export type ArticlePrintSummaryProps = {
  periodicalEditionDate: string | null;
  periodical: {
    label: string | null;
  };
  periodicalRelease: {
    label: string | null;
  };
  periodicalSection: {
    label: string | null;
  };
  periodicalSectionLayout: {
    label: string | null;
  };
};

export const ArticlePrintSummary = memo<ArticlePrintSummaryProps>(
  ({
    periodical,
    periodicalEditionDate,
    periodicalRelease,
    periodicalSection,
    periodicalSectionLayout,
  }) => {
    const parts = [];
    if (periodical?.label) {
      parts.push(periodical.label);
    }
    if (periodicalSection?.label) {
      parts.push(periodicalSection.label);
    }
    if (periodicalSectionLayout?.label) {
      parts.push(periodicalSectionLayout.label);
    }
    if (periodicalRelease?.label) {
      parts.push(periodicalRelease.label);
    }
    if (periodicalEditionDate) {
      parts.push(
        moment.tz(periodicalEditionDate, "Europe/Paris").format("DD/MM/YYYY"),
      );
    }
    return <>{parts.join(", ")}</>;
  },
) as NamedExoticComponent<ArticlePrintSummaryProps> & {
  fragments: { printSummary: DocumentNode };
};

ArticlePrintSummary.fragments = {
  printSummary: gql`
    fragment ArticlePrintSummary_printSummary on Article {
      periodicalEditionDate
      periodical {
        id
        label
      }
      periodicalSection {
        id
        label
      }
      periodicalRelease {
        id
        label
      }
      periodicalSectionLayout {
        id
        label
      }
    }
  `,
};
