import { forwardRef } from "react";

const SvgAnchor = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.183 3.34c0 .469.169.879.508 1.231.352.338.768.508 1.25.508a1.7 1.7 0 0 0 1.25-.508c.338-.339.508-.749.508-1.231s-.169-.898-.508-1.25a1.676 1.676 0 0 0-1.231-.508c-.495 0-.911.169-1.25.508a1.67 1.67 0 0 0-.527 1.25zM0 12.929c0-.104.032-.195.097-.274.065-.065.157-.091.274-.078l5.371.762c.104.013.169.052.196.118.026.078 0 .156-.079.234l-1.386 1.387c1.093 1.185 2.369 1.927 3.827 2.226V6.231a4.215 4.215 0 0 1-1.191-1.289c-.312-.521-.468-1.055-.468-1.602 0-.924.319-1.712.956-2.363C8.249.326 9.03 0 9.941 0a3.22 3.22 0 0 1 2.363.977c.664.651.996 1.432.996 2.344 0 .56-.15 1.093-.449 1.601-.3.508-.697.938-1.191 1.29v11.092c1.445-.299 2.708-1.035 3.789-2.206l-1.407-1.387c-.091-.091-.123-.169-.097-.234.026-.079.091-.124.196-.137l5.351-.743a.429.429 0 0 1 .293.059c.065.065.091.156.078.274l-.762 5.351c-.013.117-.052.189-.117.215-.078.026-.157 0-.235-.078l-1.562-1.562c-.937.976-2.037 1.744-3.3 2.304s-2.578.84-3.945.84a9.78 9.78 0 0 1-3.965-.82 9.563 9.563 0 0 1-3.3-2.305l-1.524 1.524c-.091.091-.169.123-.234.097-.079-.026-.124-.091-.137-.196z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgAnchor);
export default ForwardRef;
