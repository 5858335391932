/* eslint-disable react/prop-types */
import styled from "@xstyled/styled-components";
import { forwardRef } from "react";

import { Fieldset } from "./Fieldset";

// for interpolation purpose
const StyledFieldSet = styled(Fieldset)``;

const InnerFieldGroup = styled.box`
  &[data-orientation="vertical"] {
    display: flex;
    flex-wrap: wrap;

    [data-field-label] {
      flex-shrink: 0;
    }

    [data-field-error] {
      flex-shrink: 0;
    }

    [data-field-label] + [data-field-error] {
      margin-left: 2;
    }

    [data-field-hint] {
      flex: 1 0 100%;
    }

    [data-field-label] + [data-field-control],
    [data-field-error] + [data-field-control],
    [data-field-hint] + [data-field-control] {
      margin-top: 2;
      flex: 1 0 100%;
    }
  }

  &[data-orientation="horizontal"] {
    display: flex;
    align-items: center;

    [data-field-label] {
      display: inline-block;
      flex-shrink: 0;
      margin-right: 3;
    }

    [data-field-error] {
      display: inline-block;
      margin-left: 2;
      order: 3;
    }
  }

  ${StyledFieldSet}[data-layout="horizontal"][data-orientation='horizontal'] [data-field-label] {
    min-width: 200;
  }
`;

export const FieldGroup = forwardRef(({ state, scale, ...props }, ref) => {
  return (
    <InnerFieldGroup
      ref={ref}
      data-orientation={state.orientation}
      data-scale={scale}
      {...props}
    />
  );
});
