import imgLayoutDefault from "@/assets/imgs/preview-default.svg";
import imgLayoutEvent from "@/assets/imgs/preview-event.svg";
import imgLayoutHighLight from "@/assets/imgs/preview-highlight.svg";

export const NETWORK_STATUS = {
  LOADING: 1,
  SET_VARIABLES: 2,
  FETCH_MORE: 3,
  REFETCH: 4,
  POLL: 6,
  READY: 7,
  ERROR: 8,
};
// Lock States
export const LOCKSTATES = {
  FREE: "free",
  LOCKED_BY_ME: "lockedByMe",
  LOCKED_BY_ME_ELSEWHERE: "lockedByMeElsewhere",
  LOCKED_BY_OTHER: "lockedByOther",
};

// Drag & Drop types
export const MSG_CATEGORIES = {
  LOCK: "lock",
  PUBLISH: "publish",
};

// Lock Messages
export const LOCK_MESSAGES = {
  TAKE: "take",
  RELEASE: "release",
  REQUEST_SUBMIT: "requestSubmit",
  REQUEST_RECEIVED: "requestReceived",
  REJECTED_REQUEST: "rejectedRequest",
  TAKE_ON_TAB: "takeOnTab",
  OTHER_TAKE: "otherTake",
};

// Publish Messages
export const PUBLISH_MESSAGES = {
  SUCCESS: "success",
  FAILED: "failed",
};

export const EXPORT_TASK = {
  COMPLETE: "complete",
  NEW: "new",
  PROGRESS: "progress",
  ERROR: "error",
  ACKNOWLEDGE: "acknowledge",
};

// Drag & Drop types
export const DND_TYPES = {
  COLUMN: "column",
  CAPSULE: "capsule",
  FOLDER: "folder",
};

export const DROPPABLE_TYPES = {
  RELATED_CONTENTS: "relatedContents",
  SUGGESTED_CONTENTS: "suggestedContents",
  FRIDGE_FOLDERS: "fridgeFolders",
  FRIDGE_FOLDER: "fridgeFolder",
  EXPOSURE_PROJECTION: "exposureProjection",
  ACCORDION_HEADER: "accordionHeader",
};

export const DRAGGABLE_TYPES = {
  RELATED_CONTENT: "relatedContent",
  SUGGESTED_CONTENT: "suggestedContent",
  TAG: "tag",
  FRIDGE_FOLDER: "fridgeFolder",
  MODERN_CAPSULE_ARTICLE: "modernArticle",
};

export const PUBLICATIONS = {
  GENERIC_NAME: "publication",
  lmm: { name: "lmm", type: "mobile-app" },
  digitalPublication: { name: "Numérique", type: "digital-publication" },
  TYPES: ["lmm", "digitalPublication"],
};

export const HE_LISTENER = "homeEdition";

export const HOME_LAYOUTS = {
  DEFAULT: { name: "default", title: "Routine", img: imgLayoutDefault },
  HIGHLIGHT: { name: "event", title: "Temps Fort", img: imgLayoutHighLight },
  EVENT: { name: "revolution", title: "Évènementielle", img: imgLayoutEvent },
  MUNICIPAL: { name: "municipal", title: "Élections", img: imgLayoutEvent },
};

export const CROPS = {
  "2:1": "3",
  "3:2": "7",
};

export const HOME_IMG_SIZES = {
  "2:1": { width: 1000, height: 500 },
  "3:2": { width: 1000, height: 666 },
};

// PREVIEW
export const PREVIEW = {
  VISIBLE_EDITORIAL_TYPES: [
    "analyse",
    "chronique",
    "editorial",
    "enquete",
    "tribune",
  ],
  HOME_AREAS: {
    ZONE1: "zone1",
    ZONE1_SIDE: "zone1__side",
    ZONE2: "zone2",
    ZONE3: "zone3",
    ZONE4: "zone4",
    ZONE5: "zone5",
    ZONE6: "zone6",
    ZONE7: "zone7",
    ZONE8: "zone8",
    ZONE9: "zone9",
    ZONE10: "zone10",
  },
  ANONYMOUS_AUTHOR: "Le Monde",
  INTERVIEW_PREFIX: "Propos recueillis par",
};

export const DEVICE_TYPES = {
  DESKTOP: "desktop",
  MOBILE: "mobile",
};

export const NEWS = {
  PRIORITIES: {
    ALERT: "ALERT",
    URGENT: "URGENT",
    DEFAULT: "DEFAULT",
  },
  PROVIDERS: {
    AFP: "AFP",
    AP: "AP",
    REUTERS: "Reuters",
    BLOOMBERG: "Bloomberg",
  },
};

export const LMM_TEMPLATE_LABELS = {
  standard: "Actualité",
  event: "Briefs / Exclus",
  idea: "Idées",
  entertainment: "Grands formats / Divertissement",
};

export const LMM_TEMPLATE_COLORS = {
  standard: "#0469dc",
  idea: "#7A38FF",
  event: "#ff3c00",
  entertainment: "#E33172",
};

export const CUSTOM_FIELDS_RESOURCE_MAP = {
  article: "Article",
  author: "Signataire",
  blog: "Blog",
  campaign: "Campagne",
  periodicalRelease: "Parution",
  source: "Source",
  tag: "Rubrique",
};

export const ARTICLE_TEASER_CONTEXT_RESOURCES = {
  title: "Titre",
  chapo: "Chapô",
};
