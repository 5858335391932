import { useId } from "ariakit-react-utils/hooks";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";

import { Popover, PopoverDisclosure, usePopoverState } from "../Popover";
import { MaskInput } from "../internal/MaskInput";
import { TextboxClearAdornment } from "../internal/TextboxClearAdornment";
import { useLiveRef } from "../utils/useLiveRef";
import { SingleDatePicker } from "./DatePicker";
import { Textbox } from "./Textbox";

const format = (value) => {
  if (!value) return value;
  const [YYYY, MM, DD] = value.split("-");
  return [DD, MM, YYYY].filter(Boolean).join("/");
};

const parse = (value) => {
  if (!value) return value;
  const [DD, MM, YYYY] = value.split("/");
  return [YYYY, MM, DD].filter(Boolean).join("-");
};

const DATE_REGEXP = /\d{4}-\d{2}-\d{2}/;

export const checkIsValidDateValue = (value) =>
  value?.length === 10 &&
  DATE_REGEXP.test(value) &&
  !Number.isNaN(new Date(value).getTime());

const getPopoverAriaProps = (props) => {
  if (props["aria-label"]) return { "aria-label": props["aria-label"] };
  if (props["aria-labelledby"])
    return { "aria-labelledby": props["aria-labelledby"] };
  return {};
};

/** @type {import('react').ForwardRefExoticComponent<any>} */
export const SingleDatePickerInput = forwardRef((props, ref) => {
  const {
    scale = "lg",
    value: propValue,
    onChange,
    children,
    header,
    modifiers,
    onKeyDown,
    onBlur,
    ...otherProps
  } = props;
  const id = useId(otherProps.id ?? undefined);
  const [internalValue, setInternalValue] = useState(propValue);
  const value = format(propValue);
  const refs = useLiveRef({ internalValue, value, onChange });
  // When internalValue changes, trigger onChange if necessary
  useEffect(() => {
    const { value, onChange } = refs.current;
    if (internalValue !== value) {
      const parsedValue = parse(internalValue);
      if (checkIsValidDateValue(parsedValue)) {
        onChange(parsedValue);
      }
    }
  }, [internalValue, refs]);
  // When value changes, update internalValue if necessary
  useEffect(() => {
    const { internalValue } = refs.current;
    if (internalValue !== value) {
      setInternalValue(value);
    }
  }, [value, refs]);
  const popoverRef = useRef(null);
  const textBoxRef = useRef(null);
  const textBoxRefs = useMemo(
    () => mergeRefs([ref, textBoxRef]),
    [ref, textBoxRef],
  );
  const popover = usePopoverState({
    placement: "bottom-start",
  });
  // Keep focusing while visible
  useEffect(() => {
    if (popover.open && textBoxRef.current) {
      textBoxRef.current.focus();
    }
  });
  const validPropValueRef = useRef(null);
  if (checkIsValidDateValue(propValue)) {
    validPropValueRef.current = propValue;
  }
  return (
    <>
      <PopoverDisclosure
        ref={textBoxRefs}
        id={id}
        state={popover}
        {...otherProps}
      >
        {({ type, role, onKeyDown: ignored, ...disclosureProps }) => (
          <Textbox
            as={MaskInput}
            onChange={(event) => {
              setInternalValue(event.currentTarget.value);
            }}
            onKeyDown={(event) => {
              if (onKeyDown) onKeyDown(event);
              if (event.defaultPrevented) return;
              switch (event.key) {
                case "Escape": {
                  event.preventDefault();
                  event.stopPropagation();
                  popover.hide();
                  return;
                }
                case " ": {
                  event.preventDefault();
                  event.stopPropagation();
                  popover.show();
                  return;
                }
              }
            }}
            mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            placeholder="JJ/MM/AAAA"
            scale={scale}
            value={internalValue ?? ""}
            role="combobox"
            minWidth={128}
            autoComplete="off"
            {...disclosureProps}
          >
            {!otherProps.required && value ? (
              <TextboxClearAdornment onClick={() => onChange(null)} />
            ) : null}
          </Textbox>
        )}
      </PopoverDisclosure>
      <Popover
        ref={popoverRef}
        state={popover}
        initialFocusRef={textBoxRef}
        {...getPopoverAriaProps(otherProps)}
      >
        {popover.open && (
          <>
            {header}
            <SingleDatePicker
              className="p-4"
              value={validPropValueRef.current}
              onChange={onChange}
              disabled={otherProps.disabled}
              modifiers={modifiers}
            />
          </>
        )}
      </Popover>
    </>
  );
});

if (process.env["NODE_ENV"] !== "production") {
  SingleDatePickerInput.displayName = "SingleDatePickerInput";
}
