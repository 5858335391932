import {
  Disclosure,
  DisclosureContent,
  useDisclosureState,
} from "ariakit/disclosure";
import { camelCase } from "lodash-es";
import { IoChevronForward } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { useSetToggledAnalytics, useToggledAnalytics } from "./TogglerProvider";

function formatValue(value) {
  if (typeof value !== "number") return value;
  return value.toLocaleString();
}
function formatForLocalStorage(value) {
  return camelCase(value);
}
export function AnalyticsGroup({ title, value, children }) {
  const toggledAnalytics = useToggledAnalytics();
  const setToggledAnalytics = useSetToggledAnalytics();
  const stateFromStorage =
    toggledAnalytics && toggledAnalytics[formatForLocalStorage(title)];
  const disclosure = useDisclosureState({
    open: typeof stateFromStorage === "boolean" ? stateFromStorage : true,
  });
  function handleClick() {
    setToggledAnalytics({
      ...toggledAnalytics,
      [formatForLocalStorage(title)]: !disclosure.open,
    });
  }
  return (
    <div className="rounded-md border border-grey-border-light p-4">
      <Disclosure state={disclosure} onClick={handleClick}>
        {(disclosureProps) => (
          <div
            className="user-select-none group grid cursor-pointer items-center gap-2 font-semibold"
            style={{
              gridTemplateColumns: "max-content minmax(0, 1fr) max-content",
            }}
            {...disclosureProps}
          >
            <IoChevronForward className="transition group-aria-[expanded=true]:rotate-90" />
            <h3>{title}</h3>
            {value != null ? <span>{formatValue(value)}</span> : null}
          </div>
        )}
      </Disclosure>
      <DisclosureContent state={disclosure} className="pt-2">
        {children}
      </DisclosureContent>
    </div>
  );
}

export function AnalyticsRow({ label, value, labelTooltip }) {
  const isNaturalValue =
    typeof value === "string" || Array.isArray(value) || (value && value > 0);
  return (
    <div className="flex gap-2 border-b border-grey-border-light py-2 last:border-b-0 last:pb-0">
      {labelTooltip ? (
        <Tooltip placement="left" tooltip={labelTooltip}>
          <div className="flex-1">
            {isNaturalValue ? (
              label
            ) : (
              <span className="text-grey-on">{label}</span>
            )}
          </div>
        </Tooltip>
      ) : (
        <div className="flex-1">
          {isNaturalValue ? (
            label
          ) : (
            <span className="text-grey-on">{label}</span>
          )}
        </div>
      )}
      <div className="shrink-0">
        {isNaturalValue ? (
          <strong>{formatValue(value)}</strong>
        ) : (
          <span className="text-grey-on">—</span>
        )}
      </div>
    </div>
  );
}
