import { gql } from "@apollo/client";
import { Button as AriakitButton } from "ariakit/button";
import clsx from "clsx";
import { Tooltip } from "swash/Tooltip";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";

import { CharCountEditor, useCharCountEditorState } from "./CharCountEditor";

export type SpreadArticleDescriptionSignsProps = {
  id: number;
  nbChar: number;
  nbCharMax: number;
  disabled: boolean;
};

export const SpreadArticleDescriptionSigns = ({
  id,
  nbChar,
  nbCharMax,
  disabled,
}: SpreadArticleDescriptionSignsProps) => {
  const state = useCharCountEditorState({ articleId: id });
  const tooltip = useArticleAuditTrailTooltip("nbCharMax");

  const {
    buttonProps: {
      title: buttonTitle,
      className: buttonClassName,
      ...buttonProps
    },
  } = state;

  return (
    <Tooltip
      tooltip={state.editing || disabled ? null : tooltip ?? buttonTitle}
    >
      <AriakitButton
        as="span"
        disabled={disabled}
        className={clsx(
          buttonClassName,
          "flex select-none whitespace-nowrap font-accent",
        )}
        {...buttonProps}
      >
        <CharCountEditor state={state} nbChar={nbChar} nbCharMax={nbCharMax} />
      </AriakitButton>
    </Tooltip>
  );
};

export type ArticleDescriptionSignsProps = {
  article: {
    id: number;
    nbChar: number;
    nbCharMax: number;
  };
  disabled: boolean;
};

export const ArticleDescriptionSigns = ({
  article,
  disabled,
}: ArticleDescriptionSignsProps) => {
  return (
    <SpreadArticleDescriptionSigns
      id={article.id}
      nbChar={article.nbChar}
      nbCharMax={article.nbCharMax}
      disabled={disabled}
    />
  );
};

ArticleDescriptionSigns.fragments = {
  article: gql`
    fragment ArticleDescriptionSigns_article on Article {
      id
      nbChar
      nbCharMax
    }
  `,
};
