import { forwardRef } from "react";

const SvgSearch = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2.574 8.076c0 .718.146 1.422.439 2.114.279.678.671 1.269 1.176 1.774s1.103.897 1.794 1.176c.678.293 1.383.439 2.114.439s1.436-.14 2.114-.419c.678-.292 1.269-.691 1.774-1.196s.904-1.096 1.196-1.774c.279-.678.419-1.383.419-2.114s-.14-1.436-.419-2.114a5.325 5.325 0 0 0-1.176-1.794c-.518-.505-1.116-.897-1.794-1.176s-1.383-.419-2.114-.419-1.436.14-2.114.419-1.269.671-1.774 1.176-.904 1.096-1.196 1.774a5.945 5.945 0 0 0-.439 2.134zm-2.572 0c0-1.077.212-2.113.638-3.111a8.278 8.278 0 0 1 1.735-2.592A8.198 8.198 0 0 1 4.986.618C5.997.206 7.034 0 8.097 0s2.093.206 3.09.618a8.073 8.073 0 0 1 2.612 1.735 7.941 7.941 0 0 1 1.735 2.612c.426.997.638 2.027.638 3.09a7.948 7.948 0 0 1-1.296 4.387l5.005 5.005c.08.08.119.173.119.279 0 .12-.039.226-.119.319l-1.814 1.814c-.08.08-.18.12-.3.12s-.22-.04-.299-.12l-5.005-5.004a7.852 7.852 0 0 1-4.367 1.296c-1.063 0-2.1-.206-3.11-.618a8.168 8.168 0 0 1-2.593-1.735 8.181 8.181 0 0 1-1.755-2.632A8.308 8.308 0 0 1 0 8.075z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSearch);
export default ForwardRef;
