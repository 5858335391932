import moment from "moment";
import "moment/locale/fr";
import {
  Children,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";

export type TimeProps = {
  date: string;
  format?: string;
  children?: React.ReactNode;
  short?: boolean;
} & React.HTMLAttributes<HTMLTimeElement>;

const useFromNow = (date: string | null, short = false) => {
  const getDateFromNow = useMemo(() => {
    if (!date) return null;
    return () => {
      const fromNow = moment(date).fromNow();
      if (short) {
        return fromNow
          .replace("il y a", " ")
          .replace("quelques secondes", "maintenant");
      }
      return fromNow;
    };
  }, [date, short]);
  const [fromNow, setFromNow] = useState(getDateFromNow);
  useEffect(() => {
    if (!getDateFromNow) return;
    const id = setInterval(() => setFromNow(getDateFromNow()), 1000);
    return () => clearInterval(id);
  }, [getDateFromNow]);
  return fromNow;
};

export const Time = memo(
  forwardRef<HTMLTimeElement, TimeProps>(
    ({ date, format, short, children, ...props }, ref) => {
      const hasChildren = Children.count(children) > 0;
      const showFromNow = !hasChildren && !format;
      const fromNow = useFromNow(showFromNow ? date : null, short);
      const display = hasChildren
        ? children
        : format
          ? moment(date).tz("Europe/Paris").format(format).replace("\\n", "\n")
          : fromNow;

      return (
        <time
          ref={ref}
          data-test-hidden
          dateTime={moment(date).toISOString()}
          title={moment(date).format("LLLL")}
          {...props}
        >
          {display}
        </time>
      );
    },
  ),
);
