import { forwardRef } from "react";

const SvgCog = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.497 10.009c0 .968.347 1.786 1.04 2.453a3.338 3.338 0 0 0 2.473 1.04c.955 0 1.779-.34 2.473-1.021a3.368 3.368 0 0 0 1.021-2.473c0-.955-.341-1.779-1.021-2.473-.693-.68-1.518-1.02-2.473-1.02-.968 0-1.793.34-2.473 1.02a3.44 3.44 0 0 0-1.04 2.473zm-6.497 0c0-.432.027-.864.079-1.296l2.649-.647a7.139 7.139 0 0 1 .746-1.806L2.08 3.925A9.785 9.785 0 0 1 3.906 2.08l2.335 1.394a7.535 7.535 0 0 1 1.806-.727L8.714.098A8.7 8.7 0 0 1 10.01 0c.419 0 .844.026 1.276.079l.667 2.649a7.469 7.469 0 0 1 1.826.746l2.335-1.394c.641.51 1.243 1.119 1.806 1.825l-1.394 2.336c.327.589.576 1.191.746 1.806l2.649.667a10.654 10.654 0 0 1 0 2.571l-2.63.668a8.053 8.053 0 0 1-.766 1.825l1.414 2.316a10.085 10.085 0 0 1-1.826 1.826l-2.336-1.394a7.081 7.081 0 0 1-1.805.746l-.668 2.649a10.604 10.604 0 0 1-2.591 0l-.647-2.63a7.285 7.285 0 0 1-1.806-.765l-2.335 1.393a10.044 10.044 0 0 1-1.845-1.805l1.393-2.336a7.55 7.55 0 0 1-.726-1.805l-2.649-.668A8.545 8.545 0 0 1 0 10.01z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCog);
export default ForwardRef;
