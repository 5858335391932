import { forwardRef } from "react";

const SvgSearchSync = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.111 8.978c0 .407.08.795.241 1.165s.379.691.653.966c.275.274.597.487.966.639a2.85 2.85 0 0 0 1.151.241c.397 0 .786-.076 1.164-.227.37-.161.692-.379.966-.653.275-.265.488-.587.639-.966.161-.379.242-.767.242-1.165s-.076-.781-.228-1.151a2.91 2.91 0 0 0-.639-.965 3.058 3.058 0 0 0-.98-.653c-.369-.152-.753-.228-1.151-.228s-.786.076-1.165.228a2.894 2.894 0 0 0-.965.639 3.263 3.263 0 0 0-.653.98 3.209 3.209 0 0 0-.242 1.151zm-1.392 0c0-.587.113-1.151.34-1.691a4.214 4.214 0 0 1 .952-1.42 4.208 4.208 0 0 1 1.421-.952 4.383 4.383 0 0 1 3.395 0c.539.227 1.013.54 1.42.937a4.7 4.7 0 0 1 .952 1.435c.227.539.341 1.103.341 1.69 0 .862-.237 1.657-.71 2.387l2.727 2.727a.233.233 0 0 1 .071.171.246.246 0 0 1-.057.156l-.994.994c-.038.038-.095.057-.171.057a.258.258 0 0 1-.156-.042l-2.727-2.742c-.72.474-1.515.71-2.386.71-.578 0-1.141-.113-1.691-.34s-1.028-.545-1.434-.952a4.665 4.665 0 0 1-.952-1.434 4.322 4.322 0 0 1-.341-1.691zM3.057 3.864c.568-.975 1.236-1.761 2.003-2.358A6.45 6.45 0 0 1 7.176.441C7.952.213 8.89.066 9.988 0c1.146 0 2.159.152 3.04.455.872.302 1.79.852 2.756 1.647l.937.809.753-.738v2.173H15.23l.781-.682-.71-.739c-.776-.739-1.576-1.283-2.4-1.634-.824-.34-1.747-.525-2.77-.553-1.496-.019-2.732.218-3.708.71-.965.492-1.898 1.359-2.798 2.599zM3 17.813V15.64h2.244l-.781.682.71.738c.748.72 1.548 1.26 2.4 1.62.843.36 1.767.549 2.77.568 1.004.018 1.875-.076 2.614-.285.739-.218 1.421-.568 2.046-1.051s1.24-1.141 1.846-1.974l.554.184c-.597 1.004-1.264 1.79-2.003 2.358a6.334 6.334 0 0 1-2.102 1.08c-.767.217-1.705.364-2.813.44-1.146 0-2.159-.156-3.04-.469-.88-.302-1.799-.852-2.755-1.647l-.938-.809z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSearchSync);
export default ForwardRef;
