import { forwardRef } from "react";

const SvgWirecrutter = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.124 11.238V5.705a1.307 1.307 0 0 1-.173.37L10.494 9.75v5.584c.033-.163.096-.32.188-.46zm-6.806-.03V5.724a1.331 1.331 0 0 1-.352.574A8.566 8.566 0 0 1 3.687 8.18v7.074c.038-.131.095-.256.171-.37zM5.993 4.41a1.377 1.377 0 0 0-1.926-.034l-.038.036c-.03.03-.06.06-.088.092-.89 1.024-1.807 1.603-2.722 1.723a1.36 1.36 0 0 0-1.111.811c-.193.45-.124.966.181 1.35.305.385.798.576 1.287.5a5.963 5.963 0 0 0 2.11-.706A8.566 8.566 0 0 0 5.964 6.3a1.33 1.33 0 0 0 .027-1.892zm13.406-.158a1.354 1.354 0 0 0-1.854.37l-4.42 6.61-2.443 3.645a1.303 1.303 0 0 0-.114 1.343 1.34 1.34 0 0 0 1.142.74c.49.023.953-.22 1.208-.632L19.78 6.076a1.306 1.306 0 0 0-.381-1.824zm-6.827.002a1.354 1.354 0 0 0-1.854.37l-4.4 6.579-2.46 3.676a1.303 1.303 0 0 0 .136 1.624 1.352 1.352 0 0 0 1.745.186h.01c.168-.115.307-.266.405-.443l4.34-6.478 2.46-3.692a1.308 1.308 0 0 0-.377-1.827z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWirecrutter);
export default ForwardRef;
