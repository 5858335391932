import { forwardRef } from "react";

const SvgEye = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.802 12.864a15.592 15.592 0 0 0 2.492-1.278 15.705 15.705 0 0 0 2.114-1.593c-.799-.725-1.835-1.388-3.108-1.987a5.47 5.47 0 0 1-.315 3.091 5.37 5.37 0 0 1-1.184 1.766zM8.124 9.741c.168.432.431.763.788.994l.505-.489c-.42-.21-.667-.557-.741-1.041zm-2.162-.725c0 .725.179 1.398.537 2.019.357.62.851 1.109 1.482 1.466a3.974 3.974 0 0 0 2.019.537c.715 0 1.388-.174 2.019-.521a3.927 3.927 0 0 0 1.467-1.467c.357-.62.536-1.293.536-2.019a4.08 4.08 0 0 0-.379-1.703 11.472 11.472 0 0 0-2.114-.521c.358.242.642.557.852.946.2.389.3.81.3 1.262 0 .747-.257 1.383-.773 1.909a2.6 2.6 0 0 1-1.909.789 2.626 2.626 0 0 1-1.908-.773 2.601 2.601 0 0 1-.789-1.909c0-.442.105-.863.315-1.262.2-.389.484-.71.852-.962-.683.105-1.393.289-2.129.552a4.025 4.025 0 0 0-.379 1.656zm-4.085.977c1.325 1.209 2.74 2.14 4.244 2.792A5.049 5.049 0 0 1 5 11.081a5.447 5.447 0 0 1-.394-2.05c0-.316.021-.631.063-.947-1.094.568-2.024 1.205-2.792 1.909zM0 9.993a.79.79 0 0 1 .111-.205c.273-.452.662-.967 1.167-1.545a14.476 14.476 0 0 1 1.814-1.751 11.07 11.07 0 0 1 2.965-1.751A10.637 10.637 0 0 1 10 4c.862 0 1.724.105 2.587.316a9.47 9.47 0 0 1 2.287.867 14 14 0 0 1 1.829 1.183c.568.432 1.052.857 1.452 1.278.399.41.73.778.993 1.104.263.327.479.632.647.915l.205.332-.268.283c-.179.169-.473.432-.883.789-.4.347-.773.652-1.12.915a18.593 18.593 0 0 1-3.439 2.051c-.662.294-1.372.531-2.129.71s-1.478.268-2.161.268c-1.041 0-2.124-.195-3.249-.584a15.04 15.04 0 0 1-3.092-1.498 23.758 23.758 0 0 1-2.208-1.593c-.61-.505-1.041-.894-1.293-1.167z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEye);
export default ForwardRef;
