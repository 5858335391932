import { forwardRef } from "react";

const SvgLetterHome = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1 18.2h18V20H1zm0-4.53V.3h2.017v5.677h3.586V.299h2.035V13.67H6.603V7.675H3.017v5.995zm10.738 0V.3h3.586c2.708 0 3.679 1.326 3.679 3.922s-1.289 3.903-3.829 3.903h-1.419v5.546zm2.017-7.134h1.233c1.419 0 1.904-.728 1.904-2.297 0-1.606-.485-2.353-1.904-2.353h-1.233z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLetterHome);
export default ForwardRef;
