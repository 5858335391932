import { forwardRef } from "react";

const SvgCompress = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.657 8.809V7.382l3.466-1.162v1.406zm0 2.61V9.991l3.466-1.162v1.407zm0 2.589 3.466-1.142v-1.427l-3.466 1.162v1.406zM5.219 2.733v-.876L6.076 1h7.828l1.754 1.713H20v2.609h-1.774l-2.569.857v-.836H14.8l-2.609-2.61H5.218zm0 5.22v-.877h1.733v.857zm0 5.219v-.877h1.733v.857zm0 5.198v-.856h6.952l2.609-2.61h1.794l2.548-.856v.856h.877v2.61h-4.342l-1.733 1.732H6.096zM1.753 9.686h8.685V5.344H1.753zm-.02 5.219h8.705v-4.342H1.733zM0 15.782c0 .231.081.435.245.612.176.163.38.244.611.244h10.439a.901.901 0 0 0 .632-.244.876.876 0 0 0 .244-.612V4.467a.876.876 0 0 0-.244-.612.877.877 0 0 0-.612-.245H.877a.83.83 0 0 0-.612.245.797.797 0 0 0-.265.612z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCompress);
export default ForwardRef;
