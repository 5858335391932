import { forwardRef } from "react";

const SvgWallet = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m12.796 3.595.788-.807h5.234c.338 0 .62.113.845.338s.337.512.337.863v11.2l-.788.807V4.401a.804.804 0 0 0-.807-.807h-5.61zM8.349 7.197c0 .225.082.419.244.582a.811.811 0 0 0 .601.243.793.793 0 0 0 .581-.243.79.79 0 0 0 .244-.582.854.854 0 0 0-.225-.601c-.162-.15-.362-.225-.6-.225s-.438.075-.601.225a.82.82 0 0 0-.244.601zM7.205 8.379V4.42c1.288-1.313 1.951-1.989 1.989-2.026.087-.113.2-.206.337-.281.138-.063.256-.1.356-.113h2.908c.213 0 .401.075.563.225.15.15.225.338.225.563h-.788l-.863.863c-.187.2-.381.4-.582.601l-.15.15v3.977a.797.797 0 0 1-.243.582.764.764 0 0 1-.563.225H7.992a.808.808 0 0 1-.563-.225.827.827 0 0 1-.225-.582zM1.989 3.595l.789-.807h5.215l-.788.788zM.001 17.196v-11.2c0-.4.162-.769.487-1.107.325-.325.688-.487 1.088-.487h4.803v3.977c0 .438.163.82.488 1.145.312.312.687.468 1.125.468h2.402c.437 0 .813-.156 1.125-.468.313-.313.469-.688.469-1.126V4.402h5.61c.212 0 .4.075.563.225.15.15.225.338.225.563v11.2c0 .401-.163.77-.488 1.107-.325.325-.687.488-1.088.488H.788a.808.808 0 0 1-.563-.225.765.765 0 0 1-.225-.564z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgWallet);
export default ForwardRef;
