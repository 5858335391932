import { PubSub } from "./pubSub";

const State = {
  IDLE: 0,
  RUNNING: 1,
  STOPPED: 2,
};

/**
 * @type    {number}  Used to generate a unique id for each task
 */
let uniqueId = 0;

/**
 * A small and simple library for promise-based queues. It will execute enqueued
 * functions concurrently at a specified speed. When a task is being resolved or
 * rejected, an event is emitted.
 *
 * @example
 *    const queue = new Queue({
 *      concurrent: 1,
 *    });
 *
 *    queue.enqueue(asyncTaskA);
 *    queue.enqueue([asyncTaskB, asyncTaskC]);
 *
 *    queue.listen("end", handler);
 *
 * @class   Queue
 */
export class Queue {
  /**
   * A collection to store unresolved tasks. We use a Map here because V8 uses a
   * variant of hash tables that generally have O(1) complexity for retrieval
   * and lookup.
   *
   * @see     https://codereview.chromium.org/220293002/
   * @type    {Map}
   * @access  private
   */
  tasks = new Map();

  /**
   * @type    {number}  Amount of tasks currently handled by the queue
   * @access  private
   */
  currentlyHandled = 0;

  /**
   * @type    {State}
   * @access  public
   */
  state = State.IDLE;

  /**
   * @type    {Object}  options
   * @type    {number}  options.concurrent  How many tasks should be executed in parallel
   * @type    {boolean} options.start       Whether it should automatically execute new tasks as soon as they are added
   * @access  public
   */
  options = {
    concurrent: 5,
    start: false,
  };

  /**
   * Initializes a new queue instance with provided options.
   *
   * @param   {Object}  options
   * @param   {number}  options.concurrent  How many tasks should be executed in parallel
   * @param   {boolean} options.start       Whether it should automatically execute new tasks as soon as they are added
   * @return  {Queue}
   */
  constructor(options = {}) {
    this.pubsub = new PubSub();
    this.options = { ...this.options, ...options };
    this.options.concurrent = parseInt(this.options.concurrent, 10);
  }

  /**
   * Starts the queue if it has not been started yet.
   *
   * @emits   start
   * @return  {void}
   * @access  public
   */
  start() {
    if (this.state !== State.RUNNING && !this.isEmpty) {
      this.state = State.RUNNING;

      return this.execute();
    }
  }

  /**
   * Forces the queue to stop. New tasks will not be executed automatically even
   * if `options.start` was set to `true`.
   *
   * @return  {void}
   * @access  public
   */
  stop() {
    this.state = State.STOPPED;
  }

  /**
   * Publish an end event
   *
   * @return {void}
   * @access private
   */
  end() {
    this.pubsub.publish("end");
  }

  /**
   * Goes to the next request and stops the loop if there are no requests left.
   *
   * @emits   end
   * @return  {void}
   * @access  private
   */
  finalize() {
    this.currentlyHandled -= 1;

    if (this.currentlyHandled <= 0 && this.isEmpty) {
      this.stop();

      // Finalize doesn't force queue to stop as `Queue.stop()` does. Therefore,
      // new tasks should be still resolved automatically if `options.start` was
      // set to `true` (see `Queue.enqueue`):
      this.state = State.IDLE;

      this.end();
    }
  }

  /**
   * Executes _n_ concurrent (based od `options.concurrent`) promises from the
   * queue.
   *
   * @return  {Promise<any>}
   * @access  private
   */
  async execute() {
    const promises = [];

    this.tasks.forEach((fn, id) => {
      // Maximum amount of parallel tasks:
      if (this.currentlyHandled < this.options.concurrent) {
        this.currentlyHandled++;
        this.tasks.delete(id);

        promises.push(
          Promise.resolve(fn())
            .then(() => {
              return this.execute();
            })
            .catch((error) => {
              this.currentlyHandled -= 1;
              return error;
            })
            .finally(() => {
              this.finalize();
            }),
        );
      }
    });
  }

  /**
   * Adds tasks to the queue.
   *
   * @param   {Function|Array}  tasks     Tasks to add to the queue
   * @throws  {Error}                     When task is not a function
   * @return  {void}
   * @access  public
   */
  enqueue(tasks) {
    if (Array.isArray(tasks)) {
      tasks.map((task) => this.enqueue(task));
      return;
    }

    if (typeof tasks !== "function") {
      throw new Error(`You must provide a function, not ${typeof tasks}.`);
    }

    uniqueId = (uniqueId + 1) % Number.MAX_SAFE_INTEGER;
    this.tasks.set(uniqueId, tasks);

    // Start the queue if the queue should resolve new tasks automatically and
    // hasn't been forced to stop:
    if (this.options.start && this.state !== State.STOPPED) {
      this.start();
    }
  }

  /**
   * @see     enqueue
   * @access  public
   */
  add(tasks) {
    this.enqueue(tasks);
  }

  /**
   * Listen to events
   *
   * @param {string} event
   * @param {function} callback
   * @access public
   */
  listen(event, callback) {
    return this.pubsub.listen(event, callback);
  }

  /**
   * Size of the queue.
   *
   * @type    {number}
   * @access  public
   */
  get size() {
    return this.tasks.size;
  }

  /**
   * Checks whether the queue is empty, i.e. there's no tasks.
   *
   * @type    {boolean}
   * @access  public
   */
  get isEmpty() {
    return this.size === 0;
  }

  /**
   * Checks whether the queue is not empty and not stopped.
   *
   * @type    {boolean}
   * @access  public
   */
  get shouldRun() {
    return !this.isEmpty && this.state !== State.STOPPED;
  }
}
