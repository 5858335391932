// import React from 'react'
import styled from "@xstyled/styled-components";

export const CapsuleList = styled.div`
  position: relative;
  padding: 2;
  flex: 0 1 100%;
  transition: base;
  transition-property: opacity;
  min-height: 60;

  &[aria-disabled="true"] {
    opacity: 0.75;
  }
`;
