import { gql } from "@apollo/client";
import { x } from "@xstyled/styled-components";
import { memo } from "react";

import { useDragUrl } from "@/containers/Dnd";
import { getArticleUrl } from "@/containers/article/ArticleLink";
import * as MetaPanel from "@/containers/article/ArticleMetaPanel";
import { CurrentCell } from "@/containers/article/cells/CurrentCell";
import { DeleteCell } from "@/containers/article/cells/DeleteCell";
import { DescriptionCell } from "@/containers/article/cells/DescriptionCell";
import { PrintStateCell } from "@/containers/article/cells/PrintStateCell";
import { StateCell } from "@/containers/article/cells/StateCell";
import { UpdatedCell } from "@/containers/article/cells/UpdatedCell";
import { getArticleStatusColor } from "@/containers/article/util/status";

import { ArticleListSet as Set } from "./ArticleListSet";

export const columns = {
  description: { props: { col: true } },
  current: { props: { col: "auto", w: 64 } },
  updated: { props: { col: "auto", w: 120 } },
  state: { props: { col: "auto", w: 80 } },
  printState: { props: { col: "auto", w: 50 } },
  delete: { props: { col: "auto", w: 40 } },
};

export const ArticleHeader = memo(() => {
  return (
    <Set.ListHeader row>
      <Set.ListHeaderCell colId="description" pb={0}>
        <x.div ml={45}>Titre</x.div>
      </Set.ListHeaderCell>
      <Set.ListHeaderCell colId="current" pb={0}>
        En cours
      </Set.ListHeaderCell>
      <Set.ListHeaderCell colId="state" pb={0}>
        État
      </Set.ListHeaderCell>
      <Set.ListHeaderCell colId="updated" pb={0}>
        Modifié
      </Set.ListHeaderCell>
      <Set.ListHeaderCell colId="printState" pb={0}>
        Exporté
      </Set.ListHeaderCell>
      <Set.ListHeaderCell colId="delete" pb={0}>
        Suppr.
      </Set.ListHeaderCell>
    </Set.ListHeader>
  );
});

const fragments = {
  article: gql`
    fragment ArticleRow_article on Article {
      id
      ...getArticleStatusColor
      ...DescriptionCell_article
      ...StateCell_article
      ...CurrentCell_article
      ...UpdatedCell_article
      ...PrintStateCell_article
      ...DeleteCell_article
    }

    ${getArticleStatusColor.fragment}
    ${DescriptionCell.fragments.article}
    ${StateCell.fragments.article}
    ${CurrentCell.fragments.article}
    ${UpdatedCell.fragments.article}
    ${PrintStateCell.fragments.article}
    ${DeleteCell.fragments.article}
  `,
};

export const ArticleRow = memo(({ article, expanded }) => {
  const [, dragRef] = useDragUrl(() => getArticleUrl(article.id, true));
  const statusColor = getArticleStatusColor(article);
  const activatePanel = MetaPanel.useActivate();

  return (
    <Set.ListBody
      ref={dragRef}
      display="block"
      my={2}
      onClick={() => {
        activatePanel(article.id);
      }}
      aria-expanded={expanded}
    >
      <Set.ListRow row borderLeft={4} borderColor={statusColor}>
        <Set.ListCell colId="description" py={2}>
          <DescriptionCell article={article} />
        </Set.ListCell>
        <Set.ListCell colId="current" pt={12} pb={2}>
          <CurrentCell article={article} />
        </Set.ListCell>
        <Set.ListCell colId="state">
          <StateCell article={article} />
        </Set.ListCell>
        <Set.ListCell colId="updated">
          <UpdatedCell article={article} />
        </Set.ListCell>
        <Set.ListCell colId="printState">
          <PrintStateCell article={article} />
        </Set.ListCell>
        <Set.ListCell colId="delete">
          <DeleteCell article={article} />
        </Set.ListCell>
      </Set.ListRow>
    </Set.ListBody>
  );
});

ArticleRow.fragments = fragments;
