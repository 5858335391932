import { forwardRef } from "react";

const SvgTrash = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.001 17.504c0 .1.039.184.117.251a.344.344 0 0 0 .252.101h.704c.1 0 .189-.034.268-.101a.34.34 0 0 0 .1-.251v-10a.342.342 0 0 0-.352-.352h-.72c-.1 0-.184.028-.251.084a.34.34 0 0 0-.1.251zm-3.568 0c0 .1.033.184.101.251a.34.34 0 0 0 .251.101h.72c.1 0 .184-.034.251-.101s.101-.151.101-.251v-10c0-.1-.028-.184-.084-.251a.342.342 0 0 0-.251-.101h-.72a.38.38 0 0 0-.252.084.34.34 0 0 0-.1.251zm-.72-14.64h2.864V1.423H8.73zm-2.848 14.64a.342.342 0 0 0 .352.352h.72c.1 0 .184-.034.251-.101s.101-.151.101-.251v-10c0-.1-.034-.184-.101-.251s-.151-.101-.251-.101h-.704a.447.447 0 0 0-.268.084.342.342 0 0 0-.101.251v10.017zm-2.144 2.261V5.36c0-.123.028-.213.084-.269.056-.055.145-.083.268-.083h12.01c.335 0 .502.095.502.285v14.355a.32.32 0 0 1-.133.268.693.693 0 0 1-.369.084H4.09a.423.423 0 0 1-.285-.101.293.293 0 0 1-.084-.134zM3 3.936v-.72c0-.101.034-.184.101-.251s.151-.101.251-.101h3.937V1.423c0-.39.145-.725.435-1.005C8.003.139 8.333 0 8.712 0h2.865c.39 0 .725.139 1.005.418.279.28.418.615.418 1.005v1.424h3.937c.1 0 .184.034.251.101s.101.151.101.251v.72c0 .1-.028.184-.084.251a.342.342 0 0 1-.251.101H3.369a.379.379 0 0 1-.251-.084A.414.414 0 0 1 3 3.936z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTrash);
export default ForwardRef;
