import { forwardRef } from "react";

const SvgUser = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.003 18.675v-2.037c0-.461.061-.808.183-1.039.122-.23.353-.448.693-.651 2.824-1.629 4.853-2.709 6.089-3.238V8.451c-.571-.435-.856-1.086-.856-1.955V3.91c0-.598.082-1.134.245-1.609.162-.462.373-.835.631-1.12s.563-.516.916-.692C8.257.299 8.61.17 8.963.102A5.272 5.272 0 0 1 10.001 0c.829 0 1.548.17 2.159.509.584.34 1.025.802 1.324 1.385.285.584.427 1.249.427 1.996v2.607c0 .855-.285 1.5-.855 1.935v3.278c1.045.435 3.068 1.514 6.069 3.238.339.19.57.401.692.631.122.231.183.578.183 1.039v2.057a.415.415 0 0 1-.428.428H.449a.464.464 0 0 1-.306-.122.499.499 0 0 1-.143-.306z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUser);
export default ForwardRef;
