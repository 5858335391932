import { forwardRef } from "react";

const SvgPlay = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path d="M0 18.723V2l20 8.622z" fill="currentColor" fillRule="evenodd" />
  </svg>
);
const ForwardRef = forwardRef(SvgPlay);
export default ForwardRef;
