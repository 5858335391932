import styled, { system } from "@xstyled/styled-components";
import React, { forwardRef } from "react";

const RadioInput = styled.input`
  display: inline-flex;
  vertical-align: middle;
  padding: 0;
  justify-content: space-around;
  border: 1;
  border-color: layout-border;
  border-radius: 50%;
  background-color: white;
  width: 16;
  height: 16;
  transition: base;
  font: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &[type="radio"] {
    margin: 0;
  }

  &:focus {
    box-shadow: glow;
    border-color: primary;
  }
  &:hover:not(:disabled) {
    border-color: primary;
  }
  &:active:not(:disabled) {
    box-shadow: active;
  }
  &:disabled {
    opacity: 0.38;
  }
  &:before {
    align-self: center;
    display: block;
    content: "";
    background-color: transparent;
    border-radius: 50%;
    transition: base;
    width: 12;
    height: 12;
    mask-size: 12px;
  }
  &:checked:before {
    background-color: blue;
  }

  &[aria-invalid="true"] {
    border-color: danger-dark;

    &:focus {
      box-shadow: glow-danger;
      border-color: danger-dark;
    }

    &:hover {
      border-color: danger-dark;
    }
  }

  && {
    ${system}
  }
`;

export const Radio = forwardRef((props, ref) => (
  <RadioInput ref={ref} type="radio" {...props} />
));

export const RadioLabel = styled.labelBox`
  display: inline-flex;
  align-items: center;
  transition: base;
  font-family: base;
  line-height: 1;

  &[aria-disabled="true"] {
    opacity: 0.38;
  }

  input[type="radio"] {
    margin-right: 1ex;
  }

  &[data-scale="lg"] {
    font-size: lg;
    > input {
      width: 18;
      height: 18;
      &:before {
        width: 14;
        height: 14;
        mask-size: 14px;
      }
    }
  }
  &[data-scale="sm"] {
    font-size: sm;
    > input {
      width: 14;
      height: 14;
      &:before {
        width: 10;
        height: 10;
        mask-size: 10px;
      }
    }
  }
`;

const InnerRadioGroup = styled.box`
  margin: -2;

  ${RadioLabel} {
    padding: 2;
  }

  &:has(${RadioInput}:disabled) {
    opacity: 0.38;
  }

  ${RadioInput}:disabled {
    opacity: 1;
  }

  && {
    ${system}
  }
`;

export const RadioGroup = forwardRef((props, ref) => {
  return <InnerRadioGroup ref={ref} row role="radiogroup" {...props} />;
});

if (process.env["NODE_ENV"] !== "production") {
  Radio.displayName = "Radio";
  RadioGroup.displayName = "RadioGroup";
  RadioLabel.displayName = "RadioLabel";
}
