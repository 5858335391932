import { forwardRef } from "react";

const SvgLetterAlert = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1 18.2h18V20H1zM5.254 4.968l-.915 4.127H6.99l-.859-4.127-.392-2.11h-.074zm2.708 8.702-.617-2.895h-3.38L3.33 13.67H1.5L4.712.224h2.222l3.157 13.446zm4.038 0V.3h2.036v11.634h4.22v1.737z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLetterAlert);
export default ForwardRef;
