import { gql } from "@apollo/client";
import styled from "@xstyled/styled-components";
import clsx from "clsx";
import { IoExpand, IoVideocam } from "swash/Icon";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";

import Close from "@/assets/imgs/inline-svg/close.svg";
import {
  DialogDisclosure,
  VideoPreviewDialog,
  useDialogState,
} from "@/containers/video/VideoPreview";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeImage,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

const VideoNodeExpandedContainer = styled.div`
  [data-change="deleted"] & {
    position: relative;
    text-decoration: line-through;
    color: change-deleted;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-deleted-media;
      opacity: 0.25;
    }
    &:after {
      content: "";
      mask-image: url(${Close});
      position: absolute;
      inset: 0 0 0 0;
      background-color: white;
      mask-repeat: no-repeat;
      mask-position: center;

      mask-size: 48px;
    }
  }

  [data-change="added"] & {
    position: relative;
    color: change-added;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-added-media;
      opacity: 0.25;
    }
  }
`;

export function VideoNodeExpanded({ video, dropping }) {
  return (
    <VideoNodeExpandedContainer>
      <iframe
        title="Video"
        className={clsx(
          dropping ? "pointer-events-none" : "pointer-events-auto",
          "mt-10 h-96 w-full",
        )}
        src={video.playerUrl}
        data-test-hidden
      />
    </VideoNodeExpandedContainer>
  );
}
export function VideoNode({ video, tooltip, expanded, dropping }) {
  const previewDialog = useDialogState();

  return !expanded ? (
    <>
      <EditorNodeCard>
        <EditorNodeLayout>
          <EditorNodeTooltip tooltip={tooltip}>
            <EditorNodeIcon>
              <IoVideocam />
            </EditorNodeIcon>
          </EditorNodeTooltip>
          {video.thumbnailUrl && (
            <DialogDisclosure
              title="Prévisualiser la vidéo"
              state={previewDialog}
            >
              {(disclosureProps) => (
                <PreviewLink {...disclosureProps}>
                  <PreviewLinkContent display="flex">
                    <EditorNodeImage src={video.thumbnailUrl} />
                  </PreviewLinkContent>
                  <PreviewLinkHovering>
                    <IoExpand />
                  </PreviewLinkHovering>
                </PreviewLink>
              )}
            </DialogDisclosure>
          )}
          <EditorNodeBody multiline>
            <ChangeText title={video.videoTitle}>{video.videoTitle}</ChangeText>
          </EditorNodeBody>
        </EditorNodeLayout>
      </EditorNodeCard>
      {video.thumbnailUrl && (
        <VideoPreviewDialog state={previewDialog} videoId={video.id} />
      )}
    </>
  ) : (
    <VideoNodeExpanded video={video} dropping={dropping} />
  );
}

VideoNode.fragments = {
  video: gql`
    fragment VideoNode_video on Video {
      videoTitle: title
      thumbnailUrl
      playerUrl
      textDescription
      url
    }
  `,
};
