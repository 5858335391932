import { forwardRef } from "react";

const SvgCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2 10c0-1.43.362-2.78 1.088-4.046a7.905 7.905 0 0 1 2.897-2.881A7.833 7.833 0 0 1 10 2c1.46 0 2.81.358 4.046 1.073a8 8 0 0 1 2.896 2.912A7.92 7.92 0 0 1 18 10c0 1.46-.358 2.804-1.073 4.03A8.082 8.082 0 0 1 14 16.943c-1.216.705-2.55 1.058-4 1.058-1.471 0-2.815-.358-4.03-1.074a8.031 8.031 0 0 1-2.912-2.942A7.856 7.856 0 0 1 2 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCircle);
export default ForwardRef;
