import { forwardRef } from "react";

const SvgHuit = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.06 14.333c0 1.91 1.576 3.182 3.546 3.182 1.849 0 3.182-1.09 3.182-2.879 0-2.09-2.03-2.848-4.636-3.909-1.213.88-2.091 2.121-2.091 3.606zm4.758-5.575c1.03-1.03 1.606-2.122 1.606-3.334 0-1.697-1.06-2.94-2.848-2.94-1.485 0-2.637.97-2.637 2.637 0 1.94 1.728 2.788 3.88 3.637zM3 14.697c0-2.394 1.667-3.97 3.485-4.94v-.12C5 8.575 3.818 7.151 3.818 5.12 3.818 2 6.303 0 9.636 0c3.455 0 5.667 2.09 5.667 5.212 0 1.91-1.333 3.515-2.576 4.424v.122c1.788 1 3.334 2.424 3.334 5 0 2.97-2.606 5.242-6.516 5.242C5.788 20 3 17.818 3 14.697z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgHuit);
export default ForwardRef;
