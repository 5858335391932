import { memo } from "react";

import {
  CheckboxField,
  useCheckboxField,
} from "@/components/fields/CheckboxField";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SwitchField, useSwitchField } from "@/components/fields/SwitchField";

import { EnumField } from "./EnumField";

function CheckboxBooleanField({ name, label, hint, variant, ...options }) {
  const field = useCheckboxField(name, {
    orientation: "vertical",
    ...options,
  });

  return (
    <FieldGroup {...field}>
      <CheckboxField variant={variant} hint={hint} {...field}>
        {label}
      </CheckboxField>
    </FieldGroup>
  );
}

const enumValues = [
  { value: true, label: "Oui" },
  { value: false, label: "Non" },
];

function SelectBooleanField(props) {
  return <EnumField {...props} enum={enumValues} />;
}

function SwitchBooleanField({ name, label, tint, ...options }) {
  const field = useSwitchField(name, options);

  return (
    <FieldGroup {...field} display="flex" justifyContent="space-between">
      {label && <FieldLabel {...field}>{label}</FieldLabel>}
      <FieldError {...field} />
      <SwitchField {...field} tint={tint} />
    </FieldGroup>
  );
}

export const BooleanField = memo(({ appearance = "checkbox", ...others }) => {
  switch (appearance) {
    case "select":
      return <SelectBooleanField {...others} />;
    case "checkbox":
      return <CheckboxBooleanField {...others} />;
    case "switch":
      return <SwitchBooleanField {...others} />;
    default:
      return null;
  }
});
