import styled from "@xstyled/styled-components";

export const sortValues = (values) =>
  values.sort((labelA, labelB) => labelA.localeCompare(labelB));

export const noSort = () => 1;

export const EllipsisSelectValue = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
