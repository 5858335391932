import { forwardRef } from "react";

const SvgCheckDouble = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path d="M0 10.121c0-.078.022-.143.068-.195l1.117-1.089c.055-.052.123-.078.204-.078s.15.022.205.065l3.05 2.853a.333.333 0 0 0 .218.078c.073 0 .14-.026.205-.078l7.326-6.238a.264.264 0 0 1 .204-.091c.081 0 .15.026.205.078l1.116 1.102a.257.257 0 0 1 .082.195.258.258 0 0 1-.082.195L5.067 14.66c-.064.052-.136.078-.218.078s-.15-.026-.205-.078L.083 10.316A.286.286 0 0 1 0 10.12z" />
      <path d="M18.597 5.348c.081 0 .15.026.205.078l1.116 1.102a.257.257 0 0 1 .082.195.258.258 0 0 1-.082.195l-8.851 7.742c-.064.052-.136.078-.218.078s-.15-.026-.205-.078L8.83 12.95l1.476-1.582.339.309a.333.333 0 0 0 .217.078c.073 0 .14-.026.205-.078l7.326-6.238a.264.264 0 0 1 .204-.091zM8.157 9.53c.083 0 .15.022.205.065l1.205 1.1-1.464 1.57-1.252-1.178a.286.286 0 0 1-.082-.195.28.28 0 0 1 .067-.195l1.117-1.09a.284.284 0 0 1 .205-.077z" />
    </g>
  </svg>
);
const ForwardRef = forwardRef(SvgCheckDouble);
export default ForwardRef;
