import { gql } from "@apollo/client";

import { ArticleRemoveButton } from "@/containers/article/ArticleRemoveButton";

export function DeleteCell({ article }) {
  return <ArticleRemoveButton article={article} />;
}

DeleteCell.fragments = {
  article: gql`
    fragment DeleteCell_article on Article {
      ...ArticleRemoveButton_article
    }

    ${ArticleRemoveButton.fragments.article}
  `,
};
