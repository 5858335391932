import { forwardRef } from "react";

const SvgTwitter = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.001 16.412c.35.039.675.058.974.058 1.895 0 3.596-.584 5.102-1.752a3.99 3.99 0 0 1-2.375-.798 4.123 4.123 0 0 1-1.461-2.045c.247.038.5.058.759.058.39 0 .754-.039 1.091-.117a3.995 3.995 0 0 1-2.356-1.441A3.97 3.97 0 0 1 .8 7.765v-.039a4.13 4.13 0 0 0 1.85.487c-.559-.363-1-.85-1.325-1.46a3.98 3.98 0 0 1-.486-1.928c0-.728.175-1.416.525-2.065a11.622 11.622 0 0 0 3.778 3.038 11.736 11.736 0 0 0 4.693 1.247 3.904 3.904 0 0 1-.097-.935c0-1.13.402-2.097 1.207-2.902S12.718 2 13.847 2c1.182 0 2.175.428 2.98 1.285a7.864 7.864 0 0 0 2.609-.974c-.299.961-.896 1.714-1.792 2.259A8.46 8.46 0 0 0 20 3.928a8.839 8.839 0 0 1-2.045 2.122v.526c0 1.545-.298 3.051-.895 4.518a11.998 11.998 0 0 1-2.668 3.914 11.09 11.09 0 0 1-4.012 2.552c-1.273.467-2.636.701-4.09.701-2.285 0-4.381-.617-6.29-1.85z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTwitter);
export default ForwardRef;
