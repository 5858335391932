import { forwardRef } from "react";

const SvgParagraphStyle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.306 19.789v-2.908h.469c.406.813.672 1.548.797 2.204.375.125.765.188 1.172.188.922 0 1.555-.305 1.899-.915.344-.625.516-1.766.516-3.423V0h3.47v.422a8.332 8.332 0 0 1-1.759.586V14.49c0 3.673-1.508 5.51-4.525 5.51-.828 0-1.508-.071-2.04-.212zM4 6.026c0-1.751.602-3.197 1.805-4.338C6.993.563 8.548 0 10.471 0v12.193c-1.016.016-1.938-.125-2.767-.422-.828-.313-1.516-.742-2.063-1.289a5.81 5.81 0 0 1-1.219-1.97C4.14 7.762 3.999 6.934 4 6.027z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgParagraphStyle);
export default ForwardRef;
