import { gql } from "@apollo/client";

import { ArticlePublishDate } from "@/containers/article/ArticlePublishDate";

export function DateCell({ article }) {
  return <ArticlePublishDate article={article} />;
}

DateCell.fragments = {
  article: gql`
    fragment DateCell_article on Article {
      ...ArticlePublishDate_article
    }

    ${ArticlePublishDate.fragments.article}
  `,
};
