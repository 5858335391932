import { gql } from "@apollo/client";
import clsx from "clsx";
import { memo } from "react";

import { ArticleLayoutPricingIndicator } from "@/containers/article/ArticleLayoutPricingIndicator";
import { ArticleLiveLink } from "@/containers/article/ArticleLiveLink";
import { SpreadArticleNotesButton } from "@/containers/article/ArticleNotesButton";
import { ArticlePublishedLink } from "@/containers/article/ArticlePublishedLink";
import { SpreadArticleTitleLink } from "@/containers/article/ArticleTitleLink";

const Infos = ({ children, ...props }) => (
  <div
    className={clsx(
      "font-accent text-xs uppercase text-grey-on",
      "[&>span]:before:m-[0_0.8ex] [&>span]:before:content-['|']",
      "first:[&>span]:before:content-none",
    )}
    {...props}
  >
    {children}
  </div>
);

const Description = memo(
  ({
    id,
    editorialTypeLabel,
    title,
    url,
    isReference,
    isConfidential,
    live,
    hasNotes,
  }) => {
    return (
      <div className="flex flex-col gap-1">
        <div>
          <SpreadArticleTitleLink id={id} title={title} />
        </div>
        <div className="flex items-center gap-4">
          <Infos>
            {isConfidential && (
              <span className="font-semibold text-danger-on">Confidentiel</span>
            )}
            {isReference && <span>Contenu de référence</span>}
            <span>{editorialTypeLabel}</span>
            <span>#{id}</span>
          </Infos>
          <ArticleLiveLink article={{ live }} scale="xs" />
          <ArticlePublishedLink article={{ url }} scale="xs" />
          {hasNotes && (
            <div className="-ml-2">
              <SpreadArticleNotesButton articleId={id} hasNotes />
            </div>
          )}
        </div>
      </div>
    );
  },
);

export function DescriptionCell({ article }) {
  return (
    <div
      className="grid grid-flow-col gap-2"
      style={{ gridAutoColumns: "min-content 1fr" }}
    >
      <div className="mx-2 mt-0.5">
        <ArticleLayoutPricingIndicator article={article} />
      </div>
      <Description
        id={article.id}
        editorialTypeLabel={article.editorialType.label}
        title={article.title}
        url={article.url}
        isReference={article.isReference}
        isConfidential={article.isConfidential}
        live={article.live}
        hasNotes={article.hasNotes}
      />
    </div>
  );
}

DescriptionCell.fragments = {
  article: gql`
    fragment DescriptionCell_article on Article {
      id
      title
      isReference
      isConfidential
      editorialType {
        id
        label
      }
      hasNotes
      ...ArticleLayoutPricingIndicator_article
      ...ArticlePublishedLink_article
      ...ArticleLiveLink_article
    }

    ${ArticleLayoutPricingIndicator.fragments.article}
    ${ArticlePublishedLink.fragments.article}
    ${ArticleLiveLink.fragments.article}
  `,
};
