/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import { useToaster } from "swash/Toast";
import { useEventCallback } from "swash/utils/useEventCallback";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";
import {
  ArticleLayoutEditorialFormatSelector,
  EditorialFormat,
} from "@/containers/article/ArticleLayoutEditorialFormatSelect";
import { EditorialFormatChipButton } from "@/containers/article/fields/EditorialFormat";

const ArticleFragment = gql`
  fragment ArticleEditorialFormat_article on Article {
    editorialFormat {
      id
      label
    }
  }
`;

const Mutation = gql`
  mutation ArticleEditorialFormat_updateArticle(
    $articleId: Int!
    $editorialFormatId: Int!
  ) {
    updateArticle(
      input: { id: $articleId, editorialFormatId: $editorialFormatId }
    ) {
      id
      ...ArticleEditorialFormat_article
    }
  }
  ${ArticleFragment}
`;

export type ArticleEditorialFormatSelectProps = {
  article: {
    id: number;
    editorialFormat: EditorialFormat | null;
    layout: {
      id: number;
      hasEditorialFormats: boolean;
    };
  };
  disabled?: boolean;
};

export const ArticleEditorialFormatEdit = (
  props: ArticleEditorialFormatSelectProps,
) => {
  const tooltip = useArticleAuditTrailTooltip("editorialFormat");
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));
  const toaster = useToaster();
  const [updateEditorialFormat] = useMutation(Mutation);

  const handleChange = useEventCallback(
    (editorialFormat: EditorialFormat | null) => {
      // Actually, here the value cannot be null. Layout cannot be modified after article creation
      // and this field is not clearable
      if (!editorialFormat) return;
      updateEditorialFormat({
        variables: {
          articleId: props.article.id,
          editorialFormatId: editorialFormat.id,
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            __typename: "Article",
            id: props.article.id,
            editorialFormat: {
              id: editorialFormat.id,
              label: editorialFormat.label,
            },
          },
        },
      }).catch(() => {
        toaster.danger("La mise à jour du format a échouée");
      });
    },
  );

  const disabled = props.disabled || !props.article.layout.hasEditorialFormats;

  // An old article can still reference a disabled format.
  if (
    (!props.article.layout.hasEditorialFormats &&
      !props.article.editorialFormat) ||
    (!props.article.editorialFormat && disabled)
  )
    return null;

  if (active && !disabled) {
    return (
      <ArticleLayoutEditorialFormatSelector
        layoutId={props.article.layout.id}
        value={props.article.editorialFormat}
        onChange={handleChange}
        tooltip={tooltip ?? "Éditer le format"}
      />
    );
  }
  return (
    <span>
      <EditorialFormatChipButton
        editorialFormat={props.article.editorialFormat}
        asButton={!disabled}
        onFocus={activate}
        onMouseEnter={activate}
      />
    </span>
  );
};

ArticleEditorialFormatEdit.fragments = {
  article: gql`
    fragment ArticleEditorialFormatSelect_article on Article {
      ...ArticleEditorialFormat_article
      layout {
        id
        label
        hasEditorialFormats
      }
    }
    ${ArticleFragment}
  `,
};
