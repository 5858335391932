import { get } from "lodash-es";

import { CUSTOM_FIELDS_RESOURCE_MAP } from "@/config/constants";
import { ERRORS } from "@/config/messages";

function formatUnicityError(error, nameSpace) {
  const graphQLErrors = error?.graphQLErrors;
  if (!graphQLErrors?.length) return null;

  const errors = graphQLErrors.reduce((acc, error) => {
    const errorCustomField = error?.extensions?.exception?.cause?.customField;
    const errorCode = error?.extensions?.exception?.code;
    const errorResource = error?.extensions?.exception?.cause?.resource;
    if (!errorCustomField || !errorCode || !errorResource) return acc;
    const getError = get(ERRORS, errorCode.split(":"));
    const message = getError?.(CUSTOM_FIELDS_RESOURCE_MAP[errorResource]) ?? "";
    return { [nameSpace]: { [errorCustomField]: message }, ...acc };
  }, {});

  return Object.keys(errors).length > 0 ? errors : null;
}

export const handleUnicityError =
  (fn, nameSpace = "customFields") =>
  async (...args) => {
    try {
      await fn(...args);
    } catch (error) {
      const unicityError = formatUnicityError(error, nameSpace);
      if (unicityError) {
        return unicityError;
      }
      throw error;
    }
  };
