import { forwardRef } from "react";

const SvgGlyph = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M0 9.777c0-1.823.44-3.48 1.319-4.966.893-1.474 2.115-2.645 3.667-3.512C6.564.433 8.277 0 10.126 0s3.544.426 5.083 1.28a9.12 9.12 0 0 1 3.53 3.51C19.58 6.279 20 7.935 20 9.759c0 1.577-.31 3.018-.931 4.324a9.371 9.371 0 0 1-2.542 3.278h2.969v2.25h-7.1v-2.153c1.383-.375 2.534-1.164 3.453-2.367 1.086-1.409 1.629-3.11 1.629-5.1 0-1.45-.297-2.755-.892-3.92a6.882 6.882 0 0 0-2.58-2.754c-1.151-.685-2.438-1.028-3.861-1.028-1.41 0-2.709.343-3.899 1.028A7.134 7.134 0 0 0 3.53 6.07c-.647 1.176-.97 2.483-.97 3.918 0 1.345.259 2.573.776 3.685A6.916 6.916 0 0 0 5.179 16.1a6.325 6.325 0 0 0 2.522 1.358v2.153H.582v-2.23h2.929a9.884 9.884 0 0 1-2.58-3.317C.31 12.77 0 11.341 0 9.777"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGlyph);
export default ForwardRef;
