import { forwardRef } from "react";

const SvgCode = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12.656 15.328v-1.141l.328-.812 4.938-2.781-3.172-1.703.547-1.407L20 10.078v1zM7.5 18l6.265-16h1.657l-6.25 16zM0 11.094v-1l7.328-4.079v1.766l-5.25 2.797 5.25 2.969v1.781z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCode);
export default ForwardRef;
