// @ts-check
import { EditorState, Modifier } from "draft-js-es";
import { useEffect } from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { useReadOnly } from "@/containers/ReadOnly";

import { extractSelectedBlocksContent } from "./extractSelectedBlocksContent";
import { modifyClipboard } from "./modifyClipboard";

/**
 * useCopyModifier modify clipboard content on copy based on the editorState
 * @param {import('draft-js').EditorState} editorState
 * @param {(editorState: import('draft-js').EditorState) => void} setEditorState
 * @param {string} env
 * @param {string} editorId
 * @param {number} [articleId]
 * @param {*} containerRef
 */
export const useCopyModifier = (
  editorState,
  setEditorState,
  env,
  containerRef,
  editorId,
  articleId,
) => {
  const readOnly = useReadOnly();

  const editorStateRef = useLiveRef(editorState);
  useEffect(() => {
    const container = containerRef.current;
    /** @type {(event: ClipboardEvent) => void} */
    const handleCopy = (event) => {
      event.stopPropagation();
      const contentState = editorStateRef.current.getCurrentContent();
      const { selectedBlocks } = extractSelectedBlocksContent(
        contentState,
        editorStateRef.current.getSelection(),
      );
      modifyClipboard({
        event,
        contentState,
        selectedBlocks,
        env,
        editorId,
        articleId,
      });
    };
    /** @type {(event: ClipboardEvent) => void} */
    const handleCut = (event) => {
      event.stopPropagation();
      const contentState = editorStateRef.current.getCurrentContent();
      const selectionState = editorStateRef.current.getSelection();
      const { selectedBlocks } = extractSelectedBlocksContent(
        contentState,
        selectionState,
      );
      const modifiedContent = Modifier.removeRange(
        contentState,
        selectionState,
        "backward",
      );
      setEditorState(
        EditorState.push(
          editorStateRef.current,
          modifiedContent,
          "remove-range",
        ),
      );

      modifyClipboard({
        event,
        contentState,
        selectedBlocks,
        env,
        editorId,
        articleId,
      });
    };

    if (!readOnly) {
      container.addEventListener("copy", handleCopy);
      container.addEventListener("cut", handleCut);
    }
    return () => {
      container.removeEventListener("copy", handleCopy);
      container.removeEventListener("cut", handleCut);
    };
  }, [env, containerRef, editorStateRef, readOnly, editorId, setEditorState]);
};
