import { forwardRef } from "react";

const SvgPlusCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.196 11.245c0 .115.045.211.134.288a.394.394 0 0 0 .288.115h3.735v3.773a.464.464 0 0 0 .115.306.391.391 0 0 0 .288.116h2.491c.115 0 .21-.039.287-.116a.387.387 0 0 0 .115-.287v-3.774h3.716a.425.425 0 0 0 .307-.134.394.394 0 0 0 .115-.288v-2.49a.394.394 0 0 0-.403-.403h-3.716V4.635a.419.419 0 0 0-.135-.306.387.387 0 0 0-.287-.115H8.755a.388.388 0 0 0-.287.115.387.387 0 0 0-.115.287v3.736H4.637a.458.458 0 0 0-.307.115.392.392 0 0 0-.115.287zM0 10c0-1.788.454-3.474 1.36-5.057a9.89 9.89 0 0 1 3.621-3.602C6.513.447 8.187 0 10 0c1.827 0 3.512.447 5.058 1.341a9.989 9.989 0 0 1 3.62 3.64C19.56 6.514 20 8.187 20 10.001c0 1.826-.447 3.505-1.341 5.038A10.102 10.102 0 0 1 15 18.679c-1.52.881-3.186 1.322-5 1.322-1.839 0-3.518-.447-5.038-1.342a10.047 10.047 0 0 1-3.64-3.678A9.82 9.82 0 0 1 0 10.001z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPlusCircle);
export default ForwardRef;
