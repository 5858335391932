import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { pick, uniqBy } from "lodash-es";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/**
 * Special query to improve fetchMore:
 * - Previous request cancelling
 * - fetchMoreLoading
 * @param {any} query
 * @param {import('@apollo/client').QueryOptions} [options]
 */
export function useFetchMoreQuery(query, options = {}) {
  const apolloClient = useApolloClient();
  const result = useQuery(query, {
    nextFetchPolicy: "cache-first",
    ...options,
  });
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const [refetchLoading, setRefetchLoading] = useState(false);

  const resultRef = useRef();
  resultRef.current = result;

  const optionsRef = useRef();
  optionsRef.current = { query, ...options };

  const queryIdRef = useRef(0);

  const fetchMore = useCallback(
    (fetchMoreOptions) => {
      const combinedVariables = {
        ...optionsRef.current.variables,
        ...fetchMoreOptions.variables,
      };
      const queryId = ++queryIdRef.current;
      setFetchMoreLoading(true);
      const isOutdated = () => queryId !== queryIdRef.current;
      return apolloClient
        .query({ ...optionsRef.current, variables: combinedVariables })
        .then((fetchMoreResult) => {
          if (!isOutdated()) {
            resultRef.current.updateQuery((previousResult) =>
              fetchMoreOptions.updateQuery(previousResult, {
                fetchMoreResult: fetchMoreResult.data,
                variables: combinedVariables,
              }),
            );
          }
        })
        .catch((error) => {
          if (optionsRef.current.onError) {
            optionsRef.current.onError(error);
          }
        })
        .finally(() => {
          if (!isOutdated()) {
            setFetchMoreLoading(false);
          }
        });
    },
    [apolloClient],
  );

  const refetch = useCallback((options) => {
    setRefetchLoading(true);
    return resultRef.current.refetch(options).finally(() => {
      setRefetchLoading(false);
    });
  }, []);

  return { ...result, refetch, refetchLoading, fetchMore, fetchMoreLoading };
}

/**
 * Cancellable mutation.
 * @param {*} query
 * @param {*} options
 */
export function useCancellableMutation(mutation, options) {
  const [mutate, result] = useMutation(mutation, options);
  const abortController = useRef();
  const abort = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort();
    }
  }, []);
  const cancellableMutate = useCallback(
    (mutateParams) => {
      abort();
      const controller = new window.AbortController();
      abortController.current = controller;

      mutate({
        ...mutateParams,
        context: {
          ...mutateParams?.options?.context,
          fetchOptions: {
            ...mutateParams?.options?.context?.fetchOptions,
            controller,
          },
        },
      }).catch((error) => {
        if (error.networkError?.name === "AbortError") {
          return;
        }
        throw error;
      });
    },
    [abort, mutate],
  );
  return [cancellableMutate, { ...result, abort }];
}

const SerialMutationContext = createContext();

const PENDING_STATE = { error: null, loading: false };
const LOADING_STATE = { error: null, loading: true };

export function SerialMutationProvider({ children }) {
  const pendingOperationsRef = useRef([]);
  const currentOperationRef = useRef(null);
  const [state, setState] = useState(PENDING_STATE);
  const runPendingOperations = useCallback(() => {
    function executeOperation(operation) {
      currentOperationRef.current = operation;
      setState(LOADING_STATE);
      const [mutate, options] = operation;
      mutate(options)
        .then(() => {
          currentOperationRef.current = null;
          run();
        })
        .catch((error) => {
          currentOperationRef.current = null;
          setState({ error, loading: false });
        });
    }

    function run() {
      if (currentOperationRef.current !== null) return;
      if (pendingOperationsRef.current.length === 0) {
        setState(PENDING_STATE);
      } else {
        executeOperation(pendingOperationsRef.current.shift());
      }
    }

    return run();
  }, []);
  const addOperation = useCallback(
    (mutate, options) => {
      pendingOperationsRef.current.push([mutate, options]);
      runPendingOperations();
    },
    [runPendingOperations],
  );
  const value = useMemo(() => ({ addOperation, state }), [addOperation, state]);
  return (
    <SerialMutationContext.Provider value={value}>
      {children}
    </SerialMutationContext.Provider>
  );
}

export function useSerialMutationState() {
  const { state } = useContext(SerialMutationContext);
  return state;
}

/**
 * Serial mutation.
 * Execute mutation in series in a giving context.
 * @param {*} mutation
 * @param {*} options
 */
export function useSerialMutation(mutation, options) {
  const { addOperation } = useContext(SerialMutationContext);
  const optionsRef = useRef(options);
  useEffect(() => {
    optionsRef.current = options;
  });
  const [mutate] = useMutation(mutation, options);
  const client = useApolloClient();
  return useCallback(
    ({ optimisticResponse, ...mutateOptions }) => {
      if (optionsRef.current.update && optimisticResponse) {
        optionsRef.current.update(client, {
          data: optimisticResponse,
          optimistic: true,
        });
      }
      addOperation(mutate, mutateOptions);
    },
    [addOperation, mutate, client],
  );
}

/** @typedef {import('@apollo/client').DocumentNode} DocumentNode */
/** @typedef {import('@apollo/client').QueryResult} QueryResult */
/** @typedef {import('@apollo/client').OperationVariables} OperationVariables */

/**
 * Use query without error management.
 * @template {any} TData
 * @template {OperationVariables} TVariables
 * @param {DocumentNode} query
 * @param {{ ignoreSubsequentErrors?: boolean } & import('@apollo/client').QueryHookOptions<TData, TVariables>} [options]
 * @returns {import('@apollo/client').QueryResult<TData, TVariables>}
 */
export function useSafeQuery(
  query,
  { ignoreSubsequentErrors = false, ...options } = {},
) {
  const { error, ...others } = useQuery(query, {
    nextFetchPolicy: "cache-first",
    ...options,
  });
  if (ignoreSubsequentErrors) {
    if (error && !others.previousData) {
      throw error;
    }
  } else {
    if (error) {
      throw error;
    }
  }
  return others;
}

/**
 * Use mutation without error management.
 * @type {typeof useMutation}
 */
export function useSafeMutation(mutation, options) {
  const [mutate, { error, ...others }] = useMutation(mutation, options);
  if (error) throw error;
  return [mutate, others];
}

const isFragmentDefinition = (node) => node.kind === "FragmentDefinition";

const getFragmentDefinitionProps = (fragmentDefinition, state) => {
  return fragmentDefinition.selectionSet.selections.reduce(
    (props, selection) => {
      switch (selection.kind) {
        case "Field":
          return [...props, selection.name.value];
        case "FragmentSpread": {
          const spreadFragment = state.definitions.find(
            (node) =>
              isFragmentDefinition(node) &&
              node.name.value === selection.name.value,
          );
          return [
            ...props,
            ...getFragmentDefinitionProps(spreadFragment, state),
          ];
        }
        default:
          return props;
      }
    },
    [],
  );
};

export const getFragmentProps = (document) => {
  const definition = document.definitions.find((node) =>
    isFragmentDefinition(node),
  );
  if (!definition) {
    throw new Error("Fragment definition not found");
  }
  return getFragmentDefinitionProps(definition, {
    definitions: document.definitions,
  });
};

export const pickFragment = (obj, document) => {
  return pick(obj, getFragmentProps(document));
};

export const prependNode = (existingNodes, incomingNode, iteratee = "id") => {
  return uniqBy([incomingNode, ...existingNodes], iteratee);
};

export const appendNode = (existingNodes, incomingNode, iteratee = "id") => {
  return uniqBy([...existingNodes, incomingNode], iteratee);
};

export const mergeConnections = (existing, incoming, iteratee = "id") => {
  return {
    ...existing,
    ...incoming,
    nodes: uniqBy([...existing.nodes, ...incoming.nodes], iteratee),
  };
};

export const mergeCursorConnections = (existing, incoming) => {
  return {
    ...existing,
    ...incoming,
    nodes: [...existing.nodes, ...incoming.nodes],
  };
};

export const getGraphQlErrorsCode = (error) => {
  const graphQLErrors = error?.graphQLErrors;
  if (!graphQLErrors) return [];

  return graphQLErrors.map((error) => {
    return (
      (error?.extensions?.exception?.code || error?.extensions?.code) ??
      "default"
    );
  });
};
