import { forwardRef } from "react";

const SvgGlob = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.679 13.965H2.333a8.624 8.624 0 0 1-.996-3.574H3.59c.127.275.345.492.623.617a16.78 16.78 0 0 0 .467 2.957zm-.046-7.93c-.209.888-.346 1.786-.407 2.672-.33.144-.577.414-.69.754H1.336a8.576 8.576 0 0 1 .996-3.569h2.336a8.706 8.706 0 0 0-.036.143zm.322 8.86c.224.64.517 1.278.87 1.898.219.374.47.729.75 1.058A8.71 8.71 0 0 1 2.9 14.894zM2.9 4.966a8.697 8.697 0 0 1 3.643-2.949c-.269.322-.51.666-.718 1.027a11.21 11.21 0 0 0-.88 1.922zm6.3.925V9.46H5.93a1.266 1.266 0 0 0-.528-.67c.055-.803.174-1.6.353-2.37.04-.19.084-.363.132-.53zm0 4.499v3.574H5.887a8.125 8.125 0 0 1-.132-.526c-.187-.83-.308-1.67-.359-2.5.21-.134.376-.321.482-.548zM6.197 4.967c.153-.392.336-.788.544-1.178a6.121 6.121 0 0 1 1.671-1.954c.242-.184.507-.319.788-.403v3.535zM9.2 14.894v3.528a2.585 2.585 0 0 1-.789-.402 6.079 6.079 0 0 1-1.67-1.953c-.206-.386-.389-.78-.544-1.173zm1.461 3.527v-3.527h2.576c.063.144.154.276.264.388-.13.296-.274.574-.386.786a6.11 6.11 0 0 1-1.674 1.952c-.241.18-.503.315-.78.4zm0-13.454V1.433c.276.084.538.219.779.402a6.071 6.071 0 0 1 1.676 1.954c.16.296.281.547.382.785a1.289 1.289 0 0 0-.23.393zm7.86 4.494h-2.85a16.543 16.543 0 0 0-.38-3.118c.138-.123.248-.277.323-.45h1.91a8.58 8.58 0 0 1 .996 3.568zm-2.886-4.494a1.276 1.276 0 0 0-1.044-.819c-.148-.34-.324-.692-.554-1.103a7.533 7.533 0 0 0-.72-1.026 8.694 8.694 0 0 1 3.635 2.948zM14.486 9.46h-3.825V5.89h2.628c.153.366.477.642.864.736.191.91.303 1.863.333 2.834zm-1.207 8.39c.282-.331.533-.686.749-1.058.205-.354.4-.74.578-1.15.42-.07.785-.358.95-.749h1.396a8.675 8.675 0 0 1-3.673 2.957zm.886-4.693c-.439.08-.804.39-.952.807H10.66V10.39h3.828c-.023.899-.132 1.83-.324 2.767zm4.355-2.767a8.622 8.622 0 0 1-.997 3.574h-1.939a1.267 1.267 0 0 0-.296-.46c.226-1.027.354-2.074.382-3.114zM9.926 0C4.453 0 0 4.453 0 9.926c0 5.476 4.453 9.93 9.926 9.93s9.926-4.455 9.926-9.93C19.852 4.453 15.4 0 9.926 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGlob);
export default ForwardRef;
