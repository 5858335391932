import { gql } from "@apollo/client";
import moment from "moment";
import "moment/locale/fr";

import { useSubscribePreviousFormValues } from "@/components/forms/FormSubscribe";
import { DateField } from "@/containers/admin/CRUD";
import { PeriodicalReleaseField } from "@/containers/article/fields/PeriodicalRelease";
import { getPeriodical, getPeriodicalSection } from "@/containers/article/util";

const WEEK_DAYS = [0, 1, 2, 3, 4, 5, 6];
function getDisabledWeekDays(periodicity) {
  return WEEK_DAYS.filter(
    (weekDay) => !periodicity.weekDays.includes(weekDay),
  ).map((weekDayFr) => (weekDayFr + 1) % 7);
}

function getReleaseOptions(periodical) {
  return periodical?.releases.nodes.filter(({ enabled }) => enabled) ?? [];
}

function getWeekDays(periodicalSection, periodical) {
  return periodicalSection?.periodicity ?? periodical?.periodicity ?? [];
}

function isValidReleaseId(periodical, periodicalReleaseId) {
  const releaseOptions = getReleaseOptions(periodical);
  return releaseOptions.some(({ id }) => id === periodicalReleaseId);
}

function isValidWeekDay(periodical, periodicalSection, periodicalEditionDate) {
  const selectedWeekDay = moment(periodicalEditionDate).day() - 1;
  const dateOptions = getWeekDays(periodicalSection, periodical);
  return dateOptions.weekDays.some((weekDay) => weekDay === selectedWeekDay);
}

function isValidValue(periodical, periodicalSection, values) {
  if (periodical.editionType === "release") {
    return isValidReleaseId(periodical, values.periodicalReleaseId);
  }
  return isValidWeekDay(
    periodical,
    periodicalSection,
    values.periodicalEditionDate,
  );
}

export function PeriodicalEditionDateField({
  children,
  required,
  disabled,
  periodicals,
  periodical,
  periodicalSection,
  selectableDisabled,
}) {
  // Empty edition date field when the section is updated and value become invalid
  useSubscribePreviousFormValues(({ values, previousValues, form }) => {
    if (
      !values.periodicalId ||
      (!values.periodicalReleaseId && !values.periodicalEditionDate)
    ) {
      return;
    }

    const periodical = getPeriodical({ values, periodicals });
    const periodicalSection = getPeriodicalSection({ values, periodicals });
    const previousPeriodicalSection = getPeriodicalSection({
      values: previousValues,
      periodicals,
    });

    if (
      periodicalSection !== previousPeriodicalSection &&
      !isValidValue(periodical, periodicalSection, values)
    ) {
      form.change("periodicalReleaseId", null);
      form.change("periodicalEditionDate", null);
    }
  });

  if (!periodical) return null;

  function getField() {
    return periodical?.editionType === "release" ? (
      <PeriodicalReleaseField
        periodicalId={periodical.id}
        periodicalSectionId={periodicalSection?.id}
        required={required}
        disabled={disabled}
        label="Parution"
        selectableDisabled={selectableDisabled}
      />
    ) : (
      <DateField
        name="periodicalEditionDate"
        label="Date d’édition"
        required={required}
        disabled={disabled}
        modifiers={{
          disabled: {
            dayOfWeek: getDisabledWeekDays(
              getWeekDays(periodicalSection, periodical),
            ),
          },
        }}
        data-test-hidden
      />
    );
  }

  return typeof children === "function"
    ? children({ children: getField() })
    : getField();
}

export const PeriodicalEditionDateFieldPeriodicalSectionFragment = gql`
  fragment PeriodicalEditionDateFieldPeriodicalSectionFragment on PeriodicalSection {
    id
    periodicity {
      weekDays
      suggestDateAfterDuration
      timeDeadline
    }
  }
`;
