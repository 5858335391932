import { forwardRef } from "react";

const SvgCaretLeft = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.043 17.744c0 .133-.044.214-.133.244-.074.029-.163.007-.266-.067L5.133 10.3A.426.426 0 0 1 5 9.989c0-.118.044-.214.133-.288l7.533-7.621c.103-.074.192-.097.266-.067.073.045.11.133.11.266v15.465z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCaretLeft);
export default ForwardRef;
