import {
  Children,
  createContext,
  forwardRef,
  useContext,
  useMemo,
} from "react";
import { useField, useFormState } from "react-final-form";
import { Button } from "swash/Button";
import { IoClose } from "swash/Icon";

import { DebouncedTextInput } from "@/components/controls/TextInput";

import { AdvancedSearch } from "./advancedSearch/AdvancedSearch";

export const SearchBoxOptions = ({ children }) => children;

const BaseSearchOptions = ({ advancedSearch }) => {
  const { clearSearchBox } = useSearchBoxOptions();
  const { values } = useFormState({
    subscription: {
      values: true,
    },
  });
  return (
    <>
      {values.search ? (
        <Button
          onClick={clearSearchBox}
          appearance="text"
          scale="xs"
          iconOnly
          aria-label="Fermer"
        >
          <IoClose />
        </Button>
      ) : null}
      {advancedSearch && <AdvancedSearch />}
    </>
  );
};
export const SearchBoxField = forwardRef(
  (
    {
      scale = "lg",
      disabled,
      delay = 400,
      children: childrenProp,
      advancedSearch,
    },
    ref,
  ) => {
    const field = useField("search", {
      parse: (v) => v || null,
    });

    const children = Children.toArray(childrenProp);

    const searchBoxOptions = children.filter(
      (child) => child.type === SearchBoxOptions,
    );

    const contextValue = useMemo(
      () => ({
        clearSearchBox: () => field.input.onChange(null),
      }),
      [field],
    );

    return (
      <div>
        <DebouncedTextInput
          ref={ref}
          delay={delay}
          type="search"
          placeholder="Rechercher..."
          scale={scale}
          disabled={disabled}
          maxLength={256}
          w={1}
          {...field.input}
        >
          <SearchBoxOptionsProvider value={contextValue}>
            <div className="mr-2 flex items-center gap-1">
              <BaseSearchOptions advancedSearch={advancedSearch} />
              {searchBoxOptions}
            </div>
          </SearchBoxOptionsProvider>
        </DebouncedTextInput>
      </div>
    );
  },
);

const SearchBoxOptionsContext = createContext();

export function SearchBoxOptionsProvider({ value, children }) {
  return (
    <SearchBoxOptionsContext.Provider value={value}>
      {children}
    </SearchBoxOptionsContext.Provider>
  );
}

export function useSearchBoxOptions() {
  return useContext(SearchBoxOptionsContext);
}
