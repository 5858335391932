import { forwardRef } from "react";

const SvgLetterTitle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1 18.2h18V20H1zm3.264-4.529V1.961H1.5V.299h7.601V1.96H6.337v11.71zm10.269.299c-2.708 0-4.333-1.961-4.333-6.313V6.35C10.2 2.185 11.862 0 14.57 0c2.466 0 3.66 1.68 3.866 4.725l-1.905.355c-.037-2.447-.821-3.436-1.96-3.436-1.159 0-2.223 1.214-2.223 4.613v1.568c0 3.269 1.064 4.501 2.26 4.501 1.307 0 1.886-1.027 1.998-3.455l1.849.3c-.224 3.025-1.475 4.799-3.922 4.799z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLetterTitle);
export default ForwardRef;
