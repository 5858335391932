import { forwardRef } from "react";

const SvgDot = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9 10c0-.267.1-.5.301-.699.2-.199.433-.3.699-.301a.948.948 0 0 1 .699.301.96.96 0 0 1 0 1.398A.948.948 0 0 1 10 11a.967.967 0 0 1-.699-.301A.952.952 0 0 1 9 10"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDot);
export default ForwardRef;
