import { PanelBody } from "swash/Panel";

import { createPanel } from "@/containers/PanelManager";
import { TrackPanel } from "@/containers/article/ArticleMetaPanel";
import { StandaloneArticlePanelHeader } from "@/containers/article/ArticlePanelHeader";

import { ForecastAnalytics } from "./Forecast";
import { SiriusAnalytics } from "./Sirius";
import { TogglerProvider, useToggleAnalyticsState } from "./TogglerProvider";

export const ArticleAnalyticsPanelBody = ({ articleId }) => {
  const toggledAnalyticsState = useToggleAnalyticsState();

  return (
    <TogglerProvider state={toggledAnalyticsState}>
      <PanelBody className="flex flex-col gap-4">
        <SiriusAnalytics articleId={articleId} />
        <ForecastAnalytics articleId={articleId} />
      </PanelBody>
    </TogglerProvider>
  );
};

const PanelContent = ({ articleId, onClose }) => {
  return (
    <>
      <StandaloneArticlePanelHeader articleId={articleId} onClose={onClose} />
      <ArticleAnalyticsPanelBody articleId={articleId} />
    </>
  );
};

export const ArticleAnalyticsPanel = createPanel({
  key: "article-analytics",
  title: "Statistiques",
  render: ({ articleId, onClose }) => {
    return (
      <>
        <TrackPanel panelKey="article-analytics" />
        <PanelContent articleId={articleId} onClose={onClose} />
      </>
    );
  },
});
