import { forwardRef } from "react";

const SvgSpellCheckLong = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M16.603 10.368c0 .595.177 1.09.532 1.485.355.39.813.585 1.373.585.32 0 .595-.063.825-.188.23-.13.452-.33.667-.6l-.465-.397c-.304.385-.642.578-1.012.578s-.67-.138-.9-.413c-.23-.274-.345-.625-.345-1.05 0-.42.115-.765.345-1.035.225-.265.525-.397.9-.397.385 0 .722.185 1.012.555L20 9.086a2.264 2.264 0 0 0-.66-.577 1.702 1.702 0 0 0-.825-.195c-.564 0-1.025.192-1.38.577-.35.39-.527.882-.532 1.477zm-3.404.878h.007v-1.74c.11-.16.268-.296.472-.406.2-.11.408-.165.623-.165.36 0 .645.13.855.39.205.265.307.613.307 1.042 0 .44-.102.796-.307 1.066-.205.265-.49.397-.855.397-.215 0-.425-.055-.63-.165a1.164 1.164 0 0 1-.457-.412v.037zm-.653 1.094h.66v-.375c.35.315.735.473 1.155.473.54 0 .97-.188 1.29-.562.325-.375.487-.878.487-1.508 0-.62-.162-1.117-.488-1.493-.32-.375-.747-.562-1.282-.562-.46 0-.845.158-1.154.473V7h-.66zM8.67 10.443c.17-.425.342-.85.517-1.275.245-.59.415-1.01.51-1.26l1.013 2.535zM7.147 12.34h.78l.247-.622c.115-.275.2-.483.255-.623h2.535l.495 1.245h.78L10.049 7h-.698zM0 9.85c0 .045.014.083.045.113l2.512 2.513c.03.03.067.044.112.044a.17.17 0 0 0 .113-.037L6.765 8.5a.153.153 0 0 0 .045-.112.155.155 0 0 0-.045-.113l-.465-.45a.142.142 0 0 0-.112-.052c-.05 0-.09.015-.12.045l-3.285 3.225a.153.153 0 0 1-.113.045.151.151 0 0 1-.112-.045L.713 9.295A.15.15 0 0 0 .6 9.25a.17.17 0 0 0-.113.038l-.443.45A.173.173 0 0 0 0 9.85z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgSpellCheckLong);
export default ForwardRef;
