import { forwardRef } from "react";

const SvgLeMondeSquare = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.984 5.918c.156.3.475.417.957.352l.899-.156c.312-.04.514-.014.605.078.092.104.137.332.137.684v4.296c0 .365-.039.658-.117.879a1.33 1.33 0 0 1-.43.586l.137.235 1.464-.899c.274-.169.463-.377.567-.625.091-.248.137-.586.137-1.016V6.114l.781-.43.586.488a.831.831 0 0 1 .235.254c.026.064.039.195.039.39V15h.117l1.055-.508c.274-.144.443-.274.509-.391.078-.104.117-.306.117-.606V6.132l.762-.488 1.758.997-.215.175c-.248.208-.43.449-.547.723-.104.273-.156.631-.156 1.074v5.059c0 .221.026.397.078.527a.973.973 0 0 0 .313.352l.625.43 1.816-1.113-.156-.332-.528.274-.176-.078a.36.36 0 0 1-.175-.196c-.027-.065-.04-.202-.04-.41V7.462c0-.378.052-.684.157-.918.117-.235.312-.443.586-.625l.606-.332-.235-.391-.274.156a2.627 2.627 0 0 1-.331.157c-.209.065-.443.013-.704-.157l-1.445-.8-1.895 1.172-1.484-1.172-1.758 1.074a1.316 1.316 0 0 0-.293-.684c-.144-.182-.358-.306-.645-.371-.286-.052-.631 0-1.035.156l-1.055.372c-.26.078-.429.078-.508 0-.078-.092-.052-.248.078-.469l.215-.352-.293-.215a4.627 4.627 0 0 1-.273.313l-.176.253a.89.89 0 0 0-.118.156c-.325.443-.416.821-.273 1.134zm-1.348 8.945.157.176c.416-.3.853-.534 1.309-.704.468-.156.898-.227 1.289-.215.352.013.644.105.879.274.234.157.39.358.468.606l2.11-1.25-.215-.371-.645.371c-.443-.482-1.029-.723-1.758-.723-.651 0-1.296.176-1.934.528a5.924 5.924 0 0 0-1.66 1.309zM0 20h20V0H0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLeMondeSquare);
export default ForwardRef;
