import { forwardRef } from "react";

const SvgPalette = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.023.583c1.737 0 2.712 1.21 3.39 2.774l.084.197L11.162 5c-.623-1.82-1.192-2.667-2.14-2.667-4.01 0-7.272 3.557-7.272 7.93 0 4.867 4.084 7.328 7.582 7.957.15.026.323.04.503.04 1.859 0 4.468-1.533 6.341-3.725 1.03-1.205 1.42-2.146 1.49-2.569a2.452 2.452 0 0 0-.437-.034c-.229 0-.491.022-.781.063-.172.024-.33.035-.484.035-.112 0-.21-.02-.309-.039l-.15-.026.801-1.269.294-.465c.215-.02.443-.049.629-.049 5.994 0-1.548 9.828-7.394 9.828a4.86 4.86 0 0 1-.812-.068C4.17 19.072 0 15.586 0 10.262 0 4.842 4.075.582 9.023.582zm2.053 7.935c.04-.005.082 0 .12.016l.052.031 2.105 1.605a.237.237 0 0 1 .1.2c-.132 1.755-.755 3.08-1.809 3.87a4.58 4.58 0 0 1-2.142.825 8.947 8.947 0 0 1-3.068-.16.236.236 0 0 1 .055-.46c.187-.027 1.424-1.033 1.907-2.837.482-1.805 1.42-2.913 2.68-3.09zm-6.45.378a1.587 1.587 0 1 1-.002 3.174 1.587 1.587 0 0 1 .001-3.174zM18.5 0l1.49 1.121-5.375 8.508-2.952-2.224zM7.401 4.668a1.587 1.587 0 1 1 0 3.173 1.587 1.587 0 0 1 0-3.173z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPalette);
export default ForwardRef;
