import moment from "moment";
import {
  EmptyState,
  EmptyStatePicture,
  EmptyStateText,
  EmptyStateTitle,
} from "swash/EmptyState";
import { IoCheckmark } from "swash/Icon";
import { PageLoader } from "swash/Loader";

import speechBalloon from "@/assets/imgs/emojis/speech_balloon.png";
import { Time } from "@/components/Time";
import { ArticleComment } from "@/containers/article/panels/comments/ArticleComment";
import { useCommentScope } from "@/containers/article/panels/comments/CommentScopeProvider";

const wordings = {
  notes: {
    label: "Consignes",
    emptyTitle: "Aucune consigne pour cet article.",
    emptyText: "Les consignes de cet article apparaîtront ici",
    resolved: {
      label: "Consignes résolues",
      emptyTitle: "Aucune consigne résolue pour l’instant dans cet article.",
      emptyText: "Les consignes résolues de cet article apparaîtront ici",
    },
  },
  text: {
    label: "Commentaires",
    emptyTitle: "Aucun commentaire ouvert pour l’instant dans cet article.",
    emptyText: "Les commentaires ouverts de cet article apparaîtront ici",
    resolved: {
      label: "Commentaires résolus",
      emptyTitle: "Aucun commentaire résolu pour l’instant dans cet article.",
      emptyText: "Les commentaires résolus de cet article apparaîtront ici",
    },
  },
};

export const ArticleCommentList = ({
  comments,
  setDeletingComment,
  article,
  queryOptions,
  resolved = false,
}) => {
  const scope = useCommentScope();
  const { label, emptyTitle, emptyText } = resolved
    ? wordings[scope].resolved
    : wordings[scope];
  if (!comments) return <PageLoader />;
  if (!comments.length) {
    return (
      <EmptyState className="px-12">
        <EmptyStatePicture src={speechBalloon} />
        <EmptyStateTitle>{emptyTitle}</EmptyStateTitle>
        <EmptyStateText>{emptyText}</EmptyStateText>
      </EmptyState>
    );
  }
  return (
    <div
      role="list"
      aria-label={label}
      className="flex h-full scroll-py-2 flex-col gap-4 overflow-y-auto px-4 pb-4"
    >
      {comments.map((comment) => (
        <div
          key={comment.globalId}
          role="listitem"
          className="flex flex-col items-start gap-2"
        >
          {resolved && (
            <span className="max-w-full truncate text-grey-on">
              <IoCheckmark className="inline-block" />
              {` Résolu par ${comment.resolvedBy.firstNameInitials} ${comment.resolvedBy.lastName} le `}
              <Time date={comment.resolvedAt}>
                {moment(comment.resolvedAt).format("DD/MM/YYYY à HH:mm")}
              </Time>
            </span>
          )}

          <ArticleComment
            article={article}
            comment={comment}
            setDeletingComment={setDeletingComment}
            queryOptions={queryOptions}
          />
        </div>
      ))}
    </div>
  );
};
