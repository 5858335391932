import { forwardRef } from "react";

const SvgVideo = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.5 14.148c0 .065.02.111.059.137.026.013.065.006.117-.02l6.445-3.789a.207.207 0 0 0 .059-.156.185.185 0 0 0-.059-.137L7.676 6.394c-.039-.039-.078-.052-.117-.039s-.059.052-.059.117v7.675zM1.66 17h16.659V3.661H1.68zM0 17.937V2.723c0-.208.072-.384.215-.527A.73.73 0 0 1 .724 2h18.553c.196 0 .365.065.508.196a.714.714 0 0 1 .215.527v15.195a.714.714 0 0 1-.215.527.683.683 0 0 1-.508.196H.744a.752.752 0 0 1-.528-.196.694.694 0 0 1-.215-.508z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVideo);
export default ForwardRef;
