import { forwardRef } from "react";

const SvgLetterSocial = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1 18.2h18V20H1zM3.517 6.33h1.158c1.438 0 2.091-.709 2.091-2.278 0-1.624-.579-2.166-1.904-2.166H3.517zm0 7.34H1.5V.3h3.679c2.633 0 3.716 1.214 3.716 3.585 0 1.83-.859 2.988-2.297 3.455v.187c.729.43 1.102 1.307 1.4 2.988.356 1.868.58 2.428.972 2.876v.28H6.729c-.243-.41-.43-1.102-.56-1.923-.168-.878-.355-1.924-.523-2.69C5.478 8.18 5.31 7.9 4.619 7.9H3.517zm11.112.299c-2.316 0-3.735-1.4-4.109-4.183l1.7-.43c.224 2.26 1.232 3.082 2.446 3.082 1.176 0 1.867-.822 1.867-1.98 0-1.27-.635-1.737-2.371-2.727-2.13-1.195-3.156-2.315-3.156-4.313 0-1.924 1.4-3.418 3.623-3.418 2.54 0 3.473 1.457 3.772 3.716l-1.68.449c-.281-1.663-.748-2.652-2.092-2.652-1.084.018-1.681.765-1.681 1.68 0 1.12.523 1.625 2.054 2.54 2.222 1.307 3.51 2.036 3.51 4.351 0 2.185-1.288 3.885-3.883 3.885z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLetterSocial);
export default ForwardRef;
