import { gql } from "@apollo/client";
import { memo } from "react";

import { BagDoubleOutline } from "@/components/icons";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

const ProductSummaryNodeIcon = memo(() => {
  return (
    <EditorNodeIcon>
      <BagDoubleOutline color="dusk" />
    </EditorNodeIcon>
  );
});

export const ProductSummaryNode = ({ productSummary, tooltip }) => (
  <EditorNodeCard>
    <EditorNodeLayout>
      <EditorNodeTooltip tooltip={tooltip}>
        <ProductSummaryNodeIcon />
      </EditorNodeTooltip>
      <EditorNodeBody>
        <ChangeText title={productSummary.name}>
          {productSummary.name}
        </ChangeText>
      </EditorNodeBody>
    </EditorNodeLayout>
  </EditorNodeCard>
);

ProductSummaryNode.fragments = {
  productSummary: gql`
    fragment ProductSummaryNode_productSummary on ProductSummary {
      name
    }
  `,
};
