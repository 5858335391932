import { forwardRef } from "react";

const SvgVerticalAlign = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M15.75 19.589V.43c0-.117.046-.222.137-.313A.398.398 0 0 1 16.18 0h1.66a.399.399 0 0 1 .411.41v19.16c0 .117-.04.221-.118.312a.376.376 0 0 1-.274.118h-1.68a.4.4 0 0 1-.293-.118.476.476 0 0 1-.137-.293zm-4.57 0V.43c0-.117.039-.222.118-.313A.398.398 0 0 1 11.591 0h1.66c.117 0 .215.039.293.117a.371.371 0 0 1 .137.293v19.16a.462.462 0 0 1-.118.312.445.445 0 0 1-.293.118h-1.66a.467.467 0 0 1-.312-.118.395.395 0 0 1-.118-.293zm-4.59 0V.43c0-.117.039-.222.118-.313A.398.398 0 0 1 7.001 0h1.68c.104 0 .202.039.293.117a.398.398 0 0 1 .117.293v19.16a.466.466 0 0 1-.117.312.399.399 0 0 1-.293.118h-1.66a.399.399 0 0 1-.293-.118.471.471 0 0 1-.137-.293zm-4.59 0V.43c0-.117.046-.222.137-.313A.4.4 0 0 1 2.43 0h1.66c.117 0 .215.039.293.117a.395.395 0 0 1 .118.293v19.16a.462.462 0 0 1-.118.312.375.375 0 0 1-.273.118H2.43a.399.399 0 0 1-.293-.118.471.471 0 0 1-.137-.293z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgVerticalAlign);
export default ForwardRef;
