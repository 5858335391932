import styled, { x } from "@xstyled/styled-components";
import { forwardRef, memo } from "react";
import {
  IoAlbums,
  IoCloseCircle,
  IoCodeSlash,
  IoDownloadOutline,
  IoImages,
  IoLogoTwitter,
  IoVideocam,
  ZipFile,
} from "swash/Icon";
import { Loader } from "swash/Loader";

const commonStyles = `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const InnerDropTargetBorder = styled.svg`
  ${commonStyles}
  pointer-events: none;
  border-radius: base;
`;

export const InnerDropTarget = styled.box`
  position: relative;
  background-color: primary-lightest;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  font-family: accent;
  font-size: base;
  border-radius: base;
  transition: base;
  text-align: center;
  color: blue-darker;

  ${InnerDropTargetBorder} {
    stroke: blue;
  }

  &[data-scale="xs"] {
    min-height: 48;
    padding: 3;
  }

  &[data-scale="sm"] {
    min-height: 72;
    padding: 3;
  }

  &[data-scale="md"] {
    min-height: 80;
    padding: 28;
  }

  &[data-scale="lg"] {
    min-height: 140;
  }

  &[data-expandable][data-dropping] {
    &[data-scale="xs"],
    &[data-scale="sm"] {
      min-height: 140;
    }
    &[data-scale="md"] {
      min-height: 312;
    }
  }

  &[data-filled] {
    display: block;
    height: auto;
    text-align: left;
    padding: 3;
    background-color: grey-lightest;
    color: dusk;
    cursor: default;

    ${InnerDropTargetBorder} {
      stroke: grey;
    }
  }

  &[data-invalid] {
    background-color: red-lightest;
    color: red-darker;

    ${InnerDropTargetBorder} {
      stroke: red-dark;
    }
  }

  &[data-accept] {
    background-color: green-lightest;
    color: green-darkest;
    stroke: green;

    ${InnerDropTargetBorder} {
      stroke: green;
    }
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.38;
    cursor: default;
  }

  [data-display-mode="preview"] & {
    background-color: grey-lighter;
  }
`;

export const DropTarget = forwardRef(
  (
    {
      accept,
      invalid,
      filled,
      expandable = true,
      dropping,
      scale = "xs",
      disabled,
      children,
      icon,
      placeholder,
      mediaTypes = null,
      loading,
      ...props
    },
    ref,
  ) => {
    const { icon: Icon, text } = getDropTargetTextAndIcon({
      accept,
      invalid,
      mediaTypes,
      placeholder,
    });

    return (
      <x.div
        position="relative"
        data-field-control={props["data-field-control"]}
      >
        {loading && <DropTargetLoader />}
        <InnerDropTarget
          ref={ref}
          {...props}
          data-testid="dnd-zone"
          data-accept={accept ? "" : undefined}
          data-invalid={invalid ? "" : undefined}
          data-expandable={expandable ? "" : undefined}
          data-dropping={dropping ? "" : undefined}
          data-scale={scale}
          data-filled={filled ? "" : undefined}
          data-disabled={disabled || loading ? "" : undefined}
        >
          <DropTargetBorder />
          {children ?? <DropTargetPlaceholder icon={Icon} text={text} />}
        </InnerDropTarget>
      </x.div>
    );
  },
);

const DropTargetPlaceholder = memo(({ icon: Icon, text }) => {
  return (
    <x.div display="flex" alignItems="center" gap="3">
      {Icon && <Icon />}
      <span>{text}</span>
    </x.div>
  );
});

export const DropTargetBorder = memo(() => {
  return (
    <InnerDropTargetBorder xmlns="http://www.w3.org/2000/svg">
      <rect
        width="100%"
        height="100%"
        fill="none"
        strokeWidth="3"
        strokeDasharray="4, 7" // space between dashes
        strokeDashoffset="0"
        strokeLinecap="square"
        rx="6"
      />
    </InnerDropTargetBorder>
  );
});

const InnerDropTargetLoader = styled.div`
  ${commonStyles}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropTargetLoader = memo(() => {
  return (
    <InnerDropTargetLoader>
      <Loader size={24} />
    </InnerDropTargetLoader>
  );
});

const getDropTargetTextAndIcon = ({
  accept,
  invalid,
  mediaTypes,
  placeholder = "Glisser...",
}) => {
  if (accept) {
    return {
      text: "Déposer...",
      icon: IoDownloadOutline,
    };
  }
  if (invalid) {
    return {
      text: "Média non supporté",
      icon: IoCloseCircle,
    };
  }

  switch (true) {
    case mediaTypes?.length > 1:
      return {
        text: `Glisser ici ${generateMediaTypesPlaceholder(mediaTypes)}...`,
        icon: IoImages,
      };
    case mediaTypes?.includes("archives"):
      return {
        text: "Glisser ici les fichiers .zip depuis le volet d’enrichissements...",
        icon: ZipFile,
      };
    case mediaTypes?.includes("images"):
      return {
        text: "Glisser ici les images du portfolio...",
        icon: IoAlbums,
      };
    case mediaTypes?.includes("snippets"):
      return {
        text: "Glisser ici un snippet pour l’épingler...",
        icon: IoCodeSlash,
      };
    case mediaTypes?.includes("tweets"):
      return {
        text: "Glisser ici un tweet...",
        icon: IoLogoTwitter,
      };
    case mediaTypes?.includes("videos"):
      return {
        text: "Glisser ici une vidéo...",
        icon: IoVideocam,
      };
    default:
      return { text: placeholder, icon: IoDownloadOutline };
  }
};

const placeholderMap = {
  images: "une image",
  products: "une fiche produit",
  products_summaries: "un récapitulatif produit",
  snippets: "un snippet",
  videos: "une vidéo",
};

const generateMediaTypesPlaceholder = (mediaTypes) =>
  mediaTypes
    .sort()
    .map((mediaType) => placeholderMap[mediaType])
    .reduce((acc, value, index) => {
      if (index !== mediaTypes.length - 1) {
        acc.push(value);
      } else {
        acc.push(`ou ${value}`);
      }

      return acc;
    }, [])
    .join(", ");
