import * as Sentry from "@sentry/browser";
import { EditorState, Modifier } from "draft-js-es";
import * as React from "react";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { flushSync } from "react-dom";
import { IoEllipsisVertical, IoTrashOutline } from "swash/Icon";
import { Menu, MenuButton, MenuItem, useMenuStore } from "swash/Menu";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useEventListener } from "swash/utils/useEventListener";
import { useLiveRef } from "swash/utils/useLiveRef";

import {
  EditorBlockCapsule,
  EditorBlockCapsuleBody,
  EditorBlockCapsuleHighlight,
  EditorBlockCapsuleSidebar,
  EditorBlockCapsuleToolbar,
  EditorBlockCapsuleToolbarButton,
  EditorBlockCapsuleToolbarProvider,
} from "@/components/teleporters/EditorBlockCapsule";

import {
  useBlockTemplates,
  useIsBlockSelected,
  useReadOnlyContext,
} from "../RichEditorContext";
import { addBlock } from "../modifiers/addBlock";
import { moveBlock } from "../modifiers/moveBlock";
import { removeBlock } from "../modifiers/removeBlock";
import { replaceBlock } from "../modifiers/replaceBlock";
import { selectBlock } from "../modifiers/selectBlock";
import { hasCommandModifier } from "../utils";
import { getBlockIndex } from "../utils/Block";
import { getSelectionByTripleClick } from "../utils/Selection";

export * from "./core-convert";

const SPLIT_BLOCK = "create-node";
const TRANSFORM_URL = "transform-url";
const SIRIUS_DRAFT_BLOCK_TYPE = "application/sirius.draft-block";
const INSERT_SOFT_NEW_LINE = "insert-soft-new-line";

export const name = "core";

function getPosition(containerRef, dragItem, dropBlock, dropIndex, monitor) {
  if (!containerRef.current) return null;
  if (dragItem.key === dropBlock.key) return null;

  const hoverBoundingRect = containerRef.current.getBoundingClientRect();
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  const clientOffset = monitor.getClientOffset();
  if (!clientOffset) return null;
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  const previous = dragItem.index === dropIndex + 1;
  const next = dragItem.index === dropIndex - 1;
  const position = hoverMiddleY < hoverClientY ? "bottom" : "top";
  if (position === "bottom" && previous) return null;
  if (position === "top" && next) return null;
  return position;
}

/**
 * @param {import('draft-js').ContentBlock} block
 */
function getBlockUrl(block) {
  const text = block.getText();
  try {
    if (!text.startsWith("http")) {
      return null;
    }
    const url = new URL(text);
    return url.href;
  } catch (error) {
    return null;
  }
}

/**
 * @param {import('../RichEditorState').RichEditorState} state
 * @param {import('react').KeyboardEvent<{}>} event
 */
export const keyBindingFn = (state, event) => {
  if (event.key === "Enter") {
    if (hasCommandModifier(event) || event.shiftKey) {
      const { acceptSoftNewLine } = state;
      return acceptSoftNewLine ? INSERT_SOFT_NEW_LINE : undefined;
    }
    const url = getBlockUrl(state.anchorBlock);
    return url ? TRANSFORM_URL : SPLIT_BLOCK;
  }
  return undefined;
};

/**
 * @param {import('draft-js').EditorState} editorState
 */
function splitTextBlock(editorState) {
  let contentState = editorState.getCurrentContent();
  const initialBlockKey = editorState.getSelection().getFocusKey();
  const initialBlock = contentState.getBlockForKey(initialBlockKey);

  contentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection(),
  );

  editorState = EditorState.push(editorState, contentState, "split-block");

  if (initialBlock.getType() === "unstyled") {
    // If the block has been reset (type ="unstyled") by the Modifier.splitBlock then we remove the block's data
    contentState = Modifier.setBlockData(
      contentState,
      editorState.getSelection(),
      {},
    );
    return EditorState.push(editorState, contentState, "change-block-data");
  }
  contentState = Modifier.mergeBlockData(
    contentState,
    editorState.getSelection(),
    initialBlock.getData().toObject(),
  );

  return EditorState.push(editorState, contentState, "change-block-data");
}

function getPluginForUrls(state, urls) {
  for (const plugin of state.plugins) {
    if (plugin.handleUrls && plugin.createBlockFromUrls) {
      const result = plugin.handleUrls(
        { ...state, options: plugin.options },
        urls,
      );
      if (result === "handled") return plugin;
    }
  }
  return null;
}

/**
 * @param {import('../RichEditorState').RichEditorState} state
 */
function handleSplitBlockKeyCommand(state) {
  const { editorState, setEditorState, anchorBlock } = state;
  // If block is an atomic one, then insert a new text node
  if (anchorBlock?.getType() === "atomic") {
    const blocks = editorState.getCurrentContent().getBlocksAsArray();
    const anchorBlockIndex = blocks.indexOf(anchorBlock);
    addBlock(
      { editorState, setEditorState },
      { type: "paragraph" },
      anchorBlockIndex + 1,
    );
    return "handled";
  }

  setEditorState(splitTextBlock(editorState));
  return "handled";
}

/**
 * @param {import('../RichEditorState').RichEditorState} state
 */
function handleTransformUrlKeyCommand(state) {
  const { editorState, setEditorState, anchorBlock } = state;

  const url = getBlockUrl(state.anchorBlock);
  if (!url) return handleSplitBlockKeyCommand(state);

  const urls = [url];

  const plugin = getPluginForUrls(state, urls);
  if (!plugin) return handleSplitBlockKeyCommand(state);

  const pendingBlock = getPendingBlock();
  const replaceBlockByUrl = () =>
    replaceBlock(
      { editorState, setEditorState },
      pendingBlock.key,
      {
        type: "paragraph",
        text: url,
      },
      {
        select: false,
      },
    );

  replaceBlock({ editorState, setEditorState }, anchorBlock.key, pendingBlock, {
    select: false,
    silence: true,
  });
  const anchorBlockIndex = editorState
    .getCurrentContent()
    .getBlockMap()
    .keySeq()
    .findIndex((key) => key === anchorBlock.key);
  plugin
    .createBlockFromUrls({ ...state.getState(), options: plugin.options }, urls)
    .then(
      (block) => {
        if (!block) {
          replaceBlockByUrl();
          return;
        }
        const nextEditorState = replaceBlock(
          { editorState, setEditorState },
          anchorBlock.key,
          block,
          {
            select: false,
          },
        );

        addBlock(
          { editorState: nextEditorState, setEditorState },
          { type: "paragraph" },
          anchorBlockIndex + 1,
        );
      },
      (error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        Sentry.captureException(error);
        replaceBlockByUrl();
      },
    );

  return "handled";
}

/**
 * @param {import('../RichEditorState').RichEditorState} state
 * @param {string} command
 */
export const handleKeyCommand = (state, command) => {
  switch (command) {
    case SPLIT_BLOCK: {
      return handleSplitBlockKeyCommand(state);
    }
    case TRANSFORM_URL: {
      return handleTransformUrlKeyCommand(state);
    }
    default:
      return false;
  }
};

const accept = [SIRIUS_DRAFT_BLOCK_TYPE, NativeTypes.URL, NativeTypes.HTML];

function getPendingBlock() {
  return {
    type: "atomic",
    key: `pending-${Math.random().toString(36).slice(2, 9)}`,
    data: { active: true },
    entity: { type: "PENDING", mutability: "IMMUTABLE" },
  };
}

function getBlockStyle(block, blockTemplates) {
  const blockStyleName = block.data.get("styleName");
  if (!blockStyleName) return [null, null];
  return [
    blockTemplates.find((tpl) => tpl.name === blockStyleName)?.css ?? null,
    blockStyleName,
  ];
}

const BlockToolbarOptions = memo(({ onRemove }) => {
  const menu = useMenuStore();
  const title = "Plus d’actions";
  return (
    <>
      <MenuButton store={menu} asChild>
        <EditorBlockCapsuleToolbarButton
          title={title}
          icon={IoEllipsisVertical}
        />
      </MenuButton>
      <Menu store={menu} aria-label={title} portal>
        <MenuItem onMouseDown={onRemove}>
          <IoTrashOutline />
          Supprimer
        </MenuItem>
      </Menu>
    </>
  );
});

const BlockToolbar = memo(({ onRemove }) => {
  return (
    <EditorBlockCapsuleToolbar>
      <BlockToolbarOptions onRemove={onRemove} />
    </EditorBlockCapsuleToolbar>
  );
});

/**
 * @param {import('../RichEditorState').RichEditorState} state
 */
export const wrapBlockComponent =
  ({ BlockComponent }) =>
  (props) => {
    const {
      blockProps: {
        state: {
          editorState,
          setEditorState,
          checkBlockIsEditing,
          isRichEditorTextBox,
        },
      },
      block,
    } = props;

    const blockTemplates = useBlockTemplates();
    const readOnly = useReadOnlyContext();
    const stateRef = useLiveRef(props.blockProps.state);
    const selected = useIsBlockSelected(block.key);
    const [blockStyle, blockStyleName] = useMemo(
      () => getBlockStyle(block, blockTemplates),
      [block, blockTemplates],
    );

    const attributes = useMemo(() => {
      const attributes = {};
      if (blockStyleName) attributes["data-style-name"] = blockStyleName;
      if (block.type !== "unstyled") attributes["data-block-type"] = block.type;
      return attributes;
    }, [block.type, blockStyleName]);

    const containerRef = useRef();
    const atomic = block.getType() === "atomic";

    const requestAnimationFrameIdRef = React.useRef(null);

    React.useEffect(
      () => () => {
        if (requestAnimationFrameIdRef.current) {
          cancelAnimationFrame(requestAnimationFrameIdRef.current);
        }
      },
      [],
    );

    const [position, setPosition] = useState(null);
    const [{ dropping }, dropRef] = useDrop({
      accept,
      canDrop(item, monitor) {
        if (readOnly) return false;
        const type = monitor.getItemType();
        return accept.includes(type);
      },
      hover(item, monitor) {
        const index = getBlockIndex(editorState, block.key);
        const position = getPosition(containerRef, item, block, index, monitor);
        setPosition(position);
      },
      drop(item, monitor) {
        const index = getBlockIndex(editorState, block.key);

        const targetIndex = (() => {
          switch (position) {
            case "top":
              return item.index == null || item.index > index
                ? index
                : index - 1;
            case "bottom":
              return item.index != null && item.index < index
                ? index
                : index + 1;
            default:
              return null;
          }
        })();
        if (targetIndex === null) return;

        if (monitor.getItemType() === SIRIUS_DRAFT_BLOCK_TYPE) {
          const contentState = editorState.getCurrentContent();
          const sourceBlock = contentState.getBlockForKey(item.key);
          if (!sourceBlock) return;
          requestAnimationFrameIdRef.current = requestAnimationFrame(() => {
            flushSync(() => {
              setEditorState((editorState) =>
                moveBlock(editorState, item.key, targetIndex),
              );
            });
          });
          return;
        }

        const urls =
          item.urls || item.dataTransfer.getData("text/uri-list")?.split("\n");
        if (!urls?.length) return;

        const { state } = props.blockProps;

        const plugin = getPluginForUrls(state, urls);
        if (!plugin) return;

        const { entity: pendingEntity, ...pendingBlock } = getPendingBlock();

        const removePendingBlock = () => {
          removeBlock(stateRef.current, pendingBlock.key, null, true);
        };

        // We are now pending, waiting for the block to be created
        addBlock(stateRef.current, pendingBlock, targetIndex, pendingEntity, {
          silence: true,
        });
        plugin
          .createBlockFromUrls(
            { ...state.getState(), options: plugin.options },
            urls,
          )
          .then(
            (block) => {
              if (!block) {
                removePendingBlock();
                return;
              }
              replaceBlock(stateRef.current, pendingBlock.key, block, {
                select: false,
              });
            },
            (error) => {
              // eslint-disable-next-line no-console
              console.error(error);
              Sentry.captureException(error);
              removePendingBlock();
            },
          );
      },
      collect: (monitor) => ({
        dropping: monitor.canDrop() && monitor.isOver(),
      }),
    });
    const [, dragRef, previewRef] = useDrag({
      type: SIRIUS_DRAFT_BLOCK_TYPE,
      item: () => ({
        key: block.key,
        index: getBlockIndex(editorState, block.key),
      }),
    });
    dropRef(previewRef(containerRef));

    const handleClick = useCallback(() => {
      const { readOnly, setEditorState } = stateRef.current;
      if (readOnly || !atomic) return;
      setEditorState((editorState) => selectBlock(editorState, block.key));
    }, [stateRef, block, atomic]);

    const handleMouseDown = useCallback(
      (e) => {
        const { readOnly, setEditorState } = stateRef.current;
        if (e.detail <= 2 || readOnly || atomic) return;
        e.preventDefault();
        setEditorState((editorState) =>
          getSelectionByTripleClick(block, editorState),
        );
      },
      [stateRef, block, atomic],
    );

    const handleRemove = useEventCallback((event) => {
      event.preventDefault();
      removeBlock(stateRef.current, block.key);
    });

    const editing = checkBlockIsEditing(block.key);
    const setEditing = useCallback(
      (editing) => {
        const { setBlockEditing } = stateRef.current;
        setBlockEditing(block.key, editing);
      },
      [stateRef, block.key],
    );

    const [hovered, setHovered] = useState(false);
    const hasBeenHoveredRef = useRef(hovered);
    const handleMouseMove = useCallback(() => {
      setHovered(true);
      hasBeenHoveredRef.current = true;
    }, []);
    const handleMouseOut = useCallback(() => {
      setHovered(false);
    }, []);
    useEventListener(document, "keyup", (e) => {
      if (e.target.classList.contains("public-DraftEditor-content")) {
        setHovered(false);
      }
    });

    const change = useMemo(() => {
      const deletedStyle = block
        .getCharacterList()
        .some((c) => c.getStyle().has("DELETED"));
      const addedStyle = block
        .getCharacterList()
        .some((c) => c.getStyle().has("ADDED"));

      return deletedStyle ? "deleted" : addedStyle ? "added" : undefined;
    }, [block]);

    return !isRichEditorTextBox ? (
      <EditorBlockCapsuleToolbarProvider disabled={readOnly}>
        <EditorBlockCapsule
          ref={containerRef}
          onClick={handleClick}
          hovered={hovered}
          highlighted={atomic && selected}
          overPosition={(dropping && position) || null}
          readOnly={readOnly}
          onMouseMove={handleMouseMove}
          onMouseOut={handleMouseOut}
          onMouseDown={handleMouseDown}
          change={change}
        >
          <EditorBlockCapsuleSidebar ref={dragRef} />
          {(hovered || hasBeenHoveredRef.current) && (
            <BlockToolbar onRemove={handleRemove} />
          )}
          <EditorBlockCapsuleBody style={blockStyle} attributes={attributes}>
            {atomic && <EditorBlockCapsuleHighlight />}
            <BlockComponent
              {...props}
              editing={editing}
              setEditing={setEditing}
            />
          </EditorBlockCapsuleBody>
        </EditorBlockCapsule>
      </EditorBlockCapsuleToolbarProvider>
    ) : (
      <BlockComponent {...props} editing={editing} setEditing={setEditing} />
    );
  };
