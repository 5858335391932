import { forwardRef } from "react";

const SvgInfoCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M9.176 6.322a.39.39 0 0 0 .402.403h1.648a.39.39 0 0 0 .402-.403V4.694a.39.39 0 0 0-.402-.403H9.598a.394.394 0 0 0-.403.384zm-1.628 8.984a.39.39 0 0 0 .402.403h4.905a.43.43 0 0 0 .287-.096.387.387 0 0 0 .115-.287v-1.648a.436.436 0 0 0-.095-.287.39.39 0 0 0-.288-.115h-1.226V7.97a.364.364 0 0 0-.135-.288.36.36 0 0 0-.287-.134H7.969a.454.454 0 0 0-.306.115.431.431 0 0 0-.115.287v1.628c0 .115.038.217.115.307A.392.392 0 0 0 7.95 10h1.227v3.256H7.97a.419.419 0 0 0-.306.135.387.387 0 0 0-.116.287zM0 10c0-1.826.447-3.512 1.341-5.057.447-.728.977-1.405 1.59-2.031A9.753 9.753 0 0 1 4.942 1.36C6.488.453 8.173 0 10 0c1.826 0 3.512.447 5.057 1.341A9.96 9.96 0 0 1 18.678 5 9.861 9.861 0 0 1 20 10c0 1.328-.256 2.612-.767 3.851a9.806 9.806 0 0 1-2.145 3.237 9.96 9.96 0 0 1-3.238 2.146A9.999 9.999 0 0 1 10 20c-1.827 0-3.506-.447-5.038-1.341a9.951 9.951 0 0 1-3.64-3.64C.44 13.486 0 11.813 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgInfoCircle);
export default ForwardRef;
