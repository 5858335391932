import { createContext, useContext, useState } from "react";

import { RemoteEditImageMultipleDialogProvider } from "./ImageEditMultipleDialog";

const ImageEditMultipleContext = createContext(null);

export const ImageEditMultipleProvider = ({ children }) => {
  const [imageIds, setImageIds] = useState([]);

  return (
    <ImageEditMultipleContext.Provider value={{ imageIds, setImageIds }}>
      <RemoteEditImageMultipleDialogProvider>
        {children}
      </RemoteEditImageMultipleDialogProvider>
    </ImageEditMultipleContext.Provider>
  );
};

export const useImageEditMultiple = () => {
  return useContext(ImageEditMultipleContext);
};
