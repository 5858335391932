import { forwardRef } from "react";

const SvgFontCase = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.214 11.861c0-.417.156-.762.469-1.037.303-.275.7-.412 1.193-.412.35 0 .691.066 1.023.199.331.142.605.331.823.568v1.364c-.426.521-1.042.781-1.847.781-.473 0-.871-.137-1.193-.412a1.366 1.366 0 0 1-.469-1.051zm-.981 0c0 .682.242 1.236.725 1.662a2.413 2.413 0 0 0 1.69.653c.786 0 1.478-.246 2.074-.739v.569h.98V9.303c0-.729-.222-1.288-.667-1.676-.455-.379-1.066-.568-1.833-.568-.568 0-1.07.095-1.505.284-.427.19-.824.493-1.194.91l.554.753c.322-.369.644-.639.966-.81a2.27 2.27 0 0 1 1.079-.255c.474 0 .862.118 1.165.355.303.246.455.578.455.994v1.037c-.531-.492-1.222-.739-2.074-.739-.682 0-1.255.204-1.719.611-.455.407-.687.961-.696 1.662zM5.6 10.725l.895-2.188c.388-.947.677-1.657.866-2.131l1.719 4.319zM3.27 3.864l.54.184c.909-1.24 1.846-2.107 2.812-2.6.966-.492 2.197-.729 3.694-.71 1.032.019 1.96.204 2.784.554.815.351 1.61.89 2.387 1.62l.724.753-.781.682h2.244V2.174l-.753.738-.937-.795c-.957-.796-1.875-1.35-2.756-1.662C12.356.152 11.343 0 10.188 0c-1.042.057-1.909.18-2.6.37a6.91 6.91 0 0 0-2.429 1.222c-.701.549-1.331 1.306-1.889 2.272zm-.071 13.949.739-.739.952.81c.956.786 1.875 1.335 2.756 1.647.871.313 1.884.469 3.04.469 1.051-.066 1.922-.194 2.614-.384a6.604 6.604 0 0 0 2.386-1.207c.701-.549 1.34-1.312 1.918-2.287l-.54-.184c-.616.833-1.236 1.491-1.861 1.974a5.808 5.808 0 0 1-2.046 1.037c-.739.218-1.605.322-2.599.312-1.014-.018-1.942-.208-2.785-.568s-1.638-.9-2.386-1.619l-.725-.753.782-.682H3.199v2.173zM3 13.949h1.335l.199-.511.441-1.079.213-.526h4.347l.809 2.116h1.336L7.957 4.844H6.779z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgFontCase);
export default ForwardRef;
