/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { gql, useMutation } from "@apollo/client";
import clsx from "clsx";
import { forwardRef, memo, useEffect, useState } from "react";
import { Clickable, ClickableProps } from "swash/Clickable";
import {
  Popover,
  PopoverDisclosure,
  PopoverState,
  usePopoverState,
} from "swash/Popover";
import { useToaster } from "swash/Toast";
import { Tooltip } from "swash/Tooltip";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";

import { useArticleAuditTrailTooltip } from "@/components/ArticleAuditTrail";
import {
  ArticlePublishDate,
  ArticlePublishDateProps,
} from "@/containers/article/ArticlePublishDate";
import {
  AuthorDisplay,
  DateDisplay,
  Event,
} from "@/containers/article/ArticlePublishStatus";
import {
  ArticlePublishTime,
  ArticlePublishTimeProps,
} from "@/containers/article/ArticlePublishTime";
import { useListReadOnly } from "@/containers/list/List";

import { ArticleDateForm, ArticleDateFormFields } from "./ArticleDateForm";

type DateFieldsEditPopoverProps = {
  state: PopoverState;
  title: string;
};

const DateFieldsEditPopover = ({
  state,
  title,
}: DateFieldsEditPopoverProps) => {
  return (
    <Popover
      state={state}
      className="overflow-hidden"
      modal
      lazy={false}
      aria-label={title}
    >
      {state.open ? <ArticleDateFormFields /> : null}
    </Popover>
  );
};

type ArticleDateFormValues = {
  planning: {
    date: string | null;
    range: string | null;
  };
  isUrgent: boolean;
  isEmbargo: boolean;
};

type DateEditPopoverProps = {
  state: PopoverState;
  article: ActiveDateButtonProps["article"];
  onChange: (values: ArticleDateFormValues) => void;
  title: string;
};

const DateEditPopover = ({
  article,
  onChange,
  state,
  title,
}: DateEditPopoverProps) => {
  return (
    <Popover
      state={state}
      className="overflow-hidden"
      modal
      lazy={false}
      aria-label={title}
    >
      {state.open ? (
        <ArticleDateForm article={article} onChange={onChange} />
      ) : null}
    </Popover>
  );
};

type PublishDateTimeProps = ActiveDateButtonProps & {
  className?: string;
};

const PublishDateTime = ({ article, className }: PublishDateTimeProps) => {
  return (
    <div
      className={clsx(
        className,
        "block select-none text-start font-accent text-sm",
      )}
    >
      <div>
        <ArticlePublishDate article={article} />
      </div>
      <div>
        <ArticlePublishTime article={article} />
      </div>
    </div>
  );
};

type DateButtonProps = ActiveDateButtonProps & ClickableProps;

const DateButton = forwardRef<HTMLDivElement, DateButtonProps>(
  ({ className, article, ...props }, ref) => {
    return (
      <Clickable
        ref={ref}
        className={clsx(className, "flex h-full flex-wrap gap-2 p-1 text-sm")}
        {...props}
      >
        <PublishDateTime article={article} />
      </Clickable>
    );
  },
);

const Mutation = gql`
  mutation UpdateArticle($input: UpdateArticleInput!) {
    updateArticle(input: $input) {
      id
      ...ArticleDateForm_article
    }
  }
  ${ArticleDateForm.fragments.article}
`;

type ActiveDateButtonProps = {
  article: { id: number };
} & ArticlePublishDateProps &
  ArticlePublishTimeProps;

const ActiveDateButton = ({ article }: ActiveDateButtonProps) => {
  const title = "Éditer la date de publication souhaitée";
  const tooltip = useArticleAuditTrailTooltip("publicationDate");
  const [values, setValues] = useState<ArticleDateFormValues>();

  const toaster = useToaster();
  const [updateArticle] = useMutation(Mutation, {
    onError: () => {
      toaster.danger(
        "La date de publication de l’article n’a pas été mise à jour",
      );
    },
  });
  const popover = usePopoverState({ placement: "right-start" });
  const previousOpen = usePrevious(popover.open);
  const refs = useLiveRef({ article, values, updateArticle });

  useEffect(() => {
    const beenClosed = previousOpen && !popover.open;
    if (beenClosed) {
      const { article, values, updateArticle } = refs.current;

      updateArticle({
        variables: {
          input: {
            id: article.id,
            ...values,
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            __typename: "Article",
            id: article.id,
            ...values,
          },
        },
      });
    }
  }, [popover.open, previousOpen, refs]);

  return (
    <span className="contents" onClick={(event) => event.stopPropagation()}>
      <Tooltip tooltip={popover.open ? null : tooltip ?? title}>
        <PopoverDisclosure state={popover}>
          {(disclosureProps) => (
            <DateButton
              {...disclosureProps}
              article={
                popover.open
                  ? {
                      ...article,
                      ...values,
                    }
                  : article
              }
            />
          )}
        </PopoverDisclosure>
      </Tooltip>
      <DateEditPopover
        state={popover}
        article={article}
        onChange={setValues}
        title={title}
      />
    </span>
  );
};

export type ArticleDateEditorProps = {
  article: {
    id: number;
    initialFirstPublished: string | null;
    published: boolean;
    scheduled: boolean;
    scheduledAt: string | null;
    scheduledBy: {
      id: string;
      fullName: string;
    } | null;
    firstPublishEvents: {
      nodes: Event[];
    };
  } & ArticlePublishDateProps["article"] &
    ArticlePublishTimeProps["article"];
};

type ArticlePublishedDateTooltipProps = {
  children: React.ReactElement;
  events: Event[];
};

const ArticlePublishedDateTooltip = memo(
  ({ children, events }: ArticlePublishedDateTooltipProps) => {
    const [event = null] = events;
    return (
      <Tooltip
        tooltip={
          <>
            <div className="font-semibold">Première publication</div>
            <DateDisplay date={event?.date ?? null} />
            <AuthorDisplay user={event?.actor ?? null} />
          </>
        }
      >
        {children}
      </Tooltip>
    );
  },
);

type ArticleScheduledDateTooltipProps = {
  children: React.ReactElement;
  scheduledAt: string | null;
  scheduledBy: {
    fullName: string;
  } | null;
};

const ArticleScheduledDateTooltip = memo(
  ({
    children,
    scheduledAt,
    scheduledBy,
  }: ArticleScheduledDateTooltipProps) => {
    return (
      <Tooltip
        tooltip={
          <>
            <div className="font-semibold">Programmé</div>
            <DateDisplay date={scheduledAt} />
            <AuthorDisplay user={scheduledBy} />
          </>
        }
      >
        {children}
      </Tooltip>
    );
  },
);

export const ArticleDateEditor = ({ article }: ArticleDateEditorProps) => {
  const readOnly = useListReadOnly();
  const [active, setActive] = useState(false);
  const activate = useEventCallback(() => setActive(true));

  if (article.initialFirstPublished) {
    return (
      <ArticlePublishedDateTooltip events={article.firstPublishEvents.nodes}>
        <div>
          <PublishDateTime article={article} className="p-1" />
        </div>
      </ArticlePublishedDateTooltip>
    );
  }

  if (article.scheduled) {
    return (
      <ArticleScheduledDateTooltip
        scheduledAt={article.scheduledAt}
        scheduledBy={article.scheduledBy}
      >
        <div>
          <PublishDateTime article={article} className="p-1" />
        </div>
      </ArticleScheduledDateTooltip>
    );
  }

  if (!readOnly && active) {
    return <ActiveDateButton article={article} />;
  }

  return (
    <DateButton
      onFocus={activate}
      onMouseEnter={activate}
      article={article}
      disabled={readOnly}
    />
  );
};

ArticleDateEditor.fragments = {
  article: gql`
    fragment ArticleDateEditor_article on Article {
      id
      initialFirstPublished
      scheduled
      scheduledAt
      scheduledBy {
        id
        fullName
      }
      firstPublishEvents: events(
        where: {
          name: { eq: "article" }
          action: { in: ["prePublish", "published"] }
        }
        limit: 2
        orderBy: [{ field: updatedAt, direction: asc }]
      ) {
        nodes {
          id
          date
          actor {
            id
            fullName
          }
        }
      }
      ...ArticlePublishDate_article
      ...ArticlePublishTime_article
      ...ArticleDateForm_article
    }
    ${ArticlePublishDate.fragments.article}
    ${ArticlePublishTime.fragments.article}
    ${ArticleDateForm.fragments.article}
  `,
};

export type ArticleDateSelectorProps = {
  tooltip?: string;
} & {
  article: {
    id: number;
  };
} & ArticlePublishDateProps &
  ArticlePublishTimeProps;

export const ArticleDateSelector = ({
  tooltip,
  article,
}: ArticleDateSelectorProps) => {
  const popover = usePopoverState({ placement: "bottom-start" });
  const title = "Éditer la date de publication souhaitée";

  return (
    <>
      <Tooltip tooltip={popover.open ? null : tooltip ?? title}>
        <PopoverDisclosure state={popover}>
          {(disclosureProps) => (
            <DateButton {...disclosureProps} article={article} />
          )}
        </PopoverDisclosure>
      </Tooltip>
      <DateFieldsEditPopover state={popover} title={title} />
    </>
  );
};
