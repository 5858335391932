import styled, {
  css,
  fontSize,
  fontWeight,
  space,
} from "@xstyled/styled-components";
import clsx from "clsx";
import { ContentState } from "draft-js-es";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLiveRef } from "swash/utils/useLiveRef";

import { RichEditor } from "@/components/rich-editor/RichEditor";
import { useRichEditorState } from "@/components/rich-editor/RichEditorState";

import { RichFieldCommands } from "./RichFieldCommands";
import { SpellCheckProvider } from "./plugins/spell-check-control/SpellCheckPluginContext";

const lineHeights = {
  xs: 16,
  sm: 20,
  base: 24,
  lg: 28,
  xl: 36,
};

const sharedStyle = css`
  position: relative;
  background-color: white;
  transition: base;
  border: 1;
  border-color: layout-border;
  border-radius: base;

  &[aria-disabled="true"] {
    opacity: 0.38;
  }

  &:hover:not([aria-disabled="true"]) {
    border-color: primary;
  }

  &:active:not([aria-disabled="true"]) {
    box-shadow: active;
  }

  &:focus-within {
    box-shadow: glow;
    border-color: primary;
  }

  .public-DraftEditorPlaceholder-root {
    pointer-events: none;
    color: placeholder;
    line-height: initial;
  }

  &[aria-invalid="true"] {
    border-color: danger-dark;
    &:focus-within {
      box-shadow: glow-danger;
      border-color: danger-dark;
    }
    &:hover {
      border-color: danger-dark;
    }
  }

  &[data-scale="xs"] {
    .DraftEditor-editorContainer,
    .public-DraftEditorPlaceholder-root {
      font-size: xs;
      line-height: ${lineHeights.xs}px;
    }
    .public-DraftEditor-content,
    .public-DraftEditorPlaceholder-root {
      padding: 1 2;
    }
  }

  &[data-scale="sm"] {
    .DraftEditor-editorContainer,
    .public-DraftEditorPlaceholder-root {
      font-size: sm;
      line-height: ${lineHeights.sm}px;
    }
    .public-DraftEditor-content,
    .public-DraftEditorPlaceholder-root {
      padding: 1 2;
    }
  }

  &[data-scale="base"] {
    .DraftEditor-editorContainer,
    .public-DraftEditorPlaceholder-root {
      font-size: base;
      line-height: ${lineHeights.base}px;
    }
    .public-DraftEditor-content,
    .public-DraftEditorPlaceholder-root {
      padding: 2 2;
    }
  }

  &[data-scale="lg"] {
    .DraftEditor-editorContainer,
    .public-DraftEditorPlaceholder-root {
      font-size: lg;
      line-height: ${lineHeights.lg}px;
    }
    .public-DraftEditor-content,
    .public-DraftEditorPlaceholder-root {
      padding: 2 2;
    }
  }

  &[data-scale="xl"] {
    .DraftEditor-editorContainer,
    .public-DraftEditorPlaceholder-root {
      font-size: xl;
      line-height: ${lineHeights.xl}px;
    }
    .public-DraftEditor-content,
    .public-DraftEditorPlaceholder-root {
      padding: 2 2;
    }
  }

  &[data-borderless] {
    border: 0;
    box-shadow: none;
    .DraftEditor-editorContainer {
      border: 0;
    }
    &:active:not(:disabled) {
      box-shadow: none;
    }
  }

  &[data-serif] {
    font-family: Georgia, serif;
  }

  display: flex;

  &[data-variant="inline"] {
    .public-DraftEditor-content,
    .public-DraftEditorPlaceholder-root {
      padding: 1 2;
    }
    &:not(:focus-within) {
      border-color: transparent;
      background: transparent;
    }
    &:focus-within {
      color: dusk;
    }
    &:not(:focus-within):not(:hover) {
      button {
        opacity: 0;
      }
    }

    &[data-scale="sm"] {
      .public-DraftStyleDefault-block {
        line-height: 22px;
      }
      .public-DraftEditorPlaceholder-root {
        line-height: 22px;
      }
    }
  }

  && {
    ${space}
    .DraftEditor-editorContainer, .public-DraftEditorPlaceholder-root {
      ${fontSize}
      ${fontWeight}
    }
  }
`;

const TextInputLayout = styled.box`
  ${sharedStyle};

  [data-display-mode="preview"] & {
    border-color: grey-lighter !important;
  }

  [data-display-mode="compare"] & {
    border-color: grey-lightest !important;
  }

  overflow-x: auto;
  overflow-y: auto;
  white-space: pre-wrap;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

  ${(p) =>
    p.$maxRows != null &&
    css`
      &[data-scale="xs"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.xs * p.$maxRows + 8}px;
        `}
      }
      &[data-scale="sm"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.sm * p.$maxRows + 8}px;
        `}
      }
      &[data-scale="base"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.base * p.$maxRows + 16}px;
        `}
      }
      &[data-scale="lg"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.lg * p.$maxRows + 16}px;
        `}
      }
      &[data-scale="xl"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.xl * p.$maxRows + 16}px;
        `}
      }
    `}
`;

const TextAreaLayout = styled.box`
  ${sharedStyle};

  ${(p) =>
    p.$rows != null &&
    css`
      &[data-scale="sm"] .public-DraftEditor-content {
        ${(p) => css`
          min-height: ${lineHeights.sm * p.$rows + 8}px;
        `}
      }
      &[data-scale="base"] .public-DraftEditor-content {
        ${(p) => css`
          min-height: ${lineHeights.base * p.$rows + 16}px;
        `}
      }
      &[data-scale="lg"] .public-DraftEditor-content {
        ${(p) => css`
          min-height: ${lineHeights.lg * p.$rows + 16}px;
        `}
      }
      &[data-scale="xl"] .public-DraftEditor-content {
        ${(p) => css`
          min-height: ${lineHeights.xl * p.$rows + 16}px;
        `}
      }
    `}

  ${(p) =>
    p.$maxRows != null &&
    css`
      &[data-scale="sm"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.sm * p.$maxRows + 8}px;
        `}
      }
      &[data-scale="base"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.base * p.$maxRows + 16}px;
        `}
      }
      &[data-scale="lg"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.lg * p.$maxRows + 16}px;
        `}
      }
      &[data-scale="xl"] .public-DraftEditor-content {
        ${(p) => css`
          max-height: ${lineHeights.xl * p.$maxRows + 16}px;
        `}
      }
    `}
`;

/**
 * Scroll an editor to start.
 * @param {HTMLElement} editorElement
 */
function scrollEditorToStart(editorElement) {
  const scrollingContainer = editorElement.querySelector(
    ".public-DraftStyleDefault-block",
  );
  if (scrollingContainer) scrollingContainer.scrollLeft = 0;
}

/**
 * Create an editor state from config.
 * @param {object} params
 * @param {string} params.value
 * @param {boolean} params.multiline
 * @returns {import('draft-js').ContentState}
 */
function createEditorContent({ value, multiline }) {
  return ContentState.createFromText(
    multiline ? value : value.replace(/\n/g, " "),
  );
}

export function useRichEditorTextBoxState({
  multiline = false,
  value,
  readOnly = false,
  plugins = [],
  name,
  label,
  blockTemplates,
  onBlur,
  onChange,
  stripPastedStyles,
  whiteSpace,
  audioEnabled,
  ...props
}) {
  const [contentState, setContentState] = useState(() =>
    createEditorContent({ value, multiline }),
  );

  const editor = useRichEditorState({
    contentState,
    setContentState,
    plugins,
    readOnly,
    multiline,
    isRichEditorTextBox: true,
    name,
    label,
    blockTemplates,
    stripPastedStyles,
    whiteSpace,
    audioEnabled,
  });

  const refs = useLiveRef({ editor, value, onBlur, onChange });
  const internalValue = contentState.getPlainText();

  const updateEditorState = useCallback(
    (value) => {
      const {
        editor: { editorState, multiline },
      } = refs.current;
      // Compute fresh internal value
      const internalValue = editorState.getCurrentContent().getPlainText();

      // If the value has changed we set the new content
      if (internalValue !== value) {
        setContentState(
          createEditorContent({
            value,
            multiline,
          }),
        );
      }
    },
    [refs],
  );

  const handleBlur = useCallback(
    (event) => {
      const { onBlur, value } = refs.current;
      if (onBlur) {
        onBlur(event);
      }

      // Update editor state if necessary to avoid mismatch between state and editor
      updateEditorState(value);
    },
    [refs, updateEditorState],
  );

  // Handle `value` from outside
  useEffect(() => {
    const {
      editor: { hasFocus },
    } = refs.current;
    // If editor has focus, then we ignore input value
    // this component cannot be controlled while the user is editing.
    if (!hasFocus) {
      updateEditorState(value);
    }
  }, [value, refs, updateEditorState]);

  const initRef = useRef(false);
  // Trigger `onChange` when the value changes
  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      return;
    }
    const { onChange } = refs.current;
    if (onChange) {
      onChange(internalValue);
    }
  }, [internalValue, refs]);

  return {
    editor,
    value,
    onBlur: handleBlur,
    onChange,
    ...props,
  };
}

export const RichEditorTextBox = forwardRef(
  (
    {
      editor,
      placeholder,
      scale,
      borderless,
      rows = 3,
      maxRows,
      value,
      onChange,
      onBlur,
      autoFocus,
      rich,
      showTools,
      variant,
      serif,
      ...props
    },
    ref,
  ) => {
    const { multiline, editorState, readOnly } = editor;
    const editorRef = useRef();
    const hasFocus = editorRef.current?.props.editorState
      .getSelection()
      .getHasFocus();
    const refs = useLiveRef({ onBlur });
    const editorLayoutRef = useRef();

    const [focusOnEmpty, setFocusOnEmpty] = useState(false);

    const internalValue = editorState.getCurrentContent().getPlainText();
    const focusable =
      focusOnEmpty && !readOnly && value === "" && internalValue === "";

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          editorRef.current.focus();
        },
        focusIfEmpty: () => {
          setFocusOnEmpty(true);
        },
      }),
      [],
    );

    const handleBlur = useCallback(
      (event) => {
        const { onBlur } = refs.current;
        if (onBlur) {
          onBlur(event);
        }

        // Scroll at the start of editor
        scrollEditorToStart(editorLayoutRef.current);
      },
      [editorLayoutRef, refs],
    );

    useEffect(() => {
      if (focusable) {
        if (!hasFocus) {
          editorRef.current.focus();
        }
        setFocusOnEmpty(false);
      }
    }, [hasFocus, focusable]);

    useEffect(() => {
      if (autoFocus) {
        const raf = requestAnimationFrame(() => {
          editorRef.current.focus();
        });

        return () => {
          cancelAnimationFrame(raf);
        };
      }
    }, [autoFocus]);

    const Layout = multiline ? TextAreaLayout : TextInputLayout;

    const tools = rich && showTools;

    return (
      <Layout
        ref={editorLayoutRef}
        onBlur={handleBlur}
        data-scale={scale}
        aria-disabled={readOnly ?? undefined}
        data-borderless={borderless ? "" : undefined}
        data-variant={variant ?? undefined}
        data-serif={serif ? "" : undefined}
        $rows={rows}
        $maxRows={maxRows}
        {...props}
      >
        <div
          className={clsx(
            "scrollbar-light flex-1 overflow-auto",
            tools && "[&_.public-DraftEditor-content]:pr-6",
          )}
        >
          <RichEditor
            ref={editorRef}
            appearance="textBox"
            placeholder={placeholder}
            {...editor}
          />
        </div>
        {tools && (
          <div className="absolute top-1 right-1 z-10">
            <RichFieldCommands readOnly={readOnly} {...props} {...editor} />
          </div>
        )}
      </Layout>
    );
  },
);

const InnerStandaloneRichEditorTextBox = forwardRef((props, ref) => {
  const editor = useRichEditorTextBoxState(props);
  return <RichEditorTextBox ref={ref} {...editor} />;
});

export const StandaloneRichEditorTextBox = forwardRef(
  (
    {
      multiline = false,
      readOnly = false,
      scale = "base",
      rows,
      maxRows,
      plugins,
      ...props
    },
    ref,
  ) => {
    return (
      <SpellCheckProvider>
        <InnerStandaloneRichEditorTextBox
          ref={ref}
          multiline={multiline}
          readOnly={readOnly}
          scale={scale}
          rows={rows}
          maxRows={maxRows}
          plugins={plugins}
          {...props}
        />
      </SpellCheckProvider>
    );
  },
);
