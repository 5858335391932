import { forwardRef } from "react";

const SvgStar = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m3.871 19.731.016-.016v.016zM0 8.229h7.694L10.056 1h.016l2.346 7.229H20l-6.137 4.338 2.329 7.116-6.152-4.418-6.137 4.434 2.329-7.084z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgStar);
export default ForwardRef;
