import { forwardRef } from "react";

const SvgEraser = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.245 15.844h7.721l4.136-4.75H5.596zm-1.234.431a2.883 2.883 0 0 1 .06-.882c.077-.3.175-.529.293-.686L10.77 3h8.093c.431.013.751.17.96.47a1.3 1.3 0 0 1 .176.647v1.176L9.652 17H.383c-.222-.144-.346-.385-.372-.725z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEraser);
export default ForwardRef;
