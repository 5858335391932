import { memo } from "react";

import type {
  CellType,
  NodeProvider,
  RowType,
  RunnableInteraction,
} from "../ListState";
import { EndRow, estimateEndRowSize } from "./EndRow";
import { GroupRow, estimateGroupRowSize } from "./GroupRow";
import { HeaderRow, estimateHeaderRowSize } from "./HeaderRow";
import { LoaderRow, estimateLoaderRowSize } from "./LoaderRow";
import { NodeRow, estimateNodeRowSize } from "./NodeRow";

export type RowProps<TNode> = {
  row: RowType<TNode>;
  cells: CellType<TNode>[];
  interactions: RunnableInteraction<TNode>[];
  provider: NodeProvider<TNode> | null;
  getGroupLabel: ((key: string) => string) | null;
  getSubGroupLabel: ((key: string) => string) | null;
  toggleGroup: ((key: string) => void) | null;
  onActiveChange: (node: TNode) => void;
};

export const estimateSize = <TNode,>(row: RowType<TNode>) => {
  switch (row.type) {
    case "group":
      return estimateGroupRowSize();
    case "sub-group":
      return estimateGroupRowSize();
    case "header":
      return estimateHeaderRowSize();
    case "node":
      return estimateNodeRowSize();
    case "loader":
      return estimateLoaderRowSize();
    case "end":
      return estimateEndRowSize();
    default:
      return 0;
  }
};

const RowWrapped = <TNode,>(props: RowProps<TNode>) => {
  const { row, cells, toggleGroup, getGroupLabel, getSubGroupLabel } = props;
  switch (row.type) {
    case "group": {
      if (!toggleGroup || !getGroupLabel) {
        return null;
      }
      return (
        <GroupRow
          row={row}
          toggleGroup={toggleGroup}
          getGroupLabel={getGroupLabel}
        />
      );
    }
    case "sub-group": {
      if (!toggleGroup || !getSubGroupLabel) {
        return null;
      }
      return (
        <GroupRow
          row={row}
          toggleGroup={toggleGroup}
          getGroupLabel={getSubGroupLabel}
          subGroup
        />
      );
    }
    case "header": {
      return <HeaderRow cells={cells} />;
    }
    case "node": {
      return (
        <NodeRow
          row={row}
          cells={cells}
          interactions={props.interactions}
          provider={props.provider}
          onActiveChange={props.onActiveChange}
        />
      );
    }
    case "loader": {
      return <LoaderRow />;
    }
    case "end": {
      return <EndRow />;
    }
    default:
      return null;
  }
};

export const Row = memo(RowWrapped) as typeof RowWrapped;
