import { forwardRef } from "react";

const SvgCopy = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M6.818 14.874c0 .086.027.156.081.21a.281.281 0 0 0 .21.081h9.765a.281.281 0 0 0 .21-.081.264.264 0 0 0 .081-.194V6.208h-4.527a.265.265 0 0 1-.194-.081.263.263 0 0 1-.097-.21V1.374H7.109a.264.264 0 0 0-.194.081.261.261 0 0 0-.081.193zm-3.444 3.46c0 .075.027.14.081.194.054.053.124.08.21.08h10.46a.374.374 0 0 0 .211-.064.287.287 0 0 0 .08-.21v-1.779H5.799a.306.306 0 0 1-.243-.113.33.33 0 0 1-.097-.242V4.138H3.665a.3.3 0 0 0-.194.065.28.28 0 0 0-.08.21zM2 19.66V3.103c0-.097.032-.183.097-.258a.326.326 0 0 1 .242-.097h3.105V.339c0-.097.032-.178.097-.242A.326.326 0 0 1 5.783 0h7.939l4.834 4.818V16.2a.33.33 0 0 1-.339.34h-2.41v3.104a.355.355 0 0 1-.113.259.33.33 0 0 1-.242.097H2.356a.388.388 0 0 1-.259-.097A.33.33 0 0 1 2 19.66z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCopy);
export default ForwardRef;
