import { forwardRef } from "react";

const SvgShoppingBag = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.198 6.702h2.253l1.556-1.539h-3.809zM11.257.933a2.792 2.792 0 0 1 1.392 1.025c.354.489.531 1.032.531 1.63h2.197v-.769c0-.525-.183-.971-.549-1.337s-.806-.549-1.319-.549zM9.279 3.589h2.966a1.83 1.83 0 0 0-.531-1.319 1.82 1.82 0 0 0-1.337-.549h-.732a1.782 1.782 0 0 0-.348 1.08zm-3.114 0h2.179V2.82c0-.391.074-.758.22-1.099h-.531c-.513 0-.952.177-1.319.531a1.82 1.82 0 0 0-.549 1.337zm-.018 3.113h6.098V5.163h-6.08zm-2.803.019h1.868V5.164h-.293zM1 19.999V6.721l3.114-3.132h1.098c0-.781.275-1.447.825-1.996A2.75 2.75 0 0 1 8.033.787h1.191C9.785.262 10.426 0 11.147 0h2.344c.781 0 1.447.274 1.996.824.55.549.825 1.209.825 1.978v.787h2.655v13.279L15.854 20H1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgShoppingBag);
export default ForwardRef;
