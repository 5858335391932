import { forwardRef } from "react";

const SvgEllipsisAlt = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M14.669 9.666c0-.736.26-1.364.781-1.884A2.573 2.573 0 0 1 17.335 7c.735 0 1.363.26 1.884.782.521.521.781 1.149.781 1.884s-.26 1.363-.781 1.884a2.564 2.564 0 0 1-1.884.782 2.604 2.604 0 0 1-1.885-.764 2.588 2.588 0 0 1-.781-1.901zm-7.351 0c0-.736.26-1.364.781-1.884A2.573 2.573 0 0 1 9.984 7c.747 0 1.38.261 1.901.782.52.521.781 1.149.781 1.884s-.26 1.363-.781 1.884c-.522.521-1.15.782-1.885.782s-1.363-.255-1.884-.764a2.647 2.647 0 0 1-.798-1.901zm-7.317 0c0-.736.254-1.364.763-1.884A2.59 2.59 0 0 1 2.666 7c.735 0 1.364.261 1.884.782s.781 1.149.781 1.884c.001.735-.26 1.363-.781 1.884s-1.149.782-1.884.782-1.363-.255-1.885-.764A2.588 2.588 0 0 1 0 9.667z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEllipsisAlt);
export default ForwardRef;
