import moment from "moment";

import { USER_LEVELS } from "@/containers/User";

import { UsedInList } from "./UsedInList";

export const name = "Utilisateurs";

const columns = [
  {
    id: "email",
    Header() {
      return "Email";
    },
    Value({ node, EditLink }) {
      return <EditLink slug="users">{node.email}</EditLink>;
    },
  },
  {
    id: "name",
    Header() {
      return "Nom";
    },
    Value({ node }) {
      return [node.firstName, node.lastName].join(" ");
    },
  },
  {
    id: "level",
    Header() {
      return "Niveau d’accès";
    },
    Value({ node }) {
      const level = USER_LEVELS.find(({ name }) => name === node.level);
      return level?.label;
    },
  },
  {
    id: "services",
    Header() {
      return "Services";
    },
    Value({ node }) {
      if (node.sections.length === 0) return "Aucun service";
      return node.sections.map(({ name }) => <div key={name}>{name}</div>);
    },
  },
  {
    id: "activation",
    Header() {
      return "État";
    },
    Value({ node }) {
      return node.enabled ? "Activé" : "Désactivé";
    },
  },
  {
    id: "publisher",
    Header() {
      return "Publisher";
    },
    Value({ node }) {
      return node.publisher ? "Oui" : "Non";
    },
  },
  {
    id: "validDomain",
    Header() {
      return "Domaine valide";
    },
    Value({ node }) {
      return node.validDomain ? "Oui" : "Non";
    },
  },
  {
    id: "twoFactorEnabled",
    Header() {
      return "Ident. 2 facteurs";
    },
    Value({ node }) {
      return node.twoFactorSmsEnabled || node.twoFactorAuthenticatorEnabled
        ? "Oui"
        : "Non";
    },
  },
  {
    id: "lastConnection",
    Header() {
      return "Dernière connexion";
    },
    Value({ node }) {
      const lastConnection = node.activities.nodes[0]?.date;
      return lastConnection ? (
        <span data-test-hidden>
          {moment.tz(lastConnection, "Europe/Paris").format("LLL")}
        </span>
      ) : (
        ""
      );
    },
  },
];

export const List = ({ nodes }) => {
  return <UsedInList nodes={nodes} columns={columns} />;
};
