import { forwardRef } from "react";

const SvgGoogle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.001 9.954c0-1.467.265-2.825.794-4.076a8.997 8.997 0 0 1 2.218-3.21A10.075 10.075 0 0 1 7.295.685 11.47 11.47 0 0 1 11.263 0c1.779 0 3.342.372 4.689 1.118 1.346.745 2.464 1.815 3.354 3.21L15.88 6.113a6.147 6.147 0 0 0-1.984-1.821 5.209 5.209 0 0 0-2.615-.685c-.817 0-1.611.162-2.381.487a5.86 5.86 0 0 0-1.947 1.334c-.553.59-.98 1.275-1.281 2.056s-.451 1.605-.45 2.471c0 .889.15 1.731.45 2.525.301.793.728 1.473 1.281 2.038s1.208 1.01 1.965 1.334c.758.325 1.539.487 2.345.487s1.587-.138 2.344-.415c.746-.289 1.353-.655 1.822-1.1v-2.326h-5.122V8.927h9.179v7.412a10.088 10.088 0 0 1-3.589 2.705c-1.406.637-2.945.956-4.617.956-1.382 0-2.705-.235-3.967-.704a9.746 9.746 0 0 1-3.283-2.02 8.892 8.892 0 0 1-2.236-3.209c-.517-1.239-.782-2.609-.794-4.112z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgGoogle);
export default ForwardRef;
