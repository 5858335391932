import { getBlockIndex } from "../utils/Block";
import { addBlock } from "./addBlock";
import { removeBlock } from "./removeBlock";

/**
 * Replace a block by another.
 * @param {import('draft-js').EditorState} editorState
 * @param {string} sourceBlockKey
 * @param {{ type: import('draft-js').DraftBlockType, entity?: { type: string } }}
 * @param {object} [options]
 * @param {boolean} [options.select]
 * @param {boolean} [options.silence]
 */
export function replaceBlock(
  { editorState, setEditorState },
  sourceBlockKey,
  { entity, ...block },
  options,
) {
  const sourceBlockIndex = getBlockIndex(editorState, sourceBlockKey);
  if (sourceBlockIndex === -1) {
    return editorState;
  }

  const nextEditorState = removeBlock(
    { editorState, setEditorState },
    sourceBlockKey,
    null,
    true,
  );

  return addBlock(
    { editorState: nextEditorState, setEditorState },
    block,
    sourceBlockIndex,
    entity,
    options,
  );
}
