import { forwardRef } from "react";

const SvgUnlink = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="m6.202 5.415 4.378-4.378C11.31.32 12.17-.025 13.16.001c.99.04 1.863.43 2.619 1.173l1.349 1.368c.782.782 1.192 1.681 1.231 2.698.04 1.016-.286 1.876-.977 2.58L15.603 9.6a5.407 5.407 0 0 0-1.31-2.23l1.31-1.31a.97.97 0 0 0 .235-.84 1.494 1.494 0 0 0-.45-.9l-1.369-1.368c-.286-.26-.573-.404-.86-.43-.286-.039-.553.052-.801.274L7.98 7.174zm-5.199 9.402c.026 1.003.41 1.876 1.153 2.62l1.368 1.368c.756.755 1.656 1.153 2.698 1.192 1.029.04 1.889-.287 2.58-.978l4.378-4.378c.704-.704 1.036-1.564.997-2.58-.026-1.017-.43-1.916-1.212-2.698l-2.248-2.247h-.019l-1.76 1.76 2.248 2.266c.261.248.417.541.469.88a.974.974 0 0 1-.234.84L7.043 17.24c-.235.222-.522.306-.86.254a1.553 1.553 0 0 1-.88-.449l-1.368-1.369a1.425 1.425 0 0 1-.431-.84 1.026 1.026 0 0 1 .294-.822l1.29-1.309a5.485 5.485 0 0 1-1.31-2.209l-1.739 1.74c-.73.716-1.075 1.576-1.036 2.58z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgUnlink);
export default ForwardRef;
