import clsx from "clsx";
import * as React from "react";

import type { ButtonScale } from "../Button";

export type InputBoxScale = "sm" | "md" | "lg";

export interface InputClassNameProps {
  /**
   * The scale of the input.
   * Context dependent.
   * @default "md"
   */
  scale?: InputBoxScale;

  /**
   * Whether the input display a placeholder.
   */
  placeholder?: boolean;

  /**
   * The class name to apply to the input.
   */
  className?: string | undefined;
}

export type TextInputScale = "sm" | "md" | "lg";

export interface TextInputProps
  extends React.HTMLProps<HTMLInputElement>,
    Omit<InputClassNameProps, "placeholder"> {
  /**
   * Use this prop to use the child as the input.
   */
  asChild?: boolean;
}

type Size = number | string;

export type TextInputGroupAdornment =
  | React.ReactNode
  | ((props: {
      iconSize: number;
      buttonScale: ButtonScale;
    }) => React.ReactNode);

export interface TextInputGroupProps extends React.HTMLProps<HTMLDivElement> {
  /**
   * Auto adjust adornments.
   * @default "md"
   */
  scale?: TextInputScale;

  /**
   * Adornment to be placed at the start of the input.
   */
  startAdornment?: TextInputGroupAdornment;

  /**
   * Size of the start adornment.
   */
  startAdornmentSize?: Size;

  /**
   * Adornment to be placed at the end of the input.
   */
  endAdornment?: TextInputGroupAdornment;

  /**
   * Size of the end adornment.
   */
  endAdornmentSize?: Size;
}

export type TextInputEndAdornmentProps = React.HTMLProps<HTMLDivElement>;

const scales: Record<TextInputScale, string> = {
  lg: /* tw */ `text-base py-[0.4375rem]`,
  md: /* tw */ `text-sm py-[0.3125rem]`,
  sm: /* tw */ `text-sm py-0.5`,
};

const Adornment = (props: React.ComponentProps<"div">) => {
  return (
    <div
      className="pointer-events-none absolute top-0 bottom-0 flex items-center justify-center text-grey-on [&_button]:pointer-events-auto"
      {...props}
    />
  );
};

const getDefaultAdornmentSize = (scale: TextInputScale) => {
  switch (scale) {
    case "lg":
      return 40;
    case "md":
      return 32;
    case "sm":
      return 26;
  }
};

const getAdornmentIconSize = (scale: TextInputScale) => {
  switch (scale) {
    case "lg":
      return 18;
    case "md":
      return 16;
    case "sm":
      return 16;
  }
};

const getAdornmentButtonScale = (scale: TextInputScale): ButtonScale => {
  switch (scale) {
    case "lg":
      return "md";
    case "md":
      return "sm";
    case "sm":
      return "xs";
  }
};

const autoUnitSize = (value: Size) => {
  return typeof value === "number" ? `${value / 16}rem` : value;
};

export const TextInputGroup = React.forwardRef<
  HTMLDivElement,
  TextInputGroupProps
>((props, ref) => {
  const {
    className,
    scale = "md",
    endAdornment,
    endAdornmentSize = getDefaultAdornmentSize(scale),
    startAdornment,
    startAdornmentSize = getDefaultAdornmentSize(scale),
    children,
    style,
    ...rest
  } = props;

  const renderAdornment = (adornment: TextInputGroupAdornment) => {
    if (typeof adornment === "function") {
      return adornment({
        iconSize: getAdornmentIconSize(scale),
        buttonScale: getAdornmentButtonScale(scale),
      });
    }
    return adornment;
  };

  return (
    <div
      ref={ref}
      className={clsx("relative", className)}
      style={
        {
          ...style,
          "--end-adornment-size": endAdornment
            ? autoUnitSize(endAdornmentSize)
            : undefined,
          "--start-adornment-size": startAdornment
            ? autoUnitSize(startAdornmentSize)
            : undefined,
        } as React.CSSProperties
      }
      {...rest}
    >
      {children}
      {endAdornment && (
        <Adornment
          style={{
            right: 0,
            width: "var(--end-adornment-size)",
          }}
        >
          {renderAdornment(endAdornment)}
        </Adornment>
      )}
      {startAdornment && (
        <Adornment
          style={{
            left: 0,
            width: "var(--start-adornment-size)",
          }}
        >
          {renderAdornment(startAdornment)}
        </Adornment>
      )}
    </div>
  );
});

export const getInputClassName = ({
  className,
  scale = "md",
  placeholder,
}: InputClassNameProps) => {
  return clsx(
    className,
    placeholder ? "text-dusk-on-light/70" : "text-dusk-on",
    "pr-[var(--end-adornment-size,0.5rem)] pl-[var(--start-adornment-size,0.5rem)]",
    "text-dusk-on border border-grey-border-light rounded transition w-full",
    "placeholder:text-grey-on",
    "hover:border-primary-border",
    "focus:outline-none",
    "focus:border-primary-border w-full",
    "disabled:hover:border-inherit disabled:opacity-disabled",
    "aria-invalid:text-danger-on aria-invalid:border-danger-border",
    "aria-invalid:focus:border-danger-border",
    scales[scale],
  );
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      scale = "md",
      className,
      asChild,
      children,
      style,
      ...rest
    } = props;
    const inputClassName = getInputClassName({ className, scale });
    const inputProps = {
      ref,
      className: clsx(className, inputClassName, scales[scale]),
      ...rest,
    };
    if (asChild) {
      return React.cloneElement(
        React.Children.only(props.children as React.ReactElement),
        inputProps,
      );
    }
    return <input {...inputProps} />;
  },
);
