import { gql } from "@apollo/client";
import { fromEvent } from "file-selector";
import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { Loader } from "swash/Loader";

import {
  Image as ImageComponent,
  ImageFixedFragment,
} from "@/components/Image";
import { FileDrop, useFileDropState } from "@/components/controls/FileDrop";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import {
  FileDropField,
  useFileDropField,
} from "@/components/fields/FileDropField";
import { Redo } from "@/components/icons";
import { ERRORS } from "@/config/messages";
import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";

const MAX_FILE_SIZE = 20; // in Mega bytes

const ImageFieldImageFragment = gql`
  fragment ImageFieldImageFragment on Image {
    id
    fixed(width: 200, height: 132) {
      ...ImageFixedFragment
    }
  }

  ${ImageFixedFragment}
`;

const CreateImageMutation = gql`
  mutation ImageFieldCreateImageMutation($input: CreateImageInput!) {
    createImage(input: $input) {
      ...ImageFieldImageFragment
    }
  }
  ${ImageFieldImageFragment}
`;

const ImageQuery = gql`
  query ImageQuery($id: Int!) {
    image(id: $id) {
      ...ImageFieldImageFragment
    }
  }
  ${ImageFieldImageFragment}
`;

function FileDropControl({
  maxFileSize = MAX_FILE_SIZE,
  readFile,
  imageProps,
  deleteFile,
  accept,
  disabled,
  validator,
  ...props
}) {
  const drop = useFileDropState(props);

  /**
   * Extend the File with some additional properties
   * {@link https://react-dropzone.js.org/#!/Extending%20Dropzone}
   */
  const imagesGetter = async (event) => {
    const files = await fromEvent(event);

    return Promise.all(
      [...files].map(
        (file) =>
          new Promise((resolve) => {
            const image = new Image();
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
              image.onload = () => {
                Object.defineProperties(file, {
                  width: { value: image.width },
                  height: { value: image.height },
                  encodedImage: { value: event.target.result },
                });
                resolve(file);
              };
              image.src = event.target.result;
            };
          }),
      ),
    );
  };

  return (
    <FileDrop
      {...drop.state}
      onChange={readFile}
      accept={accept ?? { "image/*": [".jpeg", ".jpg", ".png"] }}
      validator={validator}
      getFilesFromEvent={imagesGetter}
      maxFileSize={maxFileSize}
      type="image"
      disabled={disabled}
      data-field-control
    >
      <div className="p-4 font-semibold">
        {drop.state.value ? (
          <>
            {imageProps && (
              <ImageComponent
                {...imageProps}
                maxWidth={1}
                mb={2}
                className="checkerboard-linear-gradient"
              />
            )}
            <div>
              <Button
                type="button"
                scale="sm"
                disabled={disabled}
                onClick={(event) => {
                  event.stopPropagation();
                  if (deleteFile) deleteFile();
                  drop.state.onChange(null);
                }}
              >
                Supprimer l’image
              </Button>
            </div>
          </>
        ) : (
          <>
            <Redo className="mr-1 inline h-3 w-3" />{" "}
            {drop.state.placeholder ||
              `Faire glisser une image ici JPEG, PNG de ${maxFileSize} Mo max ou cliquez pour sélectionner une image`}
          </>
        )}
      </div>
    </FileDrop>
  );
}

export function ImageField({
  name,
  format,
  parse,
  query,
  validator,
  required,
  label,
  hint,
  internal = false,
  placeholder,
  disabled,
}) {
  const field = useFileDropField(name, { required, format, label, parse });
  const form = useForm();

  const {
    state: {
      field: {
        input: { value, onChange },
      },
    },
  } = field;

  const { data: { image } = {} } = useSafeQuery(query ?? ImageQuery, {
    skip: !value,
    variables: { id: value },
  });

  const [createImage, { loading }] = useSafeMutation(CreateImageMutation, {
    update: (cache, { data: { createImage } }) => {
      cache.writeQuery({
        query: ImageQuery,
        data: {
          image: createImage,
        },
      });
    },
  });

  if (loading) return <Loader />;

  const readFile = (file) => {
    if (file) {
      createImage({ variables: { input: { file, options: { internal } } } })
        .then(({ data: { createImage } }) => {
          onChange(createImage.id);
        })
        .catch(() => {
          form.mutators.setFieldError({
            fieldName: name,
            errorMessage: ERRORS.upload.bucketStorage,
          });
        });
    }
  };

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <FileDropField
        {...field}
        as={FileDropControl}
        validator={validator}
        placeholder={placeholder}
        readFile={readFile}
        imageProps={image && image.fixed}
        maxFileSize={20}
        disabled={disabled}
      />
    </FieldGroup>
  );
}

export function EncodedImageField({
  name,
  format,
  parse,
  required,
  label,
  hint,
  placeholder,
  accept,
}) {
  const field = useFileDropField(name, { required, format, parse });

  const {
    state: {
      field: {
        input: { value: encodedImage, onChange },
      },
    },
  } = field;

  const readFile = (file) => {
    const { type: mimeType, encodedImage } = file;
    onChange({
      mimeType,
      encodedImage,
    });
  };

  const handleDeleteFile = () => {
    onChange({ mimeType: null, encodedImage: null });
  };

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <FileDropField
        {...field}
        as={FileDropControl}
        placeholder={placeholder}
        readFile={readFile}
        imageProps={{ src: encodedImage }}
        deleteFile={handleDeleteFile}
        maxFileSize={0.128}
        accept={accept}
      />
    </FieldGroup>
  );
}
