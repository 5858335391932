import { gql } from "@apollo/client";
import { memo } from "react";
import { Chip } from "swash/Chip";
import { FormLabel } from "swash/form/FormLabel";

import { useSafeQuery } from "@/containers/Apollo";
import { ExposureIcon } from "@/containers/ExposureIcon";
import { ExposureIndicator } from "@/containers/ExposureIndicator";
import { useHasPermission } from "@/containers/User";
import { HasManyField } from "@/containers/admin/CRUD";
import { ConnectionFragment } from "@/services/fragments/connectionFragment";

const ExposureFragment = gql`
  fragment ExposureFragment on Exposure {
    ...ExposureIcon_exposure
    ...ExposureIndicator_exposure
  }
  ${ExposureIcon.fragments.exposure}
  ${ExposureIndicator.fragments.exposure}
`;

const ExposuresQuery = gql`
  query ExposuresSectionQuery($offset: Int) {
    connection: exposures(where: { enabled: true }, offset: $offset) {
      nodes {
        ...ExposureFragment
      }
      ...ConnectionFragment
    }
  }
  ${ExposureFragment}
  ${ConnectionFragment}
`;

const formatArticleExposure = (articleExposures, exposureId) => {
  return (
    articleExposures.find(
      (articleExposure) =>
        Number(articleExposure.exposureId) === Number(exposureId),
    ) ?? {
      suggested: true,
      fulfilledAt: null,
      fulfilled: false,
    }
  );
};

export const ExposuresField = memo(({ article, articleExposures }) => {
  const hasWritePermission = useHasPermission(
    ["exposures:edit", "exposures:full"],
    {
      method: "some",
    },
  );
  const hasReadOnlyPermission = useHasPermission("exposures:view");

  if (!hasWritePermission && hasReadOnlyPermission) {
    return (
      <ArticleExposures article={article} articleExposures={articleExposures} />
    );
  }

  return (
    <HasManyField
      name="exposureIds"
      label="Expositions"
      query={ExposuresQuery}
      fragment={ExposureFragment}
      modelName="Exposure"
      clearable={false}
      labelSelector={(exposure) => {
        const articleExposure = formatArticleExposure(
          articleExposures,
          exposure.id,
        );
        return (
          <>
            <ExposureIndicator
              article={article}
              articleExposure={articleExposure}
              exposure={exposure}
            />
          </>
        );
      }}
      labelElementSelector={(exposure) => (
        <div className="flex items-center gap-2">
          <ExposureIcon exposure={exposure} />
          {exposure.label}
        </div>
      )}
      disabledSelector={(exposure) => {
        const articleExposure = formatArticleExposure(
          articleExposures,
          exposure.id,
        );
        return articleExposure.fulfilled;
      }}
    />
  );
});

ExposuresField.fragments = {
  article: gql`
    fragment ExposuresField_article on Article {
      ...ExposureIndicator_article
    }

    ${ExposureIndicator.fragments.article}
  `,
  articleExposures: gql`
    fragment ExposuresField_articleExposures on ArticleExposure {
      fulfilled
      exposureId
      ...ExposureIndicator_articleExposure
    }

    ${ExposureIndicator.fragments.articleExposure}
  `,
};

export const ArticleExposures = memo(({ article, articleExposures }) => {
  const { data } = useSafeQuery(ExposuresQuery);

  if (!data) {
    return null;
  }

  const articleExposuresIds = articleExposures.map(
    ({ exposureId }) => exposureId,
  );

  return (
    <>
      <FormLabel>Expositions</FormLabel>
      <div className="flex gap-1">
        {articleExposuresIds.map((id) => (
          <ExposureChip
            key={id}
            id={id}
            article={article}
            articleExposures={articleExposures}
            values={data.connection.nodes}
          />
        ))}
      </div>
    </>
  );
});

const ExposureChip = ({ id, article, articleExposures, values, children }) => {
  const articleExposure = formatArticleExposure(articleExposures, id);
  const exposure = values.find((node) => Number(node.id) === Number(id));

  if (!exposure) return null;

  return (
    <Chip key={id}>
      <ExposureIndicator
        article={article}
        articleExposure={articleExposure}
        exposure={exposure}
      />
      {typeof children === "function" ? children(articleExposure) : children}
    </Chip>
  );
};
