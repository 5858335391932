import { useCallback, useEffect } from "react";
import { useDialogState } from "swash/Dialog";
import { useLiveRef } from "swash/utils/useLiveRef";

import { SpellCheck } from "@/components/icons";

import { RichEditorToolbarButton } from "../../RichEditorToolbar";
import { useSpellCheck } from "../spell-check-control/SpellCheckPluginContext";
import { GrammarlyDialog } from "./GrammarlyDialog";
import "./grammarly.css";

export const name = "grammarly-spell-check-control";
export const label = "Corriger le texte avec Grammarly";

export const BlockControls = ({ as }) => {
  const dialog = useDialogState();
  const dialogRef = useLiveRef(dialog);

  const openDialog = useCallback(
    (event) => {
      event.preventDefault();
      dialogRef.current.toggle();
    },
    [dialogRef],
  );

  return (
    <>
      <RichEditorToolbarButton as={as} label={label} onMouseDown={openDialog}>
        <SpellCheck />
      </RichEditorToolbarButton>
      <GrammarlyDialog dialog={dialog} />
    </>
  );
};

BlockControls.group = "spell-check";

export const ButtonCommand = BlockControls;

export const usePluginProps = (state) => {
  const { registerEditor } = useSpellCheck();
  const stateRef = useLiveRef(state);

  useEffect(() => {
    const { name, label } = stateRef.current;
    return registerEditor({
      name,
      label,
      getEditorState: () => stateRef.current.editorState,
      setEditorState: (newEditorState) =>
        stateRef.current.setEditorState(newEditorState),
    });
  }, [stateRef, registerEditor]);

  return {};
};
