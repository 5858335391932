import { forwardRef } from "react";

const SvgDesktop = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M1.107 14.081h17.802V3.06H1.107zm7.28 1.527H.546A.534.534 0 0 1 0 15.062V2.546c0-.146.052-.276.156-.39A.51.51 0 0 1 .53 2h18.94a.57.57 0 0 1 .421.218.56.56 0 0 1 .109.327v12.517a.544.544 0 0 1-.14.375.53.53 0 0 1-.39.156h-7.856L11.879 18H14v1H6v-1h2.138z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgDesktop);
export default ForwardRef;
