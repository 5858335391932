import { forwardRef } from "react";

const SvgImages = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M3.841 2.557v-.204c0-.099.037-.18.111-.242A.423.423 0 0 1 4.23 2h15.418c.099 0 .179.031.241.093a.336.336 0 0 1 .111.241v11.354c0 .099-.03.179-.092.241a.354.354 0 0 1-.26.112h-.297a.395.395 0 0 1-.26-.093.392.392 0 0 1-.092-.26V3.02H4.23a.35.35 0 0 1-.278-.13c-.074-.074-.111-.185-.111-.334zM1.948 4.468v-.297c0-.099.044-.186.13-.26a.358.358 0 0 1 .26-.111h15.418c.099 0 .185.037.26.111a.323.323 0 0 1 .092.241v11.336c0 .098-.03.185-.092.26a.329.329 0 0 1-.241.092h-.316a.4.4 0 0 1-.26-.092.326.326 0 0 1-.092-.242V4.82H2.338a.404.404 0 0 1-.26-.111.364.364 0 0 1-.13-.241zM0 17.306V5.971c0-.099.031-.186.093-.26a.326.326 0 0 1 .241-.093H16.16v11.688a.3.3 0 0 1-.112.242.298.298 0 0 1-.241.111H.353a.393.393 0 0 1-.26-.093.391.391 0 0 1-.093-.26z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImages);
export default ForwardRef;
