import { gql, useApolloClient, useSubscription } from "@apollo/client";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useField } from "react-final-form";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useComboboxStore } from "swash/v2/Combobox";
import { RemoteSelect, useRemoteSelectState } from "swash/v2/RemoteSelect";

import { DroppableCapsuleList } from "@/components/DroppableCapsuleList";
import { ModernCapsuleCounter } from "@/components/ModernCapsuleCounter";
import { ModernCapsuleLimit } from "@/components/ModernCapsuleLimit";
import { useSafeQuery } from "@/containers/Apollo";
import { ModernDraggableCapsule } from "@/containers/ModernDraggableCapsule";
import { CapsuleArticle as ArticleBaseCapsule } from "@/containers/article/capsules/CapsuleArticle";
import { CapsuleRemoveButton } from "@/containers/article/capsules/CapsuleButtons";
import { CapsuleToolbar } from "@/containers/article/capsules/CapsuleToolbar";
import {
  DragContextProvider,
  useDragIndex,
  useIsDragging,
} from "@/containers/routes/home-edition/DragContext";
import dndManager from "@/services/dndManager";
import { moveItems } from "@/services/utils";

const ArticleCapsule = ({ article, onRemove, disabled }) => {
  const toolbar = onRemove ? (
    <CapsuleToolbar>
      <CapsuleRemoveButton onClick={onRemove} disabled={disabled} />
    </CapsuleToolbar>
  ) : null;
  return <ArticleBaseCapsule article={article} toolbar={toolbar} />;
};

const ArticleQuery = gql`
  query RelatedArticlesSelector_articles(
    $offset: Int
    $limit: Int
    $filters: ArticleWhere
  ) {
    connection: articles(offset: $offset, limit: $limit, where: $filters) {
      pageInfo {
        hasMore
      }
      nodes {
        id
        ...ArticleCapsule_article
      }
    }
  }

  ${ArticleBaseCapsule.fragments.article}
`;

const ArticleUpdatedSubscription = gql`
  subscription RelatedArticlesSelector_articles($ids: [Int!]!) {
    articleUpdated(where: { id: { in: $ids } }) {
      id
      ...ArticleCapsule_article
    }
  }

  ${ArticleBaseCapsule.fragments.article}
`;

function ContentItem({
  droppableId,
  index,
  article,
  limit = 10,
  onRemove,
  disabled,
}) {
  const draggableId = `CustomFieldGrid:${article.id}`;
  const dragIndex = useDragIndex({ index, draggableId, droppableId });
  const dragLimit = useDragLimit({ draggableId, dragIndex, limit });

  return (
    <ModernDraggableCapsule
      key={draggableId}
      draggableId={draggableId}
      index={index}
    >
      <ModernCapsuleCounter>{dragIndex + 1}</ModernCapsuleCounter>
      <ArticleCapsule
        article={article}
        onRemove={() => onRemove(dragIndex)}
        disabled={disabled}
      />
      {dragLimit && <ModernCapsuleLimit data-position={dragLimit.position} />}
    </ModernDraggableCapsule>
  );
}

function useDragLimit({ draggableId, dragIndex, limit }) {
  const dragging = useIsDragging(draggableId);
  if (dragging) return null;
  if (dragIndex === limit) return { position: "top" };
  if (dragIndex === limit - 1) return { position: "bottom" };
  return null;
}

function DragInitializer({ children }) {
  const [dragContext, setDragContext] = useState(null);

  return (
    <DragDropContext
      onDragStart={dndManager.onDragStart}
      onDragEnd={(...args) => {
        setDragContext(null);
        dndManager.onDragEnd(...args);
      }}
      onDragUpdate={setDragContext}
    >
      <DragContextProvider value={dragContext}>{children}</DragContextProvider>
    </DragDropContext>
  );
}

export function useArticleSelectField(
  name,
  { onChangeSelector, contents, setContents, label },
) {
  const client = useApolloClient();

  const combobox = useComboboxStore();
  const search = combobox.useState("value");

  const field = useField(name);
  const addContent = (contentValue) => {
    setContents([...contents, { id: contentValue }]);
    onChangeSelector([...contents, { id: contentValue }]);
  };
  const { onChange } = field.input;

  const { data } = useSafeQuery(ArticleQuery, {
    variables: {
      limit: 20,
      filters: {
        search,
        status: { eq: "published" },
      },
    },
    skip: !search,
  });

  const items = useMemo(
    () =>
      data?.connection.nodes.filter(
        (article) => !contents.map(({ id }) => id).includes(article.id),
      ) ?? [],
    [data, contents],
  );

  const remoteData = useMemo(
    () => ({
      items,
      hasMore: false,
      totalCount: items.length,
    }),
    [items],
  );

  const select = useRemoteSelectState({
    title: label,
    data: remoteData,
    combobox,
    value: null,
    onChange: (article) => {
      if (!article) {
        onChange(null);
        return;
      }
      addContent(article.id);
    },
    labelSelector: (article) => (
      <div className="pointer-events-none flex w-64">
        <ArticleCapsule article={article} />
      </div>
    ),
    valueSelector: (article) => article.id?.toString(),
    getItem: (id) => {
      const value = client.readFragment({
        id: `Article:${id}`,
        fragment: ArticleBaseCapsule.fragments.article,
        fragmentName:
          ArticleBaseCapsule.fragments.article.definitions[0].name.value,
      });
      if (!value) {
        throw new Error(`Article not found`);
      }
      return value;
    },
  });

  return {
    select,
  };
}

function AdditionalArticleSearchField({
  placeholder,
  label,
  onChange: onChangeSelector,
  setContents,
  contents,
  disabled,
}) {
  const { select } = useArticleSelectField("article", {
    onChangeSelector,
    setContents,
    contents,
    label,
  });

  return (
    <RemoteSelect
      state={select}
      disabled={disabled}
      aria-label={label}
      scale="lg"
      placeholder={placeholder || "Ajouter un article"}
    />
  );
}

export const RelatedArticleSelector = forwardRef(
  (
    { value, placeholder, name, label, onChange, limit, multi, disabled },
    ref,
  ) => {
    const [contents, setContents] = useState(value || []);
    const contentIds = contents.map(({ id }) => id);
    const [articles, setArticles] = useState([]);
    const { data } = useSafeQuery(ArticleQuery, {
      variables: {
        limit: 100,
        filters: {
          id: { in: contentIds },
        },
      },
      skip: contents.length === 0,
    });

    useSubscription(ArticleUpdatedSubscription, {
      variables: {
        ids: contentIds,
      },
      skip: !data,
    });

    const articleSearchFieldVisible = multi
      ? Boolean(!limit) || contents.length < limit
      : contents.length < 1;

    const onChangeRef = useLiveRef(onChange);
    const handleChange = useCallback(
      (newValue) => {
        if (onChangeRef.current) onChangeRef.current(newValue);
      },
      [onChangeRef],
    );
    const moveContent = useCallback(
      (from, to) => {
        const newValue = moveItems(from, to, contents);
        setContents(newValue);
        handleChange(newValue);
      },
      [contents, handleChange],
    );

    const deleteContent = (contentIndex) => {
      const newContents = contents.filter((_, index) => index !== contentIndex);
      setContents(newContents);
      handleChange(newContents);
    };

    function onDragEnd(dropResult) {
      // dropped nowhere
      if (!dropResult.destination) {
        return;
      }
      const { source, destination } = dropResult;
      // reordering list
      if (source.index !== destination.index) {
        moveContent(source.index, destination.index);
      }
    }

    const handlerRef = useLiveRef({ onDragEnd });

    useEffect(() => {
      const {
        current: { onDragEnd },
      } = handlerRef;
      dndManager.addListener("relatedArticle", {
        onDragEnd,
      });
      return () => dndManager.removeListener("relatedArticle");
    }, [moveContent, handlerRef]);

    useEffect(() => {
      if (!data) return;
      setArticles(data.connection.nodes);
    }, [data]);

    return (
      <>
        {contents.length > 0 && (
          <DragInitializer>
            <DroppableCapsuleList
              droppableId={`CustomFieldGrid:${name}`}
              disabled={disabled}
            >
              {[...articles]
                .filter(({ id }) => contentIds.includes(id))
                .sort(
                  (a, b) => contentIds.indexOf(a.id) - contentIds.indexOf(b.id),
                )
                .map((article, index) => (
                  <ContentItem
                    key={`${article.id}`}
                    index={index}
                    article={article}
                    onRemove={deleteContent}
                    disabled={disabled}
                    limit={limit}
                    droppableId={`CustomFieldGrid:${name}`}
                  />
                ))}
            </DroppableCapsuleList>
          </DragInitializer>
        )}
        {articleSearchFieldVisible && (
          <AdditionalArticleSearchField
            ref={ref}
            placeholder={placeholder}
            label={label}
            name={name}
            onChange={handleChange}
            setContents={setContents}
            contents={contents}
            disabled={disabled}
          />
        )}
      </>
    );
  },
);
