import { createContext, useContext, useMemo } from "react";

import { Queue } from "@/services/queue";

const QueueContext = createContext();

export const QueueProvider = ({ children, concurrent = 5 }) => {
  const queue = useMemo(
    () =>
      new Queue({
        concurrent,
      }),
    [concurrent],
  );

  return (
    <QueueContext.Provider value={{ queue }}>{children}</QueueContext.Provider>
  );
};

export const useQueue = () => {
  const ctx = useContext(QueueContext);

  if (!ctx) {
    throw Error("The `useQueue` hook must be called with a `QueueProvider`.");
  }

  return ctx;
};
