import {
  Popover as AriakitPopover,
  PopoverProps as AriakitPopoverProps,
  PopoverState as AriakitPopoverState,
  PopoverStateProps as AriakitPopoverStateProps,
  usePopoverState as useAriakitPopoverState,
} from "ariakit/popover";
import clsx from "clsx";
import * as React from "react";
import { forwardRef, useLayoutEffect, useRef } from "react";

import { useNextFrameTicked } from "./motion/Animation";
import { useLiveRef } from "./utils/useLiveRef";

export { PopoverDisclosure } from "ariakit/popover";

export interface PopoverStateProps extends AriakitPopoverStateProps {
  /**
   * Ignore any resize or scroll. The position of the popover will not be updated after it is shown.
   * @default false
   */
  ignoreUpdate?: boolean;
}

export interface PopoverState extends AriakitPopoverState {
  /**
   * Ignore any resize or scroll. The position of the popover will not be updated after it is shown.
   */
  ignoreUpdate: boolean;
}

export const usePopoverState = ({
  ignoreUpdate = false,
  getAnchorRect,
  gutter,
  ...props
}: PopoverStateProps = {}): PopoverState => {
  const persistedAnchorRef = useRef<DOMRect | null>(null);
  const state = useAriakitPopoverState({
    ...props,
    gutter: gutter ?? 4,
    getAnchorRect: ignoreUpdate
      ? (anchor) => {
          if (getAnchorRect) return getAnchorRect(anchor);
          if (persistedAnchorRef.current === null && anchor) {
            persistedAnchorRef.current = anchor.getBoundingClientRect();
          }
          return persistedAnchorRef.current;
        }
      : getAnchorRect,
  }) as PopoverState;
  useLayoutEffect(() => {
    if (state.open) {
      persistedAnchorRef.current = null;
    }
  }, [state.open]);
  state.ignoreUpdate = ignoreUpdate;
  return state;
};

export type PopoverCardProps = React.HTMLAttributes<HTMLDivElement>;

export const PopoverCard = forwardRef<HTMLDivElement, PopoverCardProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          "z-popover select-none rounded-md border border-grey-border-light bg-white shadow-md focus:outline-none",
        )}
        {...props}
      />
    );
  },
);

interface PopoverProps extends AriakitPopoverProps {
  lazy?: boolean;
}

const NestedContext = React.createContext<{
  disableHideOnInteractOutside: () => void;
  resetHideOnInteractOutside: () => void;
} | null>(null);

export const Popover = forwardRef<HTMLDivElement, PopoverProps>(
  (
    {
      lazy = true,
      modal,
      hideOnInteractOutside: initialHideOnInteractOutside,
      ...props
    },
    ref,
  ) => {
    const ticked = useNextFrameTicked({ skip: !lazy });

    const [hideOnInteractOutside, setHideOnInteractOutside] = React.useState(
      () => initialHideOnInteractOutside,
    );
    const initialHideOnInteractOutsideRef = useLiveRef(
      initialHideOnInteractOutside,
    );
    const nestedValue = React.useMemo(() => {
      return {
        disableHideOnInteractOutside: () => {
          setHideOnInteractOutside(false);
        },
        resetHideOnInteractOutside: () => {
          setHideOnInteractOutside(initialHideOnInteractOutsideRef.current);
        },
      };
    }, [initialHideOnInteractOutsideRef]);

    const parent = React.useContext(NestedContext);
    const parentRef = useLiveRef(parent);
    React.useEffect(() => {
      const { current: parent } = parentRef;
      if (!parent) return;
      if (props.state.open) {
        parent.disableHideOnInteractOutside();
      } else {
        parent.resetHideOnInteractOutside();
      }
    }, [props.state.open, parentRef]);
    if (lazy && !ticked) return null;
    return (
      <NestedContext.Provider value={nestedValue}>
        <AriakitPopover
          ref={ref}
          as={PopoverCard}
          focusable={false}
          modal={modal}
          backdrop={false}
          hideOnInteractOutside={hideOnInteractOutside}
          {...props}
        />
      </NestedContext.Provider>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Popover.displayName = "Popover";
}
