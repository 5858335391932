import { forwardRef } from "react";

const SvgExpand = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M10.687 6.992c0-.092.026-.164.079-.217L14.23 3.31l-2.106-2.106c-.053-.052-.073-.105-.06-.157.013-.053.059-.085.138-.098L19.76.005c.079-.013.138 0 .177.04a.214.214 0 0 1 .059.197l-.945 7.538c-.013.079-.045.125-.098.138-.052.026-.105.013-.157-.04L16.69 5.772l-3.464 3.444a.338.338 0 0 1-.434 0l-2.027-2.027a.268.268 0 0 1-.079-.197zM0 19.627c0 .066.02.125.059.177a.292.292 0 0 0 .177.059l7.558-.964c.079-.013.125-.046.138-.098.013-.04-.001-.086-.04-.138l-2.106-2.126 3.444-3.464c.053-.052.079-.118.079-.197s-.026-.151-.079-.216l-2.027-2.028c-.053-.052-.118-.078-.197-.078s-.151.026-.217.078l-3.464 3.445-2.106-2.087c-.052-.052-.105-.072-.157-.06-.053.014-.085.06-.098.139z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgExpand);
export default ForwardRef;
