import { forwardRef } from "react";

const SvgList = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.814 5.198V2.679a.57.57 0 0 1 .155-.388.528.528 0 0 1 .388-.155h13.081c.155 0 .291.052.407.155a.528.528 0 0 1 .155.388v2.519a.53.53 0 0 1-.542.543H6.357a.57.57 0 0 1-.388-.155.53.53 0 0 1-.155-.388m0 6.338v-2.52c0-.155.052-.284.155-.387a.496.496 0 0 1 .388-.175h13.081c.155 0 .291.052.407.155a.574.574 0 0 1 .155.388v2.519a.53.53 0 0 1-.542.543H6.357a.612.612 0 0 1-.388-.136.525.525 0 0 1-.155-.387m0 6.298v-2.5c0-.142.052-.271.155-.388a.528.528 0 0 1 .388-.155h13.081c.155 0 .291.052.407.155a.53.53 0 0 1 .155.388v2.5a.595.595 0 0 1-.155.407.525.525 0 0 1-.387.155H6.357a.57.57 0 0 1-.388-.155.551.551 0 0 1-.155-.407M0 3.977c0-.543.194-1.008.581-1.396A1.878 1.878 0 0 1 1.958 2c.53 0 .995.194 1.395.581.388.388.582.846.582 1.376 0 .543-.194 1.008-.582 1.396-.374.388-.833.581-1.376.581S.969 5.741.581 5.353A1.842 1.842 0 0 1 0 3.977m0 6.337c0-.529.194-.994.581-1.395.388-.388.847-.582 1.377-.582s.995.188 1.395.562c.388.401.582.866.582 1.396 0 .543-.194 1.008-.582 1.396a1.878 1.878 0 0 1-1.376.581 1.906 1.906 0 0 1-1.396-.581A1.881 1.881 0 0 1 0 10.314m0 6.319c0-.53.194-.989.581-1.377.388-.4.847-.6 1.377-.6.542 0 1.007.194 1.395.581.388.4.582.866.582 1.396 0 .542-.194 1.007-.582 1.395-.374.375-.833.562-1.376.562s-1.008-.187-1.396-.562A1.903 1.903 0 0 1 0 16.633"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgList);
export default ForwardRef;
