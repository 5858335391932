/* eslint-disable react/prop-types */
import styled from "@xstyled/styled-components";
import { Fragment, forwardRef } from "react";

const InnerFieldError = styled.spanBox`
  font-family: accent;
  color: danger-dark;
`;

function formatErrorList(errorOrErrors) {
  if (!errorOrErrors) return [];
  if (!Array.isArray(errorOrErrors)) return [errorOrErrors];

  let errors = [];
  let arrayErrors = [];
  errorOrErrors.forEach((error) => {
    if (typeof error === "object") {
      arrayErrors = [
        ...arrayErrors,
        ...(error
          ? Object.entries(error).map(([key, value]) => `"${key}": ${value}`)
          : {}),
      ];
      return;
    }
    errors = [...errors, error];
  });

  return [...new Set(arrayErrors), ...errors];
}

function formatErrors({ submitError, error }) {
  return [...formatErrorList(submitError), ...formatErrorList(error)];
}

const getErrors = (state) => {
  // fields with initial values and maxLength or minLength validators
  // should display errors right away without touching the field
  if (state.maxLength || state.minLength) return formatErrors(state.field.meta);
  return state.field.meta.touched ? formatErrors(state.field.meta) : [];
};

export const FieldError = forwardRef(({ state, ...props }, ref) => {
  const errors = getErrors(state);

  return errors.length > 0 ? (
    <InnerFieldError ref={ref} data-field-error role="alert" {...props}>
      {errors.every((error) => typeof error === "string")
        ? errors.join(", ")
        : errors.map((error, index) => (
            <Fragment key={index}>{error}</Fragment>
          ))}
    </InnerFieldError>
  ) : null;
});
