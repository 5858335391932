import { forwardRef } from "react";

const SvgTimesCircleEmpty = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.269 7.394a.39.39 0 0 1 .115-.288l1.705-1.705a.364.364 0 0 1 .287-.134c.116 0 .218.038.307.115L10.001 7.7l2.318-2.299c.077-.076.173-.114.288-.114s.21.031.287.095l1.724 1.705c.077.09.115.192.115.306a.39.39 0 0 1-.115.288L12.3 9.999l2.299 2.318c.09.077.134.173.134.288s-.038.21-.115.287l-1.705 1.705c-.076.09-.172.134-.287.134s-.217-.038-.306-.115l-2.319-2.318-2.318 2.318c-.076.077-.172.115-.287.115s-.211-.038-.288-.115l-1.705-1.704c-.076-.077-.115-.173-.115-.288s.032-.211.096-.288L7.702 10 5.403 7.681a.362.362 0 0 1-.134-.287zm-1.628 6.303a7.493 7.493 0 0 0 2.682 2.682 7.208 7.208 0 0 0 3.678.977 7.135 7.135 0 0 0 3.697-.996 7.486 7.486 0 0 0 2.682-2.682A7.2 7.2 0 0 0 17.357 10c0-1.341-.332-2.574-.996-3.698s-1.558-2.018-2.682-2.682a7.21 7.21 0 0 0-3.678-.977 7.133 7.133 0 0 0-3.698.997 7.488 7.488 0 0 0-2.681 2.681A7.202 7.202 0 0 0 2.645 10c0 1.34.332 2.573.996 3.697zm-2.28-8.736a9.767 9.767 0 0 1 3.64-3.64 9.857 9.857 0 0 1 5-1.321c1.813 0 3.493.447 5.038 1.341 1.545.907 2.746 2.107 3.601 3.601A9.88 9.88 0 0 1 20 10a9.887 9.887 0 0 1-1.341 5.038A9.943 9.943 0 0 1 15 18.658 9.824 9.824 0 0 1 10 20a9.912 9.912 0 0 1-5.038-1.36A9.817 9.817 0 0 1 1.342 15 9.81 9.81 0 0 1 0 10a9.926 9.926 0 0 1 1.361-5.039z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgTimesCircleEmpty);
export default ForwardRef;
