import { useId } from "ariakit-react-utils/hooks";
import { createContext, useContext } from "react";

import { Draggable } from "@/components/Draggable";
import { DND_TYPES } from "@/config/constants";

const ModernDraggableCapsuleContext = createContext();

export function ModernDraggableCapsule({
  draggableId,
  index,
  onMouseDown,
  children,
}) {
  const labelledBy = useId();
  return (
    <ModernDraggableCapsuleContext.Provider value={labelledBy}>
      <Draggable
        draggableId={draggableId}
        index={index}
        type={DND_TYPES.CAPSULE}
      >
        {(provided, { isDragging }) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            ref={provided.innerRef}
            className="relative mb-4 flex gap-2 outline-0 first:mt-2"
            data-testid="draggable-capsule"
            data-dragging={isDragging}
            onMouseDown={onMouseDown}
            aria-labelledby={labelledBy}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          >
            {children}
          </div>
        )}
      </Draggable>
    </ModernDraggableCapsuleContext.Provider>
  );
}

export const useModernDraggableCapsuleLabelId = () => {
  const id = useContext(ModernDraggableCapsuleContext);
  return id ?? null;
};
