import clsx from "clsx";
import React from "react";

export type ToastContainerProps = {
  position?: "top" | "bottom";
  children: React.ReactNode;
};

export const ToastContainer = ({ position, children }: ToastContainerProps) => {
  return (
    <div
      className={clsx(
        "fixed left-1/2 z-toast flex w-max -translate-x-1/2 transform flex-col items-center",
        `data-[position="top"]:top-6 data-[position="bottom"]:bottom-6`,
      )}
      data-position={position}
    >
      {children}
    </div>
  );
};
