import { gql } from "@apollo/client";

import {
  ArticleSectionsEdit,
  ArticleSectionsEditProps,
} from "@/containers/article/ArticleSectionsEdit";
import type { CellType } from "@/containers/list/ListState";

export type SectionsCellArticle = ArticleSectionsEditProps["article"];

export const sectionsCell: CellType<SectionsCellArticle> = {
  key: "sections",
  title: "Services",
  width: 96,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleSectionsEdit article={node} />,
  fragment: gql`
    fragment sectionsCell_article on Article {
      ...ArticleSectionsEdit_article
    }

    ${ArticleSectionsEdit.fragments.article}
  `,
};
