import { forwardRef } from "react";

const SvgArrowDownCircleEmpty = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.135 9.311c.025-.077.076-.115.153-.115h2.261V5.537c0-.115.038-.211.115-.287a.387.387 0 0 1 .287-.115h4.081c.115 0 .217.038.306.115a.365.365 0 0 1 .115.268v3.659h2.203c.077 0 .128.044.153.134a.296.296 0 0 1-.038.211l-4.617 6.072c-.115.115-.23.109-.345-.019L5.192 9.54c-.063-.076-.082-.153-.057-.23zM2.644 10a7.13 7.13 0 0 0 .997 3.698 7.499 7.499 0 0 0 2.681 2.682 7.211 7.211 0 0 0 3.679.977 7.13 7.13 0 0 0 3.697-.997 7.499 7.499 0 0 0 2.682-2.681A7.211 7.211 0 0 0 17.357 10c0-1.34-.332-2.573-.996-3.697a7.51 7.51 0 0 0-2.682-2.682 7.21 7.21 0 0 0-3.678-.977 7.139 7.139 0 0 0-3.698.996 7.501 7.501 0 0 0-2.682 2.682A7.21 7.21 0 0 0 2.644 10zM.001 10c0-1.788.453-3.467 1.36-5.038a9.76 9.76 0 0 1 3.64-3.64 9.858 9.858 0 0 1 5-1.322 9.88 9.88 0 0 1 5.038 1.342c1.545.906 2.746 2.107 3.601 3.601A9.88 9.88 0 0 1 20 10a9.89 9.89 0 0 1-1.341 5.039c-.894 1.519-2.114 2.726-3.659 3.62S11.788 20 10 20a9.9 9.9 0 0 1-5.038-1.36A9.797 9.797 0 0 1 1.341 15 9.816 9.816 0 0 1 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowDownCircleEmpty);
export default ForwardRef;
