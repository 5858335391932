import { forwardRef } from "react";

const SvgImageAlignLeft = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M12.174 3.87H20V3h-7.826zm0 1.739H20v-.87h-7.826zm0 1.762v-.87H20v.846zm0 1.716H20v-.87h-7.826zm0 1.739H20v-.87h-7.826zm0 1.739H20v-.87h-7.826zM1.67 5.632c0 .244.084.457.252.64a.932.932 0 0 0 .687.275c.259 0 .48-.092.663-.275a.873.873 0 0 0 .275-.64.98.98 0 0 0-.275-.687c-.183-.183-.404-.275-.663-.275s-.48.092-.663.275-.275.412-.275.687zm-.8 4.759V3.87h8.673v4.165L8.032 6.479l-.114-.114c-.229-.214-.473-.321-.732-.321a.791.791 0 0 0-.458.16 1.053 1.053 0 0 0-.321.23l-.046.046-.297.343c-.534.61-.961 1.076-1.282 1.396l.092.046c.229.137.473.374.732.709.259.336.397.588.412.755.016.122-.076.122-.274 0h-.023a10.826 10.826 0 0 0-1.785-.892l-.092-.046-.022-.023-.138-.046-.183-.069zm-.87.938c0 .092.038.176.114.252s.16.114.252.114h9.68a.373.373 0 0 0 .274-.114.347.347 0 0 0 .115-.252v-7.94a.427.427 0 0 0-.092-.275.426.426 0 0 0-.275-.091H.389c-.122 0-.214.03-.275.091-.076.061-.114.153-.114.275zm0 2.976h20v-.87H0zm0 1.739h20v-.87H0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImageAlignLeft);
export default ForwardRef;
