import { forwardRef } from "react";

const SvgPowerOff = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M8.226 7.664V.858a.78.78 0 0 1 .259-.599A.823.823 0 0 1 9.084 0h1.697c.24 0 .446.079.619.239a.86.86 0 0 1 .239.599v6.826a.86.86 0 0 1-.239.599.86.86 0 0 1-.599.239H9.104a.885.885 0 0 1-.619-.239.887.887 0 0 1-.259-.599zM1 11.078c0-1.584.393-3.061 1.178-4.431a8.798 8.798 0 0 1 3.194-3.254c.319-.24.592-.333.818-.28.226.04.339.227.339.559v1.517c0 .293-.066.506-.199.639-.04.04-.107.086-.2.14l-.159.1a6.465 6.465 0 0 0-1.776 2.235 6.156 6.156 0 0 0-.639 2.755c0 .851.166 1.67.499 2.455a6.337 6.337 0 0 0 3.433 3.453 6.46 6.46 0 0 0 2.455.479c.838 0 1.657-.16 2.455-.479a6.422 6.422 0 0 0 2.056-1.378 6.431 6.431 0 0 0 1.876-4.51c0-.971-.206-1.89-.619-2.755a6.618 6.618 0 0 0-1.756-2.215.764.764 0 0 0-.14-.08c-.067-.054-.12-.1-.16-.14-.2-.16-.3-.386-.3-.679V3.632c0-.199.054-.346.16-.439.2-.146.506-.093.919.159a8.868 8.868 0 0 1 3.253 3.254 8.686 8.686 0 0 1 1.198 4.451 8.894 8.894 0 0 1-.699 3.453 8.502 8.502 0 0 1-1.916 2.875 8.716 8.716 0 0 1-2.895 1.936c-1.104.452-2.249.679-3.433.679s-2.328-.227-3.433-.679a8.93 8.93 0 0 1-2.874-1.916 8.973 8.973 0 0 1-1.936-2.895A8.746 8.746 0 0 1 1 11.077z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgPowerOff);
export default ForwardRef;
