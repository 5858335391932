import { forwardRef } from "react";

const SvgArrowFork = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M.004 11.178h5.658l.214.019c.351.078.683.234.995.468l3.843 3.98c.156.157.39.339.702.546.312.195.579.339.8.43a5.537 5.537 0 0 0 1.737.39h.916v2.614l.644-.488c.078-.065.195-.156.351-.273.143-.117.241-.195.293-.234l2.536-1.99L20 15.606a98.59 98.59 0 0 1-1.288-1.014 31.1 31.1 0 0 0-.546-.43 15.198 15.198 0 0 0-.722-.565 15.024 15.024 0 0 1-.702-.566l-.546-.429-1.307-1.034v2.634h-.937a2.087 2.087 0 0 1-.683-.156 1.34 1.34 0 0 1-.527-.332L8.957 9.773l3.785-3.94c.247-.169.429-.286.546-.351a.506.506 0 0 1 .195-.059l.371-.078h1.014v2.731c.533-.403 1.041-.8 1.522-1.19l1.502-1.189a1.3 1.3 0 0 1 .097-.079c.065-.052.117-.097.157-.136l.273-.215 1.014-.8c.026-.025.208-.175.546-.448L17.17 1.795a2.93 2.93 0 0 1-.235-.176 2.735 2.735 0 0 0-.234-.175.988.988 0 0 0-.137-.098l-.546-.449L14.886 0v2.537h-.936c-.56 0-1.138.136-1.737.409-.611.235-1.105.553-1.483.957l-3.824 3.96-.253.176c-.339.221-.644.331-.917.331H0v2.81z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgArrowFork);
export default ForwardRef;
