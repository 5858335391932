import { gql, useApolloClient, useMutation } from "@apollo/client";
import deepEqual from "deep-equal";
import { useMemo } from "react";
import { useForm } from "react-final-form";
import { Button } from "swash/Button";
import { useComboboxStore } from "swash/v2/Combobox";
import { RemoteSelectList, useRemoteSelectState } from "swash/v2/RemoteSelect";
import { RichSelectValue } from "swash/v2/RichSelect";
import { Select, SelectClear, SelectPopover } from "swash/v2/Select";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SelectField, useSelectField } from "@/components/fields/SelectField";
import {
  TextInputField,
  useTextInputField,
} from "@/components/fields/TextInputField";
import { Form } from "@/components/forms/Form";
import { useSafeQuery } from "@/containers/Apollo";

const CreateKeywordMutation = gql`
  mutation CreateKeywordMutation($input: CreateKeywordInput!) {
    createKeyword(input: $input) {
      id
      label
    }
  }
`;

function CreateKeywordFormContent() {
  const field = useTextInputField("label");

  return (
    <div className="flex items-center gap-2 p-2">
      <div className="w-36">
        <TextInputField
          {...field}
          placeholder="Nouveau tag"
          tabIndex={-1}
          autoComplete="off"
          onKeyDown={(e) => e.stopPropagation()}
          required
          maxLength={255}
        />
      </div>
      <Button type="submit" scale="sm" className="shrink-0">
        Créer
      </Button>
    </div>
  );
}

function SelectControl({
  query,
  searchable,
  fragment,
  parse,
  getItem,
  placeholder,
  required,
  ...props
}) {
  const combobox = useComboboxStore();
  const search = combobox.useState("value");

  const snippetForm = useForm();
  const [createKeyword] = useMutation(CreateKeywordMutation);

  useSafeQuery(query, {
    variables: {
      value: parse(props.value),
    },
  });

  const searchQuery = useSafeQuery(query, {
    variables: {
      search,
    },
  });

  const items = useMemo(
    () => searchQuery.data?.connection.nodes ?? [],
    [searchQuery.data?.connection.nodes],
  );

  const data = useMemo(
    () => ({
      items,
      hasMore: false,
      totalCount: items.length,
    }),
    [items],
  );

  const select = useRemoteSelectState({
    value: props.value,
    onChange: props.onChange,
    data,
    combobox,
    labelSelector: (item) => item.label,
    valueSelector: (item) => item.id?.toString(),
    appearance: "chip",
    getItem: (id) => getItem(id),
  });

  return (
    <>
      <Select store={select.select} aria-label="Tags" scale="lg">
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap">
          <RichSelectValue
            state={select}
            scale="lg"
            placeholder="Insérer un tag ici..."
          />
        </div>
        <SelectClear store={select.select} />
      </Select>
      <SelectPopover store={select.select} aria-label="Tags">
        <Form
          onSubmit={async (values, form) => {
            if (!values.label) return;
            const { data } = await createKeyword({
              variables: {
                input: {
                  label: values.label,
                },
              },
            });
            await searchQuery.refetch();
            snippetForm.change(
              "keywordIds",
              parse([...props.value, data.createKeyword]),
            );
            setTimeout(form.reset);
          }}
        >
          <CreateKeywordFormContent />
        </Form>
        <RemoteSelectList state={select} placeholder="Tags..." />
      </SelectPopover>
    </>
  );
}

export function KeywordsField({
  name,
  query,
  fragment,
  required,
  searchable,
  label,
  hint,
  placeholder,
  ...others
}) {
  const client = useApolloClient();
  const getItem = (id) => {
    const value = client.readFragment({
      id: `Keyword:${id}`,
      fragment: fragment,
    });
    if (!value) {
      throw new Error("No Keyword found");
    }
    return value;
  };
  const format = (v) => (v || []).map((id) => getItem(id));
  const parse = (v) => (v || []).map((v) => v.id);
  const field = useSelectField(name, {
    required,
    format,
    parse,
    isEqual: deepEqual,
    ...others,
  });

  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <SelectField
        {...field}
        as={SelectControl}
        query={query}
        getItem={getItem}
        fragment={fragment}
        searchable={searchable}
        parse={parse}
        label={label}
        placeholder={placeholder}
      />
    </FieldGroup>
  );
}
