// eslint-disable-next-line @lemonde/import/no-illegal-import
import { gql } from "@apollo/client";

import { ArticleNode } from "@/containers/editor/nodes/ArticleNode";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { ArticleNodeFragments } from "@/containers/editor/nodes/ArticleNodeFragments";
import { fetchMedia } from "@/services/medias/fetchMedia";

import {
  AtomicBlock,
  matchAtomicBlock,
  useAtomicBlockState,
} from "../utils/AtomicUtils";

export const name = "article";
export const ATOMIC_ENTITY_TYPE = "ARTICLE";

const ArticleQuery = gql`
  query RichEditorArticlePlugin_article($id: Int!) {
    node: article(id: $id) {
      id
      ...ArticleNode_article__expanded
    }
  }
  ${ArticleNodeFragments.article__expanded}
`;

function ArticleBlock(props) {
  const atomic = useAtomicBlockState({
    props,
    query: ArticleQuery,
    getEntityData: (article, entityData) => ({
      title: entityData.metadata?.title,
      prefix: entityData.metadata?.prefix,
      displayMode: entityData?.displayMode,
    }),
  });

  return (
    <AtomicBlock {...atomic}>
      {({ node, metadata, update }) => (
        <ArticleNode
          article={node}
          metadata={metadata}
          onUpdate={update}
          expanded={props.blockProps.state.expanded}
        />
      )}
    </AtomicBlock>
  );
}

export const matchBlock = matchAtomicBlock(ATOMIC_ENTITY_TYPE);

export const blockRendererFn = () => {
  return { component: ArticleBlock, editable: false };
};

export function validateOptions(options) {
  if (!options.getDataFromUrl) {
    throw new Error(`getDataFromUrl is required`);
  }
  return options;
}

function getDataFromUrls({ options }, urls) {
  return urls.map((url) => options.getDataFromUrl(url)).find(Boolean) || null;
}

export const handleUrls = (state, urls) => {
  const data = getDataFromUrls(state, urls);
  return data ? "handled" : "not-handled";
};

const ArticleUrlQuery = gql`
  query RichEditorArticlePluginUrl_article(
    $id: Int
    $url: String
    $type: String!
  ) {
    media(id: $id, url: $url, type: $type) {
      id
      ... on Article {
        url
        title
      }
    }
  }
`;

export const createBlockFromUrls = async (state, urls) => {
  const data = getDataFromUrls(state, urls);
  const [url] = urls;

  if (!data) return null;

  const article = await fetchMedia({
    id: data.id,
    url,
    type: "articles",
    query: ArticleUrlQuery,
  });

  if (!article) return null;

  return {
    type: "atomic",
    entity: {
      type: ATOMIC_ENTITY_TYPE,
      mutability: "IMMUTABLE",
      data: {
        id: article.id,
        media: {
          id: article.id,
          url: article.url,
          title: article.title,
        },
      },
    },
  };
};
