/* eslint-disable no-alert */
import { useEffect, useRef } from "react";
import { useBlocker } from "react-router-dom";

export const useNativePrompt = ({ message, when }) => {
  const ref = useRef({ message, when });
  ref.current.message = message;
  ref.current.when = when;
  useEffect(() => {
    const handler = (event) => {
      if (!ref.current.when) return;

      // spec.
      event.preventDefault();

      // not the spec. but required by Chrome
      // eslint-disable-next-line no-param-reassign
      event.returnValue = ref.current.message;
    };
    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);
};

const RouterPrompt = ({ message, when }) => {
  // navigation should be blocked only if it's unable to trigger a save (e.g. a form without auto-save)
  let blocker = useBlocker(when);

  useEffect(() => {
    if (blocker.state === "blocked") {
      let proceed = window.confirm(message);
      if (proceed) {
        // This timeout is needed to avoid a weird "race" on POP navigations
        // between the `window.history` revert navigation and the result of
        // `window.confirm`
        setTimeout(blocker.proceed, 0);
      } else {
        blocker.reset();
      }
    }
  }, [blocker, message]);

  useEffect(() => {
    if (blocker.state === "blocked" && !when) {
      blocker.reset();
    }
  }, [blocker, when]);

  return null;
};

export const Prompt = ({ message, when, shouldBlock = false }) => {
  useNativePrompt({ message, when, shouldBlock });
  return shouldBlock ? <RouterPrompt message={message} when={when} /> : null;
};
