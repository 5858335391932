import clsx from "clsx";
import type { EditorState } from "draft-js-es";

import { addDecorator } from "@/components/rich-editor/modifiers/addDecorator";
import {
  ComposeEntity,
  CompositeEntityDecoratorProps,
  compositeEntityHandler,
  isActive,
  useCompositeEntityDecoratorState,
  withCompositeEntities,
} from "@/components/rich-editor/utils/CompositeEntity";

import type { State } from "../utils/PluginPopover";
import { getTextFromSelection } from "../utils/Selection";
import { slugify } from "../utils/Slugify";
import { COMPOSE_ENTITY_TYPE as ANCHOR_ENTITY_TYPE } from "./anchor-convert";

export * from "./anchor-convert";

const PREFIX = "huit-anchor-";

export const name = "anchor";
export const command = "anchor";

const generateAnchorId = (text: string) => {
  return PREFIX + slugify(text.slice(0, 30));
};

const { handleEntityKeyCommand } = compositeEntityHandler({
  command,
  composeEntityType: ANCHOR_ENTITY_TYPE,
  getComposeEntityData: ({ state, selection }) => {
    const contentState = state.editorState.getCurrentContent();
    const text = getTextFromSelection(contentState, selection);
    return { id: generateAnchorId(text) };
  },
});

type AnchorDecoratorProps = CompositeEntityDecoratorProps;

const AnchorDecorator = ({
  entityKey,
  contentState,
  children,
  filter,
}: AnchorDecoratorProps) => {
  const { entities, props } = useCompositeEntityDecoratorState({
    entityKey,
    filter,
    contentState,
  });
  const [anchorEntity] = entities;

  if (!anchorEntity) return children;
  return (
    <span
      className={clsx(
        `border-b border-dashed border-blue-border transition-all
        data-[focused]:rounded-t-sm data-[hovered]:border-b-2
        data-[entity-types*="link"]:data-[hovered]:border-b-[3px]
        data-[focused]:bg-blue-bg-hover-transparent`,
      )}
      id={anchorEntity["id"]}
      {...props}
    >
      {children}
    </span>
  );
};

const filter = ({ type }: ComposeEntity) => type === ANCHOR_ENTITY_TYPE;

export const getInitialEditorState = ({
  editorState,
}: {
  editorState: EditorState;
}) => {
  return addDecorator(
    editorState,
    withCompositeEntities({
      filter,
      component: AnchorDecorator,
    }),
  );
};

export const checkIsActive = (state: State) => {
  return isActive(ANCHOR_ENTITY_TYPE, state);
};

export const handleKeyCommand = (state: State, cmd: string) => {
  return handleEntityKeyCommand({
    state,
    cmd,
  });
};
