import styled from "@xstyled/styled-components";
import { memo } from "react";
import { useField } from "react-final-form";
import {
  Select,
  SelectBadgeValue,
  SelectCaret,
  SelectClearButton,
  SelectDisclosure,
  SelectMenu,
  SelectOption,
  SelectPlaceholder,
  SelectSearch,
  SelectValue,
  useSelectState,
} from "swash/Select";

import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { SelectField, useSelectField } from "@/components/fields/SelectField";
import { EnumFieldCheckboxControl } from "@/containers/admin/CRUD/fields/EnumField";
import { EllipsisSelectValue } from "@/containers/search/filterFields/util";

export const getFormatters = ({ multi }) => {
  if (multi) {
    return {
      format: (value) => (value ? value.in : []),
      parse: (value) => (value && value.length ? { in: value } : null),
    };
  }
  return {
    format: (value) => (value ? value.eq : null),
    parse: (value) => (value ? { eq: value } : null),
  };
};

const CheckboxFieldLabel = styled(FieldLabel)`
  &:after {
    content: "\u00A0:";
  }
`;

export function CheckboxEnumFilterField({
  name,
  label,
  enum: enumValues,
  multi = true,
  sortEntries = ([, labelA], [, labelB]) => labelA.localeCompare(labelB),
  w,
  scale,
  variant,
  ...others
}) {
  const field = useSelectField(name, {
    orientation: "horizontal",
    ...getFormatters({ multi }),
    ...others,
  });
  return (
    <Select>
      <FieldGroup {...field} mr={2}>
        <CheckboxFieldLabel {...field}>{label}</CheckboxFieldLabel>
        <SelectField
          {...field}
          as={EnumFieldCheckboxControl}
          enum={enumValues}
          multi={multi}
          sortEntries={sortEntries}
          variant={variant}
        />
      </FieldGroup>
    </Select>
  );
}

export function SelectEnumFilterField({
  name,
  label,
  enum: enumValues,
  searchable = false,
  clearable = true,
  multi = true,
  disabled,
  labelSelector = (label) => label,
  parseValue = (value) => value,
  renderOptionLabel = ({ label }) => label,
  sortEntries = ([, labelA], [, labelB]) => labelA.localeCompare(labelB),
  ...others
}) {
  const field = useField(name, {
    ...getFormatters({ multi }),
    ...others,
  });

  const select = useSelectState({
    value: field.input.value,
    onChange: field.input.onChange,
    clearable,
    searchable,
    multi,
    disabled,
  });

  return (
    <Select>
      <SelectDisclosure {...select} scale="base">
        {multi ? (
          <SelectBadgeValue scale="base" {...select}>
            {({ value }) =>
              value.map((v) => labelSelector(enumValues[v])).join(", ")
            }
          </SelectBadgeValue>
        ) : (
          <SelectValue as={EllipsisSelectValue} {...select}>
            {({ value }) =>
              renderOptionLabel({
                value,
                label: labelSelector(enumValues[value]),
              })
            }
          </SelectValue>
        )}
        {!searchable && (
          <SelectPlaceholder {...select} visible={multi}>
            {label}
          </SelectPlaceholder>
        )}
        {searchable && <SelectSearch {...select} placeholder={label} />}
        <SelectCaret {...select} />
        <SelectClearButton {...select} />
      </SelectDisclosure>
      <SelectMenu {...select} aria-label={label}>
        {Object.entries(enumValues)
          .sort(sortEntries)
          .filter(([, value]) =>
            searchable
              ? labelSelector(value)
                  .toLowerCase()
                  .includes(select.state.search.value.toLowerCase())
              : true,
          )
          .map(([key, value]) => (
            <SelectOption key={key} {...select} value={parseValue(key)}>
              {renderOptionLabel({
                value: parseValue(key),
                label: labelSelector(value),
              })}
            </SelectOption>
          ))}
      </SelectMenu>
    </Select>
  );
}

export const EnumFilterField = memo(({ appearance = "select", ...others }) => {
  switch (appearance) {
    case "select":
      return <SelectEnumFilterField {...others} />;
    case "checkbox":
      return <CheckboxEnumFilterField {...others} />;
    default:
      return null;
  }
});
