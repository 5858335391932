import { gql } from "@apollo/client";
import moment from "moment-timezone";
import { memo } from "react";
import { IoDocumentOutline } from "swash/Icon";

import { Time } from "@/components/Time";
import { EventStatusIcon } from "@/containers/events/EventStatusIcon";

import { ChannelsAlertFragment, channels } from "./channels";

export const getAlertStatus = (alert) => {
  const [event] = alert.prePublishedEvent.nodes;
  // Without an event, we are "pending"
  if (!event) return "pending";
  // We trust the event status until it is "success"
  if (event.status !== "success") {
    return event.status;
  }
  // Filter active deliveries
  const deliveries = alert.deliveries.filter((delivery) => {
    const channel = channels.find(
      (channel) => channel.type === delivery.channel,
    );
    if (!channel) return false;
    return alert[channel.fieldName];
  });
  // If one of the delivery is still "in_progress" or "queued", then it is "in_progress"
  if (deliveries.some((delivery) => delivery.status !== "completed")) {
    return "in_progress";
  }
  // If one of the delivery is not in "success", then it is "failure"
  if (deliveries.some((delivery) => delivery.conclusion !== "success")) {
    return "failure";
  }
  // If everything goes right, it is "success"
  return "success";
};

export const AlertStatus = memo(({ status, createdAt, publishedAt }) => {
  if (status === "pending" || !publishedAt) {
    return (
      <>
        {status === "pending" ? (
          <IoDocumentOutline
            className="inline-block"
            style={{ verticalAlign: "-10%" }}
          />
        ) : (
          <EventStatusIcon status={status} className="align-[-15%]" />
        )}{" "}
        Brouillon créé le{" "}
        <Time date={createdAt}>
          {moment.tz(createdAt, "Europe/Paris").format("DD/MM/YYYY à HH:mm")}
        </Time>
      </>
    );
  }
  return (
    <>
      <EventStatusIcon status={status} className="align-[-15%]" /> Publiée le{" "}
      <Time date={publishedAt}>
        {moment.tz(publishedAt, "Europe/Paris").format("DD/MM/YYYY à HH:mm")}
      </Time>
    </>
  );
});

const EventFragment = gql`
  fragment getAlertStatus__event on Event {
    id
    status
    alertId
  }
`;

getAlertStatus.fragments = {
  _event: EventFragment,
  alert: gql`
    fragment getAlertStatus_alert on Alert {
      prePublishedEvent: events(
        limit: 1
        where: { action: { eq: "prePublish" } }
      ) {
        nodes {
          id
          ...getAlertStatus__event
        }
      }
      deliveries {
        id
        channel
        status
        conclusion
      }
      ...ChannelsAlertFragment
    }

    ${EventFragment}
    ${ChannelsAlertFragment}
  `,
};
