import moment, { DurationInputArg1, DurationInputArg2 } from "moment";
import React, { useMemo } from "react";
import { useField } from "react-final-form";
import { shallowEqual } from "swash/utils/shallowEqual";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import {
  Select,
  SelectDatePicker,
  SelectDatePickerCaret,
  SelectDatePickerClearButton,
  SelectDatePickerDisclosure,
  SelectDatePickerPlaceholder,
  SelectDatePickerPopover,
  SelectDatePickerValue,
  formatRange,
  parseRange,
  useSelectDatePickerState,
} from "@/components/controls/SelectDatePicker";
import { FieldError } from "@/components/fields/FieldError";

export { formatRange, parseRange };

type Preset = {
  label: string;
  from: DurationInputArg1;
  to: DurationInputArg1;
  unit?: DurationInputArg2;
};
type GeneratedPreset = {
  label: string;
  value: { from: Date; to: Date };
  id: number;
};
type Children =
  | string
  | JSX.Element
  | JSX.Element[]
  | ((props: unknown) => JSX.Element);
type Scale = "sm" | "base" | "lg";
type Select = {
  state: Record<string, unknown> & {
    value: GeneratedPreset["value"];
    change: (value: GeneratedPreset["value"] | null) => any;
  };
};
type Field = ReturnType<typeof useField>;

const getPresetValue = ({ from, to, unit = "days" }: Preset) => {
  const dateFrom = from ? moment().add(from, unit).toDate() : new Date();
  const dateTo = to === undefined ? dateFrom : moment().add(to, unit).toDate();

  return formatRange({ from: dateFrom, to: dateTo });
};

const renderElement = (element: Children, props: any) => {
  if (typeof element === "function") {
    return element(props);
  }

  return element;
};

export const generatePresets = (presets: Preset[]) =>
  presets.map((preset, index) => ({
    label: preset["label"],
    value: getPresetValue(preset),
    id: index,
  }));

export const formatDate = (value?: { gte: Date; lte: Date }) => {
  if (!value) return null;
  return formatRange({
    from: value.gte,
    to: value.lte,
  });
};
export const parseDate = (value?: { from: Date; to: Date }) => {
  if (!value) return null;
  const range = parseRange(value);
  return {
    gte: range?.from,
    lte: range?.to,
  };
};

export interface SelectDateFiltersProps {
  select: Select;
  field: Field;
  dataTestHidden?: boolean;
  scale?: Scale;
  disclosureButtonElement?: Children;
  disclosureContentElement?: Children;
  placeholder?: string;
  required?: boolean;
  presets: GeneratedPreset[];
}

export const SelectDateFilters = ({
  select,
  dataTestHidden,
  scale,
  disclosureButtonElement,
  disclosureContentElement,
  placeholder,
  required,
  presets,
  field,
}: SelectDateFiltersProps) => {
  return (
    <Select data-test-hidden={dataTestHidden}>
      {/* @ts-expect-error use a js file */}
      <SelectDatePickerDisclosure {...select} scale={scale}>
        {disclosureButtonElement ? (
          renderElement(disclosureButtonElement, { select })
        ) : (
          <>
            {/* @ts-expect-error use a js file */}
            <SelectDatePickerPlaceholder {...select}>
              {placeholder}
            </SelectDatePickerPlaceholder>
            {/* @ts-expect-error use a js file */}
            <SelectDatePickerValue {...select} />
            {/* @ts-expect-error use a js file */}
            <SelectDatePickerCaret {...select} />
            {/* @ts-expect-error use a js file */}
            {!required && <SelectDatePickerClearButton {...select} />}
          </>
        )}
      </SelectDatePickerDisclosure>
      {/* @ts-expect-error use a js file */}
      <SelectDatePickerPopover {...select}>
        {disclosureContentElement ? (
          renderElement(disclosureContentElement, { select, presets })
        ) : (
          <DatePicker select={select} presets={presets} />
        )}
      </SelectDatePickerPopover>
      {/* @ts-expect-error use a js file */}
      <FieldError state={{ field }} ml={2} />
    </Select>
  );
};

interface UseDateFiltersField {
  name: string;
  label: string;
  initialValue?: Record<string, unknown>;
  format?: (value: any) => any;
  parse?: (value: any) => any;
  required?: boolean;
  modifiers?: any;
  defaultMonth?: Date;
}

export const useDateFiltersField = ({
  name,
  label,
  initialValue: initialValueProp,
  format = (value) => {
    if (!value) return null;
    return formatRange({
      from: value.gte,
      to: value.lte,
    });
  },
  parse = (value) => {
    if (!value) return null;
    const range = parseRange(value);
    return {
      gte: range?.from,
      lte: range?.to,
    };
  },
  required = false,
  modifiers,
  defaultMonth,
}: UseDateFiltersField) => {
  const isEqual = shallowEqual;
  const field = useField(name, { isEqual, format, parse });
  const initialValue = useMemo(
    () => format(initialValueProp),
    [format, initialValueProp],
  );
  const select = useSelectDatePickerState({
    ...field.input,
    initialValue,
    range: true,
    label,
    isEqual,
    clearable: !required,
    defaultActiveId: null,
    modifiers,
    defaultMonth,
  });
  return { field, select };
};

export interface DateFiltersFieldProps
  extends Omit<SelectDateFiltersProps, "select" | "field">,
    UseDateFiltersField {
  "data-test-hidden"?: boolean;
}

export const DateFiltersField = ({
  name,
  label,
  initialValue,
  format,
  parse,
  required,
  modifiers,
  defaultMonth,
  dataTestHidden,
  scale,
  disclosureButtonElement,
  disclosureContentElement,
  placeholder = label,
  presets = [],
}: DateFiltersFieldProps) => {
  const { field, select } = useDateFiltersField({
    name,
    label,
    initialValue,
    format,
    parse,
    required,
    modifiers,
    defaultMonth,
  });

  return (
    <SelectDateFilters
      select={select}
      dataTestHidden={dataTestHidden}
      scale={scale}
      disclosureButtonElement={disclosureButtonElement}
      disclosureContentElement={disclosureContentElement}
      placeholder={placeholder}
      required={required}
      presets={presets}
      field={field}
    />
  );
};

type SelectPeriodsProps = {
  presets: GeneratedPreset[];
  value: Select["state"]["value"];
  onChange: Select["state"]["change"];
};
const SelectPeriods = ({ presets, value, onChange }: SelectPeriodsProps) => {
  const enumSelect = useEnumSelectState({
    value: presets.find((preset) => shallowEqual(preset.value, value)) || null,
    onChange: (value) => {
      onChange(value ? value.value : null);
    },
    items: presets,
    title: "Périodes prédéfinies",
    required: true,
    labelSelector: (preset) => preset.label,
    valueSelector: (preset) => preset.id.toString(),
    emptyMessage: "Pas de périodes prédéfinies",
  });

  return (
    <div className="w-56">
      <EnumSelect
        state={enumSelect}
        placeholder="Période prédéfinie..."
        scale="md"
      />
    </div>
  );
};

export const DatePicker = ({
  select,
  presets,
  children,
}: {
  select: Select;
  presets: GeneratedPreset[];
  children?: React.ReactNode;
}) => {
  const childrenFilters = React.Children.toArray(children);
  return (
    <div className="flex">
      <div className="flex flex-col items-center gap-1 p-4">
        {/* @ts-expect-error use a js file */}
        <SelectDatePicker {...select} />
        <SelectPeriods
          presets={presets}
          value={select.state.value}
          onChange={select.state.change}
        />
      </div>
      {childrenFilters.length ? (
        <>
          <hr
            aria-orientation="vertical"
            className="h-auto border-0 border-l border-grey-border-light"
          />
          <div className="flex flex-col gap-4 p-4">{children}</div>
        </>
      ) : null}
    </div>
  );
};
