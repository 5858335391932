import { gql } from "@apollo/client";
import { Caption, IoCheckmark } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { SimpleInfiniteScroll } from "@/components/InfiniteScroll";
import { createListSet } from "@/components/List";
import { DateCell } from "@/containers/article/cells/DateCell";
import { DescriptionCell } from "@/containers/article/cells/DescriptionCell";
import { HourCell } from "@/containers/article/cells/HourCell";
import { StateCell } from "@/containers/article/cells/StateCell";
import { getArticleStatusColor } from "@/containers/article/util/status";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { ArticleRow as Article } from "@/containers/routes/search/resources/articles/ArticleRow";

export const CaptionCell = ({ article, resource }) => {
  const articleMedia = article.medias?.nodes.find(
    (media) => media.image.id === resource.id,
  );
  const caption = articleMedia?.metadata?.caption;

  return (
    <div className="flex justify-center text-grey-500">
      {caption && (
        <Tooltip
          tooltip={<div className="max-w-sm">{caption}</div>}
          placement="top-end"
        >
          <div display="inline-flex">
            <Caption />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export const FeaturedMediaCell = ({ article, resource }) => {
  const featured = article.medias?.nodes.some(
    (media) => media.image.id === resource.id && media.featured,
  );

  return (
    <div className="flex justify-center text-grey-500">
      {featured && <IoCheckmark />}
    </div>
  );
};

export const name = "Articles";

const getColumns = (type) => {
  return {
    date: {
      props: { col: "auto", w: 110 },
      label: "Date de publication",
      Cell: DateCell,
      disabled: true,
    },
    hour: {
      props: { col: "auto", w: 80 },
      label: "Heure",
      Cell: HourCell,
      disabled: true,
    },
    description: {
      props: { col: true },
      label: "Description",
      Cell: DescriptionCell,
    },
    state: { props: { col: "auto", w: 80 }, label: "État", Cell: StateCell },
    ...(type === "Image" && {
      caption: {
        props: { col: "auto", w: 72 },
        label: "Légende",
        Cell: CaptionCell,
      },
    }),
    ...(type === "Image" && {
      featuredMedia: {
        props: { col: "auto", w: 72 },
        label: "Média d’appel",
        Cell: FeaturedMediaCell,
      },
    }),
  };
};

const Set = createListSet();

const ArticleRow = ({ article, resource }) => {
  const statusColor = getArticleStatusColor(article);
  const columns = getColumns(resource.__typename);

  return (
    <Set.ListRow row borderLeft={4} borderColor={statusColor}>
      {Object.entries(columns).map(([key, values]) => (
        <Set.ListCell
          key={key}
          colId={key}
          aria-label={values.label}
          data-disabled={values.disabled}
        >
          <values.Cell article={article} resource={resource} />
        </Set.ListCell>
      ))}
    </Set.ListRow>
  );
};

export const List = ({ nodes, resource, ...props }) => {
  const columns = getColumns(resource.__typename);

  return (
    <div>
      <Set.Provider options={{ columns }}>
        <Set.List display="block">
          <Set.ListHeader className="pl-1" row>
            {Object.entries(columns).map(([key, values]) => (
              <Set.ListHeaderCell key={key} colId={key}>
                {values.label}
              </Set.ListHeaderCell>
            ))}
          </Set.ListHeader>
          <Set.ListBody display="block" border={1} borderColor="grey-lighter">
            {nodes.map((node) => (
              <ArticleRow key={node.id} article={node} resource={resource} />
            ))}
          </Set.ListBody>
        </Set.List>
      </Set.Provider>
      <SimpleInfiniteScroll {...props} />
    </div>
  );
};

export const UsedInArticleFragment = gql`
  fragment UsedInArticleFragment on Article {
    id
    medias {
      nodes {
        id
        featured
        metadata
        image: media {
          id
        }
      }
    }
    ...DateCell_article
    ...HourCell_article
    ...ArticleRow_article
  }

  ${DateCell.fragments.article}
  ${HourCell.fragments.article}
  ${Article.fragments.article}
`;
