import { gql } from "@apollo/client";

export const CustomFieldValueFragment = gql`
  fragment CustomFieldValueFragment on CustomFieldValue {
    customFieldId
    name
    type
    rank
    value {
      text
      richText {
        id
        type
        text
        metadata
      }
      date
      image
      file
      article
      boolean
    }
  }
`;

export const CustomFieldFragment = gql`
  fragment CustomFieldFragment on CustomField {
    id
    globalId
    type
    label
    slug
    config {
      ... on TextCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
        appearance
        allowedValues {
          label
          value
        }
        textInputType
        thirdPartyScript
        default
      }
      ... on RichTextCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on DateCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on ImageCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on FileCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
      }
      ... on ArticleCustomFieldConfig {
        label
        hint
        placeholder
        required
        multi
        limit
      }
      ... on BooleanCustomFieldConfig {
        label
        hint
        placeholder
        checkedDefault
      }
    }
  }
`;
