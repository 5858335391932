import { gql, useSubscription } from "@apollo/client";
import { useCallback, useState } from "react";
import { PageLoader } from "swash/Loader";
import { Tab, TabPanel, TabSelectList, useTabState } from "swash/Tab";

import { useSafeQuery } from "@/containers/Apollo";
import { useArticleCommentResolving } from "@/containers/article/panels/comments/ArticleComment";
import {
  ArticleCommentDeleteDialog,
  ArticleCommentFormFragment,
} from "@/containers/article/panels/comments/ArticleCommentForm";
import { ArticleCommentList } from "@/containers/article/panels/comments/ArticleCommentList";
import { CommentScopeProvider } from "@/containers/article/panels/comments/CommentScopeProvider";

const ArticleCommentsArticleFragment = gql`
  fragment ArticleComments_article on Article {
    globalId
    comments(where: { resolved: false }) {
      nodes {
        id
        anchorText
        resolved
        ...ArticleCommentForm_commentThread
      }
      totalCount
    }
    resolvedComments: comments(where: { resolved: true }) {
      nodes {
        id
        anchorText
        resolved
        resolvedAt
        resolvedBy {
          id
          firstNameInitials
          lastName
        }
        ...ArticleCommentForm_commentThread
      }
      totalCount
    }
  }
  ${ArticleCommentFormFragment}
`;

const ArticleSubscription = gql`
  subscription ArticleCommentsPanel_articleUpdated($articleId: Int!) {
    articleUpdated(where: { id: { eq: $articleId } }) {
      id
      ...ArticleComments_article
    }
  }
  ${ArticleCommentsArticleFragment}
`;

export const ArticleCommentsArticleQuery = gql`
  query ArticleComments_article($articleId: Int!) {
    article(id: $articleId) {
      id
      ...ArticleComments_article
    }
  }
  ${ArticleCommentsArticleFragment}
`;

const ArticleCommentsContainer = ({ article, queryOptions }) => {
  const resolvedComments = article.resolvedComments.nodes;

  const [selectedId, setSelectedId] = useState();
  const isCommentResolved = useArticleCommentResolving({
    resolvedComments,
    setSelectedId,
  });
  const tab = useTabState({
    selectedId,
    setSelectedId,
    defaultSelectedId: isCommentResolved ? "resolved" : "opened",
  });

  const [deletingComment, setDeletingComment] = useState(null);
  const handleCommentDeleteClose = useCallback(() => {
    setDeletingComment(null);
  }, []);

  return (
    <>
      <TabSelectList
        state={tab}
        aria-label="Filtrer les commentaires"
        className="px-4 pb-2"
      >
        <Tab state={tab} id="opened" label="Commentaires ouverts">
          Ouverts
        </Tab>
        <Tab state={tab} id="resolved" label="Commentaires résolus">
          Résolus
        </Tab>
      </TabSelectList>
      <TabPanel
        state={tab}
        tabId="opened"
        nude
        className="flex h-full min-h-0 flex-col"
      >
        <ArticleCommentList
          comments={article.comments.nodes}
          article={article}
          queryOptions={queryOptions}
          setDeletingComment={setDeletingComment}
        />
      </TabPanel>
      <TabPanel
        state={tab}
        tabId="resolved"
        nude
        className="flex h-full min-h-0 flex-col"
      >
        <ArticleCommentList
          comments={article.resolvedComments.nodes}
          article={article}
          queryOptions={queryOptions}
          setDeletingComment={setDeletingComment}
          resolved
        />
      </TabPanel>
      <ArticleCommentDeleteDialog
        commentId={deletingComment?.id}
        parentId={deletingComment?.parentId}
        queryOptions={queryOptions}
        onClose={handleCommentDeleteClose}
      />
    </>
  );
};

export const ArticleComments = ({ articleId }) => {
  const queryOptions = {
    query: ArticleCommentsArticleQuery,
    variables: { articleId },
  };
  const { data } = useSafeQuery(queryOptions.query, {
    variables: queryOptions.variables,
    skip: !articleId,
  });

  useSubscription(ArticleSubscription, {
    variables: {
      articleId,
    },
    skip: !data,
    onData: ({ client, data: { data } }) => {
      if (!data) return;
      const queryData = client.readQuery(queryOptions);
      if (!queryData) return;
      client.writeQuery({
        ...queryOptions,
        data: {
          ...queryData,
          article: {
            ...queryData.article,
            ...data.articleUpdated,
          },
        },
      });
    },
  });

  if (!data) {
    return <PageLoader />;
  }

  return (
    <CommentScopeProvider scope="text">
      <ArticleCommentsContainer
        article={data.article}
        queryOptions={queryOptions}
      />
    </CommentScopeProvider>
  );
};
