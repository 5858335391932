import clsx from "clsx";
import * as React from "react";

import type { As, Options } from "./types";

export type CardBodyProps = {
  className?: string;
  children: React.ReactNode;
};

export const CardBody = ({ children, className }: CardBodyProps) => {
  return <div className={clsx(className, "p-4")}>{children}</div>;
};

export type CardFooterProps = {
  className?: string;
  children: React.ReactNode;
};

export const CardFooter = ({ children, className }: CardFooterProps) => {
  return <div className={clsx(className, "border-t p-4")}>{children}</div>;
};

export type CardProps<T extends As = "div"> = Options<T> & {
  active?: boolean;
  className?: string;
  children: React.ReactNode;
  role?: string;
};

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, role, active, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          className,
          "rounded-md shadow-card",
          "border bg-white transition",
          role === "button" && "cursor-pointer hover:border-primary-border",
          active && "border-primary-border",
        )}
        role={role}
        {...props}
      >
        {children}
      </div>
    );
  },
);

if (process.env["NODE_ENV"] !== "production") {
  Card.displayName = "Card";
}
