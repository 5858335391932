import { gql } from "@apollo/client";

import { FieldsetField } from "@/components/fields/Fieldset";

import { useFormPeriodical, useFormPeriodicalSection } from "../util";
import {
  PeriodicalField,
  PeriodicalFieldPeriodicalFragment,
} from "./Periodical";
import {
  PeriodicalEditionDateField,
  PeriodicalEditionDateFieldPeriodicalSectionFragment,
} from "./PeriodicalEditionDate";
import {
  PeriodicalSectionField,
  PeriodicalSectionFieldPeriodicalSectionFragment,
} from "./PeriodicalSection";
import {
  PeriodicalSectionLayoutField,
  PeriodicalSectionLayoutFieldPeriodicalSectionFragment,
} from "./PeriodicalSectionLayout";

export const PrintFieldsQueryFragment = gql`
  fragment PrintFieldsQueryFragment on Periodical {
    id
    enabled
    exportDisabled
    editionType
    ...PeriodicalFieldPeriodicalFragment
    periodicity {
      weekDays
    }
    sections {
      nodes {
        id
        path
        ...PeriodicalSectionFieldPeriodicalSectionFragment
        ...PeriodicalEditionDateFieldPeriodicalSectionFragment
        ...PeriodicalSectionLayoutFieldPeriodicalSectionFragment
      }
    }
    releases {
      nodes {
        id
        label
        releaseDate
        enabled
        sections {
          id
        }
      }
    }
  }

  ${PeriodicalFieldPeriodicalFragment}
  ${PeriodicalSectionFieldPeriodicalSectionFragment}
  ${PeriodicalEditionDateFieldPeriodicalSectionFragment}
  ${PeriodicalSectionLayoutFieldPeriodicalSectionFragment}
`;

export function PrintFields({ data, disabled, required, selectableDisabled }) {
  const periodicals = data?.periodicals ?? null;
  const periodical = useFormPeriodical({ periodicals });
  const periodicalSection = useFormPeriodicalSection({ periodicals });

  return (
    <>
      <FieldsetField>
        <PeriodicalField
          required={required}
          disabled={disabled}
          periodicals={periodicals}
          selectableDisabled={selectableDisabled}
        />
      </FieldsetField>
      <PeriodicalSectionField
        required={required}
        disabled={disabled}
        periodical={periodical}
      >
        {({ children }) => <FieldsetField>{children}</FieldsetField>}
      </PeriodicalSectionField>
      <PeriodicalEditionDateField
        required={required}
        disabled={disabled}
        periodicals={periodicals}
        periodical={periodical}
        periodicalSection={periodicalSection}
        selectableDisabled={selectableDisabled}
      >
        {({ children }) => <FieldsetField>{children}</FieldsetField>}
      </PeriodicalEditionDateField>
      <PeriodicalSectionLayoutField
        required={required}
        disabled={disabled}
        periodicalSection={periodicalSection}
      >
        {({ children }) => <FieldsetField>{children}</FieldsetField>}
      </PeriodicalSectionLayoutField>
    </>
  );
}
