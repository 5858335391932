import { forwardRef } from "react";

const SvgImageAlignCenter = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M5.914 8.015c0 .281.107.538.322.769.215.215.472.323.77.323s.554-.108.769-.323c.215-.214.323-.471.323-.769a1.08 1.08 0 0 0-.323-.794c-.215-.199-.471-.298-.769-.298s-.555.099-.77.298a1.08 1.08 0 0 0-.322.794zm-.918 5.484V6.004h10v4.789l-1.885-1.91c-.298-.248-.58-.373-.845-.373a.97.97 0 0 0-.52.174c-.133.05-.257.133-.373.249l-.05.075-.348.372c-.711.81-1.199 1.348-1.464 1.613l.099.074c.249.133.522.397.819.794.298.381.455.671.472.869.016.166-.083.174-.298.025-.447-.298-1.142-.654-2.084-1.067l-.298-.1-.1-.05-.074-.024zm-.993 1.067a.41.41 0 0 0 .124.298.44.44 0 0 0 .323.124h11.117a.562.562 0 0 0 .322-.1.433.433 0 0 0 .124-.322V5.459a.49.49 0 0 0-.124-.322c-.066-.083-.174-.124-.322-.124H4.45c-.133 0-.232.041-.298.124-.1.066-.149.165-.149.297zM1 .993h17.99V0H1zm0 2.034V2.01h17.991v.992zM1 17.99h17.99v-.968H1zM1 20h17.99v-1.017H1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgImageAlignCenter);
export default ForwardRef;
