import styled from "@xstyled/styled-components";
import { useEffect, useRef, useState } from "react";
import {
  Giphy,
  IoCodeSlashOutline,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTiktok,
} from "swash/Icon";
import { Link } from "swash/Link";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";
import { useResizeObserver } from "swash/utils/useResizeObserver";

import Close from "@/assets/imgs/inline-svg/close.svg";
import { EditorBlockCapsuleToolbarActions } from "@/components/teleporters/EditorBlockCapsule";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeImage,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";
import { SnippetNodeEditSnippet } from "./SnippetNodeEditSnippet";
import { SnippetNodeFragments } from "./SnippetNodeFragments";

const SnippetContainer = styled.div`
  [data-change="deleted"] & {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-deleted-media;
      opacity: 0.25;
    }
    &:after {
      content: "";
      mask-image: url(${Close});
      position: absolute;
      inset: 0 0 0 0;
      background-color: white;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 48px;
    }
  }

  [data-change="added"] & {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-added-media;
      opacity: 0.25;
    }
  }
`;

const MAX_IFRAME_HEIGHT = 1000;

const useReloadWhenChange = (iframeRef, code) => {
  const previousCode = usePrevious(code);
  useEffect(() => {
    if (previousCode !== undefined && previousCode !== code) {
      iframeRef.current.contentWindow.location.reload();
    }
  }, [iframeRef, code, previousCode]);
};

const useBubbleMouseMove = (iframeRef) => {
  useEffect(() => {
    const iframe = iframeRef.current;
    const { contentWindow } = iframe;

    contentWindow.addEventListener("DOMContentLoaded", () => {
      const handleMouseMove = () => {
        const event = new CustomEvent("mousemove", {
          bubbles: true,
          cancelable: false,
        });

        iframe.dispatchEvent(event);
      };
      contentWindow.addEventListener("mousemove", handleMouseMove);
      return () => {
        contentWindow.removeEventListener("mousemove", handleMouseMove);
      };
    });
  }, [iframeRef]);
};

const useAutoResizeIframe = ({ maxHeight }) => {
  const [height, setHeight] = useState(null);
  const resizeIframe = useResizeObserver((entry) => {
    setHeight(Math.min(entry.contentRect.height, maxHeight));
  });
  const resizeIframeRef = useLiveRef(resizeIframe);
  const [ready, setReady] = useState(false);

  const onLoad = useEventCallback((event) => {
    if (event.currentTarget.contentDocument) {
      resizeIframeRef.current = resizeIframe(
        event.currentTarget.contentDocument.body,
      );
      setReady(true);
    }
  });

  useEffect(() => {
    if (resizeIframeRef.current && ready) {
      return resizeIframeRef.current;
    }
  }, [ready, resizeIframeRef]);

  return [onLoad, height];
};

const SnippetNodeExpanded = ({ snippet }) => {
  const iframeRef = useRef();
  useReloadWhenChange(iframeRef, snippet.code);
  useBubbleMouseMove(iframeRef);
  const [onLoad, height] = useAutoResizeIframe({
    maxHeight: MAX_IFRAME_HEIGHT,
  });

  return (
    <SnippetContainer>
      <iframe
        title={snippet.title}
        scrolling="no"
        ref={iframeRef}
        src={`/api/snippets/${snippet.id}/preview`}
        sandbox="allow-scripts allow-same-origin allow-popups"
        onLoad={onLoad}
        className="mt-4 w-full overflow-hidden"
        style={{ height }}
        data-iframe-snippet
      />
    </SnippetContainer>
  );
};

export const SnippetNode = ({
  snippet,
  tooltip,
  expanded,
  editable,
  onEdit,
}) => {
  const Icon = (() => {
    switch (snippet.providerName) {
      case "instagram":
        return IoLogoInstagram;
      case "tiktok":
        return IoLogoTiktok;
      case "giphy":
        return Giphy;
      case "facebook":
        return IoLogoFacebook;
      case "default":
      default:
        return IoCodeSlashOutline;
    }
  })();

  return (
    <>
      {editable && (
        <EditorBlockCapsuleToolbarActions>
          <SnippetNodeEditSnippet snippet={snippet} onEdit={onEdit} />
        </EditorBlockCapsuleToolbarActions>
      )}
      {!expanded ? (
        <EditorNodeCard>
          <EditorNodeLayout>
            <EditorNodeTooltip tooltip={tooltip}>
              <EditorNodeIcon>
                <Icon />
              </EditorNodeIcon>
            </EditorNodeTooltip>
            {snippet.screenshotUrl && !expanded && (
              <EditorNodeImage src={snippet.screenshotUrl} objectFit="cover" />
            )}
            <EditorNodeBody multiline={Boolean(snippet.screenshotUrl)}>
              <ChangeText title={snippet.snippetTitle}>
                {snippet.url ? (
                  <Link href={snippet.url} target="_blank">
                    {snippet.snippetTitle}
                  </Link>
                ) : (
                  snippet.snippetTitle
                )}
              </ChangeText>
            </EditorNodeBody>
          </EditorNodeLayout>
        </EditorNodeCard>
      ) : (
        <SnippetNodeExpanded snippet={snippet} />
      )}
    </>
  );
};

SnippetNode.fragments = SnippetNodeFragments;
