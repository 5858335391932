/* eslint-disable react/no-array-index-key */
import { x } from "@xstyled/styled-components";

import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormError } from "@/components/forms/FormError";
import { FormSubmittingPrompt } from "@/components/forms/FormPrompt";
import { HeaderToolbarItem } from "@/components/teleporters/HeaderToolbar";

import { SnippetCodeEditor } from "./SnippetCodeEditor";

function FormToolbarItem() {
  return (
    <HeaderToolbarItem>
      <x.div
        row
        alignItems="center"
        justifyContent="space-between"
        m={-2}
        order={1}
      >
        <x.div col="auto" p={2}>
          <FormError />
        </x.div>
      </x.div>
    </HeaderToolbarItem>
  );
}

export const SnippetForm = ({
  initialValues,
  onSubmit,
  snippet,
  editor,
  operations,
}) => {
  return (
    <Form
      collaborative
      initialValues={initialValues}
      onSubmit={onSubmit}
      style={{ height: "100%" }}
    >
      <FormAutoSubmit />
      <FormToolbarItem />
      <FormSubmittingPrompt />
      <SnippetCodeEditor
        editor={editor}
        snippet={snippet}
        operations={operations}
      />
    </Form>
  );
};
