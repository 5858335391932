import { gql } from "@apollo/client";

import {
  ArticleSuggestedMediasEdit,
  ArticleSuggestedMediasEditProps,
} from "@/containers/article/ArticleSuggestedMediasEdit";
import type { CellType } from "@/containers/list/ListState";

export type MediasCellArticle = ArticleSuggestedMediasEditProps["article"];

export const mediasCell: CellType<MediasCellArticle> = {
  key: "medias",
  title: "Médias",
  width: 80,
  cellPadding: "0.25rem 0",
  render: ({ node }) => <ArticleSuggestedMediasEdit article={node} />,
  fragment: gql`
    fragment mediasCell_article on Article {
      ...ArticleSuggestedMediasEdit_article
    }

    ${ArticleSuggestedMediasEdit.fragments.article}
  `,
};
