import { gql, useSubscription } from "@apollo/client";
import clsx from "clsx";
import moment from "moment-timezone";
import { Tooltip } from "swash/Tooltip";

import { useSafeQuery } from "@/containers/Apollo";
import {
  ExposureIcon,
  PublicationTooltipContent,
  TooltipContent,
} from "@/containers/ExposureIcon";
import { PrintCoreExposure } from "@/containers/article/PrintCoreExposure";

const ArticleExposurePlannedDatetimeSlotQuery = gql`
  query ExposureIndicator_ArticleExposure($gid: ID!) {
    articleExposure(gid: $gid) {
      gid
      plannedDatetimeSlots {
        startBound
        endBound
      }
    }
  }
`;

const ArticleExposurePlannedDatetimeSubscription = gql`
  subscription ExposureIndicator_ArticleExposureUpdated(
    $where: ExposureArticleExposureWhere!
  ) {
    articleExposureUpdated(where: $where) {
      gid
      plannedDatetimeSlots {
        startBound
        endBound
      }
    }
  }
`;

function SimpleExposureTooltip({ articleExposure, exposure }) {
  const tooltip = ["newsletter", "periodical"].includes(exposure.type) ? (
    <PublicationTooltipContent
      articleExposure={articleExposure}
      exposure={exposure}
    />
  ) : (
    <TooltipContent articleExposure={articleExposure} exposure={exposure} />
  );

  return (
    <Tooltip tooltip={tooltip}>
      <div className="flex">
        <ExposureIcon
          exposure={exposure}
          className={clsx(!articleExposure.fulfilled && "opacity-disabled")}
        />
      </div>
    </Tooltip>
  );
}

function HometagExposureTooltipContent({ articleExposure, exposure }) {
  const { data } = useSafeQuery(ArticleExposurePlannedDatetimeSlotQuery, {
    variables: {
      gid: articleExposure.gid,
    },
  });

  useSubscription(ArticleExposurePlannedDatetimeSubscription, {
    variables: {
      where: {
        exposureId: { eq: exposure.id },
      },
    },
  });

  if (!data) return null;

  const { plannedDatetimeSlots } = data.articleExposure;

  if (!plannedDatetimeSlots) {
    return (
      <SimpleExposureTooltip
        articleExposure={articleExposure}
        exposure={exposure}
      />
    );
  }

  return (
    <>
      <div>
        <strong>État :</strong>
        <div>
          Suggéré pour exposition <br />
          le {moment(plannedDatetimeSlots.startBound).format("DD/MM/YYYY")}{" "}
          entre{" "}
          <strong>{moment(plannedDatetimeSlots.startBound).hour()}h</strong> et{" "}
          <strong>{moment(plannedDatetimeSlots.endBound).hour()}h</strong>
        </div>
      </div>
      {articleExposure.fulfilledAt ? (
        <div className="mt-4">
          <strong>États précédents :</strong>
          <div>
            Exposé le{" "}
            {moment(articleExposure.fulfilledAt).format(
              "DD/MM/YYYY à HH:mm:ss",
            )}
          </div>
        </div>
      ) : null}
    </>
  );
}

function TagExposureTooltip({ exposure, articleExposure }) {
  return (
    <Tooltip
      tooltip={
        <HometagExposureTooltipContent
          articleExposure={articleExposure}
          exposure={exposure}
        />
      }
    >
      <div className="flex">
        <ExposureIcon
          exposure={exposure}
          className={clsx(!articleExposure.fulfilled && "opacity-disabled")}
        />
      </div>
    </Tooltip>
  );
}

export const ExposureIndicator = ({ articleExposure, exposure, article }) => {
  if (!articleExposure.suggested && !articleExposure.fulfilled) return null;
  if (exposure.type === "print") {
    return <PrintCoreExposure article={article} />;
  }

  if (exposure.type === "tag" && articleExposure.gid) {
    return (
      <TagExposureTooltip
        articleExposure={articleExposure}
        exposure={exposure}
      />
    );
  }

  return (
    <SimpleExposureTooltip
      articleExposure={articleExposure}
      exposure={exposure}
    />
  );
};

ExposureIndicator.fragments = {
  articleExposure: gql`
    fragment ExposureIndicator_articleExposure on ArticleExposure {
      gid
      exposureId
      suggested
      fulfilled
      fulfilledAt
      publicationDate
    }
  `,
  exposure: gql`
    fragment ExposureIndicator_exposure on Exposure {
      id
      label
      ...ExposureIcon_exposure
    }

    ${ExposureIcon.fragments.exposure}
  `,
  article: gql`
    fragment ExposureIndicator_article on Article {
      id
      ...PrintCoreExposure_article
    }

    ${PrintCoreExposure.fragments.article}
  `,
};
