import { getIn, setIn } from "final-form";
import { forwardRef, useEffect, useState } from "react";
import { useForm } from "react-final-form";
import { useLiveRef } from "swash/utils/useLiveRef";

import {
  ActiveProvider,
  useActiveState,
} from "@/components/forms/CollaborativeFormState";

import { BaseForm } from "./BaseForm";

const InitialValuesInjector = ({ initialValues, readOnly }) => {
  const form = useForm();
  const activeState = useActiveState();
  const refs = useLiveRef({ activeState, readOnly });

  // When `initialValues` changes, inject values in form
  // only for non-active fields
  useEffect(() => {
    const { activeState, readOnly } = refs.current;

    // If form is in readOnly mode, do not block any incoming update
    if (readOnly) {
      form.initialize(initialValues);
      return;
    }

    // If form is active, then block any incoming update
    if (activeState.form) return;
    const { active, values } = form.getState();
    const activeFields = active
      ? [...activeState.fields, active]
      : activeState.fields;
    const resultValues = activeFields.reduce((initialValues, field) => {
      return setIn({ ...initialValues }, field, getIn(values, field));
    }, initialValues);
    form.initialize(resultValues);
  }, [form, initialValues, refs]);

  return null;
};

export const CollaborativeForm = forwardRef(
  ({ name, initialValues, onSubmit, readOnly, children, ...props }, ref) => {
    const [initialInitialValues] = useState(initialValues);
    return (
      <BaseForm
        ref={ref}
        name={name}
        onSubmit={onSubmit}
        initialValues={initialInitialValues}
        {...props}
      >
        {(options) => {
          return (
            <ActiveProvider>
              <InitialValuesInjector
                initialValues={initialValues}
                readOnly={readOnly}
              />
              {children(options)}
            </ActiveProvider>
          );
        }}
      </BaseForm>
    );
  },
);
