import { gql } from "@apollo/client";
import { x } from "@xstyled/styled-components";
import moment from "moment";
import { forwardRef } from "react";

import { LetterIcon } from "@/components/LetterIcon";
import { NamedIcon } from "@/components/NamedIcon";
import { Time } from "@/components/Time";

function TooltipTitle({ articleExposure, exposure }) {
  const parts = [];
  if (exposure.label) {
    parts.push(exposure.label);
  }

  if (articleExposure.publicationDate) {
    parts.push(moment(articleExposure.publicationDate).format("DD/MM/YY"));
  }

  return parts.length > 0 ? (
    <div>
      <span className="font-bold">{parts.join(", ")}</span>
      {articleExposure.suggested ? "(suggéré)" : null}
    </div>
  ) : null;
}

function TooltipBody({ articleExposure }) {
  const parts = [];

  if (articleExposure.fulfilled) {
    parts.push(
      <li key="fulfilled" className="!list-outside">
        <div className="inline-flex flex-col">
          <div className="font-semibold">Sélectionné</div>
          <div>
            {articleExposure.fulfilledAt ? (
              <>
                le{" "}
                <Time
                  date={articleExposure.fulfilledAt}
                  format="DD/MM/YYYY à HH:mm:ss"
                />
              </>
            ) : null}
          </div>
        </div>
      </li>,
    );
  }

  return (
    <div className="ml-5 flex flex-col gap-2">
      <ul className="flex">{parts}</ul>
    </div>
  );
}

export function TooltipContent({ articleExposure, exposure }) {
  const parts = [];
  if (articleExposure.suggested) parts.push("suggéré");
  if (articleExposure.fulfilled) {
    if (articleExposure.fulfilledAt)
      parts.push(
        `exposé le ${moment(articleExposure.fulfilledAt).format(
          "DD/MM/YYYY à HH:mm:ss",
        )}`,
      );
    else parts.push("exposé");
  }

  return (
    <>
      {[exposure.label, parts.length ? `(${parts.join(" et ")})` : null]
        .filter(Boolean)
        .join(" ")}
    </>
  );
}

export function PublicationTooltipContent({ articleExposure, exposure }) {
  return (
    <div className="flex flex-col gap-2">
      <TooltipTitle articleExposure={articleExposure} exposure={exposure} />
      <TooltipBody articleExposure={articleExposure} />
    </div>
  );
}

export const ExposureIcon = forwardRef(({ exposure, ...props }, ref) => {
  if (exposure.icon) {
    return (
      <NamedIcon
        ref={ref}
        name={exposure.type === "print" ? "PaperCopy" : exposure.icon}
        aria-label={exposure.label}
        {...props}
      />
    );
  }
  if (exposure.acronym) {
    return (
      <x.svg as={LetterIcon} ref={ref} aria-label={exposure.label} {...props}>
        {exposure.acronym}
      </x.svg>
    );
  }
  return null;
});

ExposureIcon.fragments = {
  exposure: gql`
    fragment ExposureIcon_exposure on Exposure {
      id
      label
      icon
      acronym
      enabled
      type
    }
  `,
};
