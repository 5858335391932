import { gql } from "@apollo/client";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { EnumField, StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

export { default as logo } from "./logo.svg";

export const IntegrationConfigFragment = gql`
  fragment ForecastIntegrationConfigFragment on ForecastIntegrationConfig {
    baseUrl
    apiKey
    lang
  }
`;

function Fields() {
  return (
    <Fieldset>
      <FieldsetField>
        <StringField name="baseUrl" label="Base URL" required />
      </FieldsetField>
      <FieldsetField>
        <StringField name="apiKey" label="API Key" required />
      </FieldsetField>
      <FieldsetField>
        <EnumField
          name="lang"
          enum={{ fr: "fr", en: "en" }}
          label="Langue"
          appearance="checkbox"
          required
        />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
