import { forwardRef } from "react";

const SvgEnterKey = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M2 11.898 7.722 7.24v3.494h7.949V4H18v9.037H7.748v3.519z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgEnterKey);
export default ForwardRef;
