// eslint-disable-next-line import/named
import { gql } from "@apollo/client";
import { memo } from "react";
import { Chip } from "swash/Chip";
import { Tooltip } from "swash/Tooltip";
import { SproutOutline } from "swash/icons";

export const EVERGREEN_TITLE = "Evergreen";

export type EvergreenAlertProps = {
  evergreen: { score: number };
};

export const EvergreenAlert = memo<EvergreenAlertProps>((props) => {
  return (
    <Tooltip
      aria-label="Evergreen"
      placement="top"
      tooltip={
        <>
          <div>
            <strong>Article intemporel à fort potentiel</strong>
          </div>
          Score : {`${(props.evergreen.score * 100).toFixed()}/100`}
        </>
      }
    >
      <Chip tint="#E0F9EA" scale="sm">
        <SproutOutline />
        {EVERGREEN_TITLE}
      </Chip>
    </Tooltip>
  );
});

export type ArticleEvergreenAlertProps = {
  article: {
    evergreen: { score: number } | null;
  };
};

export const ArticleEvergreenAlert = (props: ArticleEvergreenAlertProps) => {
  if (!props.article.evergreen) {
    return null;
  }
  return <EvergreenAlert evergreen={props.article.evergreen} />;
};

ArticleEvergreenAlert.fragments = {
  article: gql`
    fragment ArticleEvergreenAlert_article on Article {
      evergreen {
        score
      }
    }
  `,
};
