import React, { forwardRef, useLayoutEffect, useRef, useState } from "react";
import { mergeRefs } from "react-merge-refs";

export type TruncateProps = React.ComponentProps<"div">;

/**
 * A component that will dynamically clamp the text to the available space.
 */
export const Truncate = forwardRef<HTMLDivElement, TruncateProps>(
  (props, ref) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [lineClamp, setLineClamp] = useState<number | null>(null);

    useLayoutEffect(() => {
      if (!textRef.current) return;

      const availableSpace = textRef.current.offsetHeight;
      const span = document.createElement("span");
      span.innerHTML = "A";
      textRef.current.appendChild(span);
      span.style.display = "flex";
      const lineHeight = span.offsetHeight;
      textRef.current.removeChild(span);
      const lineCount = Math.floor(availableSpace / lineHeight);

      setLineClamp(lineCount);
    }, [textRef]);

    return (
      <div
        ref={mergeRefs([textRef, ref])}
        style={{
          height: "fit-content",
          overflow: "hidden",
          ...(lineClamp && {
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: lineClamp,
          }),
        }}
        {...props}
      />
    );
  },
);
