import { forwardRef } from "react";

const SvgLayout = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M13.792 18.062h3.436V6.273h-3.436zM2.815 5.269V2.114h14.414v3.137zm-.036 12.793h10.168V6.273H2.779zM1.758 19.26h16.529V.757H1.758zM1 20h18.044V0H1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLayout);
export default ForwardRef;
