import { gql, useSubscription } from "@apollo/client";

import { useSafeQuery } from "@/containers/Apollo";

const ServiceSubscription = gql`
  subscription ServiceSubscription($id: Int!) {
    integrationUpdated(where: { id: { eq: $id } }) {
      id
      latestCheck {
        status
      }
    }
  }
`;

const ServiceQuery = gql`
  query ServiceQuery($serviceKey: ThirdPartyServiceKey!) {
    thirdPartyService(key: $serviceKey) {
      integrations {
        id
        latestCheck {
          status
        }
      }
    }
  }
`;

export const useIntegrationHealthCheck = (serviceKey) => {
  const { data, loading } = useSafeQuery(ServiceQuery, {
    variables: { serviceKey },
  });
  const integration = data?.thirdPartyService?.integrations?.[0];

  useSubscription(ServiceSubscription, {
    variables: { id: integration?.id },
    skip: !integration?.id,
  });
  const latestCheck = integration?.latestCheck;

  return {
    isHealthy: latestCheck ? latestCheck.status === "success" : true,
    loading,
  };
};
