import { gql } from "@apollo/client";

export const SnippetNodeFragments = {
  snippet: gql`
    fragment SnippetNode_snippet on Snippet {
      providerName
      snippetTitle: title
      url
      screenshotUrl
      code
    }
  `,
};
