import { createContext, useContext, useMemo, useState } from "react";

const LockUpdatingContext = createContext();

export const LockUpdatingProvider = ({ children }) => {
  const [lockUpdating, setLockUpdating] = useState(false);
  const value = useMemo(
    () => ({ lockUpdating, setLockUpdating }),
    [lockUpdating],
  );
  return (
    <LockUpdatingContext.Provider value={value}>
      {children}
    </LockUpdatingContext.Provider>
  );
};

export const useLockUpdating = () => {
  return useContext(LockUpdatingContext);
};
