import { gql } from "@apollo/client";

import { useDragUrl } from "@/containers/Dnd";
import { SnippetNode } from "@/containers/editor/nodes/SnippetNode";
import { SnippetNodeFragments } from "@/containers/editor/nodes/SnippetNodeFragments";

import { MediaLayout } from "./MediaLayout";
import { getMediaEventTooltip } from "./util";

export function MediaSnippet({
  media: { media: snippet, ...mediaProps },
  draggable,
  onDelete,
  deletable,
  expanded,
  editable,
  ...props
}) {
  const [, dragRef] = useDragUrl(
    () => new URL(`/snippets/${snippet.id}`, window.location.origin).href,
  );

  return (
    <MediaLayout
      draggable={draggable}
      ref={draggable ? dragRef : null}
      deletable={deletable}
      onDelete={() => onDelete(snippet.id)}
      expanded={expanded}
      {...props}
    >
      <SnippetNode
        snippet={snippet}
        expanded={expanded}
        editable={editable}
        tooltip={getMediaEventTooltip(mediaProps)}
      />
    </MediaLayout>
  );
}

MediaSnippet.fragments = {
  media: gql`
    fragment MediaSnippet_media on Media {
      id
      ...SnippetNode_snippet
    }
    ${SnippetNodeFragments.snippet}
  `,
};
