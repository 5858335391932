import { forwardRef } from "react";

const SvgCheckCircle = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M4.119 10.096c0 .064.032.121.096.173l4.214 4.214a.234.234 0 0 0 .191.095.303.303 0 0 0 .191-.076l6.954-6.954a.309.309 0 0 0 .077-.191.26.26 0 0 0-.077-.192l-1.743-1.724c-.064-.064-.127-.096-.191-.096a.257.257 0 0 0-.191.077L8.812 10.25a.24.24 0 0 1-.172.077.26.26 0 0 1-.192-.077L6.341 8.162a.256.256 0 0 0-.191-.077.315.315 0 0 0-.192.057L4.215 9.885a.444.444 0 0 0-.095.211zM0 10c0-1.749.428-3.397 1.284-4.942a10.9 10.9 0 0 1 1.648-2.146 9.14 9.14 0 0 1 2.126-1.628A10.043 10.043 0 0 1 10 0c1.75 0 3.397.428 4.943 1.284a9.912 9.912 0 0 1 2.126 1.628 9.762 9.762 0 0 1 2.606 4.54C19.892 8.27 20 9.119 20 10c0 1.75-.428 3.397-1.284 4.943a9.603 9.603 0 0 1-1.647 2.126A9.929 9.929 0 0 1 10 20c-1.749 0-3.397-.428-4.942-1.284a10.354 10.354 0 0 1-2.146-1.628 9.243 9.243 0 0 1-1.609-2.146A9.925 9.925 0 0 1 0 10z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCheckCircle);
export default ForwardRef;
