import clsx from "clsx";
import React, { forwardRef } from "react";

export type FormCheckboxProps = React.ComponentProps<"div">;

export const FormCheckbox = forwardRef<HTMLDivElement, FormCheckboxProps>(
  (props, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          "pl-6 leading-none [&>input[type=checkbox]]:float-left [&>input[type=checkbox]]:-ml-6 [&>input[type=checkbox]]:mt-1 [&:has(input[type=checkbox]:disabled)]:opacity-disabled [&>input[type=checkbox]:disabled]:opacity-100",
        )}
        {...props}
      />
    );
  },
);
