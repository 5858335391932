import { x } from "@xstyled/styled-components";
import { memo, useEffect, useMemo } from "react";
import { EnumSelect, useEnumSelectState } from "swash/v2/EnumSelect";

import { setBlockTypeAndStyle } from "../modifiers/setBlockTypeAndStyle";

export const name = "block-templates-control";

const BlockTemplatesSelector = memo(
  ({
    blockTemplates,
    blockStyleName,
    disabled,
    unlockFocus,
    lockFocus,
    setEditorState,
  }) => {
    const value = useMemo(
      () => blockTemplates.find((tpl) => tpl.name === blockStyleName) ?? null,
      [blockStyleName, blockTemplates],
    );

    const select = useEnumSelectState({
      items: blockTemplates ?? [],
      value,
      onChange: (blockTemplate) => {
        if (!blockTemplate) {
          setEditorState((editorState) =>
            setBlockTypeAndStyle(editorState, "paragraph"),
          );
          return;
        }

        setEditorState((editorState) =>
          setBlockTypeAndStyle(
            editorState,
            blockTemplate.editorType,
            blockTemplate.name ?? null,
          ),
        );
      },
      labelSelector: (tpl) => tpl.title,
      valueSelector: (tpl) => tpl.id,
    });

    const open = select.select.useState("open");

    useEffect(() => {
      if (!open) {
        unlockFocus();
      } else {
        lockFocus();
      }
    }, [unlockFocus, lockFocus, open]);

    if (!blockTemplates.length) return null;

    return (
      <>
        <EnumSelect
          state={select}
          disabled={disabled}
          placeholder="Styles"
          className="w-32"
          aria-label="Styles"
          scale="sm"
        />
      </>
    );
  },
);

export const BlockControls = ({
  lockFocus,
  unlockFocus,
  hasFocus,
  editorState,
  setEditorState,
  blockTemplates,
  plugins,
  readOnly,
}) => {
  const block = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey());

  const hasPlugin = useMemo(
    () => plugins.some((plugin) => plugin.name === "block-templates"),
    [plugins],
  );
  const enabledBlockTemplates = useMemo(
    () => blockTemplates.filter((tpl) => tpl.enabled),
    [blockTemplates],
  );

  return (
    <x.div display="flex" alignItems="center">
      <BlockTemplatesSelector
        blockTemplates={enabledBlockTemplates}
        blockStyleName={block?.getData().get("styleName") ?? null}
        disabled={!hasFocus || !hasPlugin || readOnly}
        lockFocus={lockFocus}
        unlockFocus={unlockFocus}
        setEditorState={setEditorState}
      />
    </x.div>
  );
};

BlockControls.group = "block-style";
BlockControls.width = 120;

export const checkIsControlVisible = ({ blockTemplates = [] }) =>
  blockTemplates.length > 0;
