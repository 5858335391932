import { memo } from "react";

import { getCellStyle } from "../List";
import {
  CellType,
  NodeProvider,
  NodeProviderComponent,
  NodeRowType,
  RunnableInteraction,
} from "../ListState";
import { ListCell, ListRow } from "../NudeList";

type NodeRowProps<TNode> = {
  row: NodeRowType<TNode>;
  cells: CellType<TNode>[];
  interactions: RunnableInteraction<TNode>[];
  provider: NodeProvider<TNode> | null;
  onActiveChange: (node: TNode) => void;
};

const NodeCell = <TNode,>({
  cell,
  node,
}: {
  cell: CellType<TNode>;
  node: NodeRowType<TNode>["node"];
}) => {
  const visible = cell.useIsVisible ? cell.useIsVisible() : true;
  if (!visible) return null;
  return (
    <ListCell key={cell.key} style={getCellStyle(cell)}>
      {cell.render({ node })}
    </ListCell>
  );
};

const NodeRowWrapped = <TNode,>(props: NodeRowProps<TNode>) => {
  const { row } = props;

  return (
    <NodeProviderComponent node={row.node} provider={props.provider}>
      <ListRow
        first={row.first}
        last={row.last}
        active={row.active}
        onActive={() => props.onActiveChange(row.node)}
        hidden={row.hidden}
      >
        {props.interactions.map((effect) => {
          const Component = effect.component;
          return <Component key={effect.key} node={row.node} />;
        })}
        {props.cells.map((cell) => {
          return <NodeCell key={cell.key} cell={cell} node={row.node} />;
        })}
      </ListRow>
    </NodeProviderComponent>
  );
};

export const NodeRow = memo(NodeRowWrapped) as typeof NodeRowWrapped;

export const estimateNodeRowSize = () => {
  return 67;
};
