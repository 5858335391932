import { x } from "@xstyled/styled-components";
import { Button as AriakitButton } from "ariakit/button";
import { memo, useLayoutEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { IoCloudDone, IoCloudOffline, IoCloudUpload } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { usePrevious } from "swash/utils/usePrevious";

import { Time } from "@/components/Time";

import { useFormAutoSubmitState } from "./FormAutoSubmit";

const useRecentSaved = (saving) => {
  const [recentSaved, setRecentSaved] = useState(saving);
  const previousSaving = usePrevious(saving);
  const shouldMarkAsRecentSaved = previousSaving === true && saving === false;
  useLayoutEffect(() => {
    if (shouldMarkAsRecentSaved) {
      setRecentSaved(true);
    }
  }, [shouldMarkAsRecentSaved]);
  useLayoutEffect(() => {
    const timeout = setTimeout(() => setRecentSaved(false), 2000);
    return () => clearTimeout(timeout);
  }, [recentSaved]);
  return recentSaved;
};

export const ControlledFormSavingIndicator = memo(
  ({ date, saving, user, ...props }) => {
    const form = useForm();
    const { valid } = useFormState({ subscription: { valid: true } });
    const recentSaved = useRecentSaved(saving);
    const invalid = !valid;

    const tooltip = invalid ? (
      "Le formulaire est invalide et ne peut pas être sauvegardé."
    ) : saving ? (
      "Enregistrement en cours..."
    ) : (
      <>
        Enregistré {date && <Time date={date} />}{" "}
        {user && `par ${user.firstNameInitials} ${user.lastName}`}
      </>
    );

    return (
      <x.div
        as={invalid ? AriakitButton : undefined}
        color="on-light"
        bg="transparent"
        fontFamily="base"
        border={0}
        display="flex"
        p={0}
        onClick={
          invalid
            ? () => {
                form.submit();
              }
            : null
        }
        {...props}
      >
        <Tooltip tooltip={tooltip}>
          <x.span
            fontSize="sm"
            lineHeight="16px"
            transition="base"
            role="status"
            aria-busy={!invalid && saving ? "true" : null}
            aria-label={
              invalid
                ? "Le formulaire est invalide et ne peut pas être sauvegardé."
                : saving
                  ? "Enregistrement en cours..."
                  : "Enregistré"
            }
          >
            {invalid ? (
              <x.svg
                display="inline-block"
                as={IoCloudOffline}
                verticalAlign="-15%"
              />
            ) : saving ? (
              <>
                <x.svg
                  display="inline-block"
                  as={IoCloudUpload}
                  verticalAlign="-15%"
                  mr={1}
                />
                Enregistrement...
              </>
            ) : (
              <>
                <x.svg
                  display="inline-block"
                  as={IoCloudDone}
                  verticalAlign="-15%"
                />
                {recentSaved && (
                  <x.span ml={1} data-test-hidden="no-display">
                    Enregistré
                  </x.span>
                )}
              </>
            )}
          </x.span>
        </Tooltip>
      </x.div>
    );
  },
);

export const FormSavingIndicator = memo((props) => {
  const { submitting } = useFormAutoSubmitState();
  return <ControlledFormSavingIndicator saving={submitting} {...props} />;
});
