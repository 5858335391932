import { forwardRef } from "react";

const SvgCaretRight = (props, ref) => (
  <svg
    height="1em"
    viewBox="0 0 20 20"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      d="M7.001 17.744V2.279c0-.133.037-.221.111-.266.073-.03.162-.007.265.067L14.91 9.7c.089.075.134.17.134.289a.427.427 0 0 1-.134.31l-7.511 7.622c-.103.074-.192.096-.265.067-.089-.03-.134-.111-.134-.244z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgCaretRight);
export default ForwardRef;
