import styled, { x } from "@xstyled/styled-components";
import axios from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFormState } from "react-final-form";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import { Alert } from "swash/Alert";
import { Anchor } from "swash/Anchor";
import { Button } from "swash/Button";
import { Card } from "swash/Card";
import { Link } from "swash/Link";
import { ThemeInitializer } from "swash/Theme";
import { ToastProvider, useToaster } from "swash/Toast";
import { Textbox } from "swash/controls/Textbox";

import { SiriusLogo } from "@/components";
import { TwoFactorField } from "@/components/fields/TwoFactorField";
import { FORM_ERROR, Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { NotFound } from "@/containers/NotFound";
import { StringField } from "@/containers/admin/CRUD";
import { TwoFactorAuthenticatorSteps } from "@/containers/routes/profile/security/TwoFactor/TwoFactorAuthenticatorSteps";
import { TwoFactorPhoneNumberSteps } from "@/containers/routes/profile/security/TwoFactor/TwoFactorPhoneNumberSteps";

const getErrorMessage = (errorCode, extra = {}) => {
  if (!errorCode) return null;

  switch (errorCode) {
    case "login:invalidEmail":
      return "Ce compte n’est pas lié à Sirius.";
    case "login:userDisabled":
      return "Votre compte est désactivé, veuillez contacter votre administrateur Sirius.";
    case "login:invalidCode":
      return "Le code renseigné est invalide.";
    case "api:rateLimitReached":
      return `Trop de tentatives. Veuillez attendre ${extra.limitReset}s avant de réessayer.`;
    default:
      return "Une erreur est survenue. Veuillez réessayer.";
  }
};

function getErrorExtra(error) {
  const extra = {};
  if (error?.response.status === 429) {
    extra.limitReset = error.response.headers["x-ratelimit-reset"];
  }
  return extra;
}

const LoginButton = (props) => <Button className="w-full" {...props} />;

const Separator = () => (
  <x.div display="flex" py={5} alignItems="center" justifyContent="center">
    <x.div flex="1" borderBottom={1} borderBottomColor="layout-border" />
    <x.div px={2} fontFamily="accent" color="on-light">
      ou
    </x.div>
    <x.div flex="1" borderBottom={1} borderBottomColor="layout-border" />
  </x.div>
);

const UnsecuredLoginForm = ({ redirect }) => {
  const [email, setEmail] = useState("cms@lemonde.fr");

  return (
    <form action="/unsecured-login" method="post">
      <input type="hidden" value={redirect || ""} name="redirect" />
      <x.div mb={3}>
        <Textbox
          scale="lg"
          type="email"
          name="email"
          value={email}
          placeholder="Adresse e-mail"
          onChange={(event) => setEmail(event.currentTarget.value)}
          required
        />
      </x.div>
      <LoginButton type="submit" variant="success">
        Go 🤘
      </LoginButton>
    </form>
  );
};

const LoginByEmailFormBody = ({ email }) => {
  const { submitting, submitError, submitSucceeded } = useFormState({
    subscription: {
      submitting: true,
      submitError: true,
      submitSucceeded: true,
    },
  });

  return (
    <>
      {submitSucceeded ? (
        <Alert level="success" className="mb-4">
          Nous avons envoyé un lien de connexion à <strong>{email}</strong> si
          l’e-mail est rattaché à un compte.
        </Alert>
      ) : null}
      {submitError ? (
        <Alert level="danger" className="mb-4">
          {submitError}
        </Alert>
      ) : null}
      <div className="mb-4">
        <StringField
          name="email"
          type="email"
          aria-label="Adresse e-mail"
          placeholder="Entrer votre adresse e-mail"
          disabled={submitting}
          autoFocus
        />
      </div>
      <LoginButton type="submit" disabled={submitting}>
        Recevoir un lien par e-mail
      </LoginButton>
    </>
  );
};

const LoginByEmail = ({ redirect }) => {
  const [email, setEmail] = useState(null);

  return (
    <Form
      initialValues={{ email }}
      onSubmit={async ({ email }) => {
        if (!email) return { email: "Requis" };
        try {
          const { data } = await axios.post("/ask-passwordless-token", {
            email,
            redirect,
          });
          setEmail(data.email ?? null);
        } catch (error) {
          if (error.response?.data?.error?.code) {
            return {
              [FORM_ERROR]: getErrorMessage(error.response.data.error.code),
            };
          }
          throw error;
        }
      }}
    >
      <LoginByEmailFormBody email={email} />
    </Form>
  );
};

const LoginWithGoogleButton = ({ redirect }) => {
  const url = new URL("/auth/google", window.location.origin);
  if (redirect) {
    url.searchParams.append("redirect", redirect);
  }
  return (
    <LoginButton asChild>
      <Anchor href={url.href} target="_self">
        Continuer avec Google
      </Anchor>
    </LoginButton>
  );
};

const LoginMethods = () => {
  const { search } = window.location;
  const { redirect, error } = useMemo(() => {
    const params = new URLSearchParams(search);
    const redirect = params.get("f");
    const error = params.get("error");
    return { redirect, error };
  }, [search]);

  const errorMessage = getErrorMessage(error);

  return (
    <>
      {errorMessage ? (
        <Alert level="danger" className="mb-4">
          {errorMessage}
        </Alert>
      ) : null}
      {process.env.UNSECURED_LOGIN ? (
        <>
          <UnsecuredLoginForm redirect={redirect} />
          <Separator />
        </>
      ) : null}
      <LoginWithGoogleButton redirect={redirect} />
      <Separator />
      <LoginByEmail redirect={redirect} />
    </>
  );
};

const AsteriskPhoneNumber = styled.span`
  color: grey;
  font-size: xs;
  font-weight: 300;
`;

function PhoneNumber({ value }) {
  return (
    <x.div fontWeight="600" display="inline-flex" alignItems="center">
      {value.split("").map((char, index) => {
        if (char === "✱") {
          return <AsteriskPhoneNumber key={index}>{char}</AsteriskPhoneNumber>;
        }
        return (
          <x.span whiteSpace="pre" key={index}>
            {char}
          </x.span>
        );
      })}
    </x.div>
  );
}

const TwoFactorFormBody = ({ method }) => {
  const fieldRef = useRef();
  const {
    dirtySinceLastSubmit,
    submitting,
    submitError,
    submitErrors,
    submitSucceeded,
  } = useFormState({
    subscription: {
      values: true,
      dirtySinceLastSubmit: true,
      submitting: true,
      submitError: true,
      submitErrors: true,
      submitSucceeded: true,
    },
  });

  useEffect(() => {
    fieldRef.current.focus();
  }, [method]);

  return (
    <>
      {submitErrors && !dirtySinceLastSubmit ? (
        <Alert level="danger">{submitError || submitErrors.code}</Alert>
      ) : null}
      <x.div mb={3}>
        <TwoFactorField
          ref={fieldRef}
          name="code"
          disabled={submitting || submitSucceeded}
          valid={submitSucceeded}
        />
      </x.div>
    </>
  );
};

async function sendOtpCode(params) {
  try {
    await axios.post("/otp-code", params);
  } catch (error) {
    const extra = getErrorExtra(error);
    const errorCode =
      error?.response?.data?.error?.code || error?.response?.data?.error;
    throw new Error(getErrorMessage(errorCode, extra));
  }
}

function getCodeFormError(error) {
  if (error?.response?.data?.error?.code) {
    const extra = getErrorExtra(error);
    return {
      [FORM_ERROR]: getErrorMessage(error?.response?.data?.error.code, extra),
    };
  }

  if (error?.response?.data?.error) {
    return {
      code: getErrorMessage(error?.response?.data?.error),
    };
  }

  return null;
}

function TwoFactorFormDescription({ method, phoneNumber }) {
  return method === "app" ? (
    <>
      Afin de vérifier votre appareil, renseignez le code à usage unique généré
      dans votre application.
    </>
  ) : (
    <>
      Afin de vérifier votre appareil, renseignez le code à usage unique envoyé
      par SMS au <PhoneNumber value={phoneNumber || ""} />.
    </>
  );
}

function ToggleMethodButton({ method, onClick }) {
  return (
    <Link className="text-sm" onClick={onClick}>
      {method === "app"
        ? "Recevoir mon code par SMS"
        : "Vérifier l’appareil avec une application"}
    </Link>
  );
}

function TwoFactorForm() {
  const location = useLocation();
  const toaster = useToaster();
  const urlSearchParams = new URLSearchParams(location.search);
  const redirect = urlSearchParams.get("f");
  const default2faMethod = urlSearchParams.get("m");
  const phoneNumber = urlSearchParams.get("tel");

  const [state, setState] = useState({
    method: default2faMethod,
    codeSent: false,
  });

  async function sendCode() {
    try {
      await sendOtpCode();
      toaster.success("Un code vient de vous être renvoyé");
    } catch (error) {
      toaster.warning(error?.message);
    }
  }

  function toggleMethod() {
    const newMethod = state.method === "app" ? "sms" : "app";
    const newState = { ...state, method: newMethod };
    if (newMethod === "sms" && !state.codeSent) {
      sendCode();
      newState.codeSent = true;
    }
    setState(newState);
  }

  return (
    <div>
      <Title>Vérification de l’appareil</Title>
      <div>
        <>
          <x.div mb={2}>
            <TwoFactorFormDescription
              method={state.method}
              phoneNumber={phoneNumber}
            />
          </x.div>
          <Form
            initialValues={{ code: "" }}
            onSubmit={async ({ code }) => {
              try {
                await axios.post("/otp-login", {
                  code,
                  redirect,
                  twoFactorMethod: state.method,
                });
                setTimeout(() => {
                  window.location.href = redirect || "/";
                }, 800);
              } catch (error) {
                const codeFormError = getCodeFormError(error);
                if (codeFormError) return codeFormError;

                throw error;
              }
            }}
          >
            <FormAutoSubmit />
            <TwoFactorFormBody method={state.method} />
          </Form>
        </>
      </div>
      <x.div
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={3}
      >
        {state.method === "sms" ? (
          <Button scale="sm" appearance="text" onClick={() => sendCode()}>
            Renvoyer le code
          </Button>
        ) : null}
        {default2faMethod === "app" && phoneNumber ? (
          <ToggleMethodButton method={state.method} onClick={toggleMethod} />
        ) : null}
      </x.div>
    </div>
  );
}

function TwoFactorActivationForm() {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);
  const redirect = urlSearchParams.get("f");
  const [activationFormState, setActivationFormState] = useState("intro");

  function finish() {
    window.location.href = redirect ? redirect : "/";
  }

  return (
    <>
      {activationFormState === "intro" ? (
        <x.div borderTop={1} borderTopColor="layout-border">
          <x.div pt={4} pb={3} fontFamily="accent" color="dusk-lighter">
            L’administrateur de l’espace Sirius requiert l’authentification en
            deux étapes pour tous les utilisateurs.
          </x.div>
          <x.div pb={4} fontFamily="accent" color="dusk-lightest">
            Une fois activée, lors de vos prochaines connexions, Sirius pourra
            vous demander de renseigner un code à usage unique.
          </x.div>
          <x.div
            pt={3}
            borderTop={1}
            borderTopColor="layout-border"
            display="flex"
            flexDirection="column"
            alignItems="end"
          >
            <Button type="button" onClick={() => setActivationFormState("sms")}>
              Activer l’authentification en deux étapes
            </Button>
          </x.div>
        </x.div>
      ) : null}
      {activationFormState === "sms" ? (
        <TwoFactorPhoneNumberSteps
          visible
          updating={false}
          phoneNumber=""
          finish={finish}
          showAuthenticatorDialog={() => setActivationFormState("app")}
          sendPhoneNumberValidation={async (phoneNumber) => {
            try {
              await sendOtpCode({ phoneNumber });
              return {};
            } catch (error) {
              return { error: { [FORM_ERROR]: error.message } };
            }
          }}
          validatePhoneNumber={async ({ phoneNumber, code }) => {
            try {
              await axios.post("/otp-enable", {
                code,
                phoneNumber,
              });
            } catch (error) {
              const codeFormError = getCodeFormError(error);
              return { error: codeFormError || error };
            }
          }}
        />
      ) : null}
      {activationFormState === "app" ? (
        <TwoFactorAuthenticatorSteps
          visible
          finish={finish}
          showPhoneNumberDialog={() => setActivationFormState("sms")}
          generateAuthenticatorURL={async () => {
            try {
              const { data } = await axios.post("/authenticator-generate-url");
              return data;
            } catch (error) {
              const codeFormError = getCodeFormError(error);
              return { error: codeFormError || error };
            }
          }}
          validateAuthenticator={async ({ code }) => {
            try {
              await axios.post("/authenticator-enable", {
                code,
              });
            } catch (error) {
              const codeFormError = getCodeFormError(error);
              return { error: codeFormError || error };
            }
          }}
        />
      ) : null}
    </>
  );
}

const Title = styled.h1`
  font-family: accent;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 4;
  text-align: center;
`;

const InnerLoginContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: "";
    display: block;
    flex-grow: 1;
    height: 24px;
  }
`;

function LoginContainer({ children }) {
  const location = useLocation();
  const wideCard = location.pathname.includes("enable-2fa");

  return (
    <InnerLoginContainer>
      <x.div w={wideCard ? 648 : 360}>{children}</x.div>
    </InnerLoginContainer>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ToastProvider>
        <LoginContainer>
          <Card className="p-6">
            <div className="mb-4 flex justify-center">
              <SiriusLogo width="64" height="64" />
            </div>
            <Outlet />
          </Card>
        </LoginContainer>
      </ToastProvider>
    ),
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <LoginContainer />,
      },
      { path: "/login/enable-2fa/*", element: <TwoFactorActivationForm /> },
      { path: "/login/2fa/*", element: <TwoFactorForm /> },
      {
        path: "/*",
        element: (
          <>
            <Title>Connexion à Sirius</Title>
            <LoginMethods />
          </>
        ),
      },
    ],
  },
]);

const Login = () => {
  return (
    <ThemeInitializer>
      <RouterProvider router={router} />
    </ThemeInitializer>
  );
};

export default Login;
